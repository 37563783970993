import { displayHoursMinutes } from './localSettings';
import round from './round';

const padWithZero = (str, len = 2, right = false) => {
    return str.length < len ? (right ? str+'0' : '0'+str) : str;
}

const formatHoursWithMinutes = (floatDuration) => {
    const duration = round(floatDuration, 2);
    const negative = duration < 0 || Object.is(duration, -0);
    const absDuration = Math.abs(duration);
    if(displayHoursMinutes() === true) {
        let hours = Math.floor(absDuration);
        let minutes = Math.round((absDuration-hours) * 60);
        if(minutes === 60) {
            hours = hours+1;
            minutes = 0;
        }
        return `${negative ? '-' : ''}${padWithZero(hours.toString(), 2, false)}:${padWithZero(minutes.toString(), 2, false)}`;
    } else {
        return `${negative ? '-' : ''}${padWithZero(absDuration.toFixed(2).toString(), 5, false)}`;
    }
};

export {formatHoursWithMinutes};