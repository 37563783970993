import ls from 'local-storage';
import { ABS_FORMULA_RAW, HSUP_FORMULA_RAW, PAUSE_FORMULA_RAW } from './formulas';

const checkSettingsIntegrity = () => {
    try {
        return JSON.parse(ls('settings')) || {};
    } catch {
        ls('settings', '{}');
        return {};
    }
};

const isModuleVisibleInMenu = (module) => {
    const settings = checkSettingsIntegrity();
    if(settings[module] && settings[module].enabled !== undefined && settings[module].enabled !== null) {
        return settings[module].enabled;
    } else {
        if(module === "covid") {
            return false;
        } else {
            return true;
        }
    }
}

const DEFAULT_MANUAL_COUNTER_NAMES = {
    abs: 'Absences',
    hsupp: 'H. supp.',
    pause: 'Décompte Pause',
    custom: 'Formule personnalisée'
};

const isSubmoduleVisible = (module, submodule) => {
    const settings = checkSettingsIntegrity();
    return settings[module] === undefined || (settings[module] && (settings[module][submodule] === true || settings[module][submodule] === undefined));
}

const hourEndByDay = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['hourEndByDay'] !== undefined && settings['global']['hourEndByDay'] !== null && settings['global']['hourEndByDay'] >= 0 && settings['global']['hourEndByDay'] <= 23 ? settings['global']['hourEndByDay'] : 0;
}

const timezone = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['timezone'] !== undefined && settings['global']['timezone'] !== null && settings['global']['timezone'].length > 0 ? settings['global']['timezone'] : 'Europe/Paris';
}

const displayHoursMinutes = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['displayHoursMinutes'] !== undefined && settings['global']['displayHoursMinutes'] !== null ? settings['global']['displayHoursMinutes'] : true;
}

const nightShiftBegin = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['nightShiftBegin'] !== undefined && settings['global']['nightShiftBegin'] !== null && settings['global']['nightShiftBegin'] >= 0 && settings['global']['nightShiftBegin'] <= 23 ? settings['global']['nightShiftBegin'] : 21;
}

const nightShiftEnd = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['nightShiftEnd'] !== undefined && settings['global']['nightShiftEnd'] !== null && settings['global']['nightShiftEnd'] >= 0 && settings['global']['nightShiftEnd'] <= 23 ? settings['global']['nightShiftEnd'] : 6;
}

const nightShiftBeginMinute = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['nightShiftBeginMinute'] !== undefined && settings['global']['nightShiftBeginMinute'] !== null && settings['global']['nightShiftBeginMinute'] >= 0 && settings['global']['nightShiftBeginMinute'] <= 59 ? settings['global']['nightShiftBeginMinute'] : 0;
}

const nightShiftEndMinute = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['nightShiftEndMinute'] !== undefined && settings['global']['nightShiftEndMinute'] !== null && settings['global']['nightShiftEndMinute'] >= 0 && settings['global']['nightShiftEndMinute'] <= 59 ? settings['global']['nightShiftEndMinute'] : 0;
}

const manualCounterEnabled = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['manualCounterEnabled'] !== undefined && settings['global']['manualCounterEnabled'] !== null ? settings['global']['manualCounterEnabled'] : false;
}

const manualCounterFactor = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['manualCounterFactor'] !== undefined && settings['global']['manualCounterFactor'] !== null ? settings['global']['manualCounterFactor'] : 1;
}

const manualCounterName = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['manualCounterName'] !== undefined && settings['global']['manualCounterName'] !== null ? settings['global']['manualCounterName'] : DEFAULT_MANUAL_COUNTER_NAMES.abs;
}

const manualCounterFormula = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['manualCounterFormula'] !== undefined && settings['global']['manualCounterFormula'] !== null ? settings['global']['manualCounterFormula'] : ABS_FORMULA_RAW;
}

const secondManualCounterEnabled = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['secondManualCounterEnabled'] !== undefined && settings['global']['secondManualCounterEnabled'] !== null ? settings['global']['secondManualCounterEnabled'] : false;
}

const secondManualCounterFactor = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['secondManualCounterFactor'] !== undefined && settings['global']['secondManualCounterFactor'] !== null ? settings['global']['secondManualCounterFactor'] : -1;
}

const secondManualCounterName = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['secondManualCounterName'] !== undefined && settings['global']['secondManualCounterName'] !== null ? settings['global']['secondManualCounterName'] : DEFAULT_MANUAL_COUNTER_NAMES.hsupp;
}

const secondManualCounterFormula = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['secondManualCounterFormula'] !== undefined && settings['global']['secondManualCounterFormula'] !== null ? settings['global']['secondManualCounterFormula'] : HSUP_FORMULA_RAW;
}

const thirdManualCounterEnabled = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['thirdManualCounterEnabled'] !== undefined && settings['global']['thirdManualCounterEnabled'] !== null ? settings['global']['thirdManualCounterEnabled'] : false;
}

const thirdManualCounterFactor = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['thirdManualCounterFactor'] !== undefined && settings['global']['thirdManualCounterFactor'] !== null ? settings['global']['thirdManualCounterFactor'] : 0;
}

const thirdManualCounterName = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['thirdManualCounterName'] !== undefined && settings['global']['thirdManualCounterName'] !== null ? settings['global']['thirdManualCounterName'] : DEFAULT_MANUAL_COUNTER_NAMES.pause;
}

const thirdManualCounterFormula = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['thirdManualCounterFormula'] !== undefined && settings['global']['thirdManualCounterFormula'] !== null ? settings['global']['thirdManualCounterFormula'] : PAUSE_FORMULA_RAW;
}

const nightShiftCounterEnabled = () => {
    const settings = checkSettingsIntegrity();
    return settings['global'] !== undefined && settings['global'] !== null && settings['global']['nightShiftCounterEnabled'] !== undefined && settings['global']['nightShiftCounterEnabled'] !== null ? settings['global']['nightShiftCounterEnabled'] : false;
}

const pointagesRememberFilters = () => {
    const settings = checkSettingsIntegrity();
    return settings['pointages'] !== undefined && settings['pointages'] !== null && settings['pointages']['filters'] !== undefined && settings['pointages']['filters'] !== null ? settings['pointages']['filters'] : false;
}

const pointagesOpts = () => {
    const settings = checkSettingsIntegrity();
    return settings['pointages'] !== undefined && settings['pointages'] !== null && settings['pointages']['opts'] !== undefined && settings['pointages']['opts'] !== null ? settings['pointages']['opts'] : false;
}

export {
    isModuleVisibleInMenu,
    isSubmoduleVisible,
    hourEndByDay,
    timezone,
    displayHoursMinutes,
    nightShiftBegin,
    nightShiftEnd,
    nightShiftBeginMinute,
    nightShiftEndMinute,
    manualCounterEnabled,
    manualCounterFactor,
    manualCounterName,
    manualCounterFormula,
    secondManualCounterEnabled,
    secondManualCounterFactor,
    secondManualCounterName,
    secondManualCounterFormula,
    thirdManualCounterEnabled,
    thirdManualCounterFactor,
    thirdManualCounterName,
    thirdManualCounterFormula,
    nightShiftCounterEnabled,
    pointagesRememberFilters,
    pointagesOpts,
    DEFAULT_MANUAL_COUNTER_NAMES
}