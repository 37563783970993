import * as settings from './localSettings';

const evalFormula = (f, params) => {
    const hours = parseFloat(params.hours) || 0;
    const expected = parseFloat(params.expected) || 0;
    const nightShiftHours = parseFloat(params.night) || 0;
    const manualCounterHours = parseFloat(params.manual) || 0;
    const secondManualCounterHours = parseFloat(params.secondManual) || 0;
    const thirdManualCounterHours = parseFloat(params.thirdManual) || 0;
    const manualCounterEnabled = settings.manualCounterEnabled();
    const secondManualCounterEnabled = settings.secondManualCounterEnabled();
    const thirdManualCounterEnabled = settings.thirdManualCounterEnabled();
    const nightShiftCounterEnabled = settings.nightShiftCounterEnabled();
    const rawExpr = f.replaceAll("[reel]", hours)
                        .replaceAll("[theorique]", expected)
                        .replaceAll("[cpt1]", manualCounterHours)
                        .replaceAll("[cpt2]", secondManualCounterHours)
                        .replaceAll("[cpt3]", thirdManualCounterHours)
                        .replaceAll("[cptn]", nightShiftHours)
                        .replaceAll("[cpt1_active]", JSON.stringify(manualCounterEnabled))
                        .replaceAll("[cpt2_active]", JSON.stringify(secondManualCounterEnabled))
                        .replaceAll("[cpt3_active]", JSON.stringify(thirdManualCounterEnabled))
                        .replaceAll("[cptn_active]", JSON.stringify(nightShiftCounterEnabled));
                        // balance has no meaning here since this calculation will change it
                        //.replace("[balance]", params.balance);
    try {
        const ret = eval(`"use strict";${rawExpr}`);
        return parseFloat(ret);
    } catch(err) {
        console.log(`Invalid formula : ${f}, error ${err.message}`);
        return 0;
    }
};

const ABS_FORMULA_RAW = "([reel]-[theorique]) < 0 ? -([reel]-[theorique]) : 0";
const HSUP_FORMULA_RAW = "([reel]-[theorique]) > 0 ? ([reel]-[theorique]) : 0";
const PAUSE_FORMULA_RAW = "[reel]-0";

const ABS_FORMULA = () => ABS_FORMULA_RAW;
const HSUP_FORMULA = () => HSUP_FORMULA_RAW;
const PAUSE_FORMULA = (x) => `[reel]-${x}`; 


const ABS_FORMULA_MATCH = new RegExp(`^${ABS_FORMULA().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`, 'g');
const HSUP_FORMULA_MATCH = new RegExp(`^${HSUP_FORMULA().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`, 'g');
const PAUSE_FORMULA_MATCH = /^\[reel\]-\d+(\.\d{1,2})?$/g;

const isDefaultFormula = (defaultFormulaMatch, formula) => {
    if(formula !== undefined && formula !== null) {
        return formula.match(defaultFormulaMatch) !== null;
    } else {
        return false;
    }
};

const isDefaultAbs = (formula) => isDefaultFormula(ABS_FORMULA_MATCH, formula);
const isDefaultHSup = (formula) => isDefaultFormula(HSUP_FORMULA_MATCH, formula);
const isDefaultPause = (formula) => isDefaultFormula(PAUSE_FORMULA_MATCH, formula);

const bindFormula = (defaultFormula, customFormula, params) => {
    const formulaToEval = customFormula !== undefined && customFormula !== null && customFormula !== "" ? customFormula : defaultFormula;
    return evalFormula(formulaToEval, params);
}
const manualCounter = (params) => bindFormula(ABS_FORMULA_RAW, settings.manualCounterFormula(), params);
const secondManualCounter = (params) => bindFormula(HSUP_FORMULA_RAW, settings.secondManualCounterFormula(), params);
const thirdManualCounter = (params) => bindFormula(PAUSE_FORMULA_RAW, settings.thirdManualCounterFormula(), params);

export {manualCounter, secondManualCounter, thirdManualCounter, isDefaultAbs, isDefaultHSup, isDefaultPause, ABS_FORMULA_RAW, HSUP_FORMULA_RAW, PAUSE_FORMULA_RAW, ABS_FORMULA, HSUP_FORMULA, PAUSE_FORMULA};