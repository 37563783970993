import React from 'react';
//import './Logout.css';
import { Redirect } from "react-router-dom";

class Logout extends React.Component {
  componentDidMount() {
    this.props.logOut();
  }

  render() {
    return (
      <Redirect to="/login" />
    );
  }
}

export default Logout;
