import { displayHoursMinutes } from './localSettings';

const getHoursMinutesFromEvent = (event, allowNegative = false) => {
    let inputVal = event.target.value;
    const negative = inputVal[0] === '-';
    if(negative) {
      inputVal = inputVal.slice(1);
    }
    let cursorStart = event.target.selectionStart - (negative ? 1 : 0);
    let cursorEnd = event.target.selectionEnd - (negative ? 1 : 0);
    
    let finalValue = 0.0;
    if(displayHoursMinutes() === true) {
      const isDeleting = inputVal.length < 5;
      const isInserting = inputVal.length > 5;
      let transformedInputVal = inputVal;
      if(isInserting === true) {
        if(cursorStart === 1 && cursorEnd === 1) {
          transformedInputVal = transformedInputVal.substring(0, 1) + transformedInputVal.substring(2);
        } else if(cursorStart === 2 && cursorEnd === 2) {
          transformedInputVal = transformedInputVal.substring(0, 2) + transformedInputVal.substring(3);
          cursorStart = 3;
          cursorEnd = 3;
        } else if(cursorStart === 3 && cursorEnd === 3) {
          transformedInputVal = transformedInputVal.substring(0, 2) + transformedInputVal.substring(3);
        } else if(cursorStart === 4 && cursorEnd === 4) {
          transformedInputVal = transformedInputVal.substring(0, 4) + transformedInputVal.substring(5);
        } else if(cursorStart >= 5 && cursorEnd >= 5 && cursorStart === cursorEnd) {
          transformedInputVal = transformedInputVal.substring(0, 5);
        }
      } else if(isDeleting === true) {
        if(cursorStart === 0 && cursorEnd === 0) {
          transformedInputVal = '0'+transformedInputVal;
        } else if(cursorStart === 1 && cursorEnd === 1) {
          transformedInputVal = transformedInputVal.substring(0, 1) + '0' + transformedInputVal.substring(1);
        } else if(cursorStart === 2 && cursorEnd === 2) {
          transformedInputVal = transformedInputVal.substring(0, 2) + ':' + transformedInputVal.substring(2);
        } else if(cursorStart === 3 && cursorEnd === 3) {
          transformedInputVal = transformedInputVal.substring(0, 3) + '0' + transformedInputVal.substring(3);
          cursorStart = 2;
          cursorEnd = 2;
        } else if(cursorStart >= 4 && cursorEnd >= 4 && cursorStart === cursorEnd) {
          transformedInputVal = transformedInputVal.substring(0, 4) + '0';
        }
      }
      const split = transformedInputVal.split(':');
      const hours = parseFloat(split[0]);
      const minutes = parseFloat(split[1]);
      finalValue = hours + minutes/60.0;
    } else {
      const isDeleting = inputVal.length < 5;
      const isInserting = inputVal.length > 5;
      let transformedInputVal = inputVal;
      if(isInserting === true) {
        if(cursorStart === 1 && cursorEnd === 1) {
          transformedInputVal = transformedInputVal.substring(0, 1) + transformedInputVal.substring(2);
        } else if(cursorStart === 2 && cursorEnd === 2) {
          transformedInputVal = transformedInputVal.substring(0, 2) + transformedInputVal.substring(3);
          cursorStart = 3;
          cursorEnd = 3;
        } else if(cursorStart === 3 && cursorEnd === 3) {
          transformedInputVal = transformedInputVal.substring(0, 2) + transformedInputVal.substring(3);
        } else if(cursorStart === 4 && cursorEnd === 4) {
          transformedInputVal = transformedInputVal.substring(0, 4) + transformedInputVal.substring(5);
        } else if(cursorStart >= 5 && cursorEnd >= 5 && cursorStart === cursorEnd) {
          transformedInputVal = transformedInputVal.substring(0, 5);
        }
      } else if(isDeleting === true) {
        if(cursorStart === 0 && cursorEnd === 0) {
          transformedInputVal = '0'+transformedInputVal;
        } else if(cursorStart === 1 && cursorEnd === 1) {
          transformedInputVal = transformedInputVal.substring(0, 1) + '0' + transformedInputVal.substring(1);
        } else if(cursorStart === 2 && cursorEnd === 2) {
          transformedInputVal = transformedInputVal.substring(0, 2) + '.' + transformedInputVal.substring(2);
        } else if(cursorStart === 3 && cursorEnd === 3) {
          transformedInputVal = transformedInputVal.substring(0, 3) + '0' + transformedInputVal.substring(3);
          cursorStart = 2;
          cursorEnd = 2;
        } else if(cursorStart >= 4 && cursorEnd >= 4 && cursorStart === cursorEnd) {
          transformedInputVal = transformedInputVal.substring(0, 4) + '0';
        }
      }
      finalValue = parseFloat(transformedInputVal);
    }

    if(isNaN(finalValue)) {
        finalValue = 0.0;
        cursorStart = 0;
        cursorEnd = 0;
    } else if(finalValue > 24) {
        finalValue = 24.0;
    } else if(finalValue < 0) {
        finalValue = 0.0;
    }

    finalValue = finalValue*(negative && allowNegative ? -1 : 1);
    return {finalValue, cursorStart: cursorStart+(negative ? 1 : 0), cursorEnd: cursorEnd+(negative ? 1 : 0)};
};

const getTimeFromEvent = (event) => {
    const inputVal = event.target.value;
    let cursorStart = event.target.selectionStart;
    let cursorEnd = event.target.selectionEnd;

    const isDeleting = inputVal.length < 5;
    const isInserting = inputVal.length > 5;
    let transformedInputVal = inputVal;
    if(isInserting === true) {
        if(cursorStart === 1 && cursorEnd === 1) {
            transformedInputVal = transformedInputVal.substring(0, 1) + transformedInputVal.substring(2);
        } else if(cursorStart === 2 && cursorEnd === 2) {
            transformedInputVal = transformedInputVal.substring(0, 2) + transformedInputVal.substring(3);
            cursorStart = 3;
            cursorEnd = 3;
        } else if(cursorStart === 3 && cursorEnd === 3) {
            transformedInputVal = transformedInputVal.substring(0, 2) + transformedInputVal.substring(3);
        } else if(cursorStart === 4 && cursorEnd === 4) {
            transformedInputVal = transformedInputVal.substring(0, 4) + transformedInputVal.substring(5);
        } else if(cursorStart >= 5 && cursorEnd >= 5 && cursorStart === cursorEnd) {
            transformedInputVal = transformedInputVal.substring(0, 5);
        }
    } else if(isDeleting === true) {
        if(cursorStart === 0 && cursorEnd === 0) {
            transformedInputVal = '0'+transformedInputVal;
        } else if(cursorStart === 1 && cursorEnd === 1) {
            transformedInputVal = transformedInputVal.substring(0, 1) + '0' + transformedInputVal.substring(1);
        } else if(cursorStart === 2 && cursorEnd === 2) {
            transformedInputVal = transformedInputVal.substring(0, 2) + ':' + transformedInputVal.substring(2);
        } else if(cursorStart === 3 && cursorEnd === 3) {
            transformedInputVal = transformedInputVal.substring(0, 3) + '0' + transformedInputVal.substring(3);
            cursorStart = 2;
            cursorEnd = 2;
        } else if(cursorStart >= 4 && cursorEnd >= 4 && cursorStart === cursorEnd) {
            transformedInputVal = transformedInputVal.substring(0, 4) + '0';
        }
    }
    const split = transformedInputVal.split(':');
    let hours = parseFloat(split[0]);
    let minutes = parseFloat(split[1]);

    if(isNaN(hours)) {
        hours = 0.0;
    } else if(hours >= 24) {
        hours = 23.0;
    } else if(hours < 0) {
        hours = 0.0;
    }

    if(isNaN(minutes)) {
        minutes = 0.0;
    } else if(minutes >= 60) {
        minutes = 59.0;
    } else if(minutes < 0) {
        minutes = 0.0;
    }

    return {finalValue: {hours, minutes}, cursorStart, cursorEnd};
};

export {getHoursMinutesFromEvent, getTimeFromEvent};