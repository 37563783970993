const getOriginForPointage = (p, rpiHardware, rpiHardwareNames) => {
    const rpiHardwares = Object.keys(rpiHardware).map(hostname => {
        const customName = rpiHardwareNames.find(rhn => rhn.hostname === hostname);
        return Object.assign({}, {hostname, customName: customName !== undefined ? customName.name : undefined});
    });
    const rpiHForThisPointage = rpiHardwares.find(rhn => rhn.hostname === p.location);
    return (
    p.badgeNo === 'WEB' ?
        {display: "En ligne", raw: "WEB", location: p.location || "Inconnu"} :
        p.badgeNo === 'NA' ?
            {display: "Ajouté", raw: "NA", location: ""} :
            p.badgeNo === 'PHONE' ?
                {display: "Nomade", raw: "PHONE", location: p.location} :
                p.badgeNo === 'NIGHTSHIFT' ?
                    {display: "Coupure journée", raw: "NIGHTSHIFT", location: ""} :
                    rpiHForThisPointage !== undefined ?
                        {display: rpiHForThisPointage.customName || rpiHForThisPointage.hostname || '', raw: rpiHForThisPointage, location: rpiHForThisPointage.customName || rpiHForThisPointage.hostname || ''} :
                        {display: "Inconnue", raw: "UNKNOWN", location: ""}
    );
};

export {getOriginForPointage};