import React from 'react';
//import './Order.css';
import io from './utils/io';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  TextArea
} from "semantic-ui-react";

const MONTHLY_PRICES = {
  "RPIHARDWARE": 29.9,
  "RPIHARDWAREDATAOPT": 5.0,
  "PHONEHARDWARE": 16.9,
  "GPSHARDWARE": 9.9,
  "MOBILEAPP": 2.0
};

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      nbRpiHardware: 0,
      nbBadges: 0,
      nbRpiHardwareDataOpt: 0,
      nbPhoneHardware: 0,
      nbGpsHardware: 0,
      nbMobileApp: 0,
      misc: '',
      loading: false,
      result: undefined
    };
    this.state = Object.assign(this.state, {
      listeners: {
        ORDER_HARDWARE: this.onOrderResult.bind(this)
      }
    });
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));
  }

  add(propName, value) {
    return (evt) => {
      this.setState({
        [propName]: this.state[propName] + value < 0 ? 0 : this.state[propName] + value
      });
    };
  }

  chg(propName) {
    return (evt) => {
      let value = parseInt(evt.target.value);
      if(value === null || value === undefined || isNaN(value)) {
        value = 0;
      }
      this.setState({
        [propName]: value
      });
    };
  }

  addDataOpt(value)  {
    return (evt) => {
      let newVal = this.state.nbRpiHardwareDataOpt + value;
      if(newVal < 0) {
        newVal = 0;
      }
      this.setState({
        nbRpiHardwareDataOpt: newVal,
        nbRpiHardware: newVal > this.state.nbRpiHardware ? newVal : this.state.nbRpiHardware
      });
    };
  }

  chgDataOpt(evt) {
    let newVal = parseInt(evt.target.value);
    if(newVal === null || newVal === undefined || isNaN(newVal) || newVal < 0) {
      newVal = 0;
    }
    this.setState({
      nbRpiHardwareDataOpt: newVal,
      nbRpiHardware: newVal > this.state.nbRpiHardware ? newVal : this.state.nbRpiHardware
    });
  }

  addRpiH(value)  {
    return (evt) => {
      let newVal = this.state.nbRpiHardware + value;
      if(newVal < 0) {
        newVal = 0;
      }
      this.setState({
        nbRpiHardware: newVal,
        nbRpiHardwareDataOpt: newVal < this.state.nbRpiHardwareDataOpt ? newVal : this.state.nbRpiHardwareDataOpt
      });
    };
  }

  chgRpiH(evt) {
    let newVal = parseInt(evt.target.value);
    if(newVal === null || newVal === undefined || isNaN(newVal) || newVal < 0) {
      newVal = 0;
    }
    this.setState({
      nbRpiHardware: newVal,
      nbRpiHardwareDataOpt: newVal < this.state.nbRpiHardwareDataOpt ? newVal : this.state.nbRpiHardwareDataOpt
    });
  }

  onChangeMisc(evt) {
    this.setState({
      misc: evt.target.value
    });
  }

  onClickOnOrder(evt) {
    this.setState({loading: true}, () => {
      const {totalOrder} = this.getSums();
      this.state.socket.emit('ORDER_HARDWARE', {
        nbRpiHardware: this.state.nbRpiHardware,
        nbRpiHardwareDataOpt: this.state.nbRpiHardwareDataOpt,
        nbBadges: this.state.nbBadges,
        nbPhoneHardware: this.state.nbPhoneHardware,
        nbGpsHardware: this.state.nbGpsHardware,
        nbMobileApp: this.state.nbMobileApp,
        misc: this.state.misc,
        totalOrder
      });
    });
  }

  onOrderResult(result) {
    this.setState({
      loading: false,
      result
    }, () => {
      if(result.err !== undefined) {
        setTimeout(() => {
          this.setState({result: undefined});
        }, 5000);
      }
    });
  }

  getSums() {
    const totalRpiH = (Math.round(this.state.nbRpiHardware * MONTHLY_PRICES.RPIHARDWARE * 100) / 100);
    const totalRpiHDataOpt = (Math.round(this.state.nbRpiHardwareDataOpt * MONTHLY_PRICES.RPIHARDWAREDATAOPT * 100) / 100);
    const totalPhoneH = (Math.round(this.state.nbPhoneHardware * MONTHLY_PRICES.PHONEHARDWARE * 100) / 100);
    const totalMobileApp = (Math.round(this.state.nbMobileApp * MONTHLY_PRICES.MOBILEAPP * 100) / 100);
    const totalGpsH = (Math.round(this.state.nbGpsHardware * MONTHLY_PRICES.GPSHARDWARE * 100) / 100);
    const totalOrder = (totalRpiH + totalRpiHDataOpt + totalPhoneH + totalGpsH + totalMobileApp).toFixed(2);

    return ({
      totalRpiH,
      totalRpiHDataOpt,
      totalPhoneH,
      totalMobileApp,
      totalGpsH,
      totalOrder
    });
  }

  render() {
    const {
      totalRpiH,
      totalRpiHDataOpt,
      totalPhoneH,
      totalMobileApp,
      totalGpsH,
      totalOrder
    } = this.getSums();

    return (
      <Grid columns={16} stackable padded>
          <Grid.Row className="firstRow">
            <Header dividing size="huge" as="h1">
              <Icon name='plus cart'/>
              <Header.Content>
                Commande en ligne
                <Header.Subheader>
                  Commandez ici les produits supplémentaires dont vous avez besoin !
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Row>
          {
          this.state.result !== undefined && this.state.result.err === undefined ?
          <Grid stackable centered>
            <Grid.Row>
              <br /><br />
              <Header as='h1'>
                <Icon name='check circle' color='green'></Icon>
                <Header.Content>
                Votre commande est confirmée.
                <Header.Subheader>Nous vous remercions pour votre confiance</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Label size='big' color="grey">Qté Badgeuse Murale</Label>
              </Grid.Column>
              <Grid.Column width={1}>
                <Label size='big' color="green">{this.state.nbRpiHardware}</Label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={{paddingLeft: "2em"}}>
                <Label size='big'>- dont option 4G</Label>
              </Grid.Column>
              <Grid.Column width={1}>
                <Label size='big' color="green">{this.state.nbRpiHardwareDataOpt}</Label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} style={{paddingLeft: "2em"}}>
                <Label size='big'>Qté Badges</Label>
              </Grid.Column>
              <Grid.Column width={1}>
              <Label size='big' color="green">{this.state.nbBadges}</Label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Label size='big' color="grey">Qté Badgeuse Nomade</Label>
              </Grid.Column>
              <Grid.Column width={1}>
                <Label size='big' color="green">{this.state.nbPhoneHardware}</Label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Label size='big' color="grey">Qté Application Mobile</Label>
              </Grid.Column>
              <Grid.Column width={1}>
                <Label size='big' color="green">{this.state.nbMobileApp}</Label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Label size='big' color="grey">Qté Traceur GPS</Label>
              </Grid.Column>
              <Grid.Column width={1}>
                <Label size='big' color="green">{this.state.nbGpsHardware}</Label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <br />
              <Header as='h2'>
                Le montant de votre commande est de&nbsp;&nbsp;{totalOrder}&nbsp;&nbsp;€ /mois.<br />
              </Header>
            </Grid.Row>
            {/*<Grid.Row>
              <Grid.Column width={6}>
                <h3>Informations spécifiques concernant la livraison :</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                {this.state.misc !== '' ? this.state.misc : "Aucune information spécifique communiquée." }
              </Grid.Column>
            </Grid.Row>*/}
          </Grid>
          :
          <Grid.Row>
              <Grid.Column width={8}>
              <br /><br /><br />
                <Grid stackable padded>
                  <Grid.Row>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={6}>
                      <label style={{fontWeight: 'bold'}}>Quantité de Badgeuses Murales</label><br />
                      <Input style={{width: "20%"}} disabled={this.state.loading} onChange={this.chgRpiH.bind(this)} value={this.state.nbRpiHardware} action>
                        <input />
                        <Button disabled={this.state.loading} icon onClick={this.addRpiH(-1).bind(this)}><Icon name='minus' /></Button>
                        <Button disabled={this.state.loading} icon onClick={this.addRpiH(1).bind(this)}><Icon name='plus' /></Button>
                      </Input><br />
                      <Label>+ {totalRpiH.toFixed(2)} € /mois</Label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <label style={{fontWeight: 'bold'}}>dont option 4G</label><br />
                      <Input style={{width: "20%"}} disabled={this.state.loading} onChange={this.chgDataOpt.bind(this)} value={this.state.nbRpiHardwareDataOpt} action>
                        <input />
                        <Button disabled={this.state.loading} icon onClick={this.addDataOpt(-1).bind(this)}><Icon name='minus' /></Button>
                        <Button disabled={this.state.loading} icon onClick={this.addDataOpt(1).bind(this)}><Icon name='plus' /></Button>
                      </Input><br />
                      <Label>+ {totalRpiHDataOpt.toFixed(2)} € /mois</Label>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                  <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={6}>
                      <label style={{fontWeight: 'bold'}}>Quantité de Badges</label><br />
                      <Input style={{width: "20%"}} disabled={this.state.loading} onChange={this.chg('nbBadges').bind(this)} value={this.state.nbBadges} action>
                        <input />
                        <Button disabled={this.state.loading} icon onClick={this.add('nbBadges', -1).bind(this)}><Icon name='minus' /></Button>
                        <Button disabled={this.state.loading} icon onClick={this.add('nbBadges', 1).bind(this)}><Icon name='plus' /></Button>
                      </Input><br />
                      <Label>Offerts</Label>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider hidden />
                  <Grid.Row>
                  <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={6}>
                      <label style={{fontWeight: 'bold'}}>Quantité de Badgeuses Nomades</label><br />
                      <Input style={{width: "20%"}} disabled={this.state.loading} onChange={this.chg('nbPhoneHardware').bind(this)} value={this.state.nbPhoneHardware} action>
                        <input />
                        <Button disabled={this.state.loading} icon onClick={this.add('nbPhoneHardware', -1).bind(this)}><Icon name='minus' /></Button>
                        <Button disabled={this.state.loading} icon onClick={this.add('nbPhoneHardware', 1).bind(this)}><Icon name='plus' /></Button>
                      </Input><br />
                      <Label>+ {totalPhoneH.toFixed(2)} € /mois</Label>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <label style={{fontWeight: 'bold'}}>Quantité d'Applications Mobiles</label><br />
                      <Input style={{width: "20%"}} disabled={this.state.loading} onChange={this.chg('nbMobileApp').bind(this)} value={this.state.nbMobileApp} action>
                        <input />
                        <Button disabled={this.state.loading} icon onClick={this.add('nbMobileApp', -1).bind(this)}><Icon name='minus' /></Button>
                        <Button disabled={this.state.loading} icon onClick={this.add('nbMobileApp', 1).bind(this)}><Icon name='plus' /></Button>
                      </Input><br />
                      <Label>+ {totalMobileApp.toFixed(2)} € /mois</Label>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider hidden />
                  <Grid.Row>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={6}>
                      <label style={{fontWeight: 'bold'}}>Quantité de Traceurs GPS</label><br />
                      <Input style={{width: "20%"}} disabled={this.state.loading} onChange={this.chg('nbGpsHardware').bind(this)} value={this.state.nbGpsHardware} action>
                        <input />
                        <Button disabled={this.state.loading} icon onClick={this.add('nbGpsHardware', -1).bind(this)}><Icon name='minus' /></Button>
                        <Button disabled={this.state.loading} icon onClick={this.add('nbGpsHardware', 1).bind(this)}><Icon name='plus' /></Button>
                      </Input><br />
                      <Label>+ {totalGpsH.toFixed(2)} € /mois</Label>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <br /><br />
                      <Form>
                        <label style={{fontWeight: 'bold'}}>Par défaut, les produits commandés seront expédiés et facturés à votre adresse principale.<br />Renseignez ici les instructions spécifiques relatives à cette commande.</label><br />
                        <TextArea placeholder='Ajoutez ici toute information que vous souhaitez porter à notre attention' disabled={this.state.loading} onChange={this.onChangeMisc.bind(this)} value={this.state.misc} rows={3} />
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={8} textAlign='center'>
                <br /><br />
                <Image size='huge' centered src='/order.jpg' />
                <br /><br /><br /><br />
                <Button icon labelPosition='right' size="big" disabled={this.state.loading || (this.state.nbRpiHardware === 0 && this.state.nbBadges === 0 && this.state.nbPhoneHardware === 0 && this.state.nbGpsHardware === 0 && this.state.nbMobileApp === 0)} loading={this.state.loading} onClick={this.onClickOnOrder.bind(this)} circular color='green'>VALIDER LA COMMANDE&nbsp;&nbsp;&nbsp;( {totalOrder}€ )<Icon name="check" /></Button>
                <Grid.Row>
                  {this.state.result !== undefined && this.state.result.err !== undefined ? <Header size='small' as='div'>Oups, une erreur est survenue<br /> Merci de bien vouloir réessayer</Header> : ''}
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
    );
  }
}

export default Order;
