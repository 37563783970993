import Moment from './momentfr';
import {groupByDay} from './groupByDay';
import { nightShiftBegin, nightShiftEnd, nightShiftBeginMinute, nightShiftEndMinute, timezone } from './localSettings';
import groupBy from './groupBy';

export default function(hourEndByDay, manualCounters, nightShiftBeginVal = nightShiftBegin(), nightShiftBeginMinuteVal = nightShiftBeginMinute(), nightShiftEndVal = nightShiftEnd(), nightShiftEndMinuteVal = nightShiftEndMinute(), timezoneVal = timezone()) {
    const moment = Moment(timezoneVal);
    return (pointages, days) => {
        const sortedPointages = pointages.sort((p1, p2) => p1.timestamp - p2.timestamp);

        const specificPointagesByDay = groupBy(sortedPointages.map(x => Object.assign(x, {day: moment.unix(x.timestamp).format("DD/MM/YYYY")})), 'day');

        const pointagesByDay = groupByDay(Object.keys(specificPointagesByDay).map(k => specificPointagesByDay[k]).flat(), hourEndByDay, timezoneVal);

        const sortedPointagesByDay = Object.keys(pointagesByDay).reduce((acc, day) => {
            return Object.assign(acc, {
                [day]: pointagesByDay[day].map((p, idx) => idx % 2 === 0 ? Object.assign(p, {type: 'E'}) : Object.assign(p, {type: 'S'}))
            })
        }, {});

        const isBefore = (h1, m1, h2, m2) => h1 < h2 || h1 === h2 && m1 < m2;
        const isEqualOrAfter = (h1, m1, h2, m2) => h1 > h2 || h1 === h2 && m1 >= m2;

        return {
            validatedHours: () => {
                //const days = Object.keys(sortedPointagesByDay);
                let hoursAmount = 0;
                let hoursAmountPerWeek = {};
                let hoursAmountNightShift = 0;
                let hoursAmountPerWeekNightShift = {};
                let hoursAmountManual = 0;
                let hoursAmountManualPerWeek = {};
                let hoursAmountSecondManual = 0;
                let hoursAmountSecondManualPerWeek = {};
                let hoursAmountThirdManual = 0;
                let hoursAmountThirdManualPerWeek = {};

                if(days.length === 0) {
                    hoursAmount = 0;
                    hoursAmountNightShift = 0;
                    hoursAmountManual = 0;
                    hoursAmountSecondManual = 0;
                    hoursAmountThirdManual = 0;
                } else {
                    hoursAmount = days.reduce((acc, day) => (sortedPointagesByDay[day] && sortedPointagesByDay[day].length > 0 && sortedPointagesByDay[day][0].type === 'E' ?
                        // Group them by 2
                        acc + sortedPointagesByDay[day].reduce((result, p, idx) => {
                            if (idx % 2 === 0) {
                                result.push(sortedPointagesByDay[day].slice(idx, idx + 2));
                            }
                            return result;
                        }, [])
                        .map(both => {
                            const weekIdx = `${moment.unix(both[0].timestamp).weekYear()}_${moment.unix(both[0].timestamp).week()}`;
                            if(!(weekIdx in hoursAmountPerWeek)) {
                                hoursAmountPerWeek = Object.assign({}, hoursAmountPerWeek, {[weekIdx]: 0.0 })
                            }
                            if(both[0].type === 'E' && both[1] !== undefined && both[1].type === 'S') {
                                let hoursToday = moment.duration(moment.unix(both[1].timestamp).seconds(0).diff(moment.unix(both[0].timestamp).seconds(0))).as('hours')
                                hoursAmountPerWeek = Object.assign({}, hoursAmountPerWeek, {[weekIdx]: hoursAmountPerWeek[weekIdx] + hoursToday })
                                return hoursToday;
                            } else {
                                return 0;
                            }
                        }).reduce((a,b) => Math.round((a+b) * 100000) / 100000, 0)
                    : acc
                    ), 0.0);

                    hoursAmountNightShift = days.reduce((acc, day) => {
                        return (sortedPointagesByDay[day] && sortedPointagesByDay[day].length > 0 && sortedPointagesByDay[day][0].type === 'E' ?
                        // Group them by 2
                        acc + sortedPointagesByDay[day].reduce((result, p, idx) => {
                            if (idx % 2 === 0) {
                                result.push(sortedPointagesByDay[day].slice(idx, idx + 2));
                            }
                            return result;
                        }, [])
                        .map(both => {
                            const weekIdx = `${moment.unix(both[0].timestamp).weekYear()}_${moment.unix(both[0].timestamp).week()}`;
                            if(!(weekIdx in hoursAmountPerWeekNightShift)) {
                                hoursAmountPerWeekNightShift = Object.assign({}, hoursAmountPerWeekNightShift, {[weekIdx]: 0.0 })
                            }
                            if(both[0].type === 'E' && both[1] !== undefined && both[1].type === 'S') {
                                const startTS = both[0].timestamp;
                                const endTS = both[1].timestamp;
                                let start = moment.unix(startTS);
                                let end = moment.unix(endTS);
                                const entryHour = start.hour();
                                const leaveHour = end.hour();
                                const entryMinute = start.minute();
                                const leaveMinute = end.minute();

                                const isEntryBefore = (h, m) => isBefore(entryHour, entryMinute, h, m);
                                const isEntryEqualOrAfter = (h, m) => isEqualOrAfter(entryHour, entryMinute, h, m);
                                const isLeaveBefore = (h, m) => isBefore(leaveHour, leaveMinute, h, m);
                                const isLeaveEqualOrAfter = (h, m) => isEqualOrAfter(leaveHour, leaveMinute, h, m);
                                if(
                                    isEntryEqualOrAfter(nightShiftEndVal, nightShiftEndMinuteVal)
                                    && isEntryBefore(nightShiftBeginVal, nightShiftBeginMinuteVal)
                                    && isLeaveEqualOrAfter(nightShiftEndVal, nightShiftEndMinuteVal)
                                    && isLeaveBefore(nightShiftBeginVal, nightShiftBeginMinuteVal)
                                    && isBefore(entryHour, entryMinute, leaveHour, leaveMinute)
                                ) {
                                    return 0;
                                }

                                if(
                                    isEntryEqualOrAfter(nightShiftEndVal, nightShiftEndMinuteVal)
                                    && isEntryBefore(nightShiftBeginVal, nightShiftBeginMinuteVal)
                                ) {
                                    start = start.hour(nightShiftBeginVal).minute(nightShiftBeginMinuteVal).second(0);
                                }

                                if(
                                    isLeaveEqualOrAfter(nightShiftEndVal, nightShiftEndMinuteVal)
                                    && isLeaveBefore(nightShiftBeginVal, nightShiftBeginMinuteVal)
                                ) {
                                    end = end.hour(nightShiftEndVal).minute(nightShiftEndMinuteVal).second(0).add((true/*end.format("DD/MM/YYYY") === start.format("DD/MM/YYYY")*/ ? 0 : 1), 'd');
                                }

                                // We can just take the absolute value every time. Works the same.
                                //const needAbs = isEntryEqualOrAfter(nightShiftBeginVal, nightShiftBeginMinuteVal) && isLeaveBefore(nightShiftEndVal, nightShiftEndMinuteVal);
                                const customHoursToday = Math.abs(moment.duration(end.seconds(0).diff(start.seconds(0))).as('hours'));
                                hoursAmountPerWeekNightShift = Object.assign({}, hoursAmountPerWeekNightShift, {[weekIdx]: hoursAmountPerWeekNightShift[weekIdx] + customHoursToday })
                                return customHoursToday;
                            } else {
                                return 0;
                            }
                        }).reduce((a,b) => Math.round((a+b) * 100) / 100, 0)
                    : acc
                    )
                    }, 0.0);

                    hoursAmountManual = manualCounters.filter(x => x.type === 1 && days.includes(moment.unix(x.timestamp).format("DD/MM/YYYY"))).reduce((acc, mc) => acc+mc.hours, 0.0);
                    hoursAmountManualPerWeek = manualCounters.filter(x => x.type === 1 && days.includes(moment.unix(x.timestamp).format("DD/MM/YYYY"))).reduce((acc, mc) => {
                        const weekIdx = `${moment.unix(mc.timestamp).weekYear()}_${moment.unix(mc.timestamp).week()}`;
                        return Object.assign(acc, {[weekIdx]: (acc[weekIdx] ? acc[weekIdx] : 0.0) + mc.hours});
                    }, {});
                    
                    hoursAmountSecondManual = manualCounters.filter(x => x.type === 2 && days.includes(moment.unix(x.timestamp).format("DD/MM/YYYY"))).reduce((acc, mc) => acc+mc.hours, 0.0);
                    hoursAmountSecondManualPerWeek = manualCounters.filter(x => x.type === 2 && days.includes(moment.unix(x.timestamp).format("DD/MM/YYYY"))).reduce((acc, mc) => {
                        const weekIdx = `${moment.unix(mc.timestamp).weekYear()}_${moment.unix(mc.timestamp).week()}`;
                        return Object.assign(acc, {[weekIdx]: (acc[weekIdx] ? acc[weekIdx] : 0.0) + mc.hours});
                    }, {});

                    hoursAmountThirdManual = manualCounters.filter(x => x.type === 3 && days.includes(moment.unix(x.timestamp).format("DD/MM/YYYY"))).reduce((acc, mc) => acc+mc.hours, 0.0);
                    hoursAmountThirdManualPerWeek = manualCounters.filter(x => x.type === 3 && days.includes(moment.unix(x.timestamp).format("DD/MM/YYYY"))).reduce((acc, mc) => {
                        const weekIdx = `${moment.unix(mc.timestamp).weekYear()}_${moment.unix(mc.timestamp).week()}`;
                        return Object.assign(acc, {[weekIdx]: (acc[weekIdx] ? acc[weekIdx] : 0.0) + mc.hours});
                    }, {});
                }

                return {amount: hoursAmount, perWeek: hoursAmountPerWeek, amountCustom: hoursAmountNightShift, perWeekCustom: hoursAmountPerWeekNightShift, amountManual: hoursAmountManual, amountSecondManual: hoursAmountSecondManual, amountThirdManual: hoursAmountThirdManual, perWeekManual: hoursAmountManualPerWeek, perWeekSecondManual: hoursAmountSecondManualPerWeek, perWeekThirdManual: hoursAmountThirdManualPerWeek}
            },
            pointages: Object.keys(sortedPointagesByDay).reduce((acc, day) => [...acc, ...sortedPointagesByDay[day]], [])
        };
    };
}