import React from 'react';
//import './Employees.css';
import io from './utils/io';
import 'semantic-ui-css/semantic.min.css';
import resolvePath from 'object-resolve-path';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  List,
  Loader,
  Message,
  Modal,
  Segment,
  Popup,
  Table
} from "semantic-ui-react";
import Moment from './utils/momentfr';
import {
  DateInput
} from 'semantic-ui-calendar-react';

import daysOfWeek from './utils/dow';
import { getCurrentProfileForEmployee, getStartTSForDefaultProfile } from './utils/nbHoursExpected';
import { getHoursMinutesFromEvent } from './utils/formattedInputs';
import { formatHoursWithMinutes } from './utils/formatHoursWithMinutes';

import { Importer, ImporterField } from 'react-csv-importer';
import './EmployeesImport.css';
const moment = Moment();
const importerFRLocale = {
  general: {
    goToPreviousStepTooltip: 'Revenir en arrière'
  },

  fileStep: {
    initialDragDropPrompt:
      'Glissez-Déposez votre fichier ou Cliquez pour parcourir vos dossiers !',
    activeDragDropPrompt: 'Déposer le fichier...',

    getImportError: (message) => `Erreur lors de l'import : ${message}`,
    getDataFormatError: (message) => `Veuillez vérifier le format du fichier`,
    goBackButton: 'Retour',
    nextButton: 'Suivant',

    rawFileContentsHeading: '',
    previewImportHeading: "Prévisualisation : ",
    dataHasHeadersCheckbox: "Mon tableau possède une ligne d'entête.",
    previewLoadingStatus: 'chargement de la prévisualisation...'
  },

  fieldsStep: {
    stepSubtitle: 'Glissez-Déposez les colonnes de votre tableau sur les colonnes correspondantes',
    requiredFieldsError: '* Veuillez faire correspondre les colonnes obligatoires',
    nextButton: "Lancer l'import des données",

    dragSourceAreaCaption: 'Mapper les colonnes',
    getDragSourcePageIndicator: (currentPage, pageCount) => `Page ${currentPage} sur ${pageCount}`,
    getDragSourceActiveStatus: (columnCode) => `Assigner la colonne ${columnCode}`,
    nextColumnsTooltip: 'Montrer les colonnes suivantes',
    previousColumnsTooltip: 'Montrer les colonnes précédentes',
    clearAssignmentTooltip: 'Annuler le mappage',
    selectColumnTooltip: 'Sélectionner la colonne',
    unselectColumnTooltip: 'Déselectionner la colonne',

    dragTargetAreaCaption: 'Champs ciblé',
    getDragTargetOptionalCaption: (field) => `${field} (optionel)`,
    getDragTargetRequiredCaption: (field) => `${field} (requis)`,
    dragTargetPlaceholder: 'Déposer la colonne...',
    getDragTargetAssignTooltip: (columnCode) =>
      `Mapper la colonne ${columnCode}`,
    dragTargetClearTooltip: 'Annuler le mappage',

    columnCardDummyHeader: 'Champs sans correspondance',
    getColumnCardHeader: (code) => `Colonne ${code}`
  },

  progressStep: {
    stepSubtitle: "Import",
    uploadMoreButton: 'Importer un autre fichier',
    finishButton: 'Fermer',
    statusError: "Erreur pendant l'import",
    statusComplete: 'Import terminé',
    statusPending: 'Import en cours, merci de maintenir cette page ouverte...',
    processedRowsLabel: 'Ligne en cours :'
  }
};

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      //employees: [],
      editingEmployee: {},
      error: "",
      //profiles: [],
      editingProfile: {},
      editingTeam: {},
      errorProfile: "",
      sortState: {
        employee: {
          data: []
        },
        profile: {
          data: []
        },
        team: {
          data: []
        }
      },
      deleteEmployeeModal: false,
      employeeIdToDelete: undefined,
      deleteProfileModal: false,
      profileIdToDelete: undefined,
      deleteTeamModal: false,
      teamIdToDelete: undefined,
      planningProfileModal: false,
      updateEmployeeModal: false,
      updateEmployeeConflict: undefined,
      loadingEmployees: true,
      loadingProfiles: true,
      loadingTeams: true,
      onlyActiveEmployees: true,
      teamEmployeesModalOpen: false,
      profilesVisible: false,
      reportsVisible: false,
      importEmployeeModal: false,
      importedDATA: {}
    };
    this.state = Object.assign(this.state, {
      listeners: {
        GET_EMPLOYEE: this.onEmployeesInit.bind(this),
        ADD_EMPLOYEE: this.onEmployeeAddResponse.bind(this),
        ADD_EMPLOYEES: this.onEmployeesAddResponse.bind(this),
        UPD_EMPLOYEE: this.onEmployeeUpdateResponse.bind(this),
        DEL_EMPLOYEE: this.onEmployeeDeleteResponse.bind(this),
        GET_PROFILE: this.onProfilesInit.bind(this),
        ADD_PROFILE: this.onProfileAddResponse.bind(this),
        UPD_PROFILE: this.onProfileUpdateResponse.bind(this),
        DEL_PROFILE: this.onProfileDeleteResponse.bind(this),
        GET_TEAM: this.onTeamsInit.bind(this),
        ADD_TEAM: this.onTeamAddResponse.bind(this),
        UPD_TEAM: this.onTeamUpdateResponse.bind(this),
        DEL_TEAM: this.onTeamDeleteResponse.bind(this)
      }
    });
    this.handleEscape = this.handleEscape.bind(this);
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    this.state.socket.emit('GET_EMPLOYEE');
    this.state.socket.emit('GET_PROFILE');
    this.state.socket.emit('GET_TEAM');

    // For escape key
    document.addEventListener("keydown", this.handleEscape, false);
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));

    // For escape key
    document.removeEventListener("keydown", this.handleEscape, false);
  }

  handleEscape(event) {
    if(event.keyCode === 27) {
      this.setState({
        editingEmployee: {},
        editingProfile: {},
        editingTeam: {},
        deleteProfileModal: false,
        planningProfileModal: false,
        updateEmployeeModal: false,
        deleteEmployeeModal: false,
        deleteTeamModal: false
      });
    }
  }

  onEmployeesInit(employees) {
    const sortByName = (e1, e2) => e1.name < e2.name ? -1 : 1;
    this.setState({
      sortState: Object.assign(this.state.sortState, {
        employee: Object.assign(this.state.sortState.employee, {
          data: employees.map(e => Object.assign({}, e, {activeProfileId: getCurrentProfileForEmployee(e, moment().unix(), this.props.hourEndByDay(e)).id}))
            .sort((e1, e2) => 
            e1.leftDate === null && e2.leftDate === null ?
              sortByName(e1, e2)
              : e1.leftDate === null ?
                -1
                : e2.leftDate === null ?
                  1
                  : e1.leftDate < e2.leftDate ? 1 : -1
              )
        })
      }),
      loadingEmployees: false
    });
  }

  onClickOnImportEmployee(event) {
    this.setState({
      importEmployeeModal: !this.state.importEmployeeModal
    });
  }

  onEmployeeAdd(employee) {
    this.state.socket.emit('ADD_EMPLOYEE', employee);
  }

  onEmployeeAddResponse(employee) {
    this.manageErrors(employee);
    this.setState({
      editingEmployee: employee,
      editingProfile: {},
      editingTeam: {}
    });
    this.state.socket.emit('GET_EMPLOYEE');
  }

  onEmployeesAddResponse(employees) {
    this.manageErrors(employees);
    this.setState({
      editingEmployee: {},
      editingProfile: {},
      editingTeam: {}
    });
    this.state.socket.emit('GET_EMPLOYEE');
  }

  onEmployeeUpdate(employee) {
    this.state.socket.emit('UPD_EMPLOYEE', employee);
  }

  onEmployeeUpdateResponse(employee) {
    this.setState({
      editingEmployee: {}
    });
    this.state.socket.emit('GET_EMPLOYEE');
    this.manageErrors(employee);
  }

  onEmployeeDelete(employee) {
    this.state.socket.emit('DEL_EMPLOYEE', {id: employee.id});
  }

  onEmployeeDeleteResponse(employee) {
    this.state.socket.emit('GET_EMPLOYEE');
    this.state.socket.emit('GET_TEAM');
    this.manageErrors(employee);
  }

  manageEnterKey(event) {
    if(event.key === 'Enter') {
      if(Object.keys(this.state.editingEmployee).length > 0) {
        this.onClickOnUpdEmployee(false)(event);
      } else if(Object.keys(this.state.editingProfile).length > 0) {
        this.onClickOnUpdProfile()(event);
      } else if(Object.keys(this.state.editingTeam).length > 0) {
        this.onTeamUpdate(event);
      }
    }
  }

  manageErrors(data) {
    if(data.err !== undefined) {
      this.setState({
        error: data.err
      });
      setTimeout(() => {
        this.setState({
          error: ""
        });
      }, 5000);
    }
  }

  onClickOnAddEmployee(event) {
    this.onEmployeeAdd({
      name: '- Nouvel employé',
      number: '',
      badgeNo: '0000000000',
      email: '',
      login: '',
      pointPassword: '',
      entryDate: moment().hour(0).minute(0).second(0).unix(),
      leftDate: null,
      profileId: 1,
      emailReportsEnabled: false,
      nightShiftEnabled: false
    });
    this.props.handleTour(event);
  }

  onClickOnEmployeeRow(employee) {
    return (event) => {
      if(this.state.updateEmployeeModal === false && this.state.deleteEmployeeModal === false && employee.id !== this.state.editingEmployee.id) {
        this.setState({
          editingEmployee: employee,
          editingProfile: {},
          editingTeam: {}
        });
      }
    };
  }

  onClickOnUpdEmployee(force = false) {
    return (event) => {
      const actualBadgeNo = this.state.editingEmployee.badgeNo;
      const alreadyHaveThisBadgeNo = this.state.sortState.employee.data.filter((e) => e.id !== this.state.editingEmployee.id).find((e) => e.badgeNo === actualBadgeNo);
      if(actualBadgeNo !== undefined && actualBadgeNo !== "" && actualBadgeNo !== "0000000000" && alreadyHaveThisBadgeNo !== undefined && force === false) {
        this.setState({
          updateEmployeeModal: true,
          updateEmployeeConflict: {name: alreadyHaveThisBadgeNo.name}
        });
      } else {
        this.onEmployeeUpdate(this.state.editingEmployee);
        this.setState({
          editingEmployee: {},
          updateEmployeeModal: false
        });
      }
      this.props.handleTour(event);
    };
  }

  onClickOnDel(module) {
    return (event) => {
      this[`on${module[0].toUpperCase()}${module.slice(1)}Delete`]({
        id: this.state[`${module}IdToDelete`]
      });
      this.toggleModal(module)();
    };
  }

  toggleModal(module) {
    return (event) => {
      const key = `delete${module[0].toUpperCase()}${module.slice(1)}Modal`;
      this.setState({
        [key]: !this.state[key]
      });
    };
  }

  onChangeEmployeeProp(propName) {
    const onlyNums = (str) => str.split('').filter(x => x >= '0' && x <= '9').join('');

    return (event, data) => {
      // For Dropdown
      const usefulData = data !== undefined ? 
        data.name === "entryDate" || data.name === "leftDate" ?
          moment(data.value, "DD/MM/YYYY").unix()
          : (data.checked !== undefined && data.checked !== null ? data.checked : (propName === "pointPassword" ? onlyNums(event.target.value) : data.value))
        : event.target.value;
      this.setState({
        editingEmployee: Object.assign({}, this.state.editingEmployee, {[propName]: usefulData})
      });
    };
  }

  onProfilesInit(profiles) {
    this.setState({
      sortState: Object.assign(this.state.sortState, {
        profile: Object.assign(this.state.sortState.profile, {
          data: profiles.sort((p1, p2) => p1.name < p2.name ? -1 : 1)
        })
      }),
      loadingProfiles: false
    });
  }

  onProfileAdd(profile) {
    this.state.socket.emit('ADD_PROFILE', profile);
  }

  onProfileAddResponse(profile) {
    this.manageErrorsProfile(profile);
    this.setState({
      editingProfile: profile,
      editingEmployee: {},
      editingTeam: {}
    });
    this.state.socket.emit('GET_PROFILE');
  }

  onProfileUpdate(profile) {
    this.state.socket.emit('UPD_PROFILE', profile);
  }

  onProfileUpdateResponse(profile) {
    this.setState({
      editingProfile: {}
    });
    this.state.socket.emit('GET_PROFILE');
    this.state.socket.emit('GET_EMPLOYEE');
    this.manageErrorsProfile(profile);
  }

  onProfileDelete(profile) {
    this.state.socket.emit('DEL_PROFILE', {id: profile.id});
  }

  onProfileDeleteResponse(profile) {
    this.state.socket.emit('GET_PROFILE');
    this.manageErrorsProfile(profile);
  }

  manageErrorsProfile(data) {
    if(data.err !== undefined) {
      this.setState({
        errorProfile: data.err
      });
      setTimeout(() => {
        this.setState({
          errorProfile: ""
        });
      }, 5000);
    }
  }

  onClickOnAddProfile(event) {
    this.onProfileAdd({
      name: '- Nouvel horaire',
      nbHoursExpected: [Object.keys(daysOfWeek).reduce((acc,dow) => Object.assign({}, acc, {[dow]: 0}), {})]
    });
  }

  onClickOnProfileRow(profile) {
    return (event) => {
      if(profile.id !== this.state.editingProfile.id) {
        this.setState({
          editingProfile: profile,
          editingEmployee: {},
          editingTeam: {}
        });
      }
    };
  }

  onClickOnUpdProfile() {
    return (event) => {
      this.onProfileUpdate(this.state.editingProfile);
      this.setState({
        editingProfile: {}
      });
    };
  }

  onClickAddWeekProfile(profile) {
    return (event) => {
      this.setState({
        editingProfile: Object.assign(this.state.editingProfile, {
          nbHoursExpected: [
            ...this.state.editingProfile.nbHoursExpected,
            Object.keys(daysOfWeek).reduce((accDow,dow) => Object.assign({}, accDow, {[dow]: 0}), {})
          ]
        })
      });
    };
  }

  onClickDelWeekProfile(profile, idxWeek) {
    return (event) => {
      this.setState({
        editingProfile: Object.assign(this.state.editingProfile, {
          nbHoursExpected: this.state.editingProfile.nbHoursExpected.filter((nbH, idxW) => idxW !== idxWeek)
        })
      });
    };
  }

  onChangeProfileProp(propName) {
    return (event) => {
      this.setState({
        editingProfile: Object.assign({}, this.state.editingProfile, {[propName]: event.target.value})
      });
    };
  }

  onChangeProfileDuration(idxWeek, dow) {
    return (event,data) => {
      const tgt = event.target;
      const {finalValue, cursorStart, cursorEnd} = getHoursMinutesFromEvent(event);
      let currentNbHoursExpected = [...this.state.editingProfile.nbHoursExpected];
      currentNbHoursExpected[idxWeek] = Object.assign({}, this.state.editingProfile.nbHoursExpected[idxWeek], {[dow]: event.target.value === "" ? 0 : finalValue});
      this.setState({
        editingProfile: Object.assign({}, this.state.editingProfile, {
          nbHoursExpected: currentNbHoursExpected
        })
      }, () => {
        tgt.setSelectionRange(cursorStart, cursorEnd);
      });
    }
  }

  onTeamsInit(teams) {
    const sortByName = (e1, e2) => e1.name < e2.name ? -1 : 1;
    this.setState({
      sortState: Object.assign(this.state.sortState, {
        team: Object.assign(this.state.sortState.team, {
          data: teams.sort(sortByName)
        })
      }),
      loadingTeams: false
    });
  }

  onClickOnAddTeam(event) {
    this.state.socket.emit('ADD_TEAM', ({
      name: '- Nouvelle équipe'
    }));
  }

  onTeamAddResponse(team) {
    this.manageErrors(team);
    this.setState({
      editingTeam: team,
      editingEmployee: {},
      editingProfile: {}
    });
    this.state.socket.emit('GET_TEAM');
  }

  onClickOnTeamRow(team) {
    return (event) => {
      if(team.id !== this.state.editingTeam.id) {
        this.setState({
          editingTeam: team,
          editingEmployee: {},
          editingProfile: {}
        });
      }
    };
  }

  onChangeTeamProp(propName) {
    return (event) => {
      this.setState({
        editingTeam: Object.assign({}, this.state.editingTeam, {[propName]: event.target.value})
      });
    };
  }

  onChangeTeamManagerHasWriteAccess() {
    return (event) => {
      this.setState({
        editingTeam: Object.assign({}, this.state.editingTeam, {"managerHasWriteAccess": this.state.editingTeam.managerHasWriteAccess === true ? false : true})
      });
    }
  }

  onTeamUpdate(evt) {
    this.state.socket.emit('UPD_TEAM', this.state.editingTeam);
  }

  onTeamUpdateResponse(team) {
    this.setState({
      editingTeam: {}
    });
    this.state.socket.emit('GET_TEAM');
    this.state.socket.emit('GET_EMPLOYEE');
    //this.manageErrorsProfile(team);
  }

  onTeamDelete(team) {
    this.state.socket.emit('DEL_TEAM', {id: team.id});
  }

  onTeamDeleteResponse(team) {
    this.state.socket.emit('GET_TEAM');
    this.state.socket.emit('GET_EMPLOYEE');
    //this.manageErrorsProfile(profile);
  }

  handleSort(submodule, clickedColumn) {
    return (evt) => {
      // To avoid sorting when we click on visibility icons
      if(evt.target.tagName === "TH") {
        const { column, data, direction } = this.state.sortState[submodule];
        const nextDirection = direction === 'ascending' ? 'descending' : 'ascending';
        const sortedData = data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1);
        this.setState({
          sortState: Object.assign(this.state.sortState, {
            [submodule]: {
              column: clickedColumn,
              data: nextDirection === 'ascending' ? sortedData : sortedData.reverse(),
              direction: nextDirection
            }
          })
        });
      }
    }
  }

  onModalOpen(module, id) {
    return (event) => {
      this.setState({
        [`${module}IdToDelete`]: id
      });
    }
  }

  onFocusOnDuration(event) {
    //event.target.select();
    event.target.focus();
    const tgt = event.target;
    // Firefox hack
    setTimeout(() => tgt.setSelectionRange(0,0), 50);
  }

  onChangeTeamEmployee(evt,data) {
    const employeeSelected = this.state.sortState.employee.data.find(e => e.id === data.value);
    if(evt.code === 'Enter' || (evt.code === undefined && evt.nativeEvent !== undefined && evt.nativeEvent.type === "click")) {
      if(employeeSelected !== undefined) {
        this.setState({
          editingTeam: Object.assign({}, this.state.editingTeam, {
            employees: [...this.state.editingTeam.employees.filter(e => e.id !== employeeSelected.id), employeeSelected]
          })
        });
      }
    }
  }

  onToggleOnlyActiveEmployees(event) {
    this.setState({
      onlyActiveEmployees: !this.state.onlyActiveEmployees
    });
  }

  onDelTeamEmployee(employeeId) {
    return (evt) => {
      this.setState({
        editingTeam: Object.assign({}, this.state.editingTeam, {
          employees: this.state.editingTeam.employees.filter(e => e.id !== employeeId)
        })
      });
    };
  }

  onChangeTeamManager(evt, data) {
    const employeeSelected = this.state.sortState.employee.data.find(e => e.id === data.value);
    if(employeeSelected !== undefined) {
      this.setState({
        editingTeam: Object.assign({}, this.state.editingTeam, {
          manager: employeeSelected
        })
      });
    }
  }

  render() {
    const getDefaultProfileForEmployee = (e) => {
      return this.state.sortState.profile.data.find(p => p.id === e.defaultProfileId);
    };
    const isEmployeeActive = (e) => {
      return e.entryDate < moment().unix() &&
        (e.leftDate === null || e.leftDate === undefined || e.leftDate > moment().unix());
    };
    const profilesToSelect = this.state.sortState.profile.data.map((p, idx) =>
      ({
        key: p.id,
        value: p.id,
        text: p.name
      })
    );
    const nowTS = moment().unix();
    const employeesHTML = this.state.sortState.employee.data
    .filter(e => this.state.onlyActiveEmployees ? isEmployeeActive(e) : true)
    .map((e, idx) => {
      const currentProfileForEmployee = getCurrentProfileForEmployee(e, nowTS, this.props.hourEndByDay(e));
      return <Table.Row onKeyPress={this.manageEnterKey.bind(this)} key={idx} onClick={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? this.onClickOnEmployeeRow(e).bind(this) : () => {}}>
        <Table.Cell width={1} textAlign='center'>
          {
            isEmployeeActive(e) ?
              <Icon name='check' color='green' />
              : <Icon name='delete' color='red' />
          }
        </Table.Cell>
        <Table.Cell width={2} textAlign='center'>
          {
          this.state.editingEmployee.id === e.id ?
          <Input fluid placeholder='Nom et prénom...' value={this.state.editingEmployee.name} onChange={this.onChangeEmployeeProp('name').bind(this)} />
          : e.name
          }
        </Table.Cell>
        <Table.Cell width={2} textAlign='center'>
          {
          this.state.editingEmployee.id === e.id ?
          <Input fluid placeholder='Matricule...' value={this.state.editingEmployee.number} onChange={this.onChangeEmployeeProp('number').bind(this)} />
          : e.number
          }
        </Table.Cell>
        <Table.Cell width={1} textAlign='center'>
          {
          this.state.editingEmployee.id === e.id ?
          <DateInput
            name="entryDate"
            dateFormat="DD/MM/YYYY"
            closeOnMouseLeave={false}
            fluid
            closable={true}
            placeholder="Date d'entrée"
            hideMobileKeyboard={true}
            value={moment.unix(this.state.editingEmployee.entryDate).format("DD/MM/YYYY")}
            iconPosition="left"
            onChange={this.onChangeEmployeeProp('entryDate').bind(this)}
            animation='none'
          />
          : moment.unix(e.entryDate).format("DD/MM/YYYY")
          }
        </Table.Cell>
        <Table.Cell width={1} textAlign='center'>
          {
          this.state.editingEmployee.id === e.id ?
          <DateInput
            name="leftDate"
            dateFormat="DD/MM/YYYY"
            closeOnMouseLeave={false}
            fluid
            closable={true}
            clearable={true}
            placeholder="Date de sortie"
            hideMobileKeyboard={true}
            value={
              this.state.editingEmployee.leftDate !== null ?
                moment.unix(this.state.editingEmployee.leftDate).format("DD/MM/YYYY")
                : ""
            }
            iconPosition="left"
            onChange={this.onChangeEmployeeProp('leftDate').bind(this)}
            onClear={() => this.onChangeEmployeeProp('leftDate')(null, {value: null})}
            animation='none'
          />
          : e.leftDate !== null ? moment.unix(e.leftDate).format("DD/MM/YYYY") : ""
          }
        </Table.Cell>
        <Table.Cell width={1} textAlign='center'>
          {
          this.state.editingEmployee.id === e.id ?
          <Input fluid placeholder='Login...' value={this.state.editingEmployee.login} onChange={this.onChangeEmployeeProp('login').bind(this)} />
          : e.login
          }
        </Table.Cell>
        <Table.Cell width={1} textAlign='center'>
          {
          this.state.editingEmployee.id === e.id ?
          <Input fluid placeholder="Code d'accès..." type="text" value={this.state.editingEmployee.pointPassword !== null && this.state.editingEmployee.pointPassword !== undefined ? this.state.editingEmployee.pointPassword : ""} onChange={this.onChangeEmployeeProp('pointPassword').bind(this)} />
          : "(crypté)"
          }
        </Table.Cell>
        <Table.Cell width={2} textAlign='center'>
          {
          this.state.editingEmployee.id === e.id ?
          <Input fluid placeholder='Numéro de badge...' value={this.state.editingEmployee.badgeNo} onChange={this.onChangeEmployeeProp('badgeNo').bind(this)} />
          : e.badgeNo
          }
        </Table.Cell>
        <Table.Cell width={1} textAlign='center'>
          <Popup
              content={
                this.state.editingEmployee.id === e.id ?
                `Activation ou non du pointage PC.
                Le pointage PC permet à l'employé de pointer à l'aide de son login et de son code confidentiel depuis la page web orane.online`
                : null
              }
              disabled={this.state.editingEmployee.id !== e.id}
              trigger={
                <Checkbox className="checkboxInTable" toggle onChange={this.onChangeEmployeeProp('remotePointagesEnabled').bind(this)}
                  disabled={this.state.editingEmployee.id !== e.id || !isEmployeeActive(this.state.editingEmployee)}
                  checked={this.state.editingEmployee.id === e.id ? (isEmployeeActive(this.state.editingEmployee) ? this.state.editingEmployee.remotePointagesEnabled : false) : (isEmployeeActive(e) ? e.remotePointagesEnabled : false)}
                />
              }
            />
        </Table.Cell>
        {
          this.state.reportsVisible ?
          <Table.Cell width={2} textAlign='center'>
            {
            this.state.editingEmployee.id === e.id ?
            <Input fluid placeholder='Email...' value={this.state.editingEmployee.email} onChange={this.onChangeEmployeeProp('email').bind(this)} />
            : e.email
            }
          </Table.Cell>
          : null
        }
        {
          this.state.reportsVisible ?
          <Table.Cell width={1} textAlign='center'>
            <Popup
              content={
                this.state.editingEmployee.id === e.id ?
                `Envoi automatique de rapports quotidiens, hebdomadaires et mensuels à l'employé.
                Les rapports sont envoyés à l'adresse saisie dans le champ E-mail de l'employé.`
                : null
              }
              disabled={this.state.editingEmployee.id !== e.id}
              trigger={
                <Checkbox className="checkboxInTable" toggle onChange={this.onChangeEmployeeProp('emailReportsEnabled').bind(this)}
                  disabled={this.state.editingEmployee.id !== e.id || !isEmployeeActive(this.state.editingEmployee)}
                  checked={this.state.editingEmployee.id === e.id ? (isEmployeeActive(this.state.editingEmployee) ? this.state.editingEmployee.emailReportsEnabled : false) : (isEmployeeActive(e) ? e.emailReportsEnabled : false)}
                />
              }
            />
          </Table.Cell>
          : null
        }
        {
          this.state.profilesVisible ?
            <Table.Cell width={2} textAlign='center'>
              <Dropdown
                placeholder='Profil horaire par défaut...'
                search
                selection
                options={profilesToSelect}
                value={this.state.editingEmployee.id === e.id ? this.state.editingEmployee.defaultProfileId : e.defaultProfileId}
                onClick={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? this.onClickOnEmployeeRow(e).bind(this) : () => {}}
                onChange={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? this.onChangeEmployeeProp('defaultProfileId').bind(this) : () => {}}
                disabled={this.props.isAdmin() === false && this.props.isManagerWithWriteAccess() === false}
                />&nbsp;
              <Button data-tooltip="Planification des horaires" data-position="bottom right" icon color='blue' disabled={this.state.editingEmployee.id !== e.id} onClick={() => this.setState({planningProfileModal: true})}>
                <Icon name='calendar' />
              </Button>
            </Table.Cell>
          : null
        }
        {
          this.state.profilesVisible ?
            <Table.Cell textAlign='center'>{currentProfileForEmployee.name}</Table.Cell>
          : null
        }
        {
          this.state.profilesVisible ?
            <Table.Cell textAlign='center'>{moment.unix(currentProfileForEmployee.end === null ? getStartTSForDefaultProfile(e, nowTS) : currentProfileForEmployee.start).format("DD/MM/YYYY")}</Table.Cell>
          : null
        }
        {
          this.state.profilesVisible ?
            <Table.Cell textAlign='center'>
              <Checkbox className="checkboxInTable" disabled={this.state.editingEmployee.id !== e.id} onChange={this.onChangeEmployeeProp('nightShiftEnabled').bind(this)} toggle checked={this.state.editingEmployee.id !== e.id ? e.nightShiftEnabled : this.state.editingEmployee.nightShiftEnabled} />
            </Table.Cell>
          : null
        }
        <Table.Cell width={1} textAlign='center'>
          <Modal trigger={
            <Button data-tooltip="Enregistrer les modifications" data-position="bottom right" icon color='green' disabled={this.state.editingEmployee.id !== e.id} onClick={this.onClickOnUpdEmployee().bind(this)}>
              <Icon name='check' />
            </Button>
          } basic size='small' open={this.state.updateEmployeeModal}>
            <Header icon='trash' content="Enregistrement d'un employé" />
            <Modal.Content>
              <p>
                Attention, l'employé que vous souhaitez enregistrer possède le même numéro de badge que l'employé suivant :<br /><br />
                <b>{this.state.updateEmployeeConflict !== undefined ? this.state.updateEmployeeConflict.name : ""}.</b><br /><br />
                Êtes-vous certain de vouloir effectuer cette action ?<br />
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color='red' inverted onClick={() => this.setState({updateEmployeeModal: false})}>
                <Icon name='remove' /> Non, Annuler
              </Button>
              <Button color='green' inverted onClick={this.onClickOnUpdEmployee(true).bind(this)}>
                <Icon name='checkmark' /> Oui, Confirmer
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal trigger={
            <Button disabled={this.props.isAdmin() === false || this.state.editingEmployee.id !== e.id} onClick={this.toggleModal('employee').bind(this)} data-tooltip="Supprimer l'employé" data-position="bottom right" icon color='red'>
              <Icon name='trash' />
            </Button>
          } basic size='small' open={this.state.deleteEmployeeModal} onOpen={this.onModalOpen('employee', e.id).bind(this)}>
            <Header icon='trash' content="Supprimer l'employé" />
            <Modal.Content>
              <p>
                Attention, la suppression d'un employé entraîne la perte de toutes ses données.<br />
                Êtes-vous certain de vouloir effectuer cette action ?<br />
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color='red' inverted onClick={this.toggleModal('employee').bind(this)}>
                <Icon name='remove' /> Non, Annuler
              </Button>
              <Button color='green' inverted onClick={this.onClickOnDel('employee').bind(this)}>
                <Icon name='checkmark' /> Oui, Confirmer
              </Button>
            </Modal.Actions>
          </Modal>
        </Table.Cell>
      </Table.Row>;
    });
    const errorMessage = (this.state.error !== "" ?
      <Message negative>
        <Message.Header>Une erreur est survenue</Message.Header>
        <p>{JSON.stringify(this.state.error)}</p>
      </Message>
      :
      <div />
    );
    const allDefaultProfilesIds = this.state.sortState.employee.data.map(x => x.defaultProfileId).reduce((acc, x) => acc.includes(x) ? acc : [...acc, x], []);
    const profilesHTML = this.state.sortState.profile.data.map((p, idx) => {
      return (p.id === this.state.editingProfile.id ? this.state.editingProfile : p).nbHoursExpected.map((weekNbHoursExpected, idxWeek) => {
        return (<Table.Row onKeyPress={this.manageEnterKey.bind(this)} key={`${idx}_${idxWeek}`} onClick={this.props.isAdmin() === true || (this.props.isManagerWithWriteAccess() === true && this.props.myTeamId() === p.creatorTeamId) ? this.onClickOnProfileRow(p).bind(this) : () => {}}>
          <Table.Cell textAlign='center'>
            {
              idxWeek === 0 ?
                this.state.editingProfile.id === p.id ?
                <Input placeholder='Nom...' value={this.state.editingProfile.name} onChange={this.onChangeProfileProp('name').bind(this)} />
                :  p.name
              : ''
            }
          </Table.Cell>
          <Table.Cell textAlign='center'>
            Semaine <Label circular>{idxWeek+1}</Label>
          </Table.Cell>
            {

              Object.keys(daysOfWeek).map(dow => 
                <Table.Cell textAlign='center' key={`profile_${p.id}_${idxWeek}_${dow}`}>
                  {
                  this.state.editingProfile.id === p.id ?
                    <Input
                      fluid
                      size='small'
                      label={<Label color='blue'>{daysOfWeek[dow]}</Label>}
                      placeholder="0"
                      onFocus={this.onFocusOnDuration.bind(this)}
                      //onClick={this.onFocusOnDuration.bind(this)}
                      value={formatHoursWithMinutes(this.state.editingProfile.nbHoursExpected[idxWeek][dow])}
                      onChange={this.onChangeProfileDuration(idxWeek, dow).bind(this)}
                    />
                  : [<Label key={`profile_${p.id}_${dow}_lbl`} color='blue'>{daysOfWeek[dow]}</Label>,<Label key={`profile_${p.id}_${dow}_lblval`}>{formatHoursWithMinutes(weekNbHoursExpected[dow])}</Label>]
                  }
                </Table.Cell>
              )
            }
            {
              (this.props.isAdmin() === true || (this.props.isManagerWithWriteAccess() === true && this.props.myTeamId() === p.creatorTeamId)) ?
              <Table.Cell width={1} textAlign='center'>
                {
                  idxWeek === 0 && this.state.editingProfile.id === p.id ?
                  <Button data-tooltip="Ajouter une semaine pour créer un cycle" data-position="bottom right" icon color='blue' onClick={this.onClickAddWeekProfile(p).bind(this)}>
                    <Icon name='calendar plus outline' />
                  </Button> : ''
                }
                {
                  idxWeek === 0 && <Button data-tooltip="Enregistrer les modifications" data-position="bottom right" icon color='green' disabled={this.state.editingProfile.id !== p.id} onClick={this.onClickOnUpdProfile().bind(this)}>
                    <Icon name='check' />
                  </Button>
                }
                {
                  idxWeek === 0 && <Modal trigger={
                    <Button disabled={this.props.isAdmin() === false && this.props.isManagerWithWriteAccess() === false || allDefaultProfilesIds.includes(p.id) || this.state.editingProfile.id !== p.id} onClick={this.toggleModal('profile').bind(this)} data-tooltip="Supprimer l'horaire" data-position="bottom right" icon color='red'>
                      <Icon name='trash' />
                    </Button>
                  } basic size='small' open={this.state.deleteProfileModal} onOpen={this.onModalOpen('profile', p.id).bind(this)}>
                    <Header icon='trash' content="Supprimer l'horaire" />
                    <Modal.Content>
                      <p>
                        Attention, la suppression d'un horaire encore rattaché à un employé peut entraîner une perte d'information.<br />
                        Êtes-vous certain de vouloir effectuer cette action ?<br />
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button basic color='red' inverted onClick={this.toggleModal('profile').bind(this)}>
                        <Icon name='remove' /> Non, Annuler
                      </Button>
                      <Button color='green' inverted onClick={this.onClickOnDel('profile').bind(this)}>
                        <Icon name='checkmark' /> Oui, Confirmer
                      </Button>
                    </Modal.Actions>
                  </Modal>
                }
                {
                  idxWeek > 0 && this.state.editingProfile.id === p.id ?
                  <Button data-tooltip="Supprimer la semaine" data-position="bottom right" icon color='red' onClick={this.onClickDelWeekProfile(p, idxWeek).bind(this)}>
                    <Icon name='delete' />
                  </Button> : ''
                }
              </Table.Cell>
              : <Table.Cell></Table.Cell>
            }
        </Table.Row>);
      })
    });
    const employeesWhoAlreadyAreManagers = this.state.sortState.team.data.map(t => t.manager && t.manager.id ? t.manager.id : null).filter(x => x !== null);
    const teamsHTML = this.state.sortState.team.data.map((t, idx) => {
      const editingThisTeam = this.state.editingTeam.id === t.id;
      const teamEmployees = !editingThisTeam ? t.employees : this.state.editingTeam.employees;
      const maxEmployeesOnDisplay = 16;
      return (<Table.Row onKeyPress={this.manageEnterKey.bind(this)} key={`team_${t.id}_${idx}`} onClick={this.onClickOnTeamRow(t).bind(this)}>
        <Table.Cell textAlign='center'>
          {
            editingThisTeam ?
            <Input placeholder='Nom...' value={this.state.editingTeam.name} onChange={this.onChangeTeamProp('name').bind(this)} />
            :  t.name
          }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {
          !editingThisTeam ?
            t.manager ? t.manager.name : "Non assigné"
            : <Dropdown
                upward={false}
                placeholder="Sélectionnez un employé pour l'assigner en tant que chef d'équipe"
                search
                fluid
                selection
                onChange={this.onChangeTeamManager.bind(this)}
                value={this.state.editingTeam.manager ? this.state.editingTeam.manager.id : -1}
                options={this.state.sortState.employee.data.filter(e => t.manager && e.id === t.manager.id ? true : employeesWhoAlreadyAreManagers.includes(e.id) === false).map((e, idx) => ({
                  key: e.id,
                  text: e.name,
                  value: e.id
                }))}
              />
          }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Checkbox className="checkboxInTable" toggle onChange={this.onChangeTeamManagerHasWriteAccess().bind(this)}
                disabled={!editingThisTeam}
                checked={(editingThisTeam ? this.state.editingTeam : t).managerHasWriteAccess === true}
                label={(editingThisTeam ? this.state.editingTeam : t).managerHasWriteAccess === true ? "Voir et Modifier" : "Voir seulement"}
              />
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {
            teamEmployees.slice(0, maxEmployeesOnDisplay)
              .map(({id, name}, idx) => [
                <Label key={`teamEmployee_${idx}`}>
                  {name}
                  {editingThisTeam ? <Icon name='delete' onClick={this.onDelTeamEmployee(id).bind(this)} /> : ''}
                </Label>,
                idx > 0 && idx % 7 === 0 && <br key={`teamEmployee_${idx}_linebreak`} />
              ]).flat().filter(x => x !== null)
          }
          {
              editingThisTeam ?
                <Modal
                  size='large'
                  onClose={() => this.setState({teamEmployeesModalOpen: false})}
                  onOpen={() => this.setState({teamEmployeesModalOpen: true})}
                  open={this.state.teamEmployeesModalOpen}
                  trigger={<Button onClick={() => this.setState({teamEmployeesModalOpen: true})} size="small" as="a" color='blue'>{teamEmployees.length === 0 ? "Ajouter des employés à l'équipe" :  `Modifier la liste (${teamEmployees.length} membres)`}</Button>}
                >
                  <Modal.Header>Configuration de l'équipe&nbsp;&nbsp;<b><Label size='huge' color="blue" content={t.name} /></b></Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      <Grid stackable verticalAlign='middle'>
                        <Grid.Row>
                          <Grid.Column width={7}>
                            <h3>Nombre de membres : <Label size='big' color="blue" content={this.state.editingTeam.employees.length} /></h3>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={7}>
                            <h4>Sélectionnez les employés à ajouter :</h4>
                            <Dropdown
                            upward={false}
                            placeholder="Sélectionnez un employé pour l'ajouter"
                            search
                            fluid
                            selection
                            onChange={this.onChangeTeamEmployee.bind(this)}
                            closeOnChange={false}
                            value={-1}
                            options={this.state.sortState.employee.data.filter((e) => e.teamId === undefined || e.teamId === null || e.teamId === t.id).filter((e) => this.state.editingTeam.employees.map(x => x.id).includes(e.id) === false).map((e, idx) => ({
                              key: e.id,
                              text: e.name,
                              value: e.id
                            }))}/>
                          </Grid.Column>
                          <Grid.Column width={9} textAlign='right'>
                            <List horizontal>
                              {
                                teamEmployees.map(({id, name}, idx) => 
                                  <List.Item key={`teamEmployee_${idx}`}><Label size="large" horizontal key={`teamEmployee_${idx}`}>
                                    {name}
                                    <Icon name='delete' onClick={this.onDelTeamEmployee(id).bind(this)} />
                                  </Label></List.Item>)
                              }
                            </List>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      content="Fermer"
                      onClick={() => this.setState({teamEmployeesModalOpen: false})}
                      positive
                    />
                  </Modal.Actions>
                </Modal>
              :
                <Button disabled size="small" color="blue">{teamEmployees.length === 0 ? "Ajouter des employés à l'équipe" :  `Modifier la liste (${teamEmployees.length} membres)`}</Button>
          }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <Button data-tooltip="Enregistrer les modifications" disabled={!editingThisTeam} data-position="bottom right" icon color='green' onClick={this.onTeamUpdate.bind(this)}>
            <Icon name='check' />
          </Button>
          <Modal 
            trigger={
              <Button disabled={!editingThisTeam} onClick={this.toggleModal('team').bind(this)} data-tooltip="Supprimer l'équipe" data-position="bottom right" icon color='red'>
                <Icon name='trash' />
              </Button>
            } 
            basic 
            size='small' 
            open={editingThisTeam && this.state.deleteTeamModal}
            onOpen={this.onModalOpen('team', t.id).bind(this)}>
            <Header icon='trash' content="Supprimer l'équipe" />
              <Modal.Content>
                <p>
                  Attention, la suppression d'une équipe est définitive.<br />
                  Êtes-vous certain de vouloir effectuer cette action ?<br />
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color='red' inverted onClick={this.toggleModal('team').bind(this)}>
                  <Icon name='remove' /> Non, Annuler
                </Button>
                <Button color='green' inverted onClick={this.onClickOnDel('team').bind(this)}>
                  <Icon name='checkmark' /> Oui, Confirmer
                </Button>
              </Modal.Actions>
          </Modal>
        </Table.Cell>
      </Table.Row>);
    });

    const errorMessageProfile = (this.state.errorProfile !== "" ?
      <Message negative>
        <Message.Header>Une erreur est survenue</Message.Header>
        <p>{JSON.stringify(this.state.errorProfile)}</p>
      </Message>
      :
      <div />
    );

    return (
        <Grid padded>
          <Grid.Row className="firstRow">
              <Header dividing as='h2'>
                <Icon name='user' />
                <Header.Content>
                  Gestion des employés
                  <Loader style={{float: 'right', marginLeft: '10px'}} inline active={this.state.loadingEmployees} />
                  <Button id="addEmployeeBtn" disabled={this.props.isAdmin() === false && this.props.isManagerWithWriteAccess() === false} onClick={this.onClickOnAddEmployee.bind(this)} compact icon='user plus' floated='right' color='green' content="Ajouter" size="tiny" style={{marginLeft: '10px'}} />
                  <Modal
                    centered
                    size="fullscreen"
                    id="importEmployeesModal"
                    onClose={() => this.onClickOnImportEmployee(false)}
                    onOpen={() => this.onClickOnImportEmployee(true)}
                    open={this.state.importEmployeeModal === true}
                    closeOnDimmerClick={false}
                    closeIcon={Object.keys(this.state.importedDATA).length === 0}
                    trigger={<Button id='importEmployeeBtn' disabled={this.props.isAdmin() === false} compact icon='clipboard list' floated='right' color='blue' size="tiny" style={{marginLeft: '10px'}} content="Importer une liste d'employés" />}
                    >
                    <Modal.Header>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Header as='h2'>
                            <Icon name='clipboard list' />
                            <Header.Content>
                              Importer une liste d'employés
                              <Header.Subheader>
                                <Popup
                                  style={{left: '10%'}}
                                  flowing
                                  hideOnScroll
                                  trigger={<p>Votre fichier doit être au format .csv&nbsp;&nbsp;&nbsp;<Button size='tiny' as='a'>Besoin d'aide&nbsp;&nbsp;<Icon name="info circle"/></Button></p>}
                                  on={'click'}
                                  position='bottom left'
                                  content={<Grid stackable padded>
                                    <Grid.Column width={10} verticalAlign='middle'>
                                      <b>Vous pouvez uniquement importer un fichier au format .csv</b><br /><br />
                                      <u>Passer facilement d'un fichier Excel à un ficher CSV :</u><br />
                                      <List ordered>
                                        <List.Item>Ouvrez votre fichier dans votre logiciel habituel</List.Item>
                                        <List.Item>Cliquez sur "Enregistrer sous"</List.Item>
                                        <List.Item>Choisissez le format :  "CSV (Séparateur: point-virgule) (*.csv)"</List.Item>
                                        <List.Item>Enregistrez et importez votre fichier</List.Item>
                                      </List>
                                    </Grid.Column>
                                    <Grid.Column width={6} verticalAlign='middle'>
                                    <Image size='medium' src='/ExcelToCSV.png' href='/ExcelToCSV.png'target='_blank' data-tooltip="Cliquez pour agrandir l'image"/>
                                    </Grid.Column>
                                    </Grid>
                                  }
                                />
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Modal.Header>
                    <Modal.Content>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Importer
                            locale={importerFRLocale}
                            dataHandler={async (rows) => {
                              this.setState({
                                importedDATA: rows
                              });
                              await new Promise((resolve) => setTimeout(resolve, 500));
                            }}
                            chunkSize={10000} // optional, internal parsing chunk size in bytes
                            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                            restartable={false} // optional, lets user choose to upload another file when import is complete
                            onStart={({ file, fields }) => {
                              // optional, invoked when user has mapped columns and started import
                            }}
                            onComplete={({ file, fields }) => {
                              // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                              const employeesToInsert = this.state.importedDATA.map(eData => {
                                let entryDate = moment(eData.entryDate, "DD/MM/YYYY");
                                let leftDate = moment(eData.leftDate, "DD/MM/YYYY");
                                if(!entryDate.isValid()) {
                                  entryDate = moment().hour(0).minute(0).second(0);
                                }
                                if(!leftDate.isValid()) {
                                  leftDate = null;
                                }
                                return Object.assign({
                                  name: '',
                                  number: '',
                                  badgeNo: '0000000000',
                                  email: '',
                                  login: '',
                                  pointPassword: '',
                                  entryDate: null,
                                  leftDate: null,
                                  profileId: 1,
                                  emailReportsEnabled: false,
                                  nightShiftEnabled: false
                                }, Object.assign({}, eData, {entryDate: entryDate.unix(), leftDate: leftDate !== null ? leftDate.unix() : null}));
                              });
                              this.state.socket.emit('ADD_EMPLOYEES', employeesToInsert);
                            }}
                            onClose={() => {
                              this.setState({
                                importEmployeeModal: !this.state.importEmployeeModal,
                                importedDATA: {},
                                editingEmployee: {}
                                // optional, invoked when import is done and user clicked "Finish"
                                // (if this is not specified, the widget lets the user upload another file)
                              });
                            }}
                            skipEmptyLines='greedy'
                          >
                            <ImporterField name="name" label="Nom et prénom" />
                            <ImporterField name="number" label="Matricule" optional />
                            <ImporterField name="entryDate" label="Date d'entrée" optional />
                            <ImporterField name="leftDate" label="Date de sortie" optional />
                            <ImporterField name="badgeNo" label="N° Badge" optional />
                            <ImporterField name="login" label="Login" optional />
                            <ImporterField name="pointPassword" label="Code d'accès" optional />
                            <ImporterField name="email" label="E-mail" optional />
                          </Importer>
                        </Grid.Column>
                      </Grid.Row>
                    </Modal.Content>
                  </Modal>
                  <Header.Subheader>{this.state.sortState.employee.data.length} employé(s) enregistré(s)</Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <Checkbox checked={this.state.onlyActiveEmployees} onChange={this.onToggleOnlyActiveEmployees.bind(this)} toggle label={(this.state.onlyActiveEmployees === false ? "Tous les employés" : "Seulement les employés actifs") + "  -  ( " + this.state.sortState.employee.data.filter(x => isEmployeeActive(x)).length + "/" + this.state.sortState.employee.data.length + " )"} />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button data-position="bottom left" data-tooltip={this.state.reportsVisible ? 'Masquer la colonne des rapports' : 'Afficher la colonne des rapports'} circular basic icon size='small' onClick={() => this.setState({reportsVisible: !this.state.reportsVisible})}>
                  <Icon name='mail' color={this.state.reportsVisible ? 'red' : 'green'} />
                </Button>
                <Button data-position="bottom left" data-tooltip={this.state.profilesVisible ? 'Masquer la colonne des horaires' : 'Afficher la colonne des horaires'} circular basic icon size='small' onClick={() => this.setState({profilesVisible: !this.state.profilesVisible})}>
                  <Icon name='calendar' color={this.state.profilesVisible ? 'red' : 'green'} />
                </Button>
              </Grid.Column>
            </Grid.Row>
          {
            this.state.error !== "" ?
            <Grid.Row>
              <Grid.Column width={16}>
                {errorMessage}
              </Grid.Column>
            </Grid.Row> : ""
          }
          <Grid.Row>
            <Segment basic style={{padding: '0 0 0 0', border: '0px', width: '100%', overflow: 'auto'/*, maxHeight: '700px'*/, minHeight: '100px'}}>
              <Table id="employeesTbl" singleLine striped selectable sortable style={{marginBottom: '50px'}}>
                <Table.Header id="firstHeader">
                  <Table.Row>
                    <Table.HeaderCell textAlign='center'>
                      Actif
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={this.state.sortState.employee.column === 'name' ? this.state.sortState.employee.direction : null}
                    onClick={this.handleSort('employee', 'name').bind(this)} textAlign='center'>
                      Nom &amp; Prénom&nbsp;&nbsp;&nbsp;
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'
                    sorted={this.state.sortState.employee.column === 'number' ? this.state.sortState.employee.direction : null}
                    onClick={this.handleSort('employee', 'number').bind(this)}>
                      <div data-tooltip="Renseignez le matricule de paie de l'employé pour exporter vos données dans un autre logiciel." data-position="bottom left">Matricule <Icon name='info circle' /></div>
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={this.state.sortState.employee.column === 'entryDate' ? this.state.sortState.employee.direction : null}
                    onClick={this.handleSort('employee', 'entryDate').bind(this)} textAlign='center'>
                      Date d'entrée
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={this.state.sortState.employee.column === 'leftDate' ? this.state.sortState.employee.direction : null}
                    onClick={this.handleSort('employee', 'leftDate').bind(this)} textAlign='center'>
                      <div data-tooltip="Si une date est spécifiée et atteinte, l'employé devient inactif et ses données sont conservées." data-position="bottom left">Date de sortie <Icon name='info circle' /></div>
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    style={{textAlign: 'center'}}
                    sorted={this.state.sortState.employee.column === 'login' ? this.state.sortState.employee.direction : null}
                    onClick={this.handleSort('employee', 'login').bind(this)}>
                      <div data-tooltip="Attribuez un couple login / code d'accès à vos employés pour leur donner accès au pointage en ligne.&#10;Pour créer un manager, attribuez-lui un couple login / code d'accès et nommez-le manager d'équipe." data-position="bottom right">Login <Icon name='info circle' /></div>
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{textAlign: 'center'}}>
                      <div data-tooltip="Les codes d'accès sont uniquement composés de chiffres - 4 chiffres minimum." data-position="bottom right">Code d'accès <Icon name='info circle' /></div>
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={this.state.sortState.employee.column === 'badgeNo' ? this.state.sortState.employee.direction : null}
                    onClick={this.handleSort('employee', 'badgeNo').bind(this)} style={{textAlign: 'center'}}>
                      <div data-tooltip="Reportez ici les numéros inscrits sur les badges qui vous ont été fournis." data-position="bottom right">N° de badge <Icon name='info circle' /></div>
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    sorted={this.state.sortState.employee.column === 'remotePointagesEnabled' ? this.state.sortState.employee.direction : null}
                    onClick={this.handleSort('employee', 'remotePointagesEnabled').bind(this)} style={{textAlign: 'center'}}>
                      <div data-tooltip="Permettre ou non le pointage depuis un PC pour chaque employé." data-position="bottom right">Pointage PC <Icon name='info circle' /></div>
                    </Table.HeaderCell>
                    {
                      this.state.reportsVisible ?
                      <Table.HeaderCell
                      sorted={this.state.sortState.employee.column === 'email' ? this.state.sortState.employee.direction : null}
                      onClick={this.handleSort('employee', 'email').bind(this)} textAlign='center'>
                        <div data-tooltip="Cet email sert uniquement pour l'envoi des rapports automatiques." data-position="bottom right">E-mail <Icon name='info circle' /></div>
                      </Table.HeaderCell>
                      : null
                    }
                    {
                      this.state.reportsVisible ?
                      <Table.HeaderCell
                      sorted={this.state.sortState.employee.column === 'emailReportsEnabled' ? this.state.sortState.employee.direction : null}
                      onClick={this.handleSort('employee', 'emailReportsEnabled').bind(this)} textAlign='center'>
                        <span data-tooltip={`Définissez les employés qui recevront des rapports automatiques.`} data-position="bottom right">Rapports <Icon name='info circle'></Icon></span>
                      </Table.HeaderCell>
                      : null
                    }
                    {
                      this.state.profilesVisible ?
                        <Table.HeaderCell
                          sorted={this.state.sortState.employee.column === 'defaultProfileId' ? this.state.sortState.employee.direction : null}
                          onClick={this.handleSort('employee', 'defaultProfileId').bind(this)}
                          style={{textAlign: 'center'}}>
                            Horaire par défaut
                        </Table.HeaderCell>
                        : null
                    }
                    {
                      this.state.profilesVisible ?
                        <Table.HeaderCell
                          sorted={this.state.sortState.employee.column === 'activeProfileId' ? this.state.sortState.employee.direction : null}
                          onClick={this.handleSort('employee', 'activeProfileId').bind(this)}
                          style={{textAlign: 'center'}}>
                            Horaire actif
                        </Table.HeaderCell>
                        : null
                    }
                    {
                      this.state.profilesVisible ?
                        <Table.HeaderCell style={{textAlign: 'center'}}>Depuis le</Table.HeaderCell>
                        : null
                    }
                    {
                      this.state.profilesVisible ?
                        <Table.HeaderCell
                          sorted={this.state.sortState.employee.column === 'nightShiftEnabled' ? this.state.sortState.employee.direction : null}
                          onClick={this.handleSort('employee', 'nightShiftEnabled').bind(this)}
                          style={{textAlign: 'center'}}>
                            <span data-tooltip="Activez le mode nuit pour les employés effectuant des postes à cheval sur deux jours.&#10;Rendez-vous dans les Paramètres pour régler l'heure de fin de poste des employés de nuit." data-position="bottom right">Mode nuit <Icon name='info circle'></Icon></span>
                        </Table.HeaderCell>
                        : null
                    }
                    <Table.HeaderCell width={1} style={{textAlign: 'center'}}>
                      <span data-tooltip="Cliquez sur une ligne pour la modifier sans oublier d'enregistrer vos modifications avant de passer à la suivante.&#10;Appuyez sur la touche ECHAP pour annuler les modifications en cours." data-position="bottom right"><Icon name='info circle'></Icon></span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {employeesHTML}
                  {employeesHTML.length === 0 ? <Table.Row><Table.Cell colSpan='15'></Table.Cell></Table.Row> : null}
                </Table.Body>
              </Table>
            </Segment>
            <Modal
              size='large'
              open={this.state.planningProfileModal}
              closeOnTriggerMouseLeave={false}
              closeOnDimmerClick={false}
              onClose={() => this.setState({planningProfileModal: false})}
            >
              <Modal.Header>Planning des horaires de&nbsp;&nbsp;<b>{this.state.editingEmployee.name}</b></Modal.Header>
              <Modal.Content>
                <Grid stackable>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <h4>Horaire par défault :&nbsp;<Label size='large' color="blue" content={(getDefaultProfileForEmployee(this.state.editingEmployee) || {name: ''}).name} /></h4>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column width={12}>
                      <h4>Ajouter une règle pour appliquer un horaire spécifique à votre employé sur une période définie.
                      <br />
                      Les journées qui ne correspondent à aucune règle utiliseront automatiquement l'horaire par défaut de l'employé.</h4>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <div>
                      <Button icon labelPosition='left' color='green' size="medium" floated='right' onClick={() => {
                        this.setState({
                          editingEmployee: Object.assign({}, this.state.editingEmployee, {
                            employeeProfiles: [...(this.state.editingEmployee.employeeProfiles || []), {profileId: profilesToSelect[0].key, start: moment().hour(0).minute(0).second(0).unix(), end: moment().hour(23).minute(59).second(59).unix()}]
                          })
                        })
                      }}>
                        <Icon name="plus" />
                        Ajouter&nbsp;une&nbsp;règle
                      </Button>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Table celled>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell>Horaire à appliquer</Table.HeaderCell>
                      <Table.HeaderCell>Date de début</Table.HeaderCell>
                      <Table.HeaderCell>Date de fin</Table.HeaderCell>
                      <Table.HeaderCell width={1}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {
                    this.state.editingEmployee.employeeProfiles && this.state.editingEmployee.employeeProfiles.filter(ep => ep.end !== null && ep.start > 0).map((ep, epIdx) =>
                      <Table.Row key={`ep_${ep.start}_${epIdx}`}>
                        <Table.Cell>
                        <Dropdown
                          placeholder='Profil horaire...'
                          fluid
                          search
                          selection
                          options={profilesToSelect/*.filter(p => p.key !== this.state.editingEmployee.defaultProfileId)*/}
                          value={ep.profileId}
                          onChange={(event, data) => {
                            this.setState({
                              editingEmployee: Object.assign({}, this.state.editingEmployee, {
                                employeeProfiles: this.state.editingEmployee.employeeProfiles.map(epL => ep == epL ? Object.assign({}, epL, {profileId: data.value}) : epL)
                              })
                            })
                          }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <DateInput
                            name="ruleProfileStart"
                            dateFormat="DD/MM/YYYY"
                            closeOnMouseLeave={false}
                            fluid
                            closable={true}
                            clearable={false}
                            placeholder="Date d'activation de l'horaire"
                            hideMobileKeyboard={true}
                            value={
                              moment.unix(ep.start).format("DD/MM/YYYY")
                            }
                            iconPosition="left"
                            onChange={(evt, data) => {
                              const newStart = moment(data.value, "DD/MM/YYYY").hour(0).minute(0).second(0).unix();
                              this.setState({
                                editingEmployee: Object.assign({}, this.state.editingEmployee, {
                                  employeeProfiles: this.state.editingEmployee.employeeProfiles.map(epL => ep == epL ? Object.assign({}, epL, {start: newStart, end: epL.end < newStart ? newStart : epL.end}) : epL)
                                })
                              })
                            }}
                            animation='none'
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <DateInput
                            name="ruleProfileEnd"
                            dateFormat="DD/MM/YYYY"
                            closeOnMouseLeave={false}
                            minDate={moment.unix(ep.start)}
                            fluid
                            closable={true}
                            clearable={false}
                            placeholder="Date de fin d'activation de l'horaire"
                            hideMobileKeyboard={true}
                            value={
                              moment.unix(ep.end).format("DD/MM/YYYY")
                            }
                            iconPosition="left"
                            onChange={(evt, data) => {
                              this.setState({
                                editingEmployee: Object.assign({}, this.state.editingEmployee, {
                                  employeeProfiles: this.state.editingEmployee.employeeProfiles.map(epL => ep == epL ? Object.assign({}, epL, {end: moment(data.value, "DD/MM/YYYY").hour(23).minute(59).second(59).unix()}) : epL)
                                })
                              })
                            }}
                            animation='none'
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Button onClick={() => {
                            this.setState({
                              editingEmployee: Object.assign({}, this.state.editingEmployee, {
                                employeeProfiles: this.state.editingEmployee.employeeProfiles.filter((epL) => epL !== ep)
                              })
                            });
                          }} data-tooltip="Supprimer la règle" data-position="bottom right" icon color='red'>
                            <Icon name='trash' />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  }
                  </Table.Body>
                </Table>
              </Modal.Content>
              <Modal.Actions>
                <Button positive onClick={() => {
                  this.setState({planningProfileModal: false});
                }}>
                  Fermer
                </Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row className="firstRow">
              <Header dividing as='h2'>
                <Icon name='calendar alternate outline' />
                <Header.Content>
                  Gestion des horaires
                  <Loader style={{float: 'right', marginLeft: '10px'}} inline active={this.state.loadingProfiles} />
                  <Button disabled={this.props.isAdmin() === false && this.props.isManagerWithWriteAccess() === false} onClick={this.onClickOnAddProfile.bind(this)} compact icon='calendar plus' floated='right' color='green' content="Ajouter" size="tiny" style={{marginLeft: '10px'}} />
                  <Header.Subheader>{this.state.sortState.profile.data.length} horaire(s) enregistré(s)</Header.Subheader>
                </Header.Content>
              </Header>
          </Grid.Row>
          {
            this.state.errorProfile !== "" ?
            <Grid.Row>
              <Grid.Column width={14}>
                {errorMessageProfile}
              </Grid.Column>
            </Grid.Row>
            : ""
          }
          <Grid.Row>
            <Segment basic style={{padding: '0 0 0 0', border: '0px', width: '100%', overflow: 'auto'/*, maxHeight: '400px'*/, minHeight: '100px'}}>
              <Table id="profilesTbl" singleLine striped selectable sortable style={{marginBottom: '50px'}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={3} textAlign='center'
                    sorted={this.state.sortState.profile.column === 'active' ? this.state.sortState.profile.direction : null}
                    onClick={this.handleSort('profile', 'name').bind(this)}>
                      Nom de l'horaire
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign='center'>Cycle</Table.HeaderCell>
                    <Table.HeaderCell  colSpan='7' textAlign='center'>
                      Volumes d'heures théoriques par jour&nbsp;
                      <Popup flowing hoverable position="top center" trigger={<Icon name='info circle' size='small' />}>
                        <Grid centered columns={1}>
                          <Grid.Column textAlign='center'>
                            <Header as='h4'>
                              Fonctionnement des horaires
                            </Header>
                          </Grid.Column>
                        </Grid>
                        <Divider />
                        <Grid centered columns={1}>
                          <Grid.Column>
                          Les horaires vous permettent de définir les volumes d'heures théoriques attendus des employés pour chaque jour de la semaine.<br />
                          Vous pouvez créer autant de profils que nécessaire et les appliquer aux employés concernés.<br /><br />
                          Gérez vos cycles en créant des horaires sur plusieurs semaines via le bouton&nbsp;&nbsp;<Icon name='calendar plus' color='blue' />
                          </Grid.Column>
                        </Grid>
                      </Popup>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign='center'>
                      <span data-tooltip="Cliquez sur une ligne pour la modifier sans oublier d'enregistrer vos modifications avant de passer à la suivante.&#10;Appuyez sur la touche ECHAP pour annuler les modifications en cours." data-position="bottom right"><Icon name='info circle'></Icon></span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {profilesHTML}
                  {profilesHTML.length === 0 ? <Table.Row><Table.Cell colSpan='15'></Table.Cell></Table.Row> : null}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Row>
          <Divider hidden />
          {
            this.props.isAdmin() === true ?
            [<Grid.Row className="firstRow" key="teamlistHeader">
                <Header dividing as='h2'>
                  <Icon name='users' />
                  <Header.Content>
                    Gestion des équipes
                    <Loader style={{float: 'right', marginLeft: '10px'}} inline active={this.state.loadingTeams} />
                    <Button onClick={this.onClickOnAddTeam.bind(this)} compact icon='user plus' floated='right' color='green' content="Ajouter" size="tiny" style={{marginLeft: '10px'}} />
                    <Header.Subheader>{this.state.sortState.team.data.length} équipe(s) enregistrée(s)</Header.Subheader>
                  </Header.Content>
                </Header>
            </Grid.Row>,
            <Grid.Row key="teamlistData">
              <Segment basic style={{padding: '0 0 0 0', border: '0px', width: '100%', overflow: 'auto'/*, maxHeight: '400px'*/, minHeight: '100px'}}>
                <Table id="teamsTbl" singleLine striped selectable sortable style={{marginBottom: '50px'}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell 
                      width={3}
                      sorted={this.state.sortState.team.column === 'active' ? this.state.sortState.team.direction : null}
                      onClick={this.handleSort('team', 'name').bind(this)} style={{textAlign: 'center'}}>
                        Nom de l'équipe
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3} style={{textAlign: 'center'}}>Manager de l'équipe</Table.HeaderCell>
                      <Table.HeaderCell width={2} style={{textAlign: 'center'}}>
                        Droits du manager&nbsp;
                        <Popup flowing hoverable position="top center" trigger={<Icon name='info circle' size='small' />}>
                          <Grid centered columns={1}>
                            <Grid.Column textAlign='center'>
                              <Header as='h4'>
                                Les managers ont uniquement accès aux informations des membres de leur équipe.<br />
                                Vous pouvez définir les droits de vos managers selon 2 niveaux.
                              </Header>
                            </Grid.Column>
                          </Grid>
                          <Divider />
                          <Grid centered divided columns={2}>
                            <Grid.Column>
                              <Header textAlign='center' as='h4'>Voir seulement</Header>
                                <List bulleted>
                                  <List.Item>Visualiser les informations des membres de son équipe</List.Item>
                                  <List.Item>Lire les messages</List.Item>
                                  <List.Item>Ajouter un commentaire à la journée.</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                              <Header textAlign='center' as='h4'>Voir & Modifier</Header>
                                <List bulleted>
                                  <List.Item>Créer un employé au sein de son équipe</List.Item>
                                  <List.Item>Visualiser et modifier les informations des membres de son équipe</List.Item>
                                  <List.Item>Ajouter, modifier et supprimer les horaires</List.Item>
                                  <List.Item>Lire et traiter les messages</List.Item>
                                  <List.Item>Ajouter et modifier les pointages, commentaires et compteurs</List.Item>
                                </List>
                            </Grid.Column>
                          </Grid>
                        </Popup>
                      </Table.HeaderCell>
                      <Table.HeaderCell width={7} style={{textAlign: 'center'}}>
                        Membres de l'équipe
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{textAlign: 'center'}}>
                        <span data-tooltip="Cliquez sur une ligne pour la modifier sans oublier d'enregistrer vos modifications avant de passer à la suivante.&#10;Appuyez sur la touche ECHAP pour annuler les modifications en cours." data-position="bottom right"><Icon name='info circle'></Icon></span>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {teamsHTML}
                    {teamsHTML.length === 0 ? <Table.Row><Table.Cell colSpan='15'></Table.Cell></Table.Row> : null}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Row>
            ]
            : ''
          }
        </Grid>
    );
  }
}

export default Employees;
