import {timezone} from './localSettings';

// Require because multiple moment plugins
let moment = require('moment-business-days');
require('moment/locale/fr');

//moment.locale('fr');
moment.updateLocale('fr', {
    workingWeekdays: [1, 2, 3, 4, 5]
 });

moment = require('moment-timezone');
export default (tz = timezone()) => {
    moment.tz.setDefault(tz);
    return moment;
};