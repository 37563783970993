const getTourStep = (title, content, selector = 'body', placement = 'center', clickToLeave = false) => {
    return {
      target: selector,
      content,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      showSkipButton: true,
      hideFooter: clickToLeave,
      placement,
      spotlightClicks: clickToLeave,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title
    };
};

export default [
    getTourStep(
        'Bienvenue dans ORANE',
        'Bonjour et bienvenue dans ORANE. Cet assistant va maintenant vous assister dans vos premiers pas.'
    ),
    getTourStep(
        'Bien démarrer',
        'Si vous vous connectez pour la première fois, votre base est vide. Sur la gauche de l\'écran se situe le menu principal.',
        '#sidebar',
        'right'
    ),
    getTourStep(
        'Ajouter des employés',
        'Nous allons tout d\'abord apprendre à ajouter un employé. Pour cela, cliquez sur le menu concerné à gauche de l\'écran. Essayez.',
        '#employeesMenu',
        'right',
        true
    ),
    getTourStep(
        'Ajouter des employés',
        'Bravo ! Vous vous situez désormais dans le menu de gestion des salariés et des profils horaires.'
    ),
    getTourStep(
        'Ajouter des employés',
        'Voici la liste des profils horaires. Si vous vous connectez pour la première fois, vous ne devriez voir qu\'un seul profil.',
        '#profilesTbl',
        'top'
    ),
    getTourStep(
        'Ajouter des employés',
        'Et voici la liste des employés. Si vous vous connectez pour la première fois, elle devrait être vide.',
        '#employeesTbl',
        'bottom'
    ),
    getTourStep(
        'Ajouter des employés',
        'Pour ajouter un employé, appuyez simplement sur le bouton d\'ajout situé à droite de la liste. Essayez.',
        '#addEmployeeBtn',
        'bottom',
        false
    ),
    getTourStep(
        'Ajouter des employés',
        'Bien joué. Vous constatez que le nouvel employé apparait dans la liste. Vous pouvez désormais le modifier. Une fois fait, appuyez sur le bouton d\'enregistrement à la fin de la ligne',
        '#employeesTbl',
        'bottom',
        false
    ),
    getTourStep(
        'Ajouter des employés',
        "Vous pourrez modifier votre employé à l'avenir en cliquant sur la ligne correspondante."
    ),
    getTourStep(
        'Gérer ses pointages',
        "Nous allons maintenant apprendre à gérer les pointages. Cliquez sur le menu Pointages.",
        '#pointagesMenu',
        'right',
        true
    ),
    getTourStep(
        'Gérer ses pointages',
        "Vous voilà dans le menu Pointages. Il est utilisé pour visualiser et gérer en temps réel les données de votre base. La journée affichée par défaut est la journée de la veille."
    ),
    getTourStep(
        'Gérer ses pointages',
        'La partie supérieure de cet écran regroupe les différents filtres que vous pouvez appliquer à vos données. Présentons rapidement les différentes zones.'
    ),
    getTourStep(
        'Gérer ses pointages',
        'Cette zone vous informe de la plage de temps actuellement affichée.',
        '#currentPeriodFilter',
        'bottom'
    ),
    getTourStep(
        'Gérer ses pointages',
        'Cette zone vous permet de sélectionner ou bien un seul employé, ou bien tous.',
        '#employeeFilter',
        'bottom'
    ),
    getTourStep(
        'Gérer ses pointages',
        'Cette zone vous permet de sélectionner la plage de temps que vous souhaitez visualiser.',
        '#periodFilter',
        'bottom'
    ),
    getTourStep(
        'Gérer ses pointages',
        'Cette zone vous permet de visualiser les données par date, par salarié, ou par site géographique.',
        '#byDateFilter',
        'bottom'
    ),
    getTourStep(
        'Gérer ses pointages',
        "Enfin, ces boutons vous permettent d'exporter les données vers un autre format (Excel ou PDF).",
        '#exportButtons',
        'bottom'
    ),
    getTourStep(
        'Gérer ses pointages',
        "Les résultats de vos filtres s'affichent en temps réel dans la zone inférieure.",
        '#pointagesTbl',
        'top'
    ),
    getTourStep(
        'Gérer ses pointages',
        "Si vous vous connectez pour la première fois, votre nouvel employé ne devrait pas avoir de pointage enregistré, c'est tout à fait normal.",
        '.pointagesCell',
        'top'
    ),
    getTourStep(
        'Gérer ses pointages',
        "Même si les employés utilisent les badgeuses, l'application vous permet de modifier les données brutes (afin, par exemple, de corriger une erreur). Ajoutez un pointage à votre employé.",
        '.addPointageButton',
        'bottom',
        false
    ),
    getTourStep(
        'Gérer ses pointages',
        "Bien. Maintenant vous pouvez saisir l'heure du pointage et appuyer sur le bouton de confirmation. Essayez.",
        '.pointagesCell',
        'bottom',
        false
    ),
    getTourStep(
        'Gérer ses pointages',
        "Vous pouvez constater que le pointage a bien été ajouté. La petite icône en forme de croix vous permet de le supprimer au besoin.",
        '.pointagesCell',
        'bottom'
    ),
    getTourStep(
        'Le Tableau de Bord',
        "Nous pouvons enfin avoir un aperçu du Tableau de Bord ! Cliquez sur le lien correspondant dans le menu.",
        '#dashboardMenu',
        'right',
        true
    ),
    getTourStep(
        'Le Tableau de Bord',
        "Le Tableau de Bord est l'écran principal de l'application. Il vous permet de visualiser en un clin d'oeil les données d'aujourd'hui.",
    ),
    getTourStep(
        'Le Tableau de Bord',
        "Comme vous pouvez le constater, votre employé est présent, comme l'indique la pastille verte. Ses données temporelles s'actualiseront en temps réel lorsqu'il pointera de nouveau.",
        '.dashEmployeeLine',
        'bottom'
    ),
    getTourStep(
        'Le Tableau de Bord',
        "Le Tableau de Bord affiche également le taux de présence global sur les dernières périodes de temps.",
        '.presenceRatios',
        'bottom'
    ),
    getTourStep(
        'Fin du Didacticiel',
        "Vous maîtrisez à présent les bases de votre portail ORANE. Pour toute question relative à l'utilisation, n'hésitez pas à nous contacter.",
    ),
    getTourStep(
        'Fin du Didacticiel',
        "TimeFast vous remercie et vous souhaite un agréable moment en compagnie de votre portail ORANE.",
    )
];