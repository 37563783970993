import Moment from './momentfr';
import daysOfWeek from './dow';
import { timezone } from './localSettings';

const getAllProfilesForEmployee = function(e) {
    return e.employeeProfiles.map(ep => ({
        id: ep.profileId,
        nbHoursExpected: ep.profile.nbHoursExpected,
        name: ep.profile.name,
        start: ep.start,
        end: ep.end
    }));
};

const getStartTSForDefaultProfile = function(e, now) {
    const allP = getAllProfilesForEmployee(e);
    const lastP = allP.filter(x => x.start < now && x.end !== null && x.end !== undefined && x.end < now && x.start < x.end).sort((x1, x2) => x1.start < x2.start ? 1 : -1);
    return lastP.length > 0 ? lastP[0].end+1 : e.entryDate ? e.entryDate : 0;
};

const getCurrentProfileForEmployee = function(e, now, hourEndByDay) {
    const allP = getAllProfilesForEmployee(e);
    const customMidnight = hourEndByDay();
    let p = allP.find(p => (p.start+customMidnight*3600) <= now && p.end !== null && (p.end+customMidnight*3600) >= now);
    if(p === undefined) {
        // Fallback to default profile
        p = allP.find(p => p.end === null);
        if(p === undefined) {
            // Should never happen
            p = {start: 0, end: null, nbHoursExpected: [Object.keys(daysOfWeek).reduce((acc,dow) => Object.assign({}, acc, {[dow]: 0}), {})]};
        }
    }

    return p;
};

const checkIfSpecificRuleForToday = function(e, now, timezoneVal = timezone()) {
    const moment = Moment(timezoneVal);
    return e.expectedHours.find(x => x.day === moment.unix(now).format("DD/MM/YYYY"));
}

const getNbHoursExpectedFromProfile = function(e, now, hourEndByDay, timezoneVal = timezone()) {
    const moment = Moment(timezoneVal);
    const weekDay = moment.unix(now).locale('en').format('ddd').toUpperCase();

    const ruleForToday = checkIfSpecificRuleForToday(e, now, timezoneVal);
    if(ruleForToday !== undefined) {
        return ruleForToday.hours;
    } else {
        const customMidnight = hourEndByDay();

        // Multiple weeks, we need to know the current one
        const p = getCurrentProfileForEmployee(e, now, hourEndByDay);
        const numberOfWeeks = p.nbHoursExpected.length;
        const startTS = p.end === null ? getStartTSForDefaultProfile(e, now) : p.start;
        const startM = moment.unix(startTS);
        const startMondayMidnightM = startM.weekday(0).hour(customMidnight).minute(0).second(0);
        const nowM = moment.unix(now);
        const nowMondayMidnightM = nowM.weekday(0).hour(customMidnight).minute(0).second(0);
        // We check this to be sure
        // Else triggered would mean that queried date is before employee's entryDate.
        if(nowMondayMidnightM.isAfter(startMondayMidnightM) || nowMondayMidnightM.isSame(startMondayMidnightM)) {
            const diffInWeeks = nowMondayMidnightM.diff(startMondayMidnightM, 'weeks');
            const weekIndex = diffInWeeks % numberOfWeeks;
            //console.log(`nowMondayMidnightM=${nowMondayMidnightM}, startMondayMidnightM=${startMondayMidnightM}`)
            //console.log(`diffInWeeks=${diffInWeeks}, weekIndex=${weekIndex}`)
            return p.nbHoursExpected[weekIndex][weekDay];
        } else {
            return Object.keys(daysOfWeek).reduce((acc,dow) => Object.assign({}, acc, {[dow]: 0}), {})[weekDay];
        }
    }
};

export {getCurrentProfileForEmployee, getNbHoursExpectedFromProfile, getStartTSForDefaultProfile, checkIfSpecificRuleForToday};