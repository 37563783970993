import io from 'socket.io-client';
import ls from 'local-storage';

let currentSocket = undefined;

const syncSettings = (serverSettingsStr) => {
  let badServerSettings = false;
  if(serverSettingsStr === null || serverSettingsStr === undefined || serverSettingsStr.length === undefined || serverSettingsStr.length === null || serverSettingsStr.length <= 0) {
    badServerSettings = true;
  }
  try {
    const p = JSON.parse(serverSettingsStr);
    if(typeof p !== 'object' || p === null) {
      badServerSettings = true;
    }
  } catch {
    badServerSettings = true;
  }


  if(badServerSettings) {
    // No settings (or bad settings) on the server, let's push ours
    //console.log('Using client settings (bad server setting, or no setting at all)')
    currentSocket.emit('SET_SETTINGS', ls('settings'));
  } else {
    //console.log('Using server settings')
    // Let's use server's settings
    let localSettings = {};
    try {
      localSettings = JSON.parse(ls('settings'));
    } catch {
      ls('settings', '{}')
    }
    const serverSettings = JSON.parse(serverSettingsStr);
    ls('settings', JSON.stringify(Object.assign({}, localSettings, {global: serverSettings.global || null})));
  }
};

const ioWithAuth = (email, password, token) => {
  return new Promise((resolve, reject) => {
    const socket = io();
    socket.on('AUTH_REQUIRED', () => {
      if(token !== undefined) {
        socket.emit('AUTH_CREDS', {token});
      } else {
        socket.emit('AUTH_CREDS', {email, password});
      }

      socket.on('AUTH_SUCCESSFUL', ({token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, localSettings, isAdmin}) => {
        currentSocket = socket;
        syncSettings(localSettings);
        resolve([currentSocket, token, companyCode, companyName, subscriptionStart, JSON.parse(rpiHardware), JSON.parse(phoneHardware), true, false]);
      });

      socket.on('AUTH_ERROR', (err) => {
        socket.disconnect();
        reject(err);
      });

      // TODO onConnect ? For reconnections ?

      socket.on('disconnect', () => {
        currentSocket = undefined;
        console.log(`DISCONNECTED`);
      });
    });
  });
};

const ioWithAuthAsUser = (login, password, companyCode, token) => {
  return new Promise((resolve, reject) => {
    const socket = io();
    socket.on('AUTH_REQUIRED', () => {
      if(token !== undefined) {
        socket.emit('AUTH_CREDS', {token});
      } else {
        socket.emit('AUTH_CREDS', {login, password, companyCode});
      }

      socket.on('AUTH_SUCCESSFUL', ({token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, localSettings, isAdmin, isManagerWithWriteAccess, myTeamId}) => {
        currentSocket = socket;
        syncSettings(localSettings);
        resolve([currentSocket, token, companyCode, companyName, subscriptionStart, JSON.parse(rpiHardware), JSON.parse(phoneHardware), false, isManagerWithWriteAccess, myTeamId]);
      });

      socket.on('AUTH_ERROR', ({err}) => {
        socket.disconnect();
        reject(`YOU, SHALL NOT, PASS !!  ${JSON.stringify(err)}`);
      });

      // TODO onConnect ? For reconnections ?

      socket.on('disconnect', () => {
        currentSocket = undefined;
        console.log(`DISCONNECTED`);
      });
    });
  });
};

const ioCurrent = () => currentSocket;

export default {ioWithAuth, ioWithAuthAsUser, ioCurrent};