const PHOTON_API_ROOT = "https://photon.komoot.io";
const FRANCE_BBOX = "-5.383301,41.508577,8.811035,51.454007";

// Not graphhopper....geocoding not available in open source edition :(
const geocodeAddr = (searchStr) => {
    return fetch(
        //`${PHOTON_API_ROOT}/api/?q=${encodeURI(searchStr)}&limit=10&lang=fr&bbox=${FRANCE_BBOX}`,
        `${PHOTON_API_ROOT}/api/?q=${encodeURI(searchStr)}&limit=10&lang=fr`,
        {
            method: 'GET'
        }
    ).then((response) => {
        if (!response.ok) {
            throw new Error('Photon API error');
        }
        return response.json();
    }).then((json) => {
        return json.features.map(f => {
            const {housenumber, name, street, city, postcode, state, country} = f.properties;
            const tailaddr = `${housenumber ? housenumber + ' ' : ''}${street ? street + ', ' : ''}${postcode ? postcode + ' ' : ''}${city ? city : ''}`;
            const fulladdr = `${(name ? name : '') + (tailaddr !== '' ? (name ? ' - ' : '') + tailaddr : '')}`;
            return {
                lat: f.geometry.coordinates[1],
                lon: f.geometry.coordinates[0],
                fulladdr
            };
        });
    }).catch((err) => {
        // To avoid errors, we always expect a value
        return {};
    });;
}
const reverseGeocodeAddr = (lat, lon) => {
    return fetch(
        `${PHOTON_API_ROOT}/reverse?lon=${lon}&lat=${lat}&limit=1&lang=fr`,
        {
            method: 'GET'
        }
    ).then((response) => {
        if (!response.ok) {
            throw new Error('Photon API error');
        }
        return response.json();
    }).then((json) => {
        return (json.features[0] || {}).properties || {};
    }).catch((err) => {
        // To avoid errors, we always expect a value
        return {};
    });
}

const reverseGeocodeAddrStr = (lat, lon) => {
    return reverseGeocodeAddr(lat, lon).then((json) => {
        const {name, street, city, postcode, state, country} = json;
        const tailaddr = `${street ? street + ', ' : ''}${postcode ? postcode + ' ' : ''}${city ? city : ''}`;
        const fulladdr = `${(name ? name : '') + (tailaddr !== '' ? (name ? ' - ' : '') + tailaddr : '')}`;
        return fulladdr;
    });
}

export { geocodeAddr, reverseGeocodeAddr, reverseGeocodeAddrStr }