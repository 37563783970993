import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'leaflet/dist/leaflet.css';
import {
  Button,
  Divider,
  Grid,
  Icon,
  Label,
  Image,
  Menu,
  Message,
  Modal,
  Pagination
} from "semantic-ui-react";
import { Route, Switch, Redirect, Link, withRouter } from "react-router-dom";
import Dashboard from "./Dashboard";
import LoginForm from './LoginForm';
import Employees from "./Employees";
import Manage from "./Manage";
import Messages from './Messages';
import Movings from './Movings';
import Locations from './Locations';
import Settings from "./Settings";
import Profile from "./Profile";
//import Covid from './Covid';
import Logout from "./Logout";
import io from './utils/io';
import ls from 'local-storage';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import TOUR_STEPS from './utils/tour';
import {hourEndByDay, isModuleVisibleInMenu} from './utils/localSettings';
import Order from './Order';
import { CHANGELOG_PAGES, CURRENT_CHANGELOG_VERSION, AFTERMODAL_STEPS } from './utils/changelog';
import { ANNOUNCEMENTS_ENABLED, CURRENT_ANNOUNCEMENT, CURRENT_ANNOUNCEMENT_HEADER, CURRENT_ANNOUNCEMENT_VERSION } from './utils/announcement';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownMenuStyle: {
        display: "none"
      },
      loggedIn: false,
      companyCode: undefined,
      companyName: undefined,
      subscriptionStart: undefined,
      isAdmin: false,
      isManagerWithWriteAccess: false,
      myTeamId: undefined,
      rpiHardware: {},
      phoneHardware: {},
      tour: {
        steps: TOUR_STEPS,
        stepIndex: 0,
        run: false
      },
      changelogModal: false,
      notvalidatedMessageCount: 0,
      currentChangelogPage: 0,
      shouldRunAfterModal: false,
      runAfterModal: false,
      afterModalStep: 0,
      topMessageVisible: false
    };
    this.joyrideRef = React.createRef();
  }

  componentDidMount() {
    if(window.location.search.startsWith("?token=")) {
      ls('token', window.location.search.substring(7));
      ls('isAdmin', true);
      window.location.href = "/";
    }
    const token = ls('token');
    const isAdmin = ls('isAdmin');
    const isManagerWithWriteAccess = ls('isManagerWithWriteAccess');
    const myTeamId = ls('myTeamId');
    const changelogAlreadyDisplayed = ls('changelogAlreadyDisplayed');
    const announcementAlreadyDisplayed = ls('announcementAlreadyDisplayed');
    
    if(token !== null) {
      io.ioWithAuth(null, null, token).then(([socket, token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware]) => {
        this.login(token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, isAdmin, isManagerWithWriteAccess, myTeamId);
      }).catch((e) => {
        this.logout();
      });
    }

    if(changelogAlreadyDisplayed !== CURRENT_CHANGELOG_VERSION) {
      this.displayChangelogModal();
      ls('changelogAlreadyDisplayed', CURRENT_CHANGELOG_VERSION);
    }

    if(ANNOUNCEMENTS_ENABLED === true && announcementAlreadyDisplayed !== CURRENT_ANNOUNCEMENT_VERSION) {
      this.setState({topMessageVisible: true});
      ls('announcementAlreadyDisplayed', CURRENT_ANNOUNCEMENT_VERSION);
    }
  }

  displayChangelogModal(shouldRunAfterModal = true) {
    this.setState({changelogModal: true, shouldRunAfterModal});
  }

  onCloseChangelogModal() {
    this.setState({changelogModal: false, runAfterModal: this.state.shouldRunAfterModal, shouldRunAfterModal: false});
  }

  login(token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, isAdmin = false, isManagerWithWriteAccess = false, myTeamId = undefined) {
    const isAdminChecked = isAdmin === true ? true : false;
    const isManagerWithWriteAccessChecked = isManagerWithWriteAccess === true ? true : false;
    this.setState({loggedIn: true, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, isAdmin: isAdminChecked, isManagerWithWriteAccess: isManagerWithWriteAccessChecked, myTeamId});
    ls('token', token);
    ls('companyCode', companyCode);
    ls('companyName', companyName);
    ls('subscriptionStart', subscriptionStart);
    ls('rpiHardware', rpiHardware);
    ls('phoneHardware', phoneHardware);
    ls('isAdmin', isAdminChecked);
    ls('isManagerWithWriteAccess', isManagerWithWriteAccessChecked);
    ls('myTeamId', myTeamId);

    const socket = io.ioCurrent();
    if(socket !== undefined) {
      socket.on('GET_NOTVALIDATED_MESSAGE_COUNT', ({count}) => {
        if(count !== this.state.notvalidatedMessageCount) {
          this.setState({
            notvalidatedMessageCount: count
          });
        }
      });
    }
  }

  logout() {
    this.setState({loggedIn: false, companyCode: undefined, rpiHardware: {}, phoneHardware: {}, isAdmin: false, isManagerWithWriteAccess: false});
    ls.remove('token');
    ls.remove('companyCode');
    ls.remove('rpiHardware');
    ls.remove('phoneHardware');
    ls.remove('isAdmin');
    ls.remove('isManagerWithWriteAccess');
    const ioCurrent = io.ioCurrent();
    if(ioCurrent !== undefined) {
      ioCurrent.close();
    }
  }

  isAdmin() {
    return this.state.isAdmin;
  }

  isManagerWithWriteAccess() {
    return this.state.isManagerWithWriteAccess;
  }

  myTeamId() {
    return this.state.myTeamId;
  }

  handleCloseMenuIfOpen() {
    this.setState({
      dropdownMenuStyle: {
        display: "none"
      }
    });
  }

  handleToggleDropdownMenu() {
    let newState = Object.assign({}, this.state);
    if (newState.dropdownMenuStyle.display === "none") {
      newState.dropdownMenuStyle = { display: "flex" };
    } else {
      newState.dropdownMenuStyle = { display: "none" };
    }

    this.setState(newState);
  }

  handleTour(event) {
    if(this.state.tour.run === true) {
      this.setState({
        tour: Object.assign(this.state.tour, {stepIndex: this.state.tour.stepIndex+1})
      });
    }
  }

  runTour(event) {
    this.setState({
      tour: Object.assign(this.state.tour, {run: true, stepIndex: 0})
    });
  }

  handleAfterModalCallback(data) {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({
        afterModalStep: this.state.afterModalStep+1
      });
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ runAfterModal: false });
    }
  }

  handleJoyrideCallback(data) {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ 
        tour: Object.assign(this.state.tour, {stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) })
      });
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ tour: Object.assign(this.state.tour, {run: false })});
    } /*else {
      this.setState({
        tour:
      })
    }*/

    //console.groupCollapsed(type);
    //console.log(data); //eslint-disable-line no-console
    //console.groupEnd();
  }

  hourEnd(e) {
    return () => e !== undefined && e !== null && e.nightShiftEnabled ? hourEndByDay() : 0;
  }

  render() {
    if(this.state.loggedIn === false && this.props.location.pathname !== "/login") {
      return (
        <Redirect to={{
          pathname: "/login",
          state: { from: this.props.location }
        }} />
      );
    } else {
      const socket = io.ioCurrent();
      if(socket !== undefined) {
        socket.emit('GET_NOTVALIDATED_MESSAGE_COUNT', {});
      }
      return (
      <div className="App">
        <Switch>
          <Route path="/login" exact render={(props) => <LoginForm {...props} loggedIn={this.state.loggedIn} logIn={this.login.bind(this)} />} />
          <Route path="/" render={() => (
            <div>
            <Grid id="topRightMenu" padded className="tablet computer only">
            <Menu borderless inverted fluid fixed="top">
              <Link to="/" style={{marginLeft: '42%'}}>
                <Menu.Item header id="logo">
                  <div style={{
                    backgroundColor: '#1678c2',
                    padding: '7px',
                    borderRadius: '5px'
                  }}>
                    <Image size='medium' src='/logopc.png' />
                  </div>
                </Menu.Item>
              </Link>
              <Menu.Menu position="right">
                {/*isModuleVisibleInMenu('covid') && this.isAdmin() === true ? <Link data-tooltip="Covid-19 : Vérifier les contacts entre collaborateurs" data-position="bottom right" to="/covid19"><Menu.Item active={this.props.location.pathname === '/covid19'}><Image circular src='stopcovid.jpg' size='mini' /></Menu.Item></Link> : ''*/}
                {/*isModuleVisibleInMenu('tour') === true ? <Link data-tooltip="Aide en ligne" data-position="bottom left" to="#" onClick={this.runTour.bind(this)}><Menu.Item active={this.state.tour.run === true}><Icon fitted size='big' inverted name="question circle" /></Menu.Item></Link> : ''*/}
                <Link to="/compte" data-tooltip="Compte et Aide en ligne" data-position="bottom right" ><Menu.Item active={this.props.location.pathname === '/compte'}><Icon fitted size='big' inverted name="user circle" /></Menu.Item></Link>
                {this.isAdmin() === true || this.isManagerWithWriteAccess() === true ? <Link id="orderProducts" to="/commande" data-tooltip="Commander du matériel supplémentaire" data-position="bottom right" ><Menu.Item active={this.props.location.pathname === '/commande'}><Icon.Group size='big'><Icon fitted name="cart plus"/><Icon corner='bottom left' name="star" color='yellow' inverted /></Icon.Group></Menu.Item></Link> : ''}
                <Link to="/parametres" data-tooltip="Accèder aux Paramètres" data-position="bottom right" ><Menu.Item active={this.props.location.pathname === '/parametres'}><Icon fitted size='big' inverted name="settings" /></Menu.Item></Link>
                <Link to="/logout" data-tooltip="Se déconnecter" data-position="bottom right"><Menu.Item active={this.props.location.pathname === '/logout'}><Icon fitted size='big' inverted name="sign out" /></Menu.Item></Link>
              </Menu.Menu>
            </Menu>
          </Grid>
          <Grid id="topRightMenu" padded className="mobile only">
            <Menu borderless inverted fluid fixed="top">
              <Link to="/">
                <Menu.Item header id="headerMobile">
                  <div style={{
                      backgroundColor: '#1678c2',
                      padding: '5px',
                      borderRadius: '5px'
                    }}>
                      <Image inline size='tiny' src='/logo.png' />
                    </div>
                </Menu.Item>
              </Link>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Link to="#">
                    <Button
                      basic
                      inverted
                      icon
                      toggle
                      size='mini'
                      onClick={this.handleToggleDropdownMenu.bind(this)}
                    >
                      <Icon name="content" />
                    </Button>
                  </Link>
                  {
                    /*isModuleVisibleInMenu('covid') ?
                      <Link to="/covid19">
                        <Button
                          basic
                          inverted
                          toggle
                          size='mini'
                          style={{paddingLeft: '7px', paddingRight: '5px', paddingTop: '5px', paddingBottom: '5px'}}
                          onClick={this.handleCloseMenuIfOpen.bind(this)}
                        >
                          <Image avatar fluid src='stopcovid.jpg' />
                        </Button>
                      </Link>
                    : ''*/
                  }
                  <Link to="/compte">
                    <Button
                      basic
                      inverted
                      icon
                      toggle
                      size='mini'
                      onClick={this.handleCloseMenuIfOpen.bind(this)}
                    >
                      <Icon inverted name="user circle" />
                    </Button>
                  </Link>
                  {this.isAdmin() === true || this.isManagerWithWriteAccess() === true ?
                  <Link to="/commande">
                    <Button
                      basic
                      inverted
                      icon
                      toggle
                      size='mini'
                      onClick={this.handleCloseMenuIfOpen.bind(this)}
                    >
                      <Icon inverted name="cart plus" />
                    </Button>
                  </Link> :''}
                  <Link to="/parametres">
                    <Button
                      basic
                      inverted
                      icon
                      toggle
                      size='mini'
                      onClick={this.handleCloseMenuIfOpen.bind(this)}
                    >
                      <Icon inverted name="settings" />
                    </Button>
                  </Link>
                  <Link to="/logout">
                    <Button
                      basic
                      inverted
                      icon
                      toggle
                      size='mini'
                      onClick={this.handleCloseMenuIfOpen.bind(this)}
                    >
                      <Icon inverted name="sign out" />
                    </Button>
                  </Link>
                </Menu.Item>
              </Menu.Menu>
              <Menu
                borderless
                fluid
                inverted
                vertical
                style={this.state.dropdownMenuStyle}
              >
                <Link to="/" onClick={this.handleToggleDropdownMenu.bind(this)}>
                <Menu.Item header active={this.props.location.pathname === "/"}>
                  <Icon inverted name="building" />
                  Tableau de Bord
                </Menu.Item>
                </Link>
                {
                isModuleVisibleInMenu('pointages') ? [
                <Divider fitted key="pointages_divider" />,
                <Link key="pointages_link" to="/temps" onClick={this.handleToggleDropdownMenu.bind(this)}>
                  <Menu.Item active={this.props.location.pathname === "/temps"}>
                    <Icon inverted name="clock" />
                    Temps
                  </Menu.Item>
                </Link>
                ] : <div />
                }
                {
                isModuleVisibleInMenu('movings') ?
                [<Link key="movings_link" to="/deplacements" onClick={this.handleToggleDropdownMenu.bind(this)}>
                  <Menu.Item active={this.props.location.pathname === "/deplacements"}>
                    <Icon inverted name="map signs" />
                    Déplacements
                  </Menu.Item>
                </Link>,
                <Divider key="sites_divider" fitted />
                ] : <div />
                }
                {
                isModuleVisibleInMenu('messages') ?
                <Link to="/messagerie" onClick={this.handleToggleDropdownMenu.bind(this)}>
                  <Menu.Item active={this.props.location.pathname === "/messagerie"}>
                    <Icon inverted name="envelope" />
                    Messagerie{this.state.notvalidatedMessageCount > 0 ? <Label style={{marginTop: '0', marginBottom: '0'}} circular as='div' color='red'>{this.state.notvalidatedMessageCount}</Label> : ''}
                  </Menu.Item>
                </Link>
                : <div />
                }
                <Divider fitted />
                <Link to="/collaborateurs" onClick={this.handleToggleDropdownMenu.bind(this)}>
                  <Menu.Item active={this.props.location.pathname === "/collaborateurs"}>
                    <Icon inverted name="address card" />
                    Collaborateurs
                  </Menu.Item>
                </Link>
                <Divider fitted />
                {
                isModuleVisibleInMenu('locations') ?
                [<Link key="materiels_link" to="/materiels" onClick={this.handleToggleDropdownMenu.bind(this)}>
                  <Menu.Item active={this.props.location.pathname === "/materiels"}>
                    <Icon inverted name="hdd" />
                    Matériels
                  </Menu.Item>
                </Link>,
                <Divider key="materiels_divider" fitted />
                ] : <div />
                }
              </Menu>
            </Menu>
          </Grid>
          <Divider hidden />
          <Grid padded>
            <Grid.Column
              tablet={2}
              computer={1}
              only="tablet computer"
              id="sidebar"
            >
              <Grid.Row>
              <Menu vertical borderless fluid text>
                <Link to="/" onClick={this.handleTour.bind(this)}>
                <div style={{textAlign: 'center'}} className="menuHeader" id="dashboardMenu"><Menu.Item active={this.props.location.pathname === "/"}>
                  <Icon style={{marginRight: '0px', marginBottom: '5px'}} name="building" size="big" /><br />
                  TABLEAU DE BORD
                </Menu.Item></div>
                </Link>
                <Divider hidden />
                {
                isModuleVisibleInMenu('pointages') ? 
                [<Link key="pointages_link" to="/temps" onClick={this.handleTour.bind(this)}>
                <div style={{textAlign: 'center'}} className="menuHeader" id="pointagesMenu"><Menu.Item active={this.props.location.pathname === "/temps"}>
                  <Icon style={{marginRight: '0px', marginBottom: '5px'}} name="clock" size="big" /><br />
                  TEMPS
                </Menu.Item></div>
                </Link>,
                <Divider key="pointages_divider" style={{margin: '5px'}} hidden />
                ] : <div />
                }
                {
                  isModuleVisibleInMenu('movings') ?
                  [<Link to="/deplacements" key="movings_link">
                  <div style={{textAlign: 'center'}} className="menuHeader" id="movingsMenu"><Menu.Item active={this.props.location.pathname === "/deplacements"}>
                    <Icon style={{marginRight: '0px', marginBottom: '5px'}} name="map signs" size="big" /><br />
                    DÉPLACEMENTS
                  </Menu.Item></div>
                  </Link>,
                  <Divider hidden key="sites_divider" style={{margin: '5px'}} />
                  ]
                  : <div />
                }

                {
                  isModuleVisibleInMenu('messages') ?
                  [<Link to="/messagerie" key="messagerie_link">
                  <div style={{textAlign: 'center'}} className="menuHeader" id="msgMenu"><Menu.Item active={this.props.location.pathname === "/messagerie"} title={this.state.notvalidatedMessageCount > 0 ? this.state.notvalidatedMessageCount + " messages en cours" : ''}>
                    <Icon.Group size="big">
                      <Icon style={{marginRight: '0px', marginBottom: '5px'}} name="envelope" />
                      {this.state.notvalidatedMessageCount > 0 ? <Icon id="exclamationCircle" corner="top right" color='red' name='exclamation circle' /> : ''}
                    </Icon.Group><br />
                    MESSAGERIE
                  </Menu.Item></div>
                  </Link>,
                  <Divider hidden key="messagerie_divider" style={{margin: '5px'}} />
                  ]
                  : <div />
                }

                <Link to="/collaborateurs" onClick={this.handleTour.bind(this)}>
                <div style={{textAlign: 'center'}} className="menuHeader" id="employeesMenu"><Menu.Item active={this.props.location.pathname === "/collaborateurs"}>
                  <Icon style={{marginRight: '0px', marginBottom: '5px'}} name="address card" size="big" /><br />
                  COLLABORATEURS
                </Menu.Item></div>
                </Link>
                <Divider hidden style={{margin: '5px'}} />

                {
                  isModuleVisibleInMenu('locations') && this.isAdmin() === true ?
                  [<Link to="/materiels" key="sites_link">
                  <div style={{textAlign: 'center'}} className="menuHeader" id="locationsMenu"><Menu.Item active={this.props.location.pathname === "/materiels"}>
                    <Icon style={{marginRight: '0px', marginBottom: '5px'}} name="hdd" size="big" /><br />
                    MATÉRIELS
                  </Menu.Item></div>
                  </Link>,
                  <Divider hidden key="sites_divider" style={{margin: '5px'}} />
                  ]
                  : <div />
                }
              </Menu>
              </Grid.Row>
              <Grid.Row style={{position: 'absolute', width: '100%', bottom: '5%', textAlign: 'center'}}>
                <Label style={{fontSize: '0.5vw'}} color='green'><Icon name='lock' />{`${this.isAdmin() === true ? "Administrateur" : "Manager"}`}</Label>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={14}
              computer={15}
              floated="right"
              id="content"
            >
              {
                this.state.topMessageVisible === true ?
                <Message info onDismiss={(e) => this.setState({topMessageVisible: false})}>
                  <Message.Header>{ CURRENT_ANNOUNCEMENT_HEADER }</Message.Header>
                  { CURRENT_ANNOUNCEMENT }
                </Message> : null
              }
              <Switch>
                <Route path="/temps" exact render={(props) => <Manage {...props} hourEndByDay={this.hourEnd.bind(this)} rpiHardware={this.state.rpiHardware} handleTour={this.handleTour.bind(this)} isAdmin={this.isAdmin.bind(this)} isManagerWithWriteAccess={this.isManagerWithWriteAccess.bind(this)} />} />
                <Route path="/collaborateurs" exact render={(props) => <Employees {...props} hourEndByDay={this.hourEnd.bind(this)} handleTour={this.handleTour.bind(this)} isAdmin={this.isAdmin.bind(this)} isManagerWithWriteAccess={this.isManagerWithWriteAccess.bind(this)} myTeamId={this.myTeamId.bind(this)} />} />
                <Route path="/messagerie" exact render={(props) => <Messages {...props} isAdmin={this.isAdmin.bind(this)} isManagerWithWriteAccess={this.isManagerWithWriteAccess.bind(this)} />} />
                {this.isAdmin() === true ? <Route path="/materiels" exact render={(props) => <Locations {...props} rpiHardware={this.state.rpiHardware} phoneHardware={this.state.phoneHardware} />} /> : ''}
                <Route path="/deplacements" exact render={(props) => <Movings isAdmin={this.isAdmin.bind(this)} {...props} rpiHardware={this.state.rpiHardware} />} />
                <Route path="/compte" exact render={(props) => <Profile displayChangelogModal={this.displayChangelogModal.bind(this)} isAdmin={this.isAdmin.bind(this)} {...props} companyCode={this.state.companyCode} companyName={this.state.companyName} subscriptionStart={this.state.subscriptionStart} forceUpdate={this.forceUpdate.bind(this)} />} />
                <Route path="/parametres" exact render={(props) => <Settings isAdmin={this.isAdmin.bind(this)} {...props} forceUpdate={this.forceUpdate.bind(this)} />} />
                {/*this.isAdmin() === true ? <Route path="/covid19" exact component={Covid} /> : ''*/}
                {this.isAdmin() === true || this.isManagerWithWriteAccess() === true ? <Route path="/commande" exact component={Order} /> : ''}
                <Route path="/logout" exact render={(props) => <Logout {...props} logOut={this.logout.bind(this)} />} />
                <Route path="/" render={(props) => <Dashboard {...props} rpiHardware={this.state.rpiHardware} phoneHardware={this.state.phoneHardware} hourEndByDay={this.hourEnd.bind(this)} handleTour={this.handleTour.bind(this)} />} />
              </Switch>
            </Grid.Column>
          </Grid>
          <Modal
              size='large'
              open={this.state.changelogModal}
              closeOnTriggerMouseLeave={false}
              closeOnDimmerClick={false}
              closeIcon={true}
              onClose={this.onCloseChangelogModal.bind(this)}
            >
              <Modal.Header><Icon name='cloud upload' />&nbsp;&nbsp;Découvrez vos nouvelles fonctionnalités OFFERTES !</Modal.Header>
              <Modal.Content>
              {CHANGELOG_PAGES[this.state.currentChangelogPage]}
              <br /><br />
              <Pagination firstItem={{
                'aria-label': 'Première page',
                content: '⟨⟨',
              }} lastItem={{
                'aria-label': 'Dernière page',
                content: '⟩⟩',
              }} activePage={this.state.currentChangelogPage+1} totalPages={CHANGELOG_PAGES.length} onPageChange={(evt, d) => {
                this.setState({
                  currentChangelogPage: d.activePage !== undefined && d.activePage !== null ? d.activePage-1 : 0
                });
              }} />
              </Modal.Content>
          </Modal>
          <Joyride ref={this.joyrideRef}
              callback={this.handleAfterModalCallback.bind(this)}
              debug={false}
              stepIndex={this.state.afterModalStep}
              steps={AFTERMODAL_STEPS(this.isAdmin(), this.isManagerWithWriteAccess())}
              run={this.state.runAfterModal}
              locale={{ back: 'Préc.', close: 'Fermer', last: 'Fin', next: 'Suiv.', skip: 'Fermer' }}
              showSkipButton={true}
              scrollToFirstStep={false}
              showProgress={false}
              continuous={true}
              hideBackButton={true}
              styles={{
                options: {
                  primaryColor: '#1678c2',
                }
              }}
               />
          <Joyride ref={this.joyrideRef}
              callback={this.handleJoyrideCallback.bind(this)}
              debug={false}
              stepIndex={this.state.tour.stepIndex}
              steps={this.state.tour.steps}
              run={this.state.tour.run}
              locale={{ back: 'Préc.', close: 'Fermer', last: 'Fin', next: 'Suiv.', skip: 'Fermer' }}
              showSkipButton={true}
              scrollToFirstStep={true}
              showProgress={true}
              continuous={true}
              hideBackButton={true}
              styles={{
                options: {
                  //arrowColor: '#e3ffeb',
                  //backgroundColor: '#e3ffeb',
                  //overlayColor: 'rgba(79, 26, 0, 0.4)',
                  primaryColor: '#1678c2',
                  //textColor: '#004a14',
                  //width: 900,
                  //zIndex: 1000,
                }
              }}
               />
          </div>
          )} />
        </Switch>
        </div>
      );
    }
  }
}

export default withRouter(App);
