import React from 'react';
//import './Settings.css';
import 'semantic-ui-css/semantic.min.css';
import io from './utils/io';
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Input,
  Flag,
  Icon,
  Popup,
  Segment,
  Select,
  Divider,
  Form,
  FormField,
  Label,
  Loader
} from "semantic-ui-react";
import ls from 'local-storage';
import Moment from './utils/momentfr';
import {isModuleVisibleInMenu, isSubmoduleVisible, hourEndByDay, timezone, displayHoursMinutes, nightShiftBegin, nightShiftEnd, nightShiftBeginMinute, nightShiftEndMinute, manualCounterName, manualCounterFactor, manualCounterEnabled, secondManualCounterEnabled, secondManualCounterFactor, secondManualCounterName, thirdManualCounterEnabled, thirdManualCounterFactor, thirdManualCounterName, nightShiftCounterEnabled, pointagesRememberFilters, manualCounterFormula, secondManualCounterFormula, thirdManualCounterFormula, DEFAULT_MANUAL_COUNTER_NAMES} from './utils/localSettings';
import * as formulas from './utils/formulas';
import {
  TimeInput,
} from 'semantic-ui-calendar-react';
import { getTimeFromEvent } from './utils/formattedInputs';

const moment = Moment();

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: io.ioCurrent(),
      listeners: {
        SET_SETTINGS: this.onSetSettingsResponse.bind(this),
      },

      dashboard: {
        table: isSubmoduleVisible('dashboard', 'table'),
        presenceRatios: isSubmoduleVisible('dashboard', 'presenceRatios'),
        carMap: isSubmoduleVisible('dashboard', 'carMap')
      },
      pointages: {
        enabled: isModuleVisibleInMenu('pointages'),
        filters: pointagesRememberFilters()
      },
      messages: {enabled: isModuleVisibleInMenu('messages')},
      movings: {
        enabled: isModuleVisibleInMenu('movings'),
        phones: isSubmoduleVisible('movings', 'phones'),
        cars: isSubmoduleVisible('movings', 'cars')
      },
      locations: {enabled: isModuleVisibleInMenu('locations')},
      tour: {enabled: isModuleVisibleInMenu('tour')},
      covid: {enabled: isModuleVisibleInMenu('covid')},

      global: {
        hourEndByDay: hourEndByDay(),
        timezone: timezone(),
        displayHoursMinutes: displayHoursMinutes(),
        nightShiftBegin: nightShiftBegin(),
        nightShiftEnd: nightShiftEnd(),
        nightShiftBeginMinute: nightShiftBeginMinute(),
        nightShiftEndMinute: nightShiftEndMinute(),
        manualCounterEnabled: manualCounterEnabled(),
        manualCounterFactor: manualCounterFactor(),
        manualCounterName: manualCounterName(),
        manualCounterFormula: manualCounterFormula(),
        secondManualCounterEnabled: secondManualCounterEnabled(),
        secondManualCounterFactor: secondManualCounterFactor(),
        secondManualCounterName: secondManualCounterName(),
        secondManualCounterFormula: secondManualCounterFormula(),
        thirdManualCounterEnabled: thirdManualCounterEnabled(),
        thirdManualCounterFactor: thirdManualCounterFactor(),
        thirdManualCounterName: thirdManualCounterName(),
        thirdManualCounterFormula: thirdManualCounterFormula(),
        nightShiftCounterEnabled: nightShiftCounterEnabled()
      },
      showFirstManualCounterFormula: false,
      showSecondManualCounterFormula: false,
      showThirdManualCounterFormula: false,

      pause: {
        hour: 0,
        minute: 0
      },
      savingSettings: false,
      savingSettingsResult: null
    };
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));
  }

  onSetSettingsResponse(data) {
    if(data.err === undefined) {
      ls('settings', data.res);
    }
    this.setState({savingSettings: false, savingSettingsResult: data.err === undefined}, () => {
      this.props.forceUpdate();
      setTimeout(() => this.setState({savingSettingsResult: null}), 3000);
    });
  }

  saveLS() {
    const {
      dashboard,
      pointages,
      messages,
      movings,
      locations,
      tour,
      covid,
      global
    } = this.state;
    const newSettingsStr = JSON.stringify({
      dashboard,
      pointages,
      messages,
      movings,
      locations,
      tour,
      covid,
      global
    });
    this.setState({savingSettings: true}, () => {
      if(this.props.isAdmin()) {
        this.state.socket.emit('SET_SETTINGS', newSettingsStr);
      } else {
        this.onSetSettingsResponse({res: newSettingsStr});
      }
    })
  }

  onEnableDisableMenuForModule(module) {
    return (evt) => {
      this.setState({
        [module]: Object.assign({}, this.state[module], {enabled: !this.state[module].enabled})
      }, () => {
        this.saveLS();
      })
    };
  }

  onEnableDisableSubmodule(module, submodule) {
    return (evt) => {
      this.setState({
        [module]: Object.assign({}, this.state[module], {[submodule]: !this.state[module][submodule]})
      }, () => {
        const allSubmodulesDisabled = this.state[module].enabled !== undefined && Object.keys(this.state[module]).filter(k => k !== 'enabled').reduce((acc, k) => acc && !this.state[module][k], true);
        if(allSubmodulesDisabled) {
          this.onEnableDisableMenuForModule(module)();
        } else {
          this.saveLS();
        }
      });
    };
  }

  onChangeTimeInputHourEndByDay(event, data) {
    let hour = parseInt(data.value.split(':')[0]);
    if(hour < 0 || hour > 23) {
      hour = 0;
    }
    this.setState({
      global: Object.assign(this.state.global, {
        hourEndByDay: hour
      })
    }, () => this.saveLS());
  }

  onClickOnResetFormula(manualCounterName, nameToSet, show, formula) {
    return (evt) => {
      const manualCounterNameUppercase = manualCounterName.charAt(0).toUpperCase() + manualCounterName.slice(1);
      const showKey = `show${manualCounterNameUppercase}Formula`;
      const onChangeKey = `onChange${manualCounterNameUppercase}Formula`;
      const nameKey = `${manualCounterName}Name`;
      this.setState({[showKey]: show, global: Object.assign({}, this.state.global, {[nameKey]: nameToSet})}, () => {
        // TODO ugly hack for variable size popups
        window.scrollTo(window.scrollX, window.scrollY+1);
        this[onChangeKey]({target: {value: formula}});
      });
    };
  }

  onChangePauseVal(manualCounterName) {
    return (event, data) => {
      const tgt = event.target;
      const {finalValue, cursorStart, cursorEnd} = getTimeFromEvent(event);
      const hour = finalValue.hours;
      const minute = finalValue.minutes;
  
      const duration = (hour + minute/60.0).toFixed(2);
  
      this.setState({
        pause: { hour, minute },
        global: Object.assign({}, this.state.global, {
          [`${manualCounterName}Formula`]: formulas.PAUSE_FORMULA(duration)
        })
      }, () => {
        tgt.setSelectionRange(cursorStart, cursorEnd);
        this.saveLS();
      });
    };
  }

  onChangeNightShift(type) {
    const isBeginTime = type === 'BEGIN';
    return (event, data) => {
      const split = data.value.split(':');
      const hour = parseInt(split[0]);
      const minute = parseInt(split[1]);
      if(hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
        let beginHour = isBeginTime ? hour : this.state.global.nightShiftBegin;
        let endHour = !isBeginTime ? hour : this.state.global.nightShiftEnd;
        let beginMinute = isBeginTime ? minute : this.state.global.nightShiftBeginMinute;
        let endMinute = !isBeginTime ? minute : this.state.global.nightShiftEndMinute;
        if(beginHour < endHour || (beginHour === endHour && beginMinute <= endMinute)) {
          if(isBeginTime) {
            endHour = beginHour-1;
            endMinute = 0;
          } else {
            beginHour = endHour+1;
            beginMinute = 0;
          }
        }
        this.setState({global: Object.assign(this.state.global, {
          nightShiftBegin: beginHour,
          nightShiftEnd: endHour,
          nightShiftBeginMinute: beginMinute,
          nightShiftEndMinute: endMinute
        })}, () => this.saveLS());
      }
    };
  }

  onChangeTimezone(event, data) {
    let tz = data.value;
    if(tz === undefined || tz === null || tz.length === 0) {
      tz = 'Europe/Paris';
    }
    this.setState({
      global: Object.assign(this.state.global, {
        timezone: tz
      })
    }, () => {
      this.saveLS();
      window.location.reload();
    })
  }

  onChangeDisplayHoursMinutes(event, data) {
    const newDisplayHoursMinutes = this.state.global.displayHoursMinutes === true ? false : true;
    this.setState({
      global: Object.assign(this.state.global, {
        displayHoursMinutes: newDisplayHoursMinutes
      })
    }, () => this.saveLS())
  }

  onChangeManualCounterEnabled(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        manualCounterEnabled: !this.state.global.manualCounterEnabled
      })
    }, () => this.saveLS())
  }

  onChangeManualCounterFactor(manualCounterFactor) {
    this.setState({
      global: Object.assign(this.state.global, {
        manualCounterFactor
      })
    }, () => this.saveLS());
  }

  onChangeManualCounterName(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        manualCounterName: event.target.value
      })
    }, () => this.saveLS())
  }

  onChangeManualCounterFormula(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        manualCounterFormula: event.target.value
      })
    }, () => this.saveLS())
  }

  onChangeSecondManualCounterEnabled(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        secondManualCounterEnabled: !this.state.global.secondManualCounterEnabled
      })
    }, () => this.saveLS())
  }

  onChangeSecondManualCounterFactor(secondManualCounterFactor) {
    this.setState({
      global: Object.assign(this.state.global, {
        secondManualCounterFactor
      })
    }, () => this.saveLS())
  }

  onChangeSecondManualCounterName(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        secondManualCounterName: event.target.value
      })
    }, () => this.saveLS())
  }

  onChangeSecondManualCounterFormula(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        secondManualCounterFormula: event.target.value
      })
    }, () => this.saveLS())
  }

  onChangeThirdManualCounterEnabled(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        thirdManualCounterEnabled: !this.state.global.thirdManualCounterEnabled
      })
    }, () => this.saveLS())
  }

  onChangeThirdManualCounterFactor(thirdManualCounterFactor) {
    this.setState({
      global: Object.assign(this.state.global, {
        thirdManualCounterFactor
      })
    }, () => this.saveLS())
  }

  onChangeThirdManualCounterName(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        thirdManualCounterName: event.target.value
      })
    }, () => this.saveLS())
  }

  onChangeThirdManualCounterFormula(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        thirdManualCounterFormula: event.target.value
      })
    }, () => this.saveLS())
  }

  onChangeNightShiftCounterEnabled(event) {
    this.setState({
      global: Object.assign(this.state.global, {
        nightShiftCounterEnabled: !this.state.global.nightShiftCounterEnabled
      })
    }, () => this.saveLS())
  }

  render() {
    return (
        <Grid padded>
          <Grid.Row className="firstRow">
            <Header dividing size="huge" as="h1">
              <Icon name='settings'/>
              <Header.Content>
              Paramètres généraux {this.state.savingSettings ? <Loader inline size='tiny' active /> : this.state.savingSettingsResult !== null ? this.state.savingSettingsResult === true ? <Icon name='check' size='tiny' color='green' /> : <Icon name='close' size='tiny' color='red' /> : ''}
              <Header.Subheader>Les réglages n'affectent pas vos données, vous pouvez les modifier sans risque.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Header className="timezoneHeader settingsHeader" attached='top' dividing size="large" as="h2">
            {
                <Grid verticalAlign='middle' stackable>
                  <Grid.Column width={12}>
                    Réglage de votre zone géographique&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.global.timezone === 'Europe/Paris' ? <Flag name='fr' /> : ''}
                    {this.state.global.timezone === 'Europe/London' ? <Flag name='gb' /> : ''}
                    {this.state.global.timezone === 'Europe/Dublin' ? <Flag name='ie' /> : ''}
                    {this.state.global.timezone === 'Europe/Berlin' ? <Flag name='de' /> : ''}
                    {this.state.global.timezone === 'Europe/Brussels' ? <Flag name='be' /> : ''}
                    {this.state.global.timezone === 'Europe/Luxembourg' ? <Flag name='lu' /> : ''}
                    {this.state.global.timezone === 'Europe/Zurich' ? <Flag name='ch' /> : ''}
                    {this.state.global.timezone === 'America/Guadeloupe' ? <Flag name='gp' /> : ''}
                    {this.state.global.timezone === 'America/Guyana' ? <Flag name='gy' /> : ''}
                    {this.state.global.timezone === 'America/Cayenne' ? <Flag name='gf' /> : ''}
                    {this.state.global.timezone === 'America/Martinique' ? <Flag name='mq' /> : ''}
                    {this.state.global.timezone === 'America/Montreal' ? <Flag name='ca' /> : ''}
                    {this.state.global.timezone === 'America/Port-au-Prince' ? <Flag name='ht' /> : ''}
                    {this.state.global.timezone === 'America/Miquelon' ? <Flag name='pm' /> : ''}
                    {this.state.global.timezone === 'Indian/Mauritius' ? <Flag name='mu' /> : ''}
                    {this.state.global.timezone === 'Indian/Mayotte' ? <Flag name='yt' /> : ''}
                    {this.state.global.timezone === 'Indian/Reunion' ? <Flag name='re' /> : ''}
                    {this.state.global.timezone === 'Pacific/Noumea' ? <Flag name='nc' /> : ''}
                    {this.state.global.timezone === 'Pacific/Tahiti' ? <Flag name='pf' /> : ''}
                    <Header.Subheader>Sélectionnez votre pays afin de ne pas subir les effets du décalage horaire.</Header.Subheader>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Select disabled={this.props.isAdmin() !== true} style={{float: 'right'}} placeholder='Zone géographique' defaultValue={this.state.global.timezone} options={[
                      { key: 'europe_paris', value: 'Europe/Paris', text: 'France (par défaut)' },
                      { key: 'europe_london', value: 'Europe/London', text: 'Angleterre' },
                      { key: 'europe_dublin', value: 'Europe/Dublin', text: 'Irlande' },
                      { key: 'europe_berlin', value: 'Europe/Berlin', text: 'Allemagne' },
                      { key: 'europe_brussels', value: 'Europe/Brussels', text: 'Belgique' },
                      { key: 'europe_luxembourg', value: 'Europe/Luxembourg', text: 'Luxembourg' },
                      { key: 'europe_zurich', value: 'Europe/Zurich', text: 'Suisse' },
                      { key: 'america_guadeloupe', value: 'America/Guadeloupe', text: 'Guadeloupe' },
                      { key: 'america_guyana', value: 'America/Guyana', text: 'Guyana' },
                      { key: 'america_cayenne', value: 'America/Cayenne', text: 'Guyane' },
                      { key: 'america_martinique', value: 'America/Martinique', text: 'Martinique' },
                      { key: 'america_montreal', value: 'America/Montreal', text: 'Québec' },
                      { key: 'america_portauprince', value: 'America/Port-au-Prince', text: 'Haïti' },
                      { key: 'america_miquelon', value: 'America/Miquelon', text: 'St-Pierre-et-Miquelon' },
                      { key: 'indian_mauritius', value: 'Indian/Mauritius', text: 'Île Maurice' },
                      { key: 'indian_mayotte', value: 'Indian/Mayotte', text: 'Mayotte' },
                      { key: 'indian_reunion', value: 'Indian/Reunion', text: 'La Réunion' },
                      { key: 'pacific_noumea', value: 'Pacific/Noumea', text: 'Nouvelle Calédonie' },
                      { key: 'pacific_tahiti', value: 'Pacific/Tahiti', text: 'Tahiti' }
                    ]} onChange={this.onChangeTimezone.bind(this)} />
                   </Grid.Column>
                </Grid>
            }
            </Header>
          </Grid.Row>
          <Grid.Row verticalAlign="middle">
            <Header className="timezoneHeader settingsHeader" attached='top' dividing size="large" as="h2">
              {
                <Grid verticalAlign='middle' stackable>
                  <Grid.Column width={14}>
                    Réglage du format d'affichage des temps
                    <Header.Subheader>Basculez entre l'affichage des temps au format Décimal ou Heures:Minutes.</Header.Subheader>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center'>
                    <Checkbox disabled={this.props.isAdmin() !== true} onChange={this.onChangeDisplayHoursMinutes.bind(this)} toggle checked={this.state.global.displayHoursMinutes === true} label={this.state.global.displayHoursMinutes === true ? "HH : mm" : "Décimal"} />
                  </Grid.Column>
                </Grid>
              }
            </Header>
          </Grid.Row>
          <Grid.Row width={16}>
            <Header className="hourEndByDayHeader settingsHeader" attached='top' dividing size="large" as="h2">
              {
                  <Grid verticalAlign='middle' stackable>
                    <Grid.Column width={6}>
                      <Popup flowing content={
                        <div>
                          Par défaut, la journée de travail commence et se termine à minuit (00h00).<br />
                          Vous pouvez modifiez l'heure actant la fin de journée des employés de nuit afin que les heures travaillées sur un poste à cheval sur deux jours soient comptabilisées sur une seule journée.<br /><br />
                          <u>Exemple avec un réglage à 02h00</u> : Les heures travaillées sur un poste commencé le 01/01 à 18h00 et terminé le 02/01 à 01h48 seront totalement affectées à la journée du 01/01.<br /><br /><u>Note</u> : Ce réglage s'applique uniquement aux employés dont le mode nuit est activé. ( menu Collaborateurs -&gt; Liste des employés )
                        </div> 
                      } position="bottom left"
                        trigger={
                      <div>Réglage de l'heure de fin de poste des employés de nuit <Icon name='info circle' /></div>
                      } />
                    </Grid.Column>
                    <Grid.Column width={8} only='computer'>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <TimeInput
                        style={{width: '100%'}}
                        localization="fr"
                        name="inputTimeBeginDay"
                        placeholder="Heure"
                        value={`${this.state.global.hourEndByDay < 10 ? '0' : ''}${this.state.global.hourEndByDay}h00`}
                        iconPosition="left"
                        disableMinute={true}
                        closeOnMouseLeave={false}
                        closable={true}
                        onChange={this.onChangeTimeInputHourEndByDay.bind(this)}
                        animation='none'
                        hideMobileKeyboard={true}
                        disabled={this.props.isAdmin() !== true}
                      />
                    </Grid.Column>
                  </Grid>
              }
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Header className="nightShiftHeader settingsHeader" attached='top' dividing size="large" as="h2">
              {
                <Grid verticalAlign='middle' stackable>
                  <Grid.Column width={4}>
                    <Popup flowing content={
                      <div>Les heures de travail effectuées par un employé sur la plage horaire saisie seront automatiquement affectées à son compteur Heures de nuit.<br /><br />
                      Retrouvez tous vos compteurs&nbsp; <Icon circular bordered name='unordered list' color='green'/>&nbsp;dans le menu Pointages.</div> 
                    } position="bottom left"
                      trigger={
                     <div>Compteur des heures de nuit <Icon name='info circle' /></div>
                    } />
                  </Grid.Column>
                  <Grid.Column width={4} only='computer'>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign="right" only='computer'>
                    {<p style={this.state.global.nightShiftCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Plage horaire :</p>}
                  </Grid.Column>
                  <Grid.Column width={2} textAlign="left" only='mobile'>
                    {<p style={this.state.global.nightShiftCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Plage horaire :</p>}
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <TimeInput
                      localization="fr"
                      name="nightShiftBegin"
                      placeholder="début de nuit"
                      value={`${this.state.global.nightShiftBegin < 10 ? '0' : ''}${this.state.global.nightShiftBegin}h${this.state.global.nightShiftBeginMinute < 10 ? '0' : ''}${this.state.global.nightShiftBeginMinute}`}
                      iconPosition="left"
                      disableMinute={false}
                      closeOnMouseLeave={false}
                      closable={true}
                      onChange={this.onChangeNightShift('BEGIN').bind(this)}
                      disabled={this.state.global.nightShiftCounterEnabled === false || this.props.isAdmin() !== true}
                      animation='none'
                      hideMobileKeyboard={true}
                      style={{width: '100%'}}
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <TimeInput
                      localization="fr"
                      name="nightShiftEnd"
                      placeholder="fin de nuit"
                      value={`${this.state.global.nightShiftEnd < 10 ? '0' : ''}${this.state.global.nightShiftEnd}h${this.state.global.nightShiftEndMinute < 10 ? '0' : ''}${this.state.global.nightShiftEndMinute}`}
                      iconPosition="left"
                      disableMinute={false}
                      closeOnMouseLeave={false}
                      closable={true}
                      onChange={this.onChangeNightShift('END').bind(this)}
                      disabled={this.state.global.nightShiftCounterEnabled === false || this.props.isAdmin() !== true}
                      animation='none'
                      hideMobileKeyboard={true}
                      style={{width: '100%'}}
                    />
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center'>
                    <Checkbox disabled={this.props.isAdmin() !== true} onChange={this.onChangeNightShiftCounterEnabled.bind(this)} toggle checked={this.state.global.nightShiftCounterEnabled} label={this.state.global.nightShiftCounterEnabled ? "Activé" : "Désactivé"} />
                  </Grid.Column>
                </Grid>
              }
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Header className="manualCounterHeader settingsHeader" attached='top' dividing size="large" as="h2">
              <Grid verticalAlign='middle' stackable>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Popup flowing content={
                          <div>Ce réglage vous permet d'activer un compteur personnalisé et de définir son influence sur la balance horaire des employés.<br /><br />
                          Retrouvez tous vos compteurs&nbsp; <Icon circular bordered name='unordered list' color='green'/>&nbsp;dans le menu Pointages.</div> 
                        } position="bottom left"
                          trigger={
                        <div>Compteur personnalisé 1 <Icon name='info circle' /></div>
                        } />
                  </Grid.Column>
                  <Grid.Column width={1} only='computer'>
                  </Grid.Column>
                  <Grid.Column width={1} style={{textAlign: 'right'}} only='computer'>
                    {<p style={this.state.global.manualCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Nom :</p>}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Input fluid onChange={this.onChangeManualCounterName.bind(this)} value={this.state.global.manualCounterName} disabled={this.state.global.manualCounterEnabled === false || this.props.isAdmin() !== true} placeholder="Nom du compteur" />
                  </Grid.Column>
                  <Grid.Column width={2} only='computer'></Grid.Column>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center'>
                    <Checkbox disabled={this.props.isAdmin() !== true} onChange={this.onChangeManualCounterEnabled.bind(this)} toggle checked={this.state.global.manualCounterEnabled} label={this.state.global.manualCounterEnabled ? "Activé" : "Désactivé"} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3}>
                  </Grid.Column>
                  <Grid.Column width={1} only='computer'>
                  </Grid.Column>
                  <Grid.Column width={1} style={{textAlign: 'right'}} only='computer'>
                    {<p style={this.state.global.manualCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Formule :</p>}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Input fluid onChange={this.onChangeManualCounterFormula.bind(this)} value={this.state.showManualCounterFormula ? this.state.global.manualCounterFormula : formulas.isDefaultAbs(this.state.global.manualCounterFormula) ? "[ Calcul auto. des absences ]" : formulas.isDefaultHSup(this.state.global.manualCounterFormula) ? "[ Calcul auto. des heures supp. ]" : formulas.isDefaultPause(this.state.global.manualCounterFormula) ? "[ Décompte auto. du temps de pause ]" : "[ Formule de calcul personnalisée ]" } disabled={this.state.global.manualCounterEnabled === false || this.state.showManualCounterFormula === false || this.props.isAdmin() !== true} placeholder="Entrez votre formule..." />
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='left'>
                    <Button disabled={this.props.isAdmin() !== true} icon={this.state.showManualCounterFormula === false ? 'eye' : 'eye slash'} onClick={(evt) => this.setState({showManualCounterFormula: !this.state.showManualCounterFormula})} data-position="bottom left" data-tooltip={`${this.state.showManualCounterFormula ? 'Masquer' : 'Modifier manuellement'} la formule`}></Button>
                    <Popup trigger={
                      <Button disabled={this.props.isAdmin() !== true} data-tooltip="Choisir une formule pour ce compteur" data-position="bottom left" ><Icon name='setting' />Configurer</Button>
                      } flowing on="click">
                      <Grid centered celled columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('manualCounter', DEFAULT_MANUAL_COUNTER_NAMES.abs, false, formulas.ABS_FORMULA()).bind(this)} icon color={formulas.isDefaultAbs(this.state.global.manualCounterFormula) ? 'blue' : 'grey'}>
                            Calcul des Absences
                          </Button>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('manualCounter', DEFAULT_MANUAL_COUNTER_NAMES.custom, true, "").bind(this)} icon color={!formulas.isDefaultAbs(this.state.global.manualCounterFormula) && !formulas.isDefaultHSup(this.state.global.manualCounterFormula) && !formulas.isDefaultPause(this.state.global.manualCounterFormula) ? 'blue' : 'grey'}>
                            Formule personnalisée
                          </Button>
                        </Grid.Column>
                      </Grid>
                      <br />
                      <Grid centered celled columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('manualCounter', DEFAULT_MANUAL_COUNTER_NAMES.hsupp, false, formulas.HSUP_FORMULA()).bind(this)} icon color={formulas.isDefaultHSup(this.state.global.manualCounterFormula) ? 'blue' : 'grey'}>
                            Calcul des Heures<br />Supplémentaires
                          </Button>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('manualCounter', DEFAULT_MANUAL_COUNTER_NAMES.pause, true, formulas.PAUSE_FORMULA((this.state.pause.hour*this.state.pause.minute/60.0).toFixed(2))).bind(this)} icon color={formulas.isDefaultPause(this.state.global.manualCounterFormula)	 ? 'blue' : 'grey'}>
                            Décompte du<br />temps de pause
                          </Button>
                          {
                            formulas.isDefaultPause(this.state.global.manualCounterFormula) ?
                              <Form>
                                <br />
                                <FormField>
                                  <Input focus size='mini'
                                    style={{width: '55pt'}}
                                    placeholder="Heure"
                                    icon="clock"
                                    iconPosition="left"
                                    autoFocus={true}
                                    onFocus={(evt) => evt.target.select()}
                                    onClick={(evt) => evt.target.select()}
                                    value={`${this.state.pause.hour < 10 ? '0' : ''}${this.state.pause.hour}:${this.state.pause.minute < 10 ? '0' : ''}${this.state.pause.minute}`}
                                    onChange={this.onChangePauseVal('manualCounter').bind(this)}
                                    disabled={this.props.isAdmin() !== true}
                                  />
                                  <Label size='tiny' pointing>Temps journalier à décompter</Label>
                                </FormField>
                              </Form>
                            : ''
                          }
                        </Grid.Column>
                      </Grid>
                    </Popup>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Grid>
                      <Grid.Column width={6} verticalAlign='middle'>
                        <Checkbox fitted onChange={(evt, data) => this.onChangeManualCounterFactor(data.checked ? 1 : 0)} slider disabled={this.state.global.manualCounterEnabled === false || this.props.isAdmin() !== true} checked={this.state.global.manualCounterFactor !== 0} />
                      </Grid.Column>
                      <Grid.Column width={10} verticalAlign='middle'>
                      {<p style={this.state.global.manualCounterEnabled === false ? {lineHeight: '1em', fontSize: '0.9em', color: '#838383'} : {lineHeight: '1em', fontSize: '0.9em'}}>Le compteur {this.state.global.manualCounterFactor === 0 ? "n'influence pas" : "influence"} la balance</p>}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Grid>
                        <Grid.Column width={6} verticalAlign='middle'>
                        {this.state.global.manualCounterEnabled === false || this.state.global.manualCounterFactor === 0 ? null : <Checkbox className="settingsBlackChk" onChange={(evt, data) => this.onChangeManualCounterFactor(data.checked ? -1 : 1)} slider disabled={this.state.global.manualCounterEnabled === false || this.state.global.manualCounterFactor === 0 ||  this.props.isAdmin() !== true} checked={this.state.global.manualCounterFactor === -1} />}
                        </Grid.Column>
                        <Grid.Column width={10} verticalAlign='middle'>
                        {this.state.global.manualCounterEnabled === false || this.state.global.manualCounterFactor === 0 ? null : <p style={{lineHeight: '1em', fontSize: '0.9em', fontStyle: 'normal'}}>La valeur du compteur est {this.state.global.manualCounterFactor === -1 ? "retranché de" : "ajouté à"} la balance</p>}
                        </Grid.Column>
                      </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Header className="manualCounterHeader2 settingsHeader" attached='top' dividing size="large" as="h2">
              <Grid verticalAlign='middle' stackable>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Popup flowing content={
                            <div>Ce réglage vous permet d'activer un compteur personnalisé et de définir son influence sur la balance horaire des employés.<br /><br />
                            Retrouvez tous vos compteurs&nbsp; <Icon circular bordered name='unordered list' color='green'/>&nbsp;dans le menu Pointages.</div> 
                          } position="bottom left"
                            trigger={
                          <div>Compteur personnalisé 2 <Icon name='info circle' /></div>
                          } />
                  </Grid.Column>
                  <Grid.Column width={1} only='computer'>
                  </Grid.Column>
                  <Grid.Column width={1} style={{textAlign: 'right'}} only='computer'>
                    {<p style={this.state.global.secondManualCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Nom :</p>}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Input fluid onChange={this.onChangeSecondManualCounterName.bind(this)} value={this.state.global.secondManualCounterName} disabled={this.state.global.secondManualCounterEnabled === false || this.props.isAdmin() !== true} placeholder="Nom du compteur" />
                  </Grid.Column>
                  <Grid.Column width={2} only='computer'></Grid.Column>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center'>
                    <Checkbox disabled={this.props.isAdmin() !== true} onChange={this.onChangeSecondManualCounterEnabled.bind(this)} toggle checked={this.state.global.secondManualCounterEnabled} label={this.state.global.secondManualCounterEnabled ? "Activé" : "Désactivé"} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3}>
                  </Grid.Column>
                  <Grid.Column width={1} only='computer'>
                  </Grid.Column>
                  <Grid.Column width={1} style={{textAlign: 'right'}} only='computer'>
                    {<p style={this.state.global.secondManualCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Formule :</p>}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Input fluid onChange={this.onChangeSecondManualCounterFormula.bind(this)} value={this.state.showSecondManualCounterFormula ? this.state.global.secondManualCounterFormula : formulas.isDefaultAbs(this.state.global.secondManualCounterFormula) ? "[ Calcul auto. des absences ]" : formulas.isDefaultHSup(this.state.global.secondManualCounterFormula) ? "[ Calcul auto. des heures supp. ]" : formulas.isDefaultPause(this.state.global.secondManualCounterFormula) ? "[ Décompte auto. du temps de pause ]" : "[ Formule de calcul personnalisée ]" } disabled={this.state.global.secondManualCounterEnabled === false || this.state.showSecondManualCounterFormula === false || this.props.isAdmin() !== true} placeholder="Entrez votre formule..." />
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='left'>
                    <Button disabled={this.props.isAdmin() !== true} icon={this.state.showSecondManualCounterFormula === false ? 'eye' : 'eye slash'} onClick={(evt) => this.setState({showSecondManualCounterFormula: !this.state.showSecondManualCounterFormula})} data-position="bottom left" data-tooltip={`${this.state.showSecondManualCounterFormula ? 'Masquer' : 'Modifier manuellement'} la formule`}></Button>
                    <Popup trigger={
                      <Button disabled={this.props.isAdmin() !== true} data-tooltip="Choisir une formule pour ce compteur" data-position="bottom left" ><Icon name='setting' />Configurer</Button>
                      } flowing on="click">
                      <Grid centered celled columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('secondManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.abs, false, formulas.ABS_FORMULA()).bind(this)} icon color={formulas.isDefaultAbs(this.state.global.secondManualCounterFormula) ? 'blue' : 'grey'}>
                            Calcul des Absences
                          </Button>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('secondManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.custom, true, "").bind(this)} icon color={!formulas.isDefaultAbs(this.state.global.secondManualCounterFormula) && !formulas.isDefaultHSup(this.state.global.secondManualCounterFormula) && !formulas.isDefaultPause(this.state.global.secondManualCounterFormula) ? 'blue' : 'grey'}>
                            Formule personnalisée
                          </Button>
                        </Grid.Column>
                      </Grid>
                      <br />
                      <Grid centered celled columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('secondManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.hsupp, false, formulas.HSUP_FORMULA()).bind(this)} icon color={formulas.isDefaultHSup(this.state.global.secondManualCounterFormula) ? 'blue' : 'grey'}>
                            Calcul des Heures<br />Supplémentaires
                          </Button>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('secondManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.pause, true, formulas.PAUSE_FORMULA((this.state.pause.hour*this.state.pause.minute/60.0).toFixed(2))).bind(this)} icon color={formulas.isDefaultPause(this.state.global.secondManualCounterFormula)	 ? 'blue' : 'grey'}>
                            Décompte du<br />temps de pause
                          </Button>
                          {
                            formulas.isDefaultPause(this.state.global.secondManualCounterFormula) ?
                              <Form>
                                <br />
                                <FormField>
                                  <Input focus size='mini'
                                    style={{width: '55pt'}}
                                    placeholder="Heure"
                                    icon="clock"
                                    iconPosition="left"
                                    autoFocus={true}
                                    onFocus={(evt) => evt.target.select()}
                                    onClick={(evt) => evt.target.select()}
                                    value={`${this.state.pause.hour < 10 ? '0' : ''}${this.state.pause.hour}:${this.state.pause.minute < 10 ? '0' : ''}${this.state.pause.minute}`}
                                    onChange={this.onChangePauseVal('secondManualCounter').bind(this)}
                                    disabled={this.props.isAdmin() !== true}
                                  />
                                  <Label size='tiny' pointing>Temps journalier à décompter</Label>
                                </FormField>
                              </Form>
                            : ''
                          }
                        </Grid.Column>
                      </Grid>
                    </Popup>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Grid>
                      <Grid.Column width={6} verticalAlign='middle'>
                      <Checkbox onChange={(evt, data) => this.onChangeSecondManualCounterFactor(data.checked ? 1 : 0)} slider disabled={this.state.global.secondManualCounterEnabled === false || this.props.isAdmin() !== true} checked={this.state.global.secondManualCounterFactor !== 0} />
                      </Grid.Column>
                      <Grid.Column width={10} verticalAlign='middle'>
                        {<p style={this.state.global.secondManualCounterEnabled === false ? {lineHeight: '1em', fontSize: '0.9em', color: '#838383'} : {lineHeight: '1em', fontSize: '0.9em'}}>Le compteur {this.state.global.secondManualCounterFactor === 0 ? "n'influence pas" : "influence"} la balance</p>}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Grid>
                        <Grid.Column width={6} verticalAlign='middle'>
                        {this.state.global.secondManualCounterEnabled === false || this.state.global.secondManualCounterFactor === 0 ? null : <Checkbox className="settingsBlackChk" onChange={(evt, data) => this.onChangeSecondManualCounterFactor(data.checked ? -1 : 1)} slider disabled={this.state.global.secondManualCounterEnabled === false || this.state.global.secondManualCounterFactor === 0 || this.props.isAdmin() !== true} checked={this.state.global.secondManualCounterFactor === -1} />}
                        </Grid.Column>
                        <Grid.Column width={10} verticalAlign='middle'>
                        {this.state.global.secondManualCounterEnabled === false || this.state.global.secondManualCounterFactor === 0 ? null : <p style={{lineHeight: '1em', fontSize: '0.9em', fontStyle: 'normal'}}>La valeur du compteur est {this.state.global.secondManualCounterFactor === -1 ? "retranché de" : "ajouté à"} la balance</p>}
                        </Grid.Column>
                      </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Header className="manualCounterHeader3 settingsHeader" attached='top' dividing size="large" as="h2">
              <Grid verticalAlign='middle' stackable>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Popup flowing content={
                            <div>Ce réglage vous permet d'activer un compteur personnalisé et de définir son influence sur la balance horaire des employés.<br /><br />
                            Retrouvez tous vos compteurs&nbsp; <Icon circular bordered name='unordered list' color='green'/>&nbsp;dans le menu Pointages.</div> 
                          } position="bottom left"
                            trigger={
                          <div>Compteur personnalisé 3 <Icon name='info circle' /></div>
                          } />
                  </Grid.Column>
                  <Grid.Column width={1} only='computer'>
                  </Grid.Column>
                  <Grid.Column width={1} style={{textAlign: 'right'}} only='computer'>
                    {<p style={this.state.global.thirdManualCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Nom :</p>}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Input fluid onChange={this.onChangeThirdManualCounterName.bind(this)} value={this.state.global.thirdManualCounterName} disabled={this.state.global.thirdManualCounterEnabled === false || this.props.isAdmin() !== true} placeholder="Nom du compteur" />
                  </Grid.Column>
                  <Grid.Column width={2} only='computer'></Grid.Column>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='center'>
                    <Checkbox disabled={this.props.isAdmin() !== true} onChange={this.onChangeThirdManualCounterEnabled.bind(this)} toggle checked={this.state.global.thirdManualCounterEnabled} label={this.state.global.thirdManualCounterEnabled ? "Activé" : "Désactivé"} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3}>
                  </Grid.Column>
                  <Grid.Column width={1} only='computer'>
                  </Grid.Column>
                  <Grid.Column width={1} style={{textAlign: 'right'}} only='computer'>
                    {<p style={this.state.global.thirdManualCounterEnabled === false ? {lineHeight: '1em', fontSize: '1em', color: '#838383'} : {lineHeight: '1em', fontSize: '1em'}}>Formule :</p>}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Input fluid onChange={this.onChangeThirdManualCounterFormula.bind(this)} value={this.state.showThirdManualCounterFormula ? this.state.global.thirdManualCounterFormula : formulas.isDefaultAbs(this.state.global.thirdManualCounterFormula) ? "[ Calcul auto. des absences ]" : formulas.isDefaultHSup(this.state.global.thirdManualCounterFormula) ? "[ Calcul auto. des heures supp. ]" : formulas.isDefaultPause(this.state.global.thirdManualCounterFormula) ? "[ Décompte auto. du temps de pause ]" : "[ Formule de calcul personnalisée ]" } disabled={this.state.global.thirdManualCounterEnabled === false || this.state.showThirdManualCounterFormula === false || this.props.isAdmin() !== true} placeholder="Entrez votre formule..." />
                  </Grid.Column>
                  <Grid.Column width={2} textAlign='left'>
                    <Button disabled={this.props.isAdmin() !== true} icon={this.state.showThirdManualCounterFormula === false ? 'eye' : 'eye slash'} onClick={(evt) => this.setState({showThirdManualCounterFormula: !this.state.showThirdManualCounterFormula})} data-position="bottom left" data-tooltip={`${this.state.showThirdManualCounterFormula ? 'Masquer' : 'Modifier manuellement'} la formule`}></Button>
                    <Popup trigger={
                      <Button disabled={this.props.isAdmin() !== true} data-tooltip="Choisir une formule pour ce compteur" data-position="bottom left" ><Icon name='setting' />Configurer</Button>
                      } flowing on="click">
                      <Grid centered celled columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('thirdManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.abs, false, formulas.ABS_FORMULA()).bind(this)} icon color={formulas.isDefaultAbs(this.state.global.thirdManualCounterFormula) ? 'blue' : 'grey'}>
                            Calcul des Absences
                          </Button>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('thirdManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.custom, true, "").bind(this)} icon color={!formulas.isDefaultAbs(this.state.global.thirdManualCounterFormula) && !formulas.isDefaultHSup(this.state.global.thirdManualCounterFormula) && !formulas.isDefaultPause(this.state.global.thirdManualCounterFormula) ? 'blue' : 'grey'}>
                            Formule personnalisée
                          </Button>
                        </Grid.Column>
                      </Grid>
                      <br />
                      <Grid centered celled columns={2}>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                          <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('thirdManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.hsupp, false, formulas.HSUP_FORMULA()).bind(this)} icon color={formulas.isDefaultHSup(this.state.global.thirdManualCounterFormula) ? 'blue' : 'grey'}>
                            Calcul des Heures<br />Supplémentaires
                          </Button>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle' textAlign='center'>
                        <Button disabled={this.props.isAdmin() !== true} onClick={this.onClickOnResetFormula('thirdManualCounter', DEFAULT_MANUAL_COUNTER_NAMES.pause, true, formulas.PAUSE_FORMULA((this.state.pause.hour*this.state.pause.minute/60.0).toFixed(2))).bind(this)} icon color={formulas.isDefaultPause(this.state.global.thirdManualCounterFormula)	 ? 'blue' : 'grey'}>
                          Décompte du<br />temps de pause
                          </Button>
                          {
                            formulas.isDefaultPause(this.state.global.thirdManualCounterFormula) ?
                              <Form>
                                <br />
                                <FormField>
                                  <Input focus size='mini'
                                    style={{width: '55pt'}}
                                    placeholder="Heure"
                                    icon="clock"
                                    iconPosition="left"
                                    autoFocus={true}
                                    onFocus={(evt) => evt.target.select()}
                                    onClick={(evt) => evt.target.select()}
                                    value={`${this.state.pause.hour < 10 ? '0' : ''}${this.state.pause.hour}:${this.state.pause.minute < 10 ? '0' : ''}${this.state.pause.minute}`}
                                    onChange={this.onChangePauseVal('thirdManualCounter').bind(this)}
                                    disabled={this.props.isAdmin() !== true}
                                  />
                                  <Label size='tiny' pointing>Temps journalier à décompter</Label>
                                </FormField>
                              </Form>
                            : ''
                          }
                        </Grid.Column>
                      </Grid>
                    </Popup>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Grid>
                      <Grid.Column width={6} verticalAlign='middle'>
                      <Checkbox onChange={(evt, data) => this.onChangeThirdManualCounterFactor(data.checked ? 1 : 0)} slider disabled={this.state.global.thirdManualCounterEnabled === false || this.props.isAdmin() !== true} checked={this.state.global.thirdManualCounterFactor !== 0} />
                      </Grid.Column>
                      <Grid.Column width={10} verticalAlign='middle'>
                        {<p style={this.state.global.thirdManualCounterEnabled === false ? {lineHeight: '1em', fontSize: '0.9em', color: '#838383'} : {lineHeight: '1em', fontSize: '0.9em'}}>Le compteur {this.state.global.thirdManualCounterFactor === 0 ? "n'influence pas" : "influence"} la balance</p>}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Grid>
                        <Grid.Column width={6} verticalAlign='middle'>
                        {this.state.global.thirdManualCounterEnabled === false || this.state.global.thirdManualCounterFactor === 0 ? null : <Checkbox className="settingsBlackChk" onChange={(evt, data) => this.onChangeThirdManualCounterFactor(data.checked ? -1 : 1)} slider disabled={this.state.global.thirdManualCounterEnabled === false || this.state.global.thirdManualCounterFactor === 0 || this.props.isAdmin() !== true} checked={this.state.global.thirdManualCounterFactor === -1} />}
                        </Grid.Column>
                        <Grid.Column width={10} verticalAlign='middle'>
                        {this.state.global.thirdManualCounterEnabled === false || this.state.global.thirdManualCounterFactor === 0 ? null : <p style={{lineHeight: '1em', fontSize: '0.9em', fontStyle: 'normal'}}>La valeur du compteur est {this.state.global.thirdManualCounterFactor === -1 ? "retranché de" : "ajouté à"} la balance</p>}
                        </Grid.Column>
                      </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row className="firstRow">
            <Header dividing size="huge" as="h1">
              <Icon name='desktop'/>
              <Header.Content>
              Paramètres d'affichage
                <Header.Subheader>Adaptez l'affichage des menus en fonction de vos besoins.</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row className="firstRow">
            <Grid stackable className="displaySettingsGrid">
              <Grid.Column width={8} className="displaySettingsFirstCol">
              <Grid.Row>
                <Header className="settingsHeader" attached='top' dividing size="large" as="h2" >
                  Réglage de votre Tableau de bord
                </Header>
                <Segment attached>
                  <Checkbox slider onChange={this.onEnableDisableSubmodule('dashboard', 'presenceRatios').bind(this)} checked={this.state.dashboard.presenceRatios} label="Afficher les taux de présence" /><br /><br />
                  <Checkbox slider onChange={this.onEnableDisableSubmodule('dashboard', 'table').bind(this)} checked={this.state.dashboard.table} label="Afficher le tableau des présences / horaires" /><br /><br />
                  <Checkbox slider onChange={this.onEnableDisableSubmodule('dashboard', 'carMap').bind(this)} checked={this.state.dashboard.carMap} label="Afficher la position des employés / véhicules" />
                </Segment>
              </Grid.Row>
              <Grid.Row>
                <Header className="settingsHeader" attached='top' dividing size="large" as="h2">
                  <Grid verticalAlign='middle' stackable>
                    <Grid.Column width={13}>
                      Réglage du menu Temps
                    </Grid.Column>
                    <Grid.Column width={3} textAlign='center'>
                      <Checkbox toggle onChange={this.onEnableDisableMenuForModule('pointages').bind(this)} checked={this.state.pointages.enabled} label={this.state.pointages.enabled === true ? "Affiché" : "Masqué"} />
                    </Grid.Column>
                  </Grid>
                </Header>
              </Grid.Row>
             </Grid.Column>
            <Grid.Column width={8} className="displaySettingsSecondCol">
              <Grid.Row>
                <Header className="settingsHeader" attached='top' dividing size="large" as="h2">
                  <Grid verticalAlign='middle' stackable>
                    <Grid.Column width={13}>
                      Réglage du menu Déplacements
                    </Grid.Column>
                    <Grid.Column width={3} textAlign='center'>
                      <Checkbox toggle onChange={this.onEnableDisableMenuForModule('movings').bind(this)} checked={this.state.movings.enabled} label={this.state.movings.enabled === true ? "Affiché" : "Masqué"} />
                    </Grid.Column>
                  </Grid>
                </Header>
                <Segment attached>
                  <Checkbox slider disabled={this.state.movings.enabled === false} onChange={this.onEnableDisableSubmodule('movings', 'phones').bind(this)} checked={this.state.movings.phones} label="Afficher les déplacements des employés" /><br /><br />
                  <Checkbox slider disabled={this.state.movings.enabled === false} onChange={this.onEnableDisableSubmodule('movings', 'cars').bind(this)} checked={this.state.movings.cars} label="Afficher les déplacements des véhicules" />
                </Segment>
              </Grid.Row>
              <Grid.Row>
                <Header className="settingsHeader" attached='top' dividing size="large" as="h2">
                  <Grid verticalAlign='middle' stackable>
                    <Grid.Column width={13}>
                      Réglage du menu Messagerie
                    </Grid.Column>
                    <Grid.Column width={3} textAlign='center'>
                      <Checkbox toggle onChange={this.onEnableDisableMenuForModule('messages').bind(this)} checked={this.state.messages.enabled} label={this.state.messages.enabled === true ? "Affiché" : "Masqué"} />
                    </Grid.Column>
                  </Grid>
                </Header>
              </Grid.Row>
              {
                this.props.isAdmin() === true ?
                [
                  <Grid.Row key='settings_firstRow'>
                    <Header className="settingsHeader" attached='top' dividing size="large" as="h2">
                      <Grid verticalAlign='middle' stackable>
                        <Grid.Column width={13}>
                          Réglage du menu Matériels
                        </Grid.Column>
                        <Grid.Column width={3} textAlign='center'>
                          <Checkbox toggle onChange={this.onEnableDisableMenuForModule('locations').bind(this)} checked={this.state.locations.enabled} label={this.state.locations.enabled === true ? "Affiché" : "Masqué"} />
                        </Grid.Column>
                      </Grid>
                    </Header>
                  </Grid.Row>,
                  /*<Grid.Row key='settings_secondRow'>
                      <Header attached='top' dividing size="large" as="h2">
                        Afficher le Bouton dédié Covid-19
                        <Checkbox style={{float: 'right'}} toggle onChange={this.onEnableDisableMenuForModule('covid').bind(this)} checked={this.state.covid.enabled} label="Affiché / Masqué" />
                      </Header>
                  </Grid.Row>,*/
                ]
                : ''
              }
              {/* <Grid.Row key='settings_thirdRow'>
                  <Header className="settingsHeader" attached='top' dividing size="large" as="h2">
                    Afficher le Bouton du didacticiel
                    <Checkbox style={{float: 'right'}} toggle onChange={this.onEnableDisableMenuForModule('tour').bind(this)} checked={this.state.tour.enabled} label={this.state.tour.enabled === true ? "Affiché" : "Masqué"} />
                  </Header>
              </Grid.Row> */}
            </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
    );
  }
}

export default Settings;
