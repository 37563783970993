import React from 'react';
//import './Profile.css';
import 'semantic-ui-css/semantic.min.css';
import io from './utils/io';
import {
  Button,
  Card,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Loader,
  Message,
  Portal,
  Segment,
  Table
} from "semantic-ui-react";
import { saveAs } from 'file-saver';
import { Link } from "react-router-dom";
import Moment from './utils/momentfr';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment());

const sortBills = (x, y) => {
  if(x.year < y.year) {
    return 1;
  } else if(x.year > y.year) {
    return -1;
  } else {
    if(x.month < y.month) {
      return 1;
    } else if(x.month > y.month) {
      return -1;
    } else {
      if(x.day < y.day) {
        return 1;
      } else if(x.day > y.day) {
        return -1;
      } else {
        return 0;
      }
    }
  }
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      bills: [],
      loadingBill: false,
      sortState: {
        bills: {
          column: '',
          direction: ''
        }
      },
      editingPassword: "",
      editingConfirmPassword: "",
      editingPasswordResult: undefined,
      editingPasswordErr: "",
      stats: undefined,
      avatar: undefined,
      avatarFile: "",
      billingEmails: undefined,
      addingBillingEmail: undefined,

      avatarModalOpen: false,
      copiedCodeTooltip: false,
    };
    this.state = Object.assign(this.state, {
      listeners: {
        GET_BILLS: this.onBillsInit.bind(this),
        GET_SINGLE_BILL: this.onSingleBill.bind(this),
        RESET_PASSWORD: this.onResetPasswordDone.bind(this),
        GET_STATS: this.onStatsInit.bind(this),
        GET_AVATAR: this.onAvatarInit.bind(this),
        SET_AVATAR: this.onSetAvatarResponse.bind(this),
        GET_BILLING_EMAILS: this.onBillingEmailsInit.bind(this),
        SET_BILLING_EMAILS: this.onSetBillingEmailsResponse.bind(this)
      }
    });
    this.handleEscape = this.handleEscape.bind(this);
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    this.state.socket.emit("GET_BILLS");
    this.state.socket.emit("GET_STATS");
    this.state.socket.emit("GET_AVATAR");
    this.state.socket.emit("GET_BILLING_EMAILS");

    // For escape key
    document.addEventListener("keydown", this.handleEscape, false);
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));

    // For escape key
    document.removeEventListener("keydown", this.handleEscape, false);
  }

  handleEscape(event) {
    if(event.keyCode === 27) {
      this.setState({
        addingBillingEmail: undefined
      });
    }
  }

  onAvatarInit(result) {
    this.setState({avatar: result.err === undefined ? result.data : null});
  }

  onSetAvatarResponse(result) {
    this.state.socket.emit("GET_AVATAR");
  }

  handleAvatarChange(event) {
    const file = event.target.files[0];
    this.setState({
      avatarFile: event.target.value
    })
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      // convert image file to base64 string
      const b64 = reader.result;
      this.setState({
        avatar: b64
      })
    }, false);
    if(file) {
      reader.readAsDataURL(file);
    }
  }

  handleSetAvatar(event) {
    this.setState({
      avatarModalOpen: false
    });
    this.state.socket.emit('SET_AVATAR', this.state.avatar)
  }

  handleSetDefaultAvatar(event) {
    this.setState({
      avatarModalOpen: false
    });
    this.state.socket.emit('SET_AVATAR', null)
  }

  handleAvatarModal() {
    this.setState({ avatarModalOpen: !this.state.avatarModalOpen, avatarFile: ''}, () => {
      this.state.socket.emit("GET_AVATAR");
    });
  }
  
  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  onBillingEmailsInit(result) {
    this.setState({billingEmails: result.err === undefined ? result.data : null});
  }

  onSetBillingEmailsResponse(result) {
    this.state.socket.emit("GET_BILLING_EMAILS");
  }

  onAddingBillingEmail(event) {
    this.setState({addingBillingEmail: ''});
  }

  onStopAddingBillingEmail(event) {
    this.setState({addingBillingEmail: undefined});
  }

  onChangeAddingBillingEmail(event) {
    this.setState({addingBillingEmail: event.target.value});
  }

  onAddBillingEmail(event) {
    if(this.validateEmail(this.state.addingBillingEmail) === null) {
      return;
    }
    const billingEmails = [...this.state.billingEmails, this.state.addingBillingEmail];
    this.setState({
      addingBillingEmail: undefined,
      billingEmails
    }, () => {
      this.state.socket.emit('SET_BILLING_EMAILS', billingEmails);
    });
  }

  onDelBillingEmail(idx) {
    return (event) => {
      const billingEmails = [...this.state.billingEmails.slice(0, idx), ...this.state.billingEmails.slice(idx+1, this.state.billingEmails.length)];
      this.setState({billingEmails}, () => {
        this.state.socket.emit('SET_BILLING_EMAILS', billingEmails);
      });
    };
  }



  copyToClipboard() {
    this.setState({
      copiedCodeTooltip: !this.state.copiedCodeTooltip
    });
    const copyText = this.props.companyCode !== null && this.props.companyCode !== undefined ? this.props.companyCode : ""
    navigator.clipboard.writeText(copyText);
  }

  onStatsInit(result) {
    this.setState({
      stats: result.stats
    });
  }
  
  onBillsInit(bills) {
    this.setState({
      sortState: Object.assign({}, this.state.sortState, {
        bills: {
          direction: 'descending',
          column: 'name'
        }
      }),
      bills: bills.sort(sortBills)
    });
  }

  onSingleBill(bill) {
    if(bill.err === undefined) {
      this.setState({
        loadingBill: false
      }, () => {
        saveAs(bill.content, `ORANE_FACTURE_${bill.filename}`);
      });
    }
  }

  onClickOnDownload(filename) {
    return (event) => {
      this.state.socket.emit('GET_SINGLE_BILL', filename);
      this.setState({
        loadingBill: true
      });
    };
  }

  handleSort(clickedColumn) {
    return () => {
      const { column, direction } = this.state.sortState.bills;
      const data = this.state.bills;

      this.setState({
        sortState: Object.assign({}, this.state.sortState, {
          bills: {
            direction: clickedColumn !== column ? 'ascending' : direction === 'ascending' ? 'descending' : 'ascending',
            column: clickedColumn
          }
        }),
        bills: clickedColumn !== column ? data.sort(sortBills) : data.reverse()
      });
    };
  }

  onChangePassword(event) {
    //const onlyNums = (str) => str.split('').filter(x => x >= '0' && x <= '9').join('');
    this.setState({
      editingPassword: /*onlyNums(*/event.target.value/*)*/
    });
  }

  onChangeConfirmPassword(event) {
    //const onlyNums = (str) => str.split('').filter(x => x >= '0' && x <= '9').join('');
    this.setState({
      editingConfirmPassword: /*onlyNums(*/event.target.value/*)*/
    });
  }

  onResetPassword(e) {
    if(this.state.editingConfirmPassword !== this.state.editingPassword) {
      this.setState({
        editingPasswordErr: "Les mots de passe ne sont pas identiques.",
        editingPasswordResult: false
      })
    } else {
      this.state.socket.emit('RESET_PASSWORD', this.state.editingPassword);
    }
  }

  onResetPasswordDone(x) {
    this.setState({
      editingPassword: "",
      editingConfirmPassword: "",
      editingPasswordResult: x.err === undefined || x.err === null,
      editingPasswordErr: x.err || ""
    });
  }

  render() {
    const addingEmailValid = this.validateEmail(this.state.addingBillingEmail) !== null;
    const billsHTML = this.state.bills.map((bill,idx) => <Table.Row key={`rowBill_${idx}`}>
      <Table.Cell>{`${bill.day}/${bill.month}/${bill.year}`}</Table.Cell>
      <Table.Cell>{bill.no}</Table.Cell>
      <Table.Cell textAlign='center'>
        <Button disabled={this.state.loadingBill} loading={this.state.loadingBill} data-tooltip="Enregistrer la facture" data-position="bottom right" icon color='blue' onClick={this.onClickOnDownload(bill.filename).bind(this)}>
            <Icon name='download' />
        </Button>
      </Table.Cell>
    </Table.Row>);
    const ebillsHTML = (this.state.billingEmails !== null && this.state.billingEmails !== undefined ? this.state.billingEmails : []).map((email,idx) => <Table.Row key={`email_${idx}`}>
        <Table.Cell>
          {email}
        </Table.Cell>
        <Table.Cell textAlign='center'>
            <Button disabled={this.state.billingEmails === undefined || null } data-tooltip="Supprimer ce destinataire" data-position="bottom right" icon color='red' onClick={this.onDelBillingEmail(idx).bind(this)}>
                <Icon name='trash' />
            </Button>
        </Table.Cell>
      </Table.Row>
    );

    return (
        <Grid stackable padded>
          <Grid.Row className="firstRow">
            <Header dividing size="huge" as="h1">
              <Icon name='user circle'/>
              <Header.Content>
                Informations du compte
              </Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              {
                this.state.editingPasswordResult !== undefined ?
                  this.state.editingPasswordResult === true ? <Message onDismiss={() => this.setState({editingPasswordResult: undefined})} positive header="Code d'accès modifié avec succès" /> : <Message onDismiss={() => this.setState({editingPasswordResult: undefined})} negative header="Une erreur est survenue lors de la modification du code d'accès" content={this.state.editingPasswordErr} />
                : ""
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid stackable>
                <Grid.Row textAlign='center'>
                  <Grid.Column width={5}>
                    <Card centered fluid>
                      <Portal
                        onClose={this.handleAvatarModal.bind(this)}
                        open={this.state.avatarModalOpen}
                        closeOnTriggerClick={true}
                        trigger={<Image as='a' onClick={this.props.isAdmin() === true ? this.handleAvatarModal.bind(this) : null} centered fluid src={this.state.avatar === null || this.state.avatar === undefined ? '/default-avatar.png' : this.state.avatar} wrapped />}
                      >
                        <Segment
                          style={{
                            left: '20%',
                            position: 'fixed',
                            top: '15%',
                            zIndex: 1000,
                          }}
                        >
                          <p>
                            Modifier l'avatar de l'entreprise
                            <Button 
                              style={{float: 'right'}}
                              icon='undo'
                              data-tooltip='Avatar par défaut'
                              color='blue'
                              size='tiny'
                              onClick={this.handleSetDefaultAvatar.bind(this)}
                            />
                          </p>
                          <Input
                            size='mini'
                            icon="picture"
                            iconPosition="left"
                            placeholder="Photo"
                            type="file"
                            value={this.state.avatarFile}
                            onChange={this.handleAvatarChange.bind(this)}
                            accept="image/*"
                          />
                          <Button 
                            icon='check'
                            size='tiny'
                            positive
                            onClick={this.handleSetAvatar.bind(this)}
                          />
                          <Button
                            icon='delete'
                            size='tiny'
                            negative
                            onClick={this.handleAvatarModal.bind(this)}
                          />
                        </Segment>
                      </Portal>
                      <Card.Content>
                        <Card.Header>{this.props.companyName}</Card.Header>
                        <Card.Meta>
                          {
                            this.props.subscriptionStart !== null ?
                            <span className='date'>Compte créé le {moment.unix(this.props.subscriptionStart).format("DD/MM/YYYY")}</span>
                            : ''
                          }
                        </Card.Meta>
                        <Divider hidden />
                        <Card.Description>
                          <b>Code entreprise :</b>&nbsp; 
                          <Label 
                            size="big"
                            color='blue' 
                            as='a' 
                            data-tooltip={this.state.copiedCodeTooltip ===true ? 'copié !' : 'cliquez pour copier le code'}
                            onClick={this.copyToClipboard.bind(this)}
                            >
                            {this.props.companyCode}
                          </Label>
                        </Card.Description>
                      </Card.Content>
                      {
                        this.props.isAdmin() === true ?
                        <Card.Content extra>
                          <Link to="/collaborateurs">
                            <Icon name='user' />
                            &nbsp;&nbsp;&nbsp;{(this.state.stats && this.state.stats.employeeActiveCount) || '-'} {this.state.stats && this.state.stats.employeeActiveCount > 1 ? ' employés' : ' employé'}
                            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                            <Icon name='group' />
                            &nbsp;&nbsp;&nbsp;{(this.state.stats && this.state.stats.teamCount) || '-'} {this.state.stats && this.state.stats.teamCount > 1 ? ' équipes' : ' équipe'}
                            <br />
                            <Icon name='history' />
                            &nbsp;&nbsp;{(this.state.stats && this.state.stats.pointageCount) || '-'} {this.state.stats && this.state.stats.pointageCount > 1 ? ' pointages' : ' pointage'}
                          </Link>
                        </Card.Content>
                        : ''
                      }
                    </Card>
                  </Grid.Column>                
                  <Grid.Column width={4}>
                    {this.props.isAdmin() === true ?
                      <Card fluid centered>
                        <Card.Content>
                        <Divider hidden />
                        <Divider hidden />
                          <Card.Header>Modifier votre code d'accès</Card.Header>
                          <Divider hidden />
                          <Card.Description>
                            <Input 
                              placeholder="Saisir le nouveau code"
                              icon='lock'
                              iconPosition='left'
                              type="text"
                              value={this.state.editingPassword !== null && this.state.editingPassword !== undefined ? this.state.editingPassword : ""}
                              onChange={this.onChangePassword.bind(this)}
                            />
                            <Card.Meta>
                              <br />
                            </Card.Meta>
                            <Input
                              placeholder="Confirmez le code"
                              icon='lock'
                              iconPosition='left'
                              type="text"
                              value={this.state.editingConfirmPassword !== null && this.state.editingConfirmPassword !== undefined ? this.state.editingConfirmPassword : ""}
                              onChange={this.onChangeConfirmPassword.bind(this)}
                            />
                            <br />
                            <span>8 caractères minimum</span>
                            <Divider hidden />
                            <Button
                              color={this.state.editingConfirmPassword === this.state.editingPassword && this.state.editingPassword.length > 7 ? 'green' : 'orange'}
                              inverted
                              labelPosition='right'
                              icon='check'
                              content='Réinitialiser'
                              onClick={this.onResetPassword.bind(this)}
                              data-tooltip={this.state.editingConfirmPassword === this.state.editingPassword && this.state.editingPassword.length > 7 ? "Cliquez pour enregistrer votre nouveau code d'accès Administrateur" : "Code d'accès non-conforme"}
                            />
                          </Card.Description>
                        </Card.Content>
                        <Divider hidden />
                        <Divider hidden />
                      </Card>   
                    : ''}
                    <Card centered fluid>
                    <Card.Content>
                      <Divider hidden />
                      <Divider hidden />
                      <Card.Header>Vous rencontrez un problème, vous avez besoin d'aide ?</Card.Header>
                      <Divider hidden />
                      <Divider hidden />
                      <Card.Description>
                        <Label size="big" color='blue' data-tooltip="Cette section sera bientôt disponible !"><a style={{opacity: 1, color: 'white'}} href="#" target="_self"><Icon name='laptop' />&nbsp;Vidéos de formation</a></Label>
                        <br /><br />
                        <Label size="big" color='green'><a style={{opacity: 1, color: 'white'}} href="tel:0899250450"><Icon name='phone' />&nbsp;Appelez le SAV au 0899 250 450</a></Label>
                        <br /><br />
                        <Label size="big" color='grey'><a style={{opacity: 1, color: 'white'}} href={`mailto:sav@timefast.fr?subject=SAV%20Client%20:%20${this.props.companyCode}`} ><Icon name='mail' />&nbsp;&nbsp;Ouvrir une demande d'assistance</a></Label>
                        <br /><br />
                        <Label as='a' size="big" color='teal' onClick={(evt) => this.props.displayChangelogModal(false)}><Icon name='cloud upload' />&nbsp;Notes de version</Label>
                        <Divider hidden />
                        <Divider hidden />
                      </Card.Description>
                    </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    {this.props.isAdmin() === true ?
                      <Card centered fluid>
                        <Card.Content>
                          <Divider hidden />
                          <Card.Header>e-Factures</Card.Header>
                          <Card.Description>
                          <Divider hidden />
                            <Table id="ebillsTbl" striped>
                              <Table.Header>
                                <Table.Row textAlign="center">
                                  <Table.HeaderCell textAlign="left">
                                    Dès leur arrivée dans votre portail, vos factures sont automatiquement envoyées par email aux destinataires ci-dessous.
                                  </Table.HeaderCell>
                                  <Table.HeaderCell width={3}>
                                    <Button data-tooltip="Ajouter un destinataire" data-position="bottom right" onClick={this.onAddingBillingEmail.bind(this)} compact icon='user plus' color='green' content="Ajouter" size="tiny" />
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                  {
                                    this.state.billingEmails !== null && this.state.billingEmails !== undefined && this.state.billingEmails.length === 0 ?
                                    <Table.Row><Table.Cell>Aucun destinataire renseigné</Table.Cell></Table.Row>
                                      :
                                      null
                                  }
                                  {ebillsHTML}
                                <Table.Row>
                                  <Table.Cell>
                                  { this.state.addingBillingEmail !== undefined ?
                                    <Input onChange={this.onChangeAddingBillingEmail.bind(this)} fluid action={
                                      <Button size='tiny' color='green' inverted={!addingEmailValid} icon={addingEmailValid ? 'check' : 'check'} onClick={this.onAddBillingEmail.bind(this)} data-tooltip={!addingEmailValid ? "Adresse invalide" : "Ajouter ce destinataire"} data-position="bottom center" />
                                      } placeholder="comptabilite@votresociete.com" value={this.state.addingBillingEmail} />
                                    :
                                    ''
                                    }
                                  </Table.Cell>
                                  <Table.Cell textAlign="center">
                                    { this.state.addingBillingEmail === undefined ?
                                    ''
                                    :
                                    <Button onClick={this.onStopAddingBillingEmail.bind(this)} size='small' icon='delete' color='red' data-tooltip="Annuler" data-position="bottom right" />
                                    }
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Card.Description>
                          <Divider hidden />
                        </Card.Content>
                      </Card> : ''}

                    {this.props.isAdmin() === true ?
                      <Card centered fluid>
                        <Card.Content>
                          <Divider hidden />
                          <Card.Header>Historique des factures</Card.Header>
                          <Card.Description>
                            <Divider hidden />
                                <Table id="billsTbl" singleLine striped sortable>
                                  <Table.Header>
                                    <Table.Row textAlign="center">
                                      <Table.HeaderCell width={6} sorted={this.state.sortState.bills.column === 'date' ? this.state.sortState.bills.direction : null} onClick={this.handleSort('date').bind(this)}>
                                        Date
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={8}>
                                        Numéro de facture
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={2}><Icon name='download' /></Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {billsHTML}
                                  </Table.Body>
                                </Table>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    : ''}
                  </Grid.Column>
                </Grid.Row>
              </Grid>

          </Grid.Row>
        </Grid>
    );
  }
}

export default Profile;
