import React from 'react';
//import './Dashboard.css';
import io from './utils/io'
import 'semantic-ui-css/semantic.min.css';
import Moment from './utils/momentfr';
import { extendMoment } from 'moment-range';
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  List,
  Icon,
  Label,
  Loader,
  Progress,
  Table
} from "semantic-ui-react";
import PointageUtils from './utils/pointage';
import resolvePath from 'object-resolve-path';
import {getMarkerIconForEmployee, getMarkerIconForCar, getEmployeeColor} from './utils/map';
import {reverseGeocodeAddr} from './utils/photon';
import { Map as LeafletMap, Marker, TileLayer, Tooltip } from 'react-leaflet';

import { hourEndByDay, isSubmoduleVisible, manualCounterEnabled, manualCounterFactor, manualCounterName, nightShiftCounterEnabled, secondManualCounterEnabled, secondManualCounterFactor, secondManualCounterName, thirdManualCounterEnabled, thirdManualCounterFactor, thirdManualCounterName } from './utils/localSettings';
import { getNbHoursExpectedFromProfile, checkIfSpecificRuleForToday } from './utils/nbHoursExpected';
import { formatHoursWithMinutes } from './utils/formatHoursWithMinutes';
import { getOriginForPointage } from './utils/pointageOrigin';

const moment = extendMoment(Moment());

const TODAY_SUMMARY = () => ({
  _reqID: "today",
  from: moment().hour(hourEndByDay()).minute(0).second(0).unix(),
  to: moment().add(1, 'day').hour(hourEndByDay()).minute(0).second(0).subtract(1, 'second').unix()
});
const YESTERDAY_SUMMARY = () => ({
  _reqID: "yesterday",
  from: moment().subtract(1, 'd').hour(hourEndByDay()).minute(0).second(0).unix(),
  to: moment().hour(hourEndByDay()).minute(0).second(0).subtract(1, 'second').unix()
});
const LASTWEEK_SUMMARY = () => ({
  _reqID: "lastweek",
  from: moment().subtract(1, 'w').day(1).hour(hourEndByDay()).minute(0).second(0).unix(),
  to: moment().day(0).add(1, 'day').hour(hourEndByDay()).minute(0).second(0).subtract(1, 'second').unix()
});
const LASTMONTH_SUMMARY = () => ({
  _reqID: "lastmonth",
  from: moment().subtract(1, 'month').date(1).hour(hourEndByDay()).minute(0).second(0).unix(),
  to: moment().date(1).hour(hourEndByDay()).minute(0).second(0).subtract(1, 'second').unix()
});

const SUMMARY_RANGE = (summary) => {
  return Array.from(moment.range(moment.unix(summary.from), moment.unix(summary.to)).by('day')).map(x => x.format("DD/MM/YYYY"));
}

const DEFAULT_LAT_LON = [46.9255488, 3.081462];
const DEFAULT_ZOOM = 6;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      summaries: {
        [TODAY_SUMMARY()._reqID]: [],
        [YESTERDAY_SUMMARY()._reqID]: [],
        [LASTWEEK_SUMMARY()._reqID]: [],
        [LASTMONTH_SUMMARY()._reqID]: []
      },
      sortableSummary: {
        data: []
      },
      rpiHardwareNames: {},
      locationsVisible: false,
      employees: [],
      cars: [],
      carEmployees: [],
      carsPositions: [],
      map_latLon: DEFAULT_LAT_LON,
      map_zoom: DEFAULT_ZOOM,
      mapHasFocus: false,
      loadingSummary: true,
      loadingCars: true,
      latestPhonePointages: [],
      latestPhonePointagesAddrs: {},
      allManualCountersVisible: false,
      commentariesVisible: false
    };
    this.state = Object.assign(this.state, {
      listeners: {
        GET_SUMMARY: this.onSummaryInit.bind(this),
        POINTAGE: this.onPointage.bind(this),
        GET_RPIHARDWARENAME: this.onRpiHardwareNamesInit.bind(this),
        GET_EMPLOYEE: this.onEmployeesInit.bind(this),
        GET_CARS: this.onCarsInit.bind(this),
        GET_CAREMPLOYEE: this.onCarEmployeesInit.bind(this),
        GET_CARS_POSITIONS: this.onCarsPositionsInit.bind(this),
      }
    });
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    this.state.socket.emit('GET_SUMMARY', TODAY_SUMMARY());
    this.state.socket.emit('GET_SUMMARY', YESTERDAY_SUMMARY());
    this.state.socket.emit('GET_SUMMARY', LASTWEEK_SUMMARY());
    this.state.socket.emit('GET_SUMMARY', LASTMONTH_SUMMARY());
    this.state.socket.emit('GET_RPIHARDWARENAME');
    this.state.socket.emit('GET_EMPLOYEE');
    this.state.socket.emit('GET_CARS');
    this.state.socket.emit('GET_CAREMPLOYEE');
    this.state.socket.emit('GET_CARS_POSITIONS', {_reqID: "dashboardTraccar"});
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));
  }

  onSummaryInit({_reqID, summary}) {
    this.setState({
      summaries: Object.assign({}, this.state.summaries, {[_reqID]: summary}),
      loadingSummary: false,
      latestPhonePointages: this.state.summaries[TODAY_SUMMARY()._reqID].map((e, idx) => {
        const phonePointages = e.pointages.filter((x) => x.gpsCoords !== null && x.gpsCoords !== undefined && x.gpsCoords !== '' && x.badgeNo === 'PHONE').sort((x1, x2) => x1.timestamp < x2.timestamp ? -1 : 1);
        if(phonePointages.length > 0) {
          const p = phonePointages.slice(-1)[0];
          const [lat, lon] = p.gpsCoords.split('|');
          reverseGeocodeAddr(lat, lon)
            .then(adress => this.setState({latestPhonePointagesAddrs: Object.assign(this.state.latestPhonePointagesAddrs, {[p.id]: adress})}))
          return Object.assign({employeeName: e.name}, p);
        } else {
          return null;
        }
      }).filter(x => x !== null)
    }, () => {
      if(_reqID === TODAY_SUMMARY()._reqID) {
        const sortableSummaryData = this.state.summaries[TODAY_SUMMARY()._reqID].map((e, idx) => {
          const utils = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(e.pointages, SUMMARY_RANGE(TODAY_SUMMARY()));
    
          const sortedPointages = utils.pointages;
    
          const isEmployeeStillHere = sortedPointages.length > 0 ?
            sortedPointages[sortedPointages.length-1].type === 'E'
            : false;
          const lastEntryDate = isEmployeeStillHere ?
            moment.unix(sortedPointages[sortedPointages.length-1].timestamp).format("HH:mm:ss")
            : "--";
          
          const utilsValidated = utils.validatedHours();
          const validatedHours = utilsValidated.amount;
          const validatedHoursCustom = utilsValidated.amountCustom;
          const validatedHoursManual = utilsValidated.amountManual;
          const validatedHoursSecondManual = utilsValidated.amountSecondManual;
          const validatedHoursThirdManual = utilsValidated.amountThirdManual;
          const expectedHours = getNbHoursExpectedFromProfile(e, TODAY_SUMMARY().from, this.props.hourEndByDay(e));
          const balanceToday = validatedHours + (manualCounterFactor()*validatedHoursManual) + (secondManualCounterFactor()*validatedHoursSecondManual) + (thirdManualCounterFactor()*validatedHoursThirdManual) - expectedHours;
          
          return {
            validatedHours,
            sortedPointages,
            isEmployeeStillHere,
            lastEntryDate,
            e: Object.assign({}, e, {balanceToday, validatedHoursCustom, validatedHoursManual, validatedHoursSecondManual, validatedHoursThirdManual})
          };
        });
        this.setState({
          sortableSummary: Object.assign(this.state.sortableSummary, {
            data: sortableSummaryData.sort((d1, d2) => d1.e.name < d2.e.name ? -1 : 1)
          })
        });
      }
    });
  }

  onEmployeesInit(employees) {
    this.setState({
      employees
    });
  }

  onCarsInit(cars) {
    this.setState({
      cars,
      loadingCars: false,
      map_latLon: this.getMapCenter()
    });
  }

  onCarEmployeesInit(carEmployees) {
    this.setState({
      carEmployees
    });
  }


  onCarsPositionsInit({summary}) {
    const validPositions = summary.filter((car) => car.position !== null && car.position !== undefined && car.position.length === 2);
    Promise.all(
      validPositions.map(carPosition =>
        reverseGeocodeAddr(carPosition.position[0], carPosition.position[1])
          .then(adress => Object.assign(carPosition, {position : Object.assign(carPosition.position, {adress})}))
      )
    ).then(carsPositions => this.setState({carsPositions}));
  }


  onRpiHardwareNamesInit(rpiHardwareNames) {
    this.setState({
      rpiHardwareNames
    }, () => {
      //this.state.socket.emit('GET_LOCATION');
    });
  }

  onPointage(pointage) {
    this.state.socket.emit('GET_SUMMARY', TODAY_SUMMARY());
    this.state.socket.emit('GET_SUMMARY', YESTERDAY_SUMMARY());
    this.state.socket.emit('GET_SUMMARY', LASTWEEK_SUMMARY());
    this.state.socket.emit('GET_SUMMARY', LASTMONTH_SUMMARY());
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state.sortableSummary;

    if (column !== clickedColumn) {
      this.setState({
        sortableSummary: {
          column: clickedColumn,
          data: data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1),
          direction: 'ascending'
        }
      });
      return;
    }

    this.setState({
      sortableSummary: {
        data: data.reverse(),
        direction: direction === 'ascending' ? 'descending' : 'ascending',
        column
      }
    });
  }

  handleToggleLocationVisibility(evt) {
    this.setState({
      locationsVisible: !this.state.locationsVisible
    });
  }

  handleToggleManualCountersVisibility(evt) {
    this.setState({
      allManualCountersVisible: !this.state.allManualCountersVisible
    });
  }

  handleToggleCommentaryVisibility(evt) {
    this.setState({
      commentariesVisible: !this.state.commentariesVisible
    });
  }

  // See any point on the map directly
  handleGoTo([lat, lon]) {
    return (evt) => {
      this.setState({
        map_latLon: [lat, lon],
        map_zoom: 17
      });
    };
  }

  handleGoToCar([lat, lon]) {
    return (evt) => {
      this.setState({
        map_latLon: [lat, lon],
        map_zoom: 17
      });
    };
  }

  getMapCenter() {
    const carPos = this.state.carsPositions.filter(c => c.position[0] !== 0 && c.position[1] !== 0);
    if(carPos.length === 0) {
      return DEFAULT_LAT_LON;
    }
    const minLat = carPos.reduce((acc,c) => c.position[0] < acc ? c.position[0] : acc, 90);
    const maxLat = carPos.reduce((acc,c) => c.position[0] > acc ? c.position[0] : acc, 0);
    const minLon = carPos.reduce((acc,c) => c.position[1] < acc ? c.position[1] : acc, 180);
    const maxLon = carPos.reduce((acc,c) => c.position[1] > acc ? c.position[1] : acc, 0);
    return [(maxLat+minLat)/2, (maxLon+minLon)/2];
  }

  render() {
    if(this.mapRef.current !== null && this.mapRef.current !== undefined) {
      this.mapRef.current.leafletElement.invalidateSize();
    }
    const now = moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY").unix();

    const summaryData = this.state.sortableSummary.data;
    //const presenceHoursTotal = summaryData.map(({validatedHours}) => validatedHours).reduce((a,b) => a+b, 0);
    const expectedHoursPerEmployee = summaryData.map(({e}) => ({id: e.id, nbHoursExpected: getNbHoursExpectedFromProfile(e, now, this.props.hourEndByDay(e)), specificRule: checkIfSpecificRuleForToday(e, now) !== undefined}));
    //const expectedHoursTotal = expectedHoursPerEmployee.map(eh => eh.nbHoursExpected).reduce((a,b) => a+b, 0);
    //const presenceRatio = Math.round((presenceHoursTotal / expectedHoursTotal) * 100);

    const summaryHTML = summaryData.map(({e, lastEntryDate, sortedPointages, validatedHours, isEmployeeStillHere}, idx) => {
      const commentaryForTheDay = e.commentaries.find(c => c.day === moment.unix(now).format("DD/MM/YYYY"));
      return (
        <Table.Row key={idx}>
          <Table.Cell textAlign='center' width={1}><Icon name="circle" color={isEmployeeStillHere ? 'green' : 'red'}/></Table.Cell>
          <Table.Cell textAlign='center' width={4}>{e.name}</Table.Cell>
          <Table.Cell textAlign='left'>
          {
            sortedPointages.map((p, idx) => (
              <Label as='span' key={idx}>
                <Icon name='clock' color={p.type === 'E' ? 'green' : 'red'} />
                {moment.unix(p.timestamp).format("HH:mm")}
              </Label>
            ))
          }
          </Table.Cell>
          {
            this.state.locationsVisible ?
            <Table.Cell textAlign='left'>
              {sortedPointages.map((p, idx) => {
                const labelKey = `site_${e.id}_${p.id}_${idx}`;
                const origin = getOriginForPointage(p, this.props.rpiHardware, this.state.rpiHardwareNames);
                return (
                  origin.raw === 'WEB' ?
                  <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage en ligne" data-position="bottom left"><Icon name='laptop' style={{marginRight: '0'}} /> PC</Label> :
                    origin.raw === 'NA' ?
                      <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage ajouté" data-position="bottom left"><Icon name='edit' style={{marginRight: '0'}} /></Label> :
                        origin.raw === 'PHONE' ?
                            (
                              origin.location !== "" && origin.location !== "Inconnu" ?
                              <Label key={labelKey} as='span' size='medium'>{origin.location}</Label>
                              :
                              <Label key={labelKey} as='span' size='medium' data-tooltip="Retrouvez l'adresse dans le menu 'Déplacements'" data-position="bottom left"><Icon name='street view' style={{marginRight: '0'}} /></Label>
                            ) :
                            origin.raw === 'NIGHTSHIFT' ?
                            <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage auto. coupure journée" data-position="bottom left"><Icon name='cut' style={{marginRight: '0'}} /></Label> :
                              origin.raw !== 'UNKNOWN' ?
                                <Label key={labelKey} as='span' size='medium'>{origin.display}</Label> :
                                <Label key={labelKey} as='span' size='medium' data-tooltip="Origine inconnue" data-position="bottom left"><Icon name='question circle outline' style={{marginRight: '0'}} /></Label>
                );
              })}
            </Table.Cell>
            : null
          }
          {
            this.state.commentariesVisible ?
            <Table.Cell textAlign='left'>{commentaryForTheDay !== undefined ? commentaryForTheDay.value : ''}</Table.Cell>
            : null
          }
          {
            this.state.allManualCountersVisible && manualCounterEnabled() ?
            <Table.Cell textAlign='center' width={1}>
              <Label as='span' color={'grey'}>{formatHoursWithMinutes(e.validatedHoursManual)}</Label>
            </Table.Cell>
            : null
          }
          {
            this.state.allManualCountersVisible && secondManualCounterEnabled() ?
            <Table.Cell textAlign='center' width={1}>
              <Label as='span' color={'grey'}>{formatHoursWithMinutes(e.validatedHoursSecondManual)}</Label>
            </Table.Cell>
            : null
          }
          {
            this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
            <Table.Cell textAlign='center' width={1}>
              <Label as='span' color={'grey'}>{formatHoursWithMinutes(e.validatedHoursThirdManual)}</Label>
            </Table.Cell>
            : null
          }
          {
            this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
            <Table.Cell textAlign='center' width={1}>
              <Label as='span' color={'grey'}>{formatHoursWithMinutes(e.validatedHoursCustom)}</Label>
            </Table.Cell>
            : null
          }
          <Table.Cell textAlign='center' width={1}><Label as='span' color='grey'>{formatHoursWithMinutes(validatedHours)}</Label></Table.Cell>
          <Table.Cell textAlign='center' width={1}>
            <Label as='span' color={expectedHoursPerEmployee.find(eh => eh.id === e.id).specificRule ? 'black' : 'grey'}>{formatHoursWithMinutes(expectedHoursPerEmployee.find(eh => eh.id === e.id).nbHoursExpected)}</Label>
          </Table.Cell>
          <Table.Cell textAlign='center' width={1}><Label as='span' color={validatedHours < expectedHoursPerEmployee.find(eh => eh.id === e.id).nbHoursExpected ? "orange" : "green"}>{formatHoursWithMinutes(e.balanceToday)}</Label></Table.Cell>
        </Table.Row>
      );
    });

    // TODO refactoring
    const todaySummaryData = this.state.summaries[TODAY_SUMMARY()._reqID].map((e, idx) => {
      const utils = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(e.pointages, SUMMARY_RANGE(TODAY_SUMMARY()));
      const validatedHours = utils.validatedHours().amount;
      return {
        e, validatedHours
      };
    });
    const presenceHoursTotalToday = todaySummaryData.map(({validatedHours}) => validatedHours).reduce((a,b) => a+b, 0);
    const expectedHoursTotalToday = todaySummaryData.map(({e}) => getNbHoursExpectedFromProfile(e, TODAY_SUMMARY().from, this.props.hourEndByDay(e))).reduce((a,b) => a+b, 0);
    const presenceRatioToday = (expectedHoursTotalToday > 0 ? Math.round((presenceHoursTotalToday / expectedHoursTotalToday) * 100) : 100);

    const yesterdaySummaryData = this.state.summaries[YESTERDAY_SUMMARY()._reqID].map((e, idx) => {
      const utils = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(e.pointages, SUMMARY_RANGE(YESTERDAY_SUMMARY()));
      const validatedHours = utils.validatedHours().amount;
      return {
        e, validatedHours
      };
    });
    const presenceHoursTotalYesterday = yesterdaySummaryData.map(({validatedHours}) => validatedHours).reduce((a,b) => a+b, 0);
    const expectedHoursTotalYesterday = yesterdaySummaryData.map(({e}) => getNbHoursExpectedFromProfile(e, YESTERDAY_SUMMARY().from, this.props.hourEndByDay(e))).reduce((a,b) => a+b, 0);
    const presenceRatioYesterday = (expectedHoursTotalYesterday > 0 ? Math.round((presenceHoursTotalYesterday / expectedHoursTotalYesterday) * 100) : 100);

    const lastWeekSummaryData = this.state.summaries[LASTWEEK_SUMMARY()._reqID].map((e, idx) => {
      const utils = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(e.pointages, SUMMARY_RANGE(LASTWEEK_SUMMARY()));
      const validatedHours = utils.validatedHours().amount;
      return {
        e, validatedHours
      };
    });
    // TODO working days
    const presenceHoursTotalLastWeek = lastWeekSummaryData.map(({validatedHours}) => validatedHours).reduce((a,b) => a+b, 0);
    const expectedHoursTotalLastWeek = lastWeekSummaryData.map(({e}) => {
      const daysOfLastWeek = Array.from(moment.range(moment.unix(LASTWEEK_SUMMARY().from), moment.unix(LASTWEEK_SUMMARY().to)).by('day'));
      return daysOfLastWeek.reduce((acc,day) =>
        acc + getNbHoursExpectedFromProfile(e, day.unix(), this.props.hourEndByDay(e))
      , 0);
    }).reduce((a,b) => a+b, 0);
    const presenceRatioLastWeek = (expectedHoursTotalLastWeek > 0 ? Math.round((presenceHoursTotalLastWeek / expectedHoursTotalLastWeek) * 100) : 100);
    const lastMonthSummaryData = this.state.summaries[LASTMONTH_SUMMARY()._reqID].map((e, idx) => {
      const utils = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(e.pointages, SUMMARY_RANGE(LASTMONTH_SUMMARY()));
      const validatedHours = utils.validatedHours().amount;
      return {
        e, validatedHours
      };
    });
    // TODO working days
    //const totalWorkingDaysInLastMonth = moment().subtract(1, 'month').businessDaysIntoMonth();
    const presenceHoursTotalLastMonth = lastMonthSummaryData.map(({validatedHours}) => validatedHours).reduce((a,b) => a+b, 0);
    const expectedHoursTotalLastMonth = lastMonthSummaryData.map(({e}) => {
      const daysOfLastMonth = Array.from(moment.range(moment.unix(LASTMONTH_SUMMARY().from), moment.unix(LASTMONTH_SUMMARY().to)).by('day'));
      return daysOfLastMonth.reduce((acc,day) =>
        acc + getNbHoursExpectedFromProfile(e, day.unix(), this.props.hourEndByDay(e))
      , 0);
    }).reduce((a,b) => a+b, 0);
    const presenceRatioLastMonth = (expectedHoursTotalLastMonth > 0 ? Math.round((presenceHoursTotalLastMonth / expectedHoursTotalLastMonth) * 100) : 100);
    const getCarsMarkers = () => {
      return this.state.carsPositions.filter((car) => car.lastUpdate !== null).map((car, idx) => {
        const carEntity = this.state.cars.find(x => x.name === car.name) || {};
        const carEmployeeEntity = this.state.carEmployees.find(x => x.carImei === carEntity.imei) || {};
        const employee = this.state.employees.find(x => x.id === carEmployeeEntity.employeeId) || {};
        return (
          <Marker icon={getMarkerIconForCar(car.name)} key={`car_marker_start_${idx}`} position={car.position}>
            {
              <Tooltip>
                {employee.name || '"Conducteur inconnu"'}<br />
                {carEmployeeEntity.licensePlate || `${car.name}`}<br />
                {moment(car.lastUpdate).format("DD/MM/YYYY à HH:mm") || ""}
              </Tooltip>
            }
          </Marker>
        );
      });
    };

    const phonesSummaryHTML = this.state.latestPhonePointages.map((pointage, idx) => {
      const addr = this.state.latestPhonePointagesAddrs[pointage.id];
      return <List.Item key={`phonepointage_${idx}`} onClick={this.handleGoTo(pointage.gpsCoords.split('|')).bind(this)}>
        <List.Icon name='point' size='large' verticalAlign='middle' style={{color: getEmployeeColor(pointage.employeeName)}} />
        <List.Content>
          <List.Header as='a'>{pointage.employeeName}</List.Header>
          <List.Description as='a'>{moment.unix(pointage.timestamp).format("DD/MM/YYYY HH:mm")}</List.Description>
          {
            addr !== null && addr !== undefined ? 
            <Label>{addr.name || ''} {addr.street || ''}<br />{addr.postcode} {addr.city}</Label>
            : <Label>{pointage.gpsCoords}</Label>
          }
        </List.Content>
      </List.Item>;
    });

    const getPhoneMarkers = () => {
      return this.state.latestPhonePointages.map((p, idx) => {
        const addr = this.state.latestPhonePointagesAddrs[p.id];
        const [lat, lon] = p.gpsCoords.split('|');
        return (
          <Marker icon={getMarkerIconForEmployee(p.employeeName)} key={`phone_marker_start_${idx}`} position={{lat, lon}}>
            {
              <Tooltip>
                {p.employeeName || ''}<br />
                {addr !== null && addr !== undefined ? `${addr.name || ''} ${addr.street || ''}` : p.gpsCoords}<br />
                {addr !== null && addr !== undefined ? `${addr.postcode || ''} ${addr.city || ''}` : ''}<br />
                {moment(p.timestamp).format("DD/MM/YYYY à HH:mm") || ""}
              </Tooltip>
            }
          </Marker>
        );
      });
    };

    const carsSummaryHTML = this.state.carsPositions.filter((car) => car.lastUpdate !== null).map((car, idx) => {
      const carEntity = this.state.cars.find(x => x.name === car.name) || {};
      const carEmployeeEntity = this.state.carEmployees.find(x => x.carImei === carEntity.imei) || {};
      const employee = this.state.employees.find(x => x.id === carEmployeeEntity.employeeId) || {};
      return <List.Item key={`car_${idx}`} onClick={this.handleGoToCar(car.position).bind(this)}>
        <List.Icon name='shipping' size='large' verticalAlign='middle' style={{color: getEmployeeColor(car.name)}} />
        <List.Content>
          <List.Header as='a'>{employee.name || '"Conducteur inconnu"'}</List.Header>
          <List.Description as='a'>{carEmployeeEntity.licensePlate || `${car.name}`}</List.Description>
          <Label>{car.position.adress.name || ''} {car.position.adress.street || ''}<br />{car.position.adress.postcode} {car.position.adress.city}</Label> 
        </List.Content>
      </List.Item>;
    });

    const numberOfColsDisplayed = 1 + (this.state.cars.length > 0 ? 1 : 0);
    
    const getPhoneSegment = () => (
      <Grid.Row> 
        <Header className="settingsHeader" attached='top' dividing size="large" as="h2" >
          <Icon name="street view" />
          Pointages Géolocalisés du jour
        </Header>
        <Segment attached style={{overflow: 'auto', maxHeight: '600px', height: '100%'}} padded>
          <List relaxed size="large">
            {phonesSummaryHTML.length > 0 ? phonesSummaryHTML : <Label>Aucun pointage géolocalisé sur la journée</Label>}
          </List>
        </Segment>
      </Grid.Row>
    );
    const getPhoneCol = (w) => (
      <Grid.Column width={w} textAlign='left'>
        {getPhoneSegment()}
      </Grid.Column>
    );
    const getCarsSegment = () => (
      <Grid.Row> 
        <Header className="settingsHeader" attached='top' dividing size="large" as="h2" >
        <Icon name="car" />
          Géolocalisation des véhicules
        </Header>
        <Segment attached style={{overflow: 'auto', maxHeight: '600px', height: '100%' }} padded>
          <List relaxed size="large">
            {carsSummaryHTML}
          </List>
        </Segment>
      </Grid.Row>
    );
    const getCarsCol = (w) => (
      <Grid.Column width={w} textAlign='left'>
        {getCarsSegment()}
      </Grid.Column>
    );

    return (
        <Grid padded stackable className="dashboardGrid">
          <Grid.Row className="firstRow">
            <Grid.Column width={4}>
              <Header size="huge" as="h1">
                <Header.Content>
                  Tableau de Bord
                  <Loader style={{marginLeft: '10px'}} inline active={this.state.loadingSummary} />
                </Header.Content>
              </Header>
            </Grid.Column>
            {
                isSubmoduleVisible('dashboard', 'presenceRatios') ?
                  [
                    <Grid.Column textAlign='left' width={2} key='presenceRatios_today'>
                      <Progress as='a' size='medium' data-tooltip={
                              `Présence du ${moment.unix(TODAY_SUMMARY().from).format("DD/MM/YYYY")}`
                            } data-position="bottom right" percent={presenceRatioToday} progress active 
                            color={presenceRatioToday > 80 ? 'green' : presenceRatioToday > 50 ? 'olive' : 'yellow'}>
                              Aujourd'hui
                      </Progress>
                    </Grid.Column>,
                    <Grid.Column textAlign='left' width={2} key='presenceRatios_yesterday'>
                      <Progress as='a' size='medium' data-tooltip={
                              `Présence du ${moment.unix(YESTERDAY_SUMMARY().from).format("DD/MM/YYYY")}`
                            } data-position="bottom right" percent={presenceRatioYesterday} progress
                            color={presenceRatioYesterday > 80 ? 'green' : presenceRatioYesterday > 50 ? 'olive' : 'yellow'}>
                              Hier
                      </Progress>
                    </Grid.Column>,
                    <Grid.Column textAlign='left' width={2} key='presenceRatios_lastWeek'>
                      <Progress as='a' size='medium' data-tooltip={
                              `Présence du ${moment.unix(LASTWEEK_SUMMARY().from).format("DD/MM/YYYY")} au ${moment.unix(LASTWEEK_SUMMARY().to).hour(0).minute(0).second(0).subtract(hourEndByDay() > 0 ? 1 : 0, 'second').format("DD/MM/YYYY")}`
                            } data-position="bottom right" percent={presenceRatioLastWeek} progress
                            color={presenceRatioLastWeek > 80 ? 'green' : presenceRatioLastWeek > 50 ? 'olive' : 'yellow'}>
                              Semaine dernière
                      </Progress>
                    </Grid.Column>,
                    <Grid.Column textAlign='left' width={2} key='presenceRatios_lastMonth'>
                      <Progress as='a' size='medium' data-tooltip={
                              `Présence du ${moment.unix(LASTMONTH_SUMMARY().from).format("DD/MM/YYYY")} au ${moment.unix(LASTMONTH_SUMMARY().to).hour(0).minute(0).second(0).subtract(hourEndByDay() > 0 ? 1 : 0, 'second').format("DD/MM/YYYY")}`
                            } data-position="bottom right" percent={presenceRatioLastMonth} progress 
                            color={presenceRatioLastMonth > 80 ? 'green' : presenceRatioLastMonth > 50 ? 'olive' : 'yellow'}>
                              Mois dernier
                      </Progress>
                    </Grid.Column>
                  ]
                  : <Grid.Column width={8}></Grid.Column>
                        }
            <Grid.Column textAlign='right' verticalAlign='middle' width={4}>
              <Header as="h2">
                {moment().format('dddd Do MMMM YYYY')}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{padding: 0}}>
            <Grid.Column>
              <Divider />
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{padding: 0}}>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          { 
          isSubmoduleVisible('dashboard', 'table') ?
          <Grid.Row>
            <Grid.Column>
              <Segment basic style={{overflow: 'auto', maxHeight: '70vh', padding: '0 0 0 0', border: '0px'}}>
                <Table id="dashboardTbl" className="dashEmployeeLine" size='large' basic compact celled stackable striped sortable style={{marginBottom: '50px'}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        textAlign='center'
                        sorted={this.state.sortableSummary.column === 'isEmployeeStillHere' ? this.state.sortableSummary.direction : null}
                        onClick={this.handleSort('isEmployeeStillHere').bind(this)}>
                          En poste
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign='center'
                        sorted={this.state.sortableSummary.column === 'e.name' ? this.state.sortableSummary.direction : null}
                        onClick={this.handleSort('e.name').bind(this)}>
                          Employé
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>
                        Pointages&nbsp;&nbsp;&nbsp;
                          <Button data-position="bottom right" data-tooltip={this.state.locationsVisible ? 'Masquer l\'origine des pointages' : 'Afficher l\'origine des pointages'} circular basic icon size='mini' onClick={this.handleToggleLocationVisibility.bind(this)}>
                            <Icon name='location arrow' color={this.state.locationsVisible ? 'red' : 'green'} />
                          </Button>
                          <Button data-position="bottom right" data-tooltip={this.state.commentariesVisible ? 'Masquer les commentaires' : 'Afficher les commentaires'} circular basic icon size='mini' onClick={this.handleToggleCommentaryVisibility.bind(this)}>
                            <Icon name='comment alternate' color={this.state.commentariesVisible ? 'red' : 'green'} />
                          </Button>
                          {
                            manualCounterEnabled() || secondManualCounterEnabled() || thirdManualCounterEnabled() || nightShiftCounterEnabled() ?
                            <Button data-position="bottom right" data-tooltip={this.state.allManualCountersVisible ? 'Masquer les compteurs supplémentaires' : 'Afficher les compteurs supplémentaires'} circular basic icon size='mini' onClick={this.handleToggleManualCountersVisibility.bind(this)}>
                              <Icon name='unordered list' color={this.state.allManualCountersVisible ? 'red' : 'green'} />
                            </Button> : null
                          }
                      </Table.HeaderCell>
                      {
                        this.state.locationsVisible ?
                        <Table.HeaderCell textAlign='center'>Origine</Table.HeaderCell>
                        : null
                      }
                      {
                        this.state.commentariesVisible ?
                        <Table.HeaderCell textAlign='center'>Commentaires</Table.HeaderCell>
                        : null
                      }
                      {
                        this.state.allManualCountersVisible && manualCounterEnabled() ?
                        <Table.HeaderCell
                          textAlign='center'
                          sorted={this.state.sortableSummary.column === 'e.validatedHoursManual' ? this.state.sortableSummary.direction : null}
                          onClick={this.handleSort('e.validatedHoursManual').bind(this)}>
                          {manualCounterName() || 'Cpt. Perso 1'}
                        </Table.HeaderCell>
                        : null
                      }
                      {
                        this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                        <Table.HeaderCell
                          textAlign='center'
                          sorted={this.state.sortableSummary.column === 'e.validatedHoursSecondManual' ? this.state.sortableSummary.direction : null}
                          onClick={this.handleSort('e.validatedHoursSecondManual').bind(this)}>
                          {secondManualCounterName() || 'Cpt. Perso 2'}
                        </Table.HeaderCell>
                        : null
                      }
                      {
                        this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                        <Table.HeaderCell
                          textAlign='center'
                          sorted={this.state.sortableSummary.column === 'e.validatedHoursThirdManual' ? this.state.sortableSummary.direction : null}
                          onClick={this.handleSort('e.validatedHoursThirdManual').bind(this)}>
                          {thirdManualCounterName() || 'Cpt. Perso 3'}
                        </Table.HeaderCell>
                        : null
                      }
                      {
                        this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                        <Table.HeaderCell
                          textAlign='center'
                          sorted={this.state.sortableSummary.column === 'e.validatedHoursCustom' ? this.state.sortableSummary.direction : null}
                          onClick={this.handleSort('e.validatedHoursCustom').bind(this)}>
                          H. de nuit
                        </Table.HeaderCell>
                        : null
                      }
                      <Table.HeaderCell
                        textAlign='center'
                        sorted={this.state.sortableSummary.column === 'validatedHours' ? this.state.sortableSummary.direction : null}
                        onClick={this.handleSort('validatedHours').bind(this)}>
                        Réel
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign='center'
                        sorted={this.state.sortableSummary.column === 'e.profile.nbHoursExpected' ? this.state.sortableSummary.direction : null}
                        onClick={this.handleSort('e.profile.nbHoursExpected').bind(this)}>
                        Théorique
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign='center'
                        sorted={this.state.sortableSummary.column === 'e.balanceToday' ? this.state.sortableSummary.direction : null}
                        onClick={this.handleSort('e.balanceToday').bind(this)}>
                        Balance
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {summaryHTML}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row> : ''
          }
          <Grid.Row style={{padding: 0}}>
          </Grid.Row>
          {
              isSubmoduleVisible('dashboard', 'carMap') ?
              [<Grid.Row key="geoloc_headerRow">
                <Loader style={{marginLeft: '10px'}} inline active={this.state.loadingSummary} />
              </Grid.Row>,
              (numberOfColsDisplayed > 1 ? <Grid.Row key="geoloc_subheaderRow">
                {
                  numberOfColsDisplayed > 1 ?
                  <Grid.Column width={8}>
                  </Grid.Column> : null
                }
                {
                  this.state.cars.length > 0 && numberOfColsDisplayed > 1 ?
                  <Grid.Column width={8}>
                  </Grid.Column> : null
                }
              </Grid.Row> : null
              ),
              (numberOfColsDisplayed > 1 ?
                <Grid.Row key="geoloc_phoneCarRow">
                {
                  getPhoneCol(8)
                }
                {
                  this.state.cars.length > 0 ?
                  getCarsCol(8) : null
                }
                </Grid.Row>
                : null
              ),
              isSubmoduleVisible('dashboard', 'carMap') ?
              <Grid.Row key="geoloc_mapRow">
                <Grid.Column width={numberOfColsDisplayed <= 1 ? 12 : 16}>
                  <LeafletMap ref={this.mapRef} scrollWheelZoom={this.state.mapHasFocus} onfocus={() => this.setState({mapHasFocus: true})} onblur={() => this.setState({mapHasFocus: false})} zIndex={50} style={{height: '600px', width: '100%'}} center={this.state.map_latLon} zoom={this.state.map_zoom}>
                    <TileLayer
                      zIndex={50}
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {getPhoneMarkers()}
                    {getCarsMarkers()}
                  </LeafletMap>
                </Grid.Column>
                {
                  numberOfColsDisplayed <= 1 ?
                  <Grid.Column style={{height: '100%'}} width={4}>
                    {
                      getPhoneSegment()
                    }
                    {
                      this.state.cars.length > 0 ?
                      getCarsSegment() : null
                    }
                  </Grid.Column>
                  : null
                }
              </Grid.Row>  : null]
              : null
          }
        </Grid>
    );
  }
}

export default Dashboard;
