import Hashes from 'jshashes';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const luminance = (r, g, b) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ?
      v / 12.92 :
      Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const contrast = (rgb1, rgb2) => {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

const getContrast = (rgbHex1, rgbHex2) => {
  const r1 = Number(`0x${rgbHex1.substring(1, 3)}`);
  const g1 = Number(`0x${rgbHex1.substring(3, 5)}`);
  const b1 = Number(`0x${rgbHex1.substring(5, 7)}`);
  const r2 = Number(`0x${rgbHex2.substring(1, 3)}`);
  const g2 = Number(`0x${rgbHex2.substring(3, 5)}`);
  const b2 = Number(`0x${rgbHex2.substring(5, 7)}`);
  return contrast([r1, g1, b1], [r2, g2, b2]);
};

const getEmployeeColor = (eId) => {
    const eIdMD5 = new Hashes.MD5().hex(eId.toString());
    const r = eIdMD5[0] + eIdMD5[1];
    const g = eIdMD5[Math.round(eIdMD5.length/2)] + eIdMD5[Math.round(eIdMD5.length/2)+1];
    const b = eIdMD5[eIdMD5.length-2] + eIdMD5[eIdMD5.length-1];
    return `#${r}${g}${b}`;
}

const getMarkerIconForEmployee = (eId) => {
    const myCustomColour = getEmployeeColor(eId);
    const markerHtmlStyles = `
      background-color: ${myCustomColour};
    `;

    return new L.DivIcon({
      className: "divIconLeaflet",
      iconSize: [41, 51], // size of the icon
      iconAnchor: [20, 51], // point of the icon which will correspond to marker's location
      popupAnchor: [10, -51], // point from which the popup should open relative to the iconAnchor
      html: `<div style="${markerHtmlStyles}"></div>`
    });
}

const getMarkerIconForCar = (carName) => {
  const myCustomColour = getEmployeeColor(carName);

  return new L.DivIcon({
    //iconUrl: 'leaf-green.png',
    className: "iconLeaflet",
    iconSize: [20, 25], // size of the icon
    iconAnchor: [20, 25], // point of the icon which will correspond to marker's location
    popupAnchor: [10, -51], // point from which the popup should open relative to the iconAnchor
    html: `<div><i style="translate(0%, 60%); color: ${myCustomColour};" class="big truck icon"></i></div>`
  });
}

export {getEmployeeColor, getMarkerIconForEmployee, getMarkerIconForCar, getContrast};