import React from 'react';
//import './Messages.css';
import io from './utils/io';
import 'semantic-ui-css/semantic.min.css';
import resolvePath from 'object-resolve-path';
import {
  Button,
  Grid,
  Checkbox,
  Header,
  Dropdown,
  Icon,
  Image,
  Input,
  Label,
  Loader,
  Message,
  Modal,
  Pagination,
  Table
} from "semantic-ui-react";
import Moment from './utils/momentfr';
import { saveAs } from 'file-saver';
import {
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import { hourEndByDay } from './utils/localSettings';
import mime from 'mime';

const moment = Moment();
const MESSAGES_PER_PAGE = 20;

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      error: "",
      sortState: {
        data: []
      },
      messagesPictures: {},
      deleteMessageModal: false,
      messageIdToDelete: undefined,
      selectedEmployeeFilter: [],
      selectedDateFilter: {
        from: moment(),
        to: moment(),
        readable: ""
      },
      employees: [],
      onlyNotValidated: true,
      saveAfterNextDownload: false,
      currentPage: 0,
      contentFilter: '',
      hasAttachmentFilter: false,
      loadingMessages: true
    };
    this.state = Object.assign(this.state, {
      listeners: {
        REMOTE_MESSAGE: this.onMessage.bind(this),
        GET_MESSAGE: this.onMessagesInit.bind(this),
        //GET_MESSAGE_PICTURE: this.onMessagesPictures.bind(this),
        GET_SINGLE_MESSAGE_PICTURE: this.onSingleMessagePictures.bind(this),
        UPD_MESSAGE: this.onMessageUpdateResponse.bind(this),
        DEL_MESSAGE: this.onMessageDeleteResponse.bind(this),
        GET_EMPLOYEE: this.onEmployeesInit.bind(this),
      }
    });
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    this.state.socket.emit('GET_MESSAGE');
    this.state.socket.emit('GET_EMPLOYEE');
    this.initDateFilter();
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));
  }

  initDateFilter() {
    this.setState({
      selectedDateFilter: {
        /*from: moment().subtract(1, 'd').hours(hourEndByDay()).minutes(0).seconds(0),
        to: moment().hours(hourEndByDay()).minutes(0).seconds(0).subtract(1, 'second'),*/
        readable: ""
      }
    });
  }

  onChangeDateFilter(e,data) {
    // TODO WTF ??
    const parts = data.value.split(' - ');
    let [from, to] = parts;

    if(from === "") {
      from = undefined;
    }
    if(to === "") {
      to = undefined;
    }

    if(from === undefined && to === undefined) {
      this.initDateFilter();
    } else {
      const readable = data.value;
      this.setState({
        selectedDateFilter: {
          from: from ? moment(from, "DD/MM/YYYY").hour(hourEndByDay()) : from,
          to: to ? moment(to, "DD/MM/YYYY").add(1, 'day').hour(hourEndByDay()).subtract(1, 'second') : to,
          readable
        },
        currentPage: 0
      });
    }
  }

  onMessage(message) {
    const mPicture = message.picture;
    //this.onMessagesInit([...this.state.sortState.data, Object.assign(message, {picture: undefined})], false);
    this.setState({
      sortState: Object.assign(this.state.sortState, {
        data: [...this.state.sortState.data, Object.assign(message, {picture: undefined})]
      })
    }, () => {
      if(this.state.sortState.column !== undefined) {
        this.handleSort(this.state.sortState.column, false)();
      } else {
        this.onMessagesInit(this.state.sortState.data, false);
      }
      this.setState({
        messagesPictures: Object.assign(this.state.messagesPictures, {
          [message.id]: mPicture
        })
      });
    });
  }

  onMessagesInit(messages, getPicture = false) {
    this.setState({
      sortState: Object.assign(this.state.sortState, {
        data: messages.sort((m1, m2) => m1.timestamp > m2.timestamp ? -1 : 1)
      }),
      loadingMessages: false
    }, () => {
      if(getPicture === true) {
        this.state.socket.emit('GET_MESSAGE_PICTURE');
      }
    });
  }

  onEmployeesInit(employees) {
    this.setState({
      employees: employees.sort((e1, e2) => e1.name < e2.name ? -1 : 1)
    });
  }

  /*onMessagesPictures(messagesPictures) {
    this.setState({
      messagesPictures: messagesPictures.reduce((acc,mp) => Object.assign(acc, {[mp.id]: mp.picture}), {})
    });
  }*/

  onSingleMessagePictures(messagePicture) {
    this.setState({
      messagesPictures: Object.assign({}, this.state.messagesPictures, {[messagePicture.id]: messagePicture.picture})
    }, () => {
      if(this.state.saveAfterNextDownload !== false) {
        this.save(this.state.saveAfterNextDownload, messagePicture.picture);
        this.setState({
          saveAfterNextDownload: false
        });
      }
    });
  }

  onMessageDelete(message) {
    this.state.socket.emit('DEL_MESSAGE', {id: message.id});
  }

  onMessageDeleteResponse(message) {
    this.manageErrors(message, () => {
      this.setState({
        sortState: {
          data: this.state.sortState.data.filter(d => d.id !== message.id)
        }
      });
    });
  }

  onModalOpen(module, id) {
    return (event) => {
      this.setState({
        [`${module}IdToDelete`]: id
      });
    }
  }

  toggleModal(module) {
    return (event) => {
      const key = `delete${module[0].toUpperCase()}${module.slice(1)}Modal`;
      this.setState({
        [key]: !this.state[key]
      });
    };
  }

  manageErrors(data, ifNoErr = undefined) {
    if(data.err !== undefined) {
      this.setState({
        error: data.err
      });
      setTimeout(() => {
        this.setState({
          error: ""
        });
      }, 5000);
    } else if(ifNoErr !== undefined) {
      ifNoErr();
    }
  }

  onClickOnDelMessage() {
    return (event) => {
      this.onMessageDelete({
        id: this.state.messageIdToDelete
      });
      this.toggleModal('message')();
    };
  }

  onChangeContentFilter(evt) {
    this.setState({contentFilter: evt.target.value, currentPage: 0});
  }

  handleSort(clickedColumn, switchDirection = true) {
    return () => {
      const { column, data, direction } = this.state.sortState;

      if (column !== clickedColumn) {
        this.setState({
          sortState: {
            column: clickedColumn,
            data: data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1),
            direction: 'ascending'
          }
        });
        return;
      }
      const newDirection = (switchDirection === true ? direction === 'ascending' ? 'descending' : 'ascending' : direction);
      this.setState({
        sortState: {
          data: (switchDirection === true ? data.reverse() : data.sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? (newDirection === 'ascending' ? -1 : 1) : (newDirection === 'ascending' ? 1 : -1))),
          direction: newDirection,
          column
        }
      });
    }
  }

  findExtension(dataURL) {
    const mimeType = dataURL.slice(0, 100).split(';')[0].split(':')[1];
    return mime.getExtension(mimeType) || 'jpg';
  }

  onClickOnDownload(id) {
    return (event) => {
      const msg = this.state.sortState.data.find(m => m.id === id);
      if(msg !== undefined && msg !== null && msg.hasPicture) {
        const msgPicture = this.state.messagesPictures[id];
        if(msgPicture === undefined || msgPicture === null || msgPicture === '') {
          this.setState({
            saveAfterNextDownload: id
          }, () => {
            this.onClickOnView(msg)(event);
          })
        } else {
          this.save(id, msgPicture);
        }
      }
    };
  }

  save(msgId, msgPicture) {
    const extension = this.findExtension(msgPicture.substring(0, 30));
    saveAs(msgPicture, `PIECE_JOINTE_${msgId}.${extension}`);
  }

  onClickOnReadMessage(id, valueToSend = true) {
    return (event) => {
      this.state.socket.emit('UPD_MESSAGE', {id, read: valueToSend});
    };
  }

  onMessageUpdateResponse(message) {
    this.setState({
      sortState: {
        data: this.state.sortState.data.map(d => d.id === message.id ? Object.assign(d, {read: message.read}) : d)
      }
    });
  }

  onChangeEmployeeFilter(e,data) {
    this.setState({
      selectedEmployeeFilter: data.value,
      currentPage: 0
    });
  }

  onToggleOnlyNotValidated(event) {
    this.setState({
      onlyNotValidated: !this.state.onlyNotValidated
    });
  }

  onClickOnView(m) {
    return (evt) => {
      this.setState({
        messagesPictures: Object.assign({}, this.state.messagesPictures, {
          [m.id]: null
        })
      }, () => {
        this.state.socket.emit('GET_SINGLE_MESSAGE_PICTURE', {id: m.id});
      });
    };
  }

  getFilePreview(mId, fileContent) {
    const extension = this.findExtension(fileContent);
    switch(extension) {
      case 'mp4':
        return <span data-tooltip="Cliquez pour masquer la pièce jointe" data-position="bottom left" onClick={(evt) => this.setState({messagesPictures: Object.assign({}, this.state.messagesPictures, {[mId]: undefined})})}>
          <video style={{maxWidth: '200px'}} controls>
            <source type="video/webm" src={fileContent} />
            <source type="video/mp4" src={fileContent} />
          </video>
        </span>;
      case 'avi':
      case 'mkv':
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file video outline' /></span>;
      case 'pdf':
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file pdf outline' /></span>;
      case 'csv':
      case 'xls':
      case 'xlsx':
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file excel outline' /></span>;
      case 'doc':
      case 'docx':
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file word outline' /></span>;
      case 'ppt':
      case 'pptx':
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file powerpoint outline' /></span>;
      case 'rar':
      case 'zip':
      case '7z':
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file archive outline' /></span>;
      case 'wav':
      case 'ogg':
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file audio outline' /></span>;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'bmp':
        return <span data-tooltip="Cliquez pour masquer la pièce jointe" data-position="top right" onClick={(evt) => this.setState({messagesPictures: Object.assign({}, this.state.messagesPictures, {[mId]: undefined})})}><Image src={fileContent} size='small' /></span>;
      default:
        return <span data-tooltip="Le fichier n'est pas prévisualisable, merci de le télécharger" data-position="top right"><Icon size="large" name='file outline' /></span>;
    }
  }

  render() {
    let {from,to} = this.state.selectedDateFilter;
    /*if(from === undefined) {
      from = moment().subtract(1, 'day').hour(hourEndByDay()).minute(0).second(0);
    }
    if(to === undefined) {
      to = moment().hour(hourEndByDay()).minute(59).second(59).subtract(1, 'hour');
    }*/

    const filteredMessages = this.state.sortState.data.filter(x => this.state.selectedEmployeeFilter.length === 0 || this.state.selectedEmployeeFilter.includes(x.employee.id))
      .filter(x => from !== undefined && to !== undefined ? x.timestamp >= from.unix() && x.timestamp <= to.unix() : true)
      .filter(x => this.state.onlyNotValidated === true ? x.read === false : true)
      .filter(x => this.state.hasAttachmentFilter === true ? x.hasPicture : true)
      .filter(x => this.state.contentFilter !== '' ? x.content.toLowerCase().includes(this.state.contentFilter) : true);

      
    const messagesHTML = filteredMessages.slice(this.state.currentPage*MESSAGES_PER_PAGE, (this.state.currentPage+1)*MESSAGES_PER_PAGE).map((m, idx) => {
      return <Table.Row key={idx}>
        <Table.Cell collapsing textAlign='center'>
          {m.employee.name}
        </Table.Cell>
        <Table.Cell collapsing textAlign='center'>
          {moment.unix(m.timestamp).format("DD/MM/YYYY à HH:mm")}
        </Table.Cell>
        <Table.Cell>
          {m.content}
        </Table.Cell>
        <Table.Cell collapsing textAlign='center'>
          {
            this.state.messagesPictures[m.id] === undefined ?
              (m.hasPicture ? 
                <Button disabled={this.state.messagesPictures[m.id] !== undefined || m.hasPicture === false} color="blue" data-tooltip="Voir la pièce jointe" data-position="bottom right" icon onClick={this.onClickOnView(m).bind(this)}>
                  <Icon name='eye' />
                </Button> :
                ''
              )
            : this.state.messagesPictures[m.id] === null ?
              <Loader active inline />
              : this.state.messagesPictures[m.id] || m.hasPicture === false ? this.getFilePreview(m.id, this.state.messagesPictures[m.id]) : "N/A"
          }
          {
            m.hasPicture ? 
            <Button data-tooltip="Enregistrer la pièce jointe" data-position="bottom right" icon color='blue' onClick={this.onClickOnDownload(m.id).bind(this)}>
              <Icon name='download' />
            </Button>
            : ''
          }
        </Table.Cell>
        <Table.Cell collapsing textAlign='center'>
          <Button.Group>
            <Button color={m.read === true ? undefined : 'orange'} disabled={m.read === true || (this.props.isManagerWithWriteAccess() === false && this.props.isAdmin() === false)} onClick={this.onClickOnReadMessage(m.id, true).bind(this)}>en cours</Button>
            <Button.Or text='|' />
            <Button color={m.read === true ? 'green' : undefined} disabled={m.read !== true || (this.props.isManagerWithWriteAccess() === false && this.props.isAdmin() === false)} onClick={this.onClickOnReadMessage(m.id, false).bind(this)}>validé</Button>
          </Button.Group>
        </Table.Cell>
        <Table.Cell textAlign='center' collapsing>
          <Modal trigger={
            <Button disabled={this.props.isAdmin() === false} onClick={this.toggleModal('message').bind(this)} data-tooltip="Supprimer le message" data-position="bottom right" icon color='red'>
              <Icon name='trash' />
            </Button>
          } basic size='small' open={this.state.deleteMessageModal} onOpen={this.onModalOpen('message', m.id).bind(this)}>
            <Header icon='trash' content="Suppression d'un message" />
            <Modal.Content>
              <p>
                Attention, la suppression d'un message est définitive.
                Êtes-vous certain de vouloir effectuer cette action ?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color='red' inverted onClick={this.toggleModal('message').bind(this)}>
                <Icon name='remove' /> Non, Annuler
              </Button>
              <Button color='green' inverted onClick={this.onClickOnDelMessage().bind(this)}>
                <Icon name='checkmark' /> Oui, Confirmer
              </Button>
            </Modal.Actions>
          </Modal>
        </Table.Cell>
    </Table.Row>
    }
    );

    const errorMessage = (this.state.error !== "" ?
      <Message negative>
        <Message.Header>Une erreur est survenue</Message.Header>
        <p>{JSON.stringify(this.state.error)}</p>
      </Message>
      :
      <div />
    );

    const filterEmployeeOpts = this.state.employees/*.filter(e => e.entryDate <= to.unix() && (e.leftDate === null || e.leftDate >= from.unix()))*/
      .map((e, idx) => ({
        key: e.id,
        text: e.name,
        value: e.id
      }));

    const totalPages = Math.ceil(filteredMessages.length / MESSAGES_PER_PAGE);

    return (
        <Grid padded stackable>
          <Grid.Row className="firstRow">
              <Header dividing as='h1'>
                <Icon name='envelope' />
                <Header.Content>
                  Messagerie
                  <Loader style={{marginLeft: '10px'}} inline active={this.state.loadingMessages} />
                </Header.Content>
              </Header>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Label size="big" style={{backgroundColor: 'transparent'}}>Période actuellement affichée :</Label>
              <Label id="currentPeriodFilter" color="blue" size="big">
                {
                  from !== undefined && to !== undefined ?
                    from.format("DD/MM/YYYY") + " - " + moment.unix(to.unix()).subtract(hourEndByDay(), 'hours').subtract(1, 'second').format("DD/MM/YYYY")
                    : "Depuis toujours"
                }
              </Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <Dropdown
                  placeholder='Tous les employés'
                  search
                  fluid
                  selection
                  multiple
                  clearable
                  onChange={this.onChangeEmployeeFilter.bind(this)}
                  value={this.state.selectedEmployeeFilter}
                  options={filterEmployeeOpts}
                />
            </Grid.Column>
            <Grid.Column width={3} id="periodFilter">
              <DatesRangeInput
                  name="dateFilter"
                  autoComplete="off"
                  localization="fr"
                  dateFormat="DD/MM/YYYY"
                  placeholder="Par période"
                  closeOnMouseLeave={false}
                  onClear={this.initDateFilter.bind(this)}
                  value={this.state.selectedDateFilter.readable}
                  closable
                  clearable
                  fluid
                  clearIcon="trash"
                  allowSameEndDate
                  iconPosition="right"
                  hideMobileKeyboard
                  onChange={this.onChangeDateFilter.bind(this)}
                  animation='none'
                />
            </Grid.Column>
            <Grid.Column width={3}>
              <Input
                placeholder='Recherche dans le contenu du message'
                icon='search'
                fluid
                onChange={this.onChangeContentFilter.bind(this)}
                value={this.state.contentFilter}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3} textAlign='center'>
              <Checkbox checked={this.state.onlyNotValidated} onChange={this.onToggleOnlyNotValidated.bind(this)} toggle label={(this.state.onlyNotValidated === false ? "Tous les messages" : "Messages en cours") + " ("+ filteredMessages.length + "/" + this.state.sortState.data.length + ")"}  />
            </Grid.Column>
            <Grid.Column width={3} textAlign='center'>
              <Checkbox label='Seulement avec pièce jointe' toggle checked={this.state.hasAttachmentFilter} onChange={(evt) => this.setState({hasAttachmentFilter: !this.state.hasAttachmentFilter})} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {errorMessage}
          </Grid.Row>
          {
            totalPages > 1 ?
            <Grid.Row>
              <Grid.Column width={14}>
                <Pagination
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                  lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                  activePage={this.state.currentPage+1} totalPages={totalPages} onPageChange={(evt, d) => this.setState({currentPage: d.activePage !== undefined && d.activePage !== null ? d.activePage-1 : 0})} />
              </Grid.Column>
            </Grid.Row>
            : null
          }
          <Grid.Row>
            <Table celled striped selectable sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                  textAlign='center'
                  sorted={this.state.sortState.column === 'name' ? this.state.sortState.direction : null}
                  onClick={this.handleSort('name').bind(this)}>
                    Employé
                  </Table.HeaderCell>
                  <Table.HeaderCell
                  textAlign='center'
                  sorted={this.state.sortState.column === 'timestamp' ? this.state.sortState.direction : null}
                  onClick={this.handleSort('timestamp').bind(this)}>
                    Date et Heure
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>
                    Message
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>
                    Pièce jointe
                  </Table.HeaderCell>
                  <Table.HeaderCell
                  textAlign='center'
                  sorted={this.state.sortState.column === 'read' ? this.state.sortState.direction : null}
                  onClick={this.handleSort('read').bind(this)}>
                    Statut
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {messagesHTML}
                {messagesHTML.length === 0 ? <Table.Row><Table.Cell colSpan='15'></Table.Cell></Table.Row> : null}
              </Table.Body>
            </Table>
          </Grid.Row>
          {
            totalPages > 1 ?
            <Grid.Row>
              <Grid.Column width={14}>
                <Pagination
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                  firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                  lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                  prevItem={{ content: <Icon name='angle left' />, icon: true }}
                  nextItem={{ content: <Icon name='angle right' />, icon: true }}
                  activePage={this.state.currentPage+1} totalPages={Math.ceil(filteredMessages.length / MESSAGES_PER_PAGE)} onPageChange={(evt, d) => this.setState({currentPage: d.activePage !== undefined && d.activePage !== null ? d.activePage-1 : 0})} />
              </Grid.Column>
            </Grid.Row>
            : null
          }
        </Grid>
    );
  }
}

export default Messages;
