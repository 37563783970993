import Moment from './momentfr';
import groupBy from './groupBy';
import { timezone } from './localSettings';

const getFakeDay = function(p, hourEndByDay, timezoneVal = timezone()) {
    const moment = Moment(timezoneVal);
    const mmt = moment.unix(p.timestamp);
    const tonightMidnightMmt = moment.unix(p.timestamp).add(mmt.hours() < hourEndByDay() ? 0 : 1, 'day').hours(0).minutes(0).seconds(0).milliseconds(0)
    const endOfDayMmt = moment.unix(p.timestamp).add(mmt.hours() < hourEndByDay() ? 0 : 1, 'day').hours(hourEndByDay()).minutes(0).seconds(0).milliseconds(0)

    return (mmt.isBefore(endOfDayMmt) ? (mmt.isBefore(tonightMidnightMmt) ? mmt : mmt.subtract(1, 'day')) : mmt);
};

const groupByDay = function(pointages, hourEndByDay, timezoneVal = timezone()) {
    const moment = Moment(timezoneVal);
    const data = pointages.map((p) => {
        const mmt = moment.unix(p.timestamp);
        return Object.assign({}, p, {
            realDay: mmt.format("DD/MM/YYYY"),
            day: getFakeDay(p, hourEndByDay, timezoneVal).format("DD/MM/YYYY")
        });
    });
    return groupBy(data, 'day');
};

export {groupByDay, getFakeDay};