import React from 'react';
//import './Manage.css';
import io from './utils/io';
import Moment from './utils/momentfr';
import {getFakeDay} from './utils/groupByDay';
import PointageUtils from './utils/pointage';
import { extendMoment } from 'moment-range';
import { saveAs } from 'file-saver';
import iconv from 'iconv-lite';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Checkbox,
  Dimmer,
  Dropdown,
  Accordion,
  Divider,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Popup,
  Radio,
  Table,
  Card,
  Modal
} from "semantic-ui-react";
import {
  DatesRangeInput
} from 'semantic-ui-calendar-react';
import {hourEndByDay, pointagesRememberFilters, pointagesOpts, nightShiftBegin, nightShiftBeginMinute, nightShiftEnd, nightShiftEndMinute, timezone} from './utils/localSettings';
import { getNbHoursExpectedFromProfile, checkIfSpecificRuleForToday } from './utils/nbHoursExpected';
import { formatHoursWithMinutes } from './utils/formatHoursWithMinutes';
import { getHoursMinutesFromEvent, getTimeFromEvent } from './utils/formattedInputs';
import * as formulas from './utils/formulas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {reverseGeocodeAddrStr} from './utils/photon';
import { getOriginForPointage } from './utils/pointageOrigin';
import { manualCounterEnabled, manualCounterName, manualCounterFactor, secondManualCounterEnabled, secondManualCounterName, secondManualCounterFactor, thirdManualCounterEnabled, thirdManualCounterName, thirdManualCounterFactor, nightShiftCounterEnabled } from './utils/localSettings';
import ls from 'local-storage';
// eslint-disable-next-line import/no-webpack-loader-syntax
import DateDataWorker from "worker-loader?inline=no-fallback!./utils/workers/gridDataByDate.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import EmployeeDataWorker from "worker-loader?inline=no-fallback!./utils/workers/gridDataByEmployee.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import LocationDataWorker from "worker-loader?inline=no-fallback!./utils/workers/gridDataByLocation.js";
import round from './utils/round';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const moment = extendMoment(Moment());

/*const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};*/

const MANUAL_COUNTER_1 = 1;
const MANUAL_COUNTER_2 = 2;
const MANUAL_COUNTER_3 = 3;

const PREDEFINED_DATE_FILTERS = [
  {
    key: 'sinceThisWeek',
    text: 'Depuis le début de la semaine',
    value: {
      from: moment().startOf('isoWeek'),
      to: moment()
    }
  },
  {
    key: 'sinceThisMonth',
    text: 'Depuis le début du mois',
    value: {
      from: moment().startOf('month'),
      to: moment()
    }
  },
  {
    key: 'thisWeek',
    text: 'Cette semaine',
    value: {
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek')
    }
  },
  {
    key: 'thisMonth',
    text: 'Ce mois-ci',
    value: {
      from: moment().startOf('month'),
      to: moment().endOf('month')
    }
  },
  {
    key: 'lastWeek',
    text: 'La semaine dernière',
    value: {
      from: moment().subtract(1, 'weeks').startOf('isoWeek'),
      to: moment().subtract(1, 'weeks').endOf('isoWeek')
    }
  },
  {
    key: 'lastMonth',
    text: 'Le mois dernier',
    value: {
      from: moment().subtract(1, 'months').startOf('month'),
      to: moment().subtract(1, 'months').endOf('month')
    }
  },
  {
    key: 'thisYear',
    text: "Depuis le début de l'année",
    value: {
      from: moment().startOf('Year'),
      to: moment()
    }
  }
];

const getPredefinedFilters = () => PREDEFINED_DATE_FILTERS.map(f => Object.assign({}, f, {value: {
  from: moment(f.value.from.format("DD/MM/YYYY"), "DD/MM/YYYY").hour(hourEndByDay()),
  to: moment(f.value.to.format("DD/MM/YYYY"), "DD/MM/YYYY").add(1, 'day').hour(hourEndByDay()).subtract(1, 'second')
}}));

class Manage extends React.Component {
  constructor(props) {
    super(props);
    const rememberedFilters = pointagesRememberFilters();
    const rememberedOpts = pointagesOpts();
    const {selectedEmployeeFilter,selectedTeamFilter,selectedDateFilter,selectedPredefinedDateFilter,selectedTeamId,selectedLocationFilter,selectedLocationName,groupType} = rememberedFilters !== false ? rememberedFilters : ({
      selectedEmployeeFilter: [],
      selectedTeamFilter: "ALL",
      selectedDateFilter: {
        from: moment(),
        to: moment(),
        readable: ""
      },
      selectedTeamId: -1,
      selectedLocationFilter: "ALL",
      selectedLocationName: "ALL",
      groupType: 'DATE',
      selectedPredefinedDateFilter: ""
    });
    const {onlyNotEmptyLines, onlyLinesWithExpectedHours, onlyTotals, optsAccordionActiveIdx, displayOnlyInvalid} = ({
      onlyNotEmptyLines: rememberedOpts.onlyNotEmptyLines || false,
      onlyLinesWithExpectedHours: rememberedOpts.onlyLinesWithExpectedHours || false,
      onlyTotals: rememberedOpts.onlyTotals || false,
      optsAccordionActiveIdx: rememberedOpts.displayOpts === true ? 0 : -1,
      displayOnlyInvalid: rememberedOpts.displayOnlyInvalid || false
    });
    const {pointagesVisible, locationsVisible, rawPointagesVisible, commentariesVisible, allManualCountersVisible} = ({
      pointagesVisible: rememberedOpts.pointagesVisible !== undefined && rememberedOpts.pointagesVisible !== null ? rememberedOpts.pointagesVisible : true,
      locationsVisible: rememberedOpts.locationsVisible || false,
      rawPointagesVisible: rememberedOpts.rawPointagesVisible || false,
      commentariesVisible: rememberedOpts.commentariesVisible || false,
      allManualCountersVisible: rememberedOpts.allManualCountersVisible || false
    });

    const foundPredefinedFilter = getPredefinedFilters(PREDEFINED_DATE_FILTERS).find(x => x.key === selectedPredefinedDateFilter);
    const from = foundPredefinedFilter !== undefined ? moment.unix(foundPredefinedFilter.value.from.unix()) : moment.unix(selectedDateFilter.from);
    const to = foundPredefinedFilter !== undefined ? moment.unix(foundPredefinedFilter.value.to.unix()) : moment.unix(selectedDateFilter.to);
    const readable = foundPredefinedFilter !== undefined ? `${foundPredefinedFilter.value.from.format("DD/MM/YYYY")} - ${foundPredefinedFilter.value.to.format("DD/MM/YYYY")}` : selectedDateFilter.readable;
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      //pointages: [],
      summary: [],
      gridData: [],
      employees: [],
      locations: [],
      teams: [],
      selectedEmployeeFilter,
      selectedTeamFilter,
      selectedDateFilter: {
        from,
        to,
        readable
      },
      selectedTeamId,
      selectedLocationFilter,
      selectedLocationName,
      groupType,
      onlyNotEmptyLines,
      onlyLinesWithExpectedHours,
      onlyTotals,
      pointageToAdd: undefined,
      commentariesToSet: [],
      rawPointagesVisible,
      pointagesVisible,
      locationsVisible,
      commentariesVisible,
      allManualCountersVisible,
      rpiHardwareNames: [],
      changingExpectedHours: [],
      manualCounterHours: [],
      loadingHTML: true,
      rememberFilters: rememberedFilters === false ? false : true,
      optsAccordionActiveIdx,
      displayOnlyInvalid,
      groupMode: false,
      avatar: undefined,
      selectedPredefinedDateFilter,
      confirmClearCounters: false
    };
    this.state = Object.assign(this.state, {
      listeners: {
        //GET_POINTAGE: this.onPointagesInit.bind(this),
        GET_SUMMARY: this.onSummaryInit.bind(this),
        POINTAGE: this.onPointage.bind(this),
        GET_EMPLOYEE: this.onEmployeesInit.bind(this),
        GET_LOCATION: this.onLocationsInit.bind(this),
        ADD_POINTAGE: this.onAddPointageConfirmed.bind(this),
        DEL_POINTAGE: this.onDelPointageConfirmed.bind(this),
        SET_COMMENTARY: this.onSetCommentaryConfirmed.bind(this),
        SET_MANUAL_COUNTER: this.onSetManualCounterConfirmed.bind(this),
        SET_GROUP_EDIT: this.onSetGroupEditConfirmed.bind(this),
        GET_RPIHARDWARENAME: this.onRpiHardwareNamesInit.bind(this),
        SET_EXPECTED: this.onSetExpectedConfirmed.bind(this),
        GET_TEAM: this.onTeamsInit.bind(this),
        GET_AVATAR: this.onAvatarInit.bind(this)
      }
    });
    this.handleEscape = this.handleEscape.bind(this);
  }

  handleClickOnOptsAccordion(e, titleProps) {
    const { index } = titleProps
    const activeIndex = this.state.optsAccordionActiveIdx;
    this.setState({ optsAccordionActiveIdx: activeIndex === index ? -1 : index }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  initDateFilter() {
    this.setState({
      selectedDateFilter: {
        from: moment().subtract(1, 'd').hours(hourEndByDay()).minutes(0).seconds(0),
        to: moment().add(1, 'd').hours(hourEndByDay()).minutes(0).seconds(0).subtract(1, 'second'),
        readable: ""
      },
      selectedPredefinedDateFilter: ''
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
      this.refreshSummary();
    });
  }

  refreshSummary() {
    if(this.state.selectedDateFilter.from !== undefined && this.state.selectedDateFilter.to !== undefined) {
      const summaryParams = {
        _reqID: "manage",
        from: this.state.selectedDateFilter.from.unix(),
        to: this.state.selectedDateFilter.to.unix(),
        employeeId: this.state.selectedEmployeeFilter.length === 0 ? undefined : this.state.selectedEmployeeFilter
      };
      this.state.socket.emit('GET_SUMMARY', summaryParams);
    }
  }

  handleEscape(event, andThen = undefined) {
    if(event.keyCode === 27) {
      this.setState({
        pointageToAdd: undefined,
        commentariesToSet: [],
        changingExpectedHours: [],
        manualCounterHours: [],
        groupMode: false
      }, () => andThen !== undefined ? andThen() : true);
    }
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    //this.state.socket.emit('GET_POINTAGE');
    this.state.socket.emit('GET_EMPLOYEE');
    this.state.socket.emit('GET_TEAM');
    this.state.socket.emit('GET_RPIHARDWARENAME');
    this.state.socket.emit('GET_AVATAR');

    if(this.state.rememberFilters === false) {
      this.initDateFilter();
    }

    // For escape key
    document.addEventListener("keydown", this.handleEscape, false);
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));

    // For escape key
    document.removeEventListener("keydown", this.handleEscape, false);
  }

  onAvatarInit(result) {
    this.setState({avatar: result.err === undefined ? result.data : null});
  }

  onTeamsInit(teams) {
    this.setState({
      teams
    });
  }

  onEmployeesInit(employees) {
    this.setState({
      employees: employees.sort((e1, e2) => e1.name < e2.name ? -1 : 1)
    });
  }

  onLocationsInit(locations) {
    const locationsCount = locations.length + 1;
    const rpiHardwares = Object.keys(this.props.rpiHardware).map((hostname, idx) => ({id: locationsCount+idx, name: hostname}))
      .map(rpiH => {
        const customName = this.state.rpiHardwareNames.find(rhn => rhn.hostname === rpiH.name);
        return Object.assign({}, rpiH, {customName: customName !== undefined ? customName.name : undefined});
      });
    this.setState({
      locations: [...rpiHardwares,
        ...locations.sort((l1, l2) => l1.name < l2.name ? -1 : 1), {id: 0, name: 'Inconnu'}]
    }, () => {
      this.refreshSummary();
    });
  }

  /*onPointagesInit(pointages) {
    this.setState({
      pointages
    });
  }*/

  onSummaryInit({_reqID, summary}) {
    this.setState({
      // Resetting gridData here would cause height changing, scroll changing, and flickering...
      //gridData: [],
      summary: summary.sort((s1, s2) => s1.name < s2.name ? -1 : 1).filter(x => this.state.selectedTeamFilter !== "ALL" ? x.teamId === this.state.selectedTeamId : true)
    }, () => {
      this.getGridData(this.state.groupType || "DATE");
    });
  }

  onPointage(pointage) {
    this.refreshSummary();
    /*this.setState({
      pointages: [...this.state.pointages, pointage]
    });*/
  }

  onCreatingPointage(momentDay, employeeId, location = undefined) {
    return (e) => {
      const now = moment();
      this.setState({
        pointageToAdd: {
          timestamp: momentDay.hour(now.hour()).minute(now.minute()).seconds(0).unix(),
          employeeId,
          badgeNo: 'NA',
          location
        }
      });
      this.props.handleTour();
    };
  }

  onAddPointage(e) {
    this.state.socket.emit('ADD_POINTAGE', this.state.pointageToAdd);
    this.setState({
      pointageToAdd: undefined
    });
    this.props.handleTour();
  }

  onAddPointageConfirmed() {
    this.refreshSummary();
  }

  onDelPointage(pointageId) {
    return (e) => {
      this.state.socket.emit('DEL_POINTAGE', {
        id: pointageId
      });
    };
  }

  onDelPointageConfirmed() {
    this.refreshSummary();
  }

  onSettingCommentary(commentaryId, momentDay, employeeId, value) {
    return (e) => {
      const day = momentDay.hour(0).minute(0).seconds(0).format("DD/MM/YYYY");
      const commentary = this.state.commentariesToSet.find(x => x.employeeId === employeeId && x.day === day);
      if(commentary === undefined) {
        this.setState({
          commentariesToSet: [({
            id: commentaryId,
            day,
            employeeId,
            value
          })],
          groupMode: false
        });
      }
    };
  }

  onChangeCommentaryValue(employeeId, momentDay) {
    return (event) => {
      const day = momentDay.hour(0).minute(0).seconds(0).format("DD/MM/YYYY");
      const commentary = this.state.commentariesToSet.find(x => x.employeeId === employeeId && x.day === day);
      if(commentary !== undefined) {
        const commentariesToSet = [...this.state.commentariesToSet.filter(x => x.employeeId !== employeeId || x.day !== day), Object.assign({}, commentary, {value: event.target.value})];
        this.setState({
          commentariesToSet
        });
      }
    }
  }

  onSetCommentary(employeeId, momentDay) {
    return (e) => {
      const day = momentDay.hour(0).minute(0).seconds(0).format("DD/MM/YYYY");
      const commentary = this.state.commentariesToSet.find(x => x.employeeId === employeeId && x.day === day);
      if(commentary !== undefined) {
        this.state.socket.emit('SET_COMMENTARY', commentary);
        this.setState({
          commentariesToSet: this.state.commentariesToSet.filter(x => x.employeeId !== employeeId || x.day !== day)
        });
      }
    };
  }

  onSetCommentaryConfirmed() {
    this.refreshSummary();
  }

  onSetGroupEditConfirmed() {
    this.refreshSummary();
  }

  onChangeCreatingPointageDateTime(employeeId) {
    return (event,data) => {
      const tgt = event.target;
      const {finalValue, cursorStart, cursorEnd} = getTimeFromEvent(event);
      const {hours, minutes} = finalValue;      

      this.setState({
        pointageToAdd: Object.assign(this.state.pointageToAdd, {
          timestamp: moment.unix(this.state.pointageToAdd.timestamp).hours(hours).minutes(minutes).unix(),
          employeeId
        })
      }, () => {
        tgt.setSelectionRange(cursorStart, cursorEnd);
      });
    }
  }

  onChangeEmployeeFilter(e,data) {
    this.setState({
      selectedEmployeeFilter: data.value
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
      this.refreshSummary();
    });
  }

  onChangeTeamFilter(e,data) {
    this.setState({
      selectedTeamFilter: data.value,
      selectedTeamId: data.value === "ALL" ? this.state.teams.length > 0 ? this.state.teams[0].id : -1 : data.value,
      selectedEmployeeFilter: []
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
      this.refreshSummary();
    });
  }

  onChangeLocationFilter(e,data) {
    const [lType, idStr] = data.value.split('_');
    const id = parseInt(idStr);
    const locationSelected = this.state.locations.find(l => l.id === id && (lType === 'location' ? l.radius !== undefined : l.radius === undefined));
    this.setState({
      selectedLocationFilter: locationSelected !== undefined ? data.value : "ALL",
      selectedLocationName: locationSelected !== undefined ? locationSelected.name : "ALL",
      gridData: []
      //onlyNotEmptyLines: locationSelected !== undefined ? true : false
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
      this.getGridData(this.state.groupType || "DATE");
    });
  }

  onChangeDateFilter(e,data) {
    // TODO WTF ??
    const parts = data.value.split(' - ');
    let [from, to] = parts;

    if(from === "") {
      from = undefined;
    }
    if(to === "") {
      to = undefined;
    }

    if(from === undefined && to === undefined) {
      this.initDateFilter();
    } else {
      const readable = data.value;
      this.setState({
        selectedPredefinedDateFilter: '',
        selectedDateFilter: {
          from: from ? moment(from, "DD/MM/YYYY").hour(hourEndByDay()) : from,
          to: to ? moment(to, "DD/MM/YYYY").add(1, 'day').hour(hourEndByDay()).subtract(1, 'second') : to,
          readable
        }
      }, () => {
        this.onToggleRememberFilters(this.state.rememberFilters)(null);
        this.refreshSummary();
      });
    }
  }

  onChangePredefinedDateFilter(e,data) {
    const key = data.value;
    if(key === '') {
      this.setState({selectedPredefinedDateFilter: key})
    } else {
      const filter = getPredefinedFilters(PREDEFINED_DATE_FILTERS).find(x => x.key === key);
      if(filter !== undefined) {
        const {from, to} = filter.value;
        this.setState({
          selectedPredefinedDateFilter: key,
          selectedDateFilter: {
            from,
            to,
            readable: `${from.format("DD/MM/YYYY")} - ${to.format("DD/MM/YYYY")}`
          }
        }, () => {
          this.onToggleRememberFilters(this.state.rememberFilters)(null);
          this.refreshSummary();
        });
      }
    }
  }

  handleToggleRawPointageVisibility(event) {
    this.setState({
      rawPointagesVisible: !this.state.rawPointagesVisible
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  handleTogglePointagesVisibility(event) {
    this.setState({
      pointagesVisible: !this.state.pointagesVisible
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  handleToggleLocationVisibility(event) {
    this.setState({
      locationsVisible: !this.state.locationsVisible
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  handleToggleCommentaryVisibility(event) {
    this.setState({
      commentariesVisible: !this.state.commentariesVisible
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  handleToggleManualCountersVisibility(event) {
    this.setState({
      allManualCountersVisible: !this.state.allManualCountersVisible
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  handleGroupTypeChange(e, {value}) {
    if(value !== this.state.groupType) {
      this.handleEscape({keyCode: 27}, () => {
        this.setState({
          groupType: value,
          gridData: [],
          pointageToAdd: undefined,
          commentariesToSet: [],
          groupMode: false,
          onlyNotEmptyLines: value === 'LOCATION' ? true : false,
          selectedLocationFilter: value === 'LOCATION' ? this.state.selectedLocationFilter : 'ALL'
        }, () => {
          this.onToggleRememberFilters(this.state.rememberFilters)(null);
          this.getGridData(this.state.groupType || "DATE");
        }); 
      });
    }
  }

  onFocusOnPointageDateTime(event) {
    event.target.select();
  }

  manageEnter(ifEnterDo) {
    return (event) => {
      if(event.key === 'Enter' && this.state.groupMode === false) {
        ifEnterDo();
      }
    }
  }

  onRpiHardwareNamesInit(rpiHardwareNames) {
    this.setState({
      rpiHardwareNames
    }, () => {
      this.state.socket.emit('GET_LOCATION');
    });
  }

  onFocusOnExpected(event) {
    //event.target.select();
  }

  onBlurOnExpected(event) {
    // TODO not working
    /*this.setState({
      changingExpectedHours: []
    });*/
  }

  onChangeOnExpected(employee, momentDate) {
    return (event,data) => {
      const tgt = event.target;
      const {finalValue, cursorStart, cursorEnd} = getHoursMinutesFromEvent(event);
      const expected = this.state.changingExpectedHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.employee && x.employee.id === employee.id);
      const others = this.state.changingExpectedHours.filter(x => x.day !== momentDate.format("DD/MM/YYYY") || (x.employee && x.employee.id !== employee.id));
      this.setState({
        changingExpectedHours: expected !== undefined ? [...others, Object.assign({}, expected, {
          hours: finalValue
        })] : others
      }, () => {
        tgt.setSelectionRange(cursorStart, cursorEnd);
      });
    }
  }

  onClickOnExpected(employee, timestamp) {
    const hours = getNbHoursExpectedFromProfile(employee, timestamp, this.props.hourEndByDay(employee));
    return (evt) => {
      this.setState({
        changingExpectedHours: [{
          employee,
          day: moment.unix(timestamp).format("DD/MM/YYYY"),
          hours
        }]
      });
    };
  }

  onDelExpected(employee, timestamp) {
    return (evt) => {
      this.state.socket.emit('SET_EXPECTED', {
        employeeId: employee.id,
        day: moment.unix(timestamp).format("DD/MM/YYYY"),
        deleteOnly: true
      });
    };
  }

  onSetExpected(e, momentDate) {
    return (evt) => {
      const expected = this.state.changingExpectedHours.find(x => x.day === momentDate.format('DD/MM/YYYY') && x.employee && x.employee.id === e.id)
      if(expected !== undefined) {
        const {employee, day, hours} = expected;
        this.state.socket.emit('SET_EXPECTED', {
          employeeId: employee.id,
          day: day,
          hours
        });
        this.setState({
          changingExpectedHours: []
        });
      }
    };
  }

  onSetExpectedConfirmed() {
    this.refreshSummary();
  }

  onChangeOnManualCounter(employeeId, timestamp, type) {
    return (event,data) => {
      const tgt = event.target;
      const {finalValue, cursorStart, cursorEnd} = getHoursMinutesFromEvent(event, true);
      const foundHours = this.state.manualCounterHours.find(x => x.day === moment.unix(timestamp).format("DD/MM/YYYY") && x.employee.id === employeeId && x.type === type);
      const manualCounterHours = [...this.state.manualCounterHours.filter(x => x.day !== moment.unix(timestamp).format("DD/MM/YYYY") || x.employee.id !== employeeId || x.type !== type), Object.assign({}, foundHours, {hours: finalValue})];
      this.setState({
        manualCounterHours
      }, () => {
        tgt.setSelectionRange(cursorStart, cursorEnd);
      });
    }
  }

  onClickOnManualCounter(employee, timestamp, type, hours) {
    return (evt) => {
      const found = this.state.manualCounterHours.find(x => x.day === moment.unix(timestamp).format("DD/MM/YYYY") && x.employee.id === employee.id && x.type === type);
      if(found === undefined) {
        this.setState({
          manualCounterHours: [{
            employee,
            day: moment.unix(timestamp).format("DD/MM/YYYY"),
            hours,
            type
          }]
        });
      }
    };
  }

  onDelManualCounter(employee, timestamp, type) {
    return (evt) => {
      this.state.socket.emit('SET_MANUAL_COUNTER', {
        employeeId: employee.id,
        day: moment.unix(timestamp).format("DD/MM/YYYY"),
        deleteOnly: true,
        type
      });
    };
  }

  onSetManualCounter(employee, timestamp, type) {
    return (event) => {
      const found = this.state.manualCounterHours.find(x => x.day === moment.unix(timestamp).format("DD/MM/YYYY") && x.employee.id === employee.id && x.type === type);
      if(found !== undefined) {
        const {employee, day, hours, type} = found;
        this.state.socket.emit('SET_MANUAL_COUNTER', {
          employeeId: employee.id,
          day,
          hours,
          type
        });
        this.setState({
          manualCounterHours: []
        });
      }
    };
  }

  onSetManualCounterConfirmed() {
    this.refreshSummary();
  }

  handleClickOnEditGroupCancel() {
    return (evt) => {
      this.handleEscape({keyCode: 27});
    };
  }

  extractFromEntries(entries) {
    const commentariesToSet = entries.map((dateEntry) => ({
      id: (dateEntry.commentary && dateEntry.commentary.id) || undefined,
      day: dateEntry.momentDate.format("DD/MM/YYYY"),
      employeeId: dateEntry.id,
      value: (dateEntry.commentary && dateEntry.commentary.value) || ''
    }));
    const changingExpectedHours = entries.map((dateEntry) => ({
      day: dateEntry.momentDate.format("DD/MM/YYYY"),
      employeeId: dateEntry.id,
      employee: {
        id: dateEntry.id
      },
      hours: dateEntry.nbHoursExpected
    }));
    const manualCounterHours1 = entries.map((dateEntry) => ({
      employeeId: dateEntry.id,
      employee: {
        id: dateEntry.id
      },
      day: dateEntry.momentDate.format("DD/MM/YYYY"),
      hours: dateEntry.manualCounter ? dateEntry.manualCounter.hours : formulas.manualCounter({hours: dateEntry.validatedHours !== null && dateEntry.validatedHours !== undefined ? dateEntry.validatedHours : dateEntry.validated.amount, expected: dateEntry.nbHoursExpected, manual: 0.0, secondManual: dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0.0, thirdManual: dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0.0, night: dateEntry.validatedHoursCustom !== null && dateEntry.validatedHoursCustom !== undefined ? dateEntry.validatedHoursCustom : dateEntry.validated.amountCustom}),
      type: MANUAL_COUNTER_1
    }));
    const manualCounterHours2 = entries.map((dateEntry) => ({
      employeeId: dateEntry.id,
      employee: {
        id: dateEntry.id
      },
      day: dateEntry.momentDate.format("DD/MM/YYYY"),
      hours: dateEntry.secondManualCounter ? dateEntry.secondManualCounter.hours : formulas.secondManualCounter({hours: dateEntry.validatedHours !== null && dateEntry.validatedHours !== undefined ? dateEntry.validatedHours : dateEntry.validated.amount, expected: dateEntry.nbHoursExpected, manual: dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0.0, secondManual: 0.0, thirdManual: dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0.0, night: dateEntry.validatedHoursCustom !== null && dateEntry.validatedHoursCustom !== undefined ? dateEntry.validatedHoursCustom : dateEntry.validated.amountCustom}),
      type: MANUAL_COUNTER_2
    }));
    const manualCounterHours3 = entries.map((dateEntry) => ({
      employeeId: dateEntry.id,
      employee: {
        id: dateEntry.id
      },
      day: dateEntry.momentDate.format("DD/MM/YYYY"),
      hours: dateEntry.thirdManualCounter ? dateEntry.thirdManualCounter.hours : formulas.thirdManualCounter({hours: dateEntry.validatedHours !== null && dateEntry.validatedHours !== undefined ? dateEntry.validatedHours : dateEntry.validated.amount, expected: dateEntry.nbHoursExpected, manual: dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0.0, secondManual: dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0.0, thirdManual: 0.0, night: dateEntry.validatedHoursCustom !== null && dateEntry.validatedHoursCustom !== undefined ? dateEntry.validatedHoursCustom : dateEntry.validated.amountCustom}),
      type: MANUAL_COUNTER_3
    }));
    return {commentariesToSet, changingExpectedHours, manualCounterHours1, manualCounterHours2, manualCounterHours3};
  }

  handleZero(entries) {
    return (evt) => {
      this.handleEscape({keyCode: 27}, () => {
        const {changingExpectedHours, manualCounterHours1, manualCounterHours2, manualCounterHours3} = this.extractFromEntries(entries);
        const manualCounterHours = [...manualCounterHours1, ...manualCounterHours2, ...manualCounterHours3];
  
        const expected = changingExpectedHours.map(e => ({employeeId: e.employee.id, day: e.day, hours: e.hours, deleteOnly: true}));
        const manualCounters = manualCounterHours.map(mc => ({employeeId: mc.employee.id, day: mc.day, hours: mc.hours, type: mc.type, deleteOnly: true}));
        this.setState({confirmClearCounters: false, loadingHTML: true}, () => {
          this.state.socket.emit('SET_GROUP_EDIT', {commentaries: [], expected, manualCounters});
        });
      });
    };
  }

  handleClickOnEditGroup(entries, criteria) {
    return (evt) => {
      if(this.state.groupMode === criteria) {
        // Save changes (only if value changed)
        const commentaries = this.state.commentariesToSet.filter((commentary) => {
          const previousCommentDE = entries.find(x => x.commentary === undefined || (x.commentary.day === commentary.day && x.commentary.employeeId === commentary.employeeId));
          return previousCommentDE !== undefined && ((previousCommentDE.commentary === undefined && commentary.value != '') || (previousCommentDE.commentary !== undefined && previousCommentDE.commentary.value !== commentary.value));
        });
        const expected = this.state.changingExpectedHours.filter((expected) => {
          const previousExpectedDE = entries.find(x => x.id === expected.employee.id && x.momentDate.format("DD/MM/YYYY") === expected.day);
          return (previousExpectedDE !== undefined && previousExpectedDE.nbHoursExpected !== expected.hours) || (previousExpectedDE === undefined && expected.hours !== 0);
        }).map(e => ({employeeId: e.employee.id, day: e.day, hours: e.hours}));
        const manualCounters = this.state.manualCounterHours.filter((manualCounter) => {
          if(manualCounter.type === MANUAL_COUNTER_1) {
            const previousMCDE = entries.find(x => x.id === manualCounter.employeeId && (x.manualCounter === undefined || x.manualCounter.day === manualCounter.day && x.manualCounter.type === MANUAL_COUNTER_1));
            return previousMCDE !== undefined && ((previousMCDE.manualCounter === undefined && manualCounter.hours !== 0) || (previousMCDE.manualCounter && previousMCDE.manualCounter.hours !== manualCounter.hours));
          } else if(manualCounter.type === MANUAL_COUNTER_2) {
            const previousMCDE = entries.find(x => x.id === manualCounter.employeeId && (x.secondManualCounter === undefined || x.secondManualCounter.day === manualCounter.day && x.secondManualCounter.type === MANUAL_COUNTER_2));
            return previousMCDE !== undefined && ((previousMCDE.secondManualCounter === undefined && manualCounter.hours !== 0) || (previousMCDE.secondManualCounter && previousMCDE.secondManualCounter.hours !== manualCounter.hours));
          } else if(manualCounter.type === MANUAL_COUNTER_3) {
            const previousMCDE = entries.find(x => x.id === manualCounter.employeeId && (x.thirdManualCounter === undefined || x.thirdManualCounter.day === manualCounter.day && x.thirdManualCounter.type === MANUAL_COUNTER_3));
            return previousMCDE !== undefined && ((previousMCDE.thirdManualCounter === undefined && manualCounter.hours !== 0) || (previousMCDE.thirdManualCounter && previousMCDE.thirdManualCounter.hours !== manualCounter.hours));
          } else {
            return false;
          }
        }).map(mc => ({employeeId: mc.employee.id, day: mc.day, hours: mc.hours, type: mc.type}));

        this.setState({
          loadingHTML: true,
          commentariesToSet: [],
          changingExpectedHours: [],
          manualCounterHours: [],
          groupMode: false
        }, () => {
          this.state.socket.emit('SET_GROUP_EDIT', {commentaries, expected, manualCounters});
        });
      } else {
        const {commentariesToSet, changingExpectedHours, manualCounterHours1, manualCounterHours2, manualCounterHours3} = this.extractFromEntries(entries);
        this.setState({
          commentariesToSet,
          changingExpectedHours,
          manualCounterHours: [...manualCounterHours1, ...manualCounterHours2, ...manualCounterHours3],
          groupMode: criteria
        });
      }
    };
  }

  getExportData() {
    const getOrigin = (p) => getOriginForPointage(p, this.props.rpiHardware, this.state.rpiHardwareNames).display || '';
    const byDate = this.state.groupType === 'DATE';
    const byLocation = this.state.groupType === 'LOCATION';
    let result = undefined;
    const gridData = this.state.gridData;
    if(byDate) {
          const expectedOnPeriod = gridData.reduce((acc, [formattedDate, dateEntries]) => {
            return acc + dateEntries.reduce((acc2, de) => acc2 + getNbHoursExpectedFromProfile(de, moment(formattedDate, "DD/MM/YYYY").unix(), this.props.hourEndByDay(de)), 0.0);
          }, 0.0);

          const validatedOnPeriod = gridData.reduce((acc, [formattedDate, dateEntries], idx) => acc+dateEntries.reduce((acc2, de) => acc2+de.validatedHours, 0.0), 0.0);
          const customValidatedOnPeriod = gridData.reduce((acc, [formattedDate, dateEntries], idx) => acc+dateEntries.reduce((acc2, de) => acc2+de.validatedHoursCustom, 0.0), 0.0);

          const validatedManualOnPeriod = gridData.reduce((acc, [formattedDate, dateEntries], idx) => acc+dateEntries.reduce((acc2, de) => acc2+de.validatedManual, 0.0), 0.0);
          const validatedSecondManualOnPeriod = gridData.reduce((acc, [formattedDate, dateEntries], idx) => acc+dateEntries.reduce((acc2, de) => acc2+de.validatedSecondManual, 0.0), 0.0);
          const validatedThirdManualOnPeriod = gridData.reduce((acc, [formattedDate, dateEntries], idx) => acc+dateEntries.reduce((acc2, de) => acc2+de.validatedThirdManual, 0.0), 0.0);
          
          const validatedPerWeek = gridData.reduce((acc, [formattedDate, dateEntries], idx) => {
            const momentDate = moment(formattedDate, "DD/MM/YYYY");
            const week = momentDate.week();
            const validatedInDateEntries = dateEntries.reduce((accDe, de) => accDe + de.validatedHours, 0.0);
            const validatedCustomInDateEntries = dateEntries.reduce((accDe, de) => accDe + de.validatedHoursCustom, 0.0);
            const validatedManualInDateEntries = dateEntries.reduce((accDe, de) => accDe + (de.validatedManual ? de.validatedManual : 0.0), 0.0);
            const validatedSecondManualInDateEntries = dateEntries.reduce((accDe, de) => accDe + (de.validatedSecondManual ? de.validatedSecondManual : 0.0), 0.0);
            const validatedThirdManualInDateEntries = dateEntries.reduce((accDe, de) => accDe + (de.validatedThirdManual ? de.validatedThirdManual : 0.0), 0.0);
            const expectedInDateEntries = dateEntries.reduce((accDe, e) => accDe + (getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)) || 0.0), 0.0);
            const balanceInDateEntries = validatedInDateEntries + (manualCounterEnabled() ? manualCounterFactor()*validatedManualInDateEntries : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualInDateEntries : 0.0)+ (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualInDateEntries : 0.0) - expectedInDateEntries;
    
            return Object.assign({}, acc, {
              validated: Object.assign({}, acc.validated, {
                [week]: (acc.validated[week] !== undefined && isNaN(acc.validated[week]) === false ? acc.validated[week] + validatedInDateEntries : validatedInDateEntries)
              }),
              validatedCustom: Object.assign({}, acc.validatedCustom, {
                [week]: (acc.validatedCustom[week] !== undefined && isNaN(acc.validatedCustom[week]) === false ? acc.validatedCustom[week] + validatedCustomInDateEntries : validatedCustomInDateEntries)
              }),
              validatedManual: Object.assign({}, acc.validatedManual, {
                [week]: (acc.validatedManual[week] !== undefined && isNaN(acc.validatedManual[week]) === false ? acc.validatedManual[week] + validatedManualInDateEntries : validatedManualInDateEntries)
              }),
              validatedSecondManual: Object.assign({}, acc.validatedSecondManual, {
                [week]: (acc.validatedSecondManual[week] !== undefined && isNaN(acc.validatedSecondManual[week]) === false ? acc.validatedSecondManual[week] + validatedSecondManualInDateEntries : validatedSecondManualInDateEntries)
              }),
              validatedThirdManual: Object.assign({}, acc.validatedThirdManual, {
                [week]: (acc.validatedThirdManual[week] !== undefined && isNaN(acc.validatedThirdManual[week]) === false ? acc.validatedThirdManual[week] + validatedThirdManualInDateEntries : validatedThirdManualInDateEntries)
              }),
              expected: Object.assign({}, acc.expected, {
                [week]: (acc.expected[week] !== undefined && isNaN(acc.expected[week]) === false ? acc.expected[week] + expectedInDateEntries : expectedInDateEntries)
              }),
              balance: Object.assign({}, acc.balance, {
                [week]: (acc.balance[week] !== undefined && isNaN(acc.balance[week]) === false ? acc.balance[week] + balanceInDateEntries : balanceInDateEntries)
              }),
            })
          }, {validated: {}, validatedCustom: {}, validatedManual: {}, validatedSecondManual: {}, validatedThirdManual: {}, expected: {}, balance: {}});

          const allDaysFormatted = gridData.map(([formattedDate, dateEntries]) => formattedDate);
          result = [{
            header: true,
            name: '',
            numberOfPointages: 0,
            validatedOnPeriod: formatHoursWithMinutes(validatedOnPeriod),
            customValidatedOnPeriod: formatHoursWithMinutes(customValidatedOnPeriod),
            validatedManualOnPeriod: formatHoursWithMinutes(validatedManualOnPeriod),
            validatedSecondManualOnPeriod: formatHoursWithMinutes(validatedSecondManualOnPeriod),
            validatedThirdManualOnPeriod: formatHoursWithMinutes(validatedThirdManualOnPeriod),
            expectedOnPeriod: formatHoursWithMinutes(expectedOnPeriod),
            balanceOnPeriod: formatHoursWithMinutes(
              validatedOnPeriod
              -
              expectedOnPeriod
              +
              (manualCounterEnabled() ? manualCounterFactor()*validatedManualOnPeriod : 0.0)
              +
              (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualOnPeriod : 0.0)
              +
              (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualOnPeriod : 0.0)
            )
          }, ...gridData.reduce((acc, [formattedDate, dateEntries], idx) => {
            const lines = dateEntries.map((dateEntry) => ({
              day: formattedDate,
              dow: moment(formattedDate, "DD/MM/YYYY").format('ddd').toUpperCase(),
              employee: dateEntry.name,
              employeeNumber: dateEntry.number,
              pointages: dateEntry.pointages.map((p, idxPt) =>
                  moment.unix(p.timestamp).format("HH:mm")
              ),
              locations: dateEntry.pointages.map((p, idxPt) =>
                getOrigin(p)
              ),
              approximativeAddrs: dateEntry.pointages.map((p, idxPt) =>
                p.gpsCoords !== null && p.gpsCoords !== undefined ?
                  reverseGeocodeAddrStr(p.gpsCoords.split('|')[0], p.gpsCoords.split('|')[1])
                  : Promise.resolve("")
              ),
              commentary: dateEntry.commentary !== undefined ? dateEntry.commentary.value || "" : "",
              validated: formatHoursWithMinutes(dateEntry.validatedHours),
              validatedCustom: formatHoursWithMinutes(dateEntry.validatedHoursCustom),
              validatedManual: formatHoursWithMinutes(dateEntry.validatedManual),
              validatedSecondManual: formatHoursWithMinutes(dateEntry.validatedSecondManual),
              validatedThirdManual: formatHoursWithMinutes(dateEntry.validatedThirdManual),
              expected: formatHoursWithMinutes(dateEntry.nbHoursExpected),
              balance: formatHoursWithMinutes(
                dateEntry.validatedHours
                +
                (manualCounterEnabled() ? manualCounterFactor()*(dateEntry.validatedManual !== undefined ? dateEntry.validatedManual : 0.0) : 0.0)
                +
                (secondManualCounterEnabled() ? secondManualCounterFactor()*(dateEntry.validatedSecondManual !== undefined ? dateEntry.validatedSecondManual : 0.0) : 0.0)
                +
                (thirdManualCounterEnabled() ? thirdManualCounterFactor()*(dateEntry.validatedThirdManual !== undefined ? dateEntry.validatedThirdManual : 0.0) : 0.0)
                -
                dateEntry.nbHoursExpected
              )
            }));
            
            const entriesData = [
              {
                header: true,
                headerText: this.state.onlyTotals === true ? formattedDate : '(journée entière)',
                name: '',
                numberOfPointages: 0,
                validatedOnPeriod: formatHoursWithMinutes(dateEntries.reduce((acc, de) => acc+de.validatedHours, 0.0)),
                customValidatedOnPeriod: formatHoursWithMinutes(dateEntries.reduce((acc, de) => acc+de.validatedHoursCustom, 0.0)),
                validatedManualOnPeriod: formatHoursWithMinutes(dateEntries.reduce((acc, de) => acc+de.validatedManual, 0.0)),
                validatedSecondManualOnPeriod: formatHoursWithMinutes(dateEntries.reduce((acc, de) => acc+de.validatedSecondManual, 0.0)),
                validatedThirdManualOnPeriod: formatHoursWithMinutes(dateEntries.reduce((acc, de) => acc+de.validatedThirdManual, 0.0)),
                expectedOnPeriod: formatHoursWithMinutes(dateEntries.reduce((acc, de) => acc+de.nbHoursExpected, 0.0)),
                balanceOnPeriod: formatHoursWithMinutes(dateEntries.reduce((acc, de) =>
                  acc
                  +
                  de.validatedHours
                  +
                  (manualCounterEnabled() ? manualCounterFactor()*(de.validatedManual !== undefined ? de.validatedManual : 0.0) : 0.0)
                  +
                  (secondManualCounterEnabled() ? secondManualCounterFactor()*(de.validatedSecondManual !== undefined ? de.validatedSecondManual : 0.0) : 0.0)
                  +
                  (thirdManualCounterEnabled() ? thirdManualCounterFactor()*(de.validatedThirdManual !== undefined ? de.validatedThirdManual : 0.0) : 0.0)
                  -
                  de.nbHoursExpected
                , 0.0))
              },
              ...(this.state.onlyTotals ? [] : lines)
            ];

            const momentDate = moment(formattedDate, "DD/MM/YYYY");
            const week = momentDate.week();
            const lastMonday = moment.unix(momentDate.unix()).subtract(6, 'd').format("DD/MM/YYYY");
            if(week === moment.unix(momentDate.unix()).add(1, 'd').week()-1 && allDaysFormatted.includes(lastMonday)) {
              return [...acc, entriesData, ({
                day: `Semaine ${week}`,
                dow: '',
                employee: '',
                employeeNumber: '',
                pointages: [],
                locations: [],
                approximativeAddrs: [],
                commentary: "",
                validated: formatHoursWithMinutes(validatedPerWeek.validated[week]),
                validatedCustom: formatHoursWithMinutes(validatedPerWeek.validatedCustom[week]),
                validatedManual: formatHoursWithMinutes(validatedPerWeek.validatedManual[week]),
                validatedSecondManual: formatHoursWithMinutes(validatedPerWeek.validatedSecondManual[week]),
                validatedThirdManual: formatHoursWithMinutes(validatedPerWeek.validatedThirdManual[week]),
                expected: formatHoursWithMinutes(validatedPerWeek.expected[week]),
                balance: formatHoursWithMinutes(validatedPerWeek.balance[week])
              })];
            } else {
              return [...acc, entriesData];
            }
          }, [])];
      } else if(byLocation) {
        const allDays = Array.from(this.getDayRange());
        const allDaysFormatted = allDays.map(x => x.format("DD/MM/YYYY"));

        result = this.state.gridData.map(([location, {l, pointagesForThisLocation, utils, rawUtils, validated, validatedAllLocations, validatedOnPeriod, validatedManualOnPeriod, validatedSecondManualOnPeriod, validatedThirdManualOnPeriod, customValidatedOnPeriod, expectedHoursOnPeriod, balanceOnPeriod}]) => {
          const weeks = Array.from(this.getWeekRange()).map((x) => `${x.weekYear()}_${x.week()}`);

          const defaultPerWeek = () => weeks.reduce((a, k) => Object.assign(a, {[k]: 0.0}), {});
          const calculatePerWeekFromValidated = (keyToAdd, customValidated) => {
            return weeks.reduce((a, w) => {
              return Object.assign(a, {
                [w]: a[w] + customValidated.map(x => x[keyToAdd]).reduce((acc,pw) => acc+(pw[w] !== undefined ? pw[w] : 0.0), 0.0)
              })
            }, defaultPerWeek());
          };
          const validatedPerWeek = calculatePerWeekFromValidated('perWeek', validated);
          const validatedCustomPerWeek = calculatePerWeekFromValidated('perWeekCustom', validated);
          const validatedManualPerWeek = calculatePerWeekFromValidated('perWeekManual', validatedAllLocations);
          const validatedSecondManualPerWeek = calculatePerWeekFromValidated('perWeekSecondManual', validatedAllLocations);
          const validatedThirdManualPerWeek = calculatePerWeekFromValidated('perWeekThirdManual', validatedAllLocations);
          // TODO Expected does not depend on location...
          const summaryByWeek = this.state.summary.map(e => allDays.reduce((acc, day) => {
            return Object.assign(acc, {
              [`${day.weekYear()}_${day.week()}`]: acc[`${day.weekYear()}_${day.week()}`] + getNbHoursExpectedFromProfile(e, day.unix(), this.props.hourEndByDay(e))
            });
          }, defaultPerWeek()));
          const expectedPerWeek = weeks.reduce((a, w) => {
            return Object.assign(a, {
              [w]: a[w] + summaryByWeek.reduce((acc, x) => acc + x[w], 0.0)
            });
          }, defaultPerWeek());
          const balancePerWeek = weeks.reduce((a, w) => {
            return Object.assign(a, {
              [w]: validatedPerWeek[w] + (manualCounterEnabled() ? manualCounterFactor()*validatedManualPerWeek[w] : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualPerWeek[w] : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualPerWeek[w] : 0.0) - expectedPerWeek[w]
            });
          }, {});
          
          return [{
            header: true,
            name: `Site : ${l}`,
            numberOfPointages: pointagesForThisLocation.length,
            validatedOnPeriod: formatHoursWithMinutes(validatedOnPeriod),
            customValidatedOnPeriod: formatHoursWithMinutes(customValidatedOnPeriod),
            validatedManualOnPeriod: formatHoursWithMinutes(validatedManualOnPeriod),
            validatedSecondManualOnPeriod: formatHoursWithMinutes(validatedSecondManualOnPeriod),
            validatedThirdManualOnPeriod: formatHoursWithMinutes(validatedThirdManualOnPeriod),
            expectedOnPeriod: 'NA',
            balanceOnPeriod: 'NA'
          }, ...(this.state.onlyTotals ? [] : allDaysFormatted.reduce((acc, day, idx) => {
            const momentDate = moment(day, "DD/MM/YYYY");
            const week = momentDate.week();
            const weekIdx = `${momentDate.weekYear()}_${week}`;
            const lastMonday = moment.unix(momentDate.unix()).subtract(6, 'd').format("DD/MM/YYYY");
            //const dayOfWeek = momentDate.locale('en').format('ddd').toUpperCase();
            const dayOfWeekFr = moment(day, "DD/MM/YYYY").format('ddd').toUpperCase();

            const locationData = pointagesForThisLocation.map((e, eIdx) => {
              const commentary = e.commentaries.find(c => c.day === day);
              const pointagesToday = utils[eIdx].pointages.filter(p => getFakeDay(p, this.props.hourEndByDay(e)).format("DD/MM/YYYY") === day).map(p => p.location === '' || p.location === undefined || p.location === null ? Object.assign({}, p, {location: 'Inconnu'}) : p);
              //const rawPointagesToday = e.pointages.filter(p => moment.unix(p.timestamp).format("DD/MM/YYYY") === day);
              const validatedToday = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(pointagesToday || [], [day]).validatedHours();

              return {
                day: day,
                dow: dayOfWeekFr,
                employeeId: e.id,
                employee: e.name,
                employeeNumber: e.number,
                commentary: commentary !== undefined ? commentary.value || "" : "",
                pointages: pointagesToday !== undefined ? pointagesToday.map((p, idxPt) => (
                    moment.unix(p.timestamp).format("HH:mm")
                )) : [],
                locations: pointagesToday !== undefined ? pointagesToday.map((p, idxPt) => (
                    getOrigin(p)
                )) : [],
                approximativeAddrs: pointagesToday !== undefined ? pointagesToday.map((p, idxPt) => (
                  p.gpsCoords !== null && p.gpsCoords !== undefined ?
                    reverseGeocodeAddrStr(p.gpsCoords.split('|')[0], p.gpsCoords.split('|')[1])
                    : Promise.resolve("")
                )) : [],
                validated: formatHoursWithMinutes(
                  validatedToday.amount
                ),
                validatedCustom: formatHoursWithMinutes(
                  validatedToday.amountCustom
                ),
                validatedManual: formatHoursWithMinutes(
                  validatedToday.amountManual
                ),
                validatedSecondManual: formatHoursWithMinutes(
                  validatedToday.amountSecondManual
                ),
                validatedThirdManual: formatHoursWithMinutes(
                  validatedToday.amountThirdManual
                ),
                expected: formatHoursWithMinutes(
                  getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))
                ),
                balance: formatHoursWithMinutes(
                  validatedToday.amount
                  +
                  (manualCounterEnabled() ? manualCounterFactor()*(validatedToday.amountManual !== undefined ? validatedToday.amountManual : 0.0) : 0.0)
                  +
                  (secondManualCounterEnabled() ? secondManualCounterFactor()*(validatedToday.amountSecondManual !== undefined ? validatedToday.amountSecondManual : 0.0) : 0.0)
                  +
                  (thirdManualCounterEnabled() ? thirdManualCounterFactor()*(validatedToday.amountThirdManual !== undefined ? validatedToday.amountThirdManual : 0.0) : 0.0)
                  -
                  getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))
                )
              }
            });

            if(moment.unix(momentDate.unix()).add(1, 'd').week() !== week && allDaysFormatted.includes(lastMonday)) {
              return [...acc, locationData, ({
                day: `Semaine ${week}`,
                dow: '',
                employeeId: '',
                employee: '',
                employeeNumber: '',
                commentary: "",
                pointages: [],
                locations: [],
                approximativeAddrs: [],
                validated: formatHoursWithMinutes(validatedPerWeek[weekIdx]),
                validatedCustom: formatHoursWithMinutes(validatedCustomPerWeek[weekIdx]),
                validatedManual: formatHoursWithMinutes(validatedManualPerWeek[weekIdx]),
                validatedSecondManual: formatHoursWithMinutes(validatedSecondManualPerWeek[weekIdx]),
                validatedThirdManual: formatHoursWithMinutes(validatedThirdManualPerWeek[weekIdx]),
                expected: formatHoursWithMinutes(expectedPerWeek[weekIdx]),
                balance: formatHoursWithMinutes(balancePerWeek[weekIdx])
              })];
            } else {
              return [...acc, locationData];
            }
          }, [])).flat()];
        });
      } else {
        result = this.state.gridData.map(([e, allDaysFormattedForThisEmp, {pointagesPerDay, rawPointagesPerDay, validated, validatedOnPeriod, manualCounterTotal, secondManualCounterTotal, thirdManualCounterTotal, expectedOnPeriod, balanceOnPeriod, customValidated}]) => {
          const customValidatedOnPeriod = customValidated;
          const validatedManualOnPeriod = manualCounterTotal;
          const validatedSecondManualOnPeriod = secondManualCounterTotal;
          const validatedThirdManualOnPeriod = thirdManualCounterTotal;

          const weeks = Array.from(this.getWeekRange()).map((x) => `${x.weekYear()}_${x.week()}`);
    
          const defaultPerWeek = () => weeks.reduce((a, k) => Object.assign(a, {[k]: 0.0}), {});
          const calculatePerWeekFromValidated = (keyToAdd) => {
            return weeks.reduce((a, w) => {
              return Object.assign({}, a, {
                [w]: a[w] + (validated[keyToAdd][w] || 0.0)
              })
            }, defaultPerWeek());
          };
          const validatedPerWeek = calculatePerWeekFromValidated('perWeek');
  
          const validatedCustomPerWeek = calculatePerWeekFromValidated('perWeekCustom');
          const validatedManualPerWeek = calculatePerWeekFromValidated('perWeekManual');
          const validatedSecondManualPerWeek = calculatePerWeekFromValidated('perWeekSecondManual');
          const validatedThirdManualPerWeek = calculatePerWeekFromValidated('perWeekThirdManual');
          const summaryByWeek = allDaysFormattedForThisEmp.map(x => moment(x, "DD/MM/YYYY")).reduce((acc, day) => {
            return Object.assign(acc, {
              [`${day.weekYear()}_${day.week()}`]: acc[`${day.weekYear()}_${day.week()}`] + getNbHoursExpectedFromProfile(e, day.unix(), this.props.hourEndByDay(e))
            });
          }, defaultPerWeek());
          const expectedPerWeek = weeks.reduce((a, w) => {
            return Object.assign(a, {
              [w]: a[w] + summaryByWeek[w]
            });
          }, defaultPerWeek());
          const balancePerWeek = weeks.reduce((a, w) => {
            return Object.assign(a, {
              [w]: validatedPerWeek[w] + (manualCounterEnabled() ? manualCounterFactor()*validatedManualPerWeek[w] : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualPerWeek[w] : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualPerWeek[w] : 0.0) - expectedPerWeek[w]
            });
          }, {});

          const numberOfPointages = allDaysFormattedForThisEmp.reduce((acc, day) => acc + (pointagesPerDay[day] !== undefined ? pointagesPerDay[day].length : 0), 0);
          return [{
            header: true,
            name: e.name,
            numberOfPointages,
            validatedOnPeriod: formatHoursWithMinutes(validatedOnPeriod),
            customValidatedOnPeriod: formatHoursWithMinutes(customValidatedOnPeriod),
            validatedManualOnPeriod: formatHoursWithMinutes(validatedManualOnPeriod),
            validatedSecondManualOnPeriod: formatHoursWithMinutes(validatedSecondManualOnPeriod),
            validatedThirdManualOnPeriod: formatHoursWithMinutes(validatedThirdManualOnPeriod),
            expectedOnPeriod: formatHoursWithMinutes(expectedOnPeriod),
            balanceOnPeriod: formatHoursWithMinutes(
              validatedOnPeriod
              -
              expectedOnPeriod
              +
              (manualCounterEnabled() ? manualCounterFactor()*validatedManualOnPeriod : 0.0)
              +
              (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualOnPeriod : 0.0)
              +
              (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualOnPeriod : 0.0)
            )
          }, ...(this.state.onlyTotals ? [] : allDaysFormattedForThisEmp.reduce((acc, day, idx) => {
            const momentDate = moment(day, "DD/MM/YYYY");
            //const dayOfWeek = momentDate.locale('en').format('ddd').toUpperCase();
            const dayOfWeekFr = moment(day, "DD/MM/YYYY").format('ddd').toUpperCase();
            const commentary = e.commentaries.find(c => c.day === day);
            const pointagesToday = (pointagesPerDay[day] || []).map(p => p.location === '' || p.location === undefined || p.location === null ? Object.assign({}, p, {location: 'Inconnu'}) : p);
            const validatedToday = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(pointagesToday, [day]).validatedHours();

            const dayData = {
              day: day,
              dow: dayOfWeekFr,
              employeeId: e.id,
              employee: e.name,
              employeeNumber: e.number,
              commentary: commentary !== undefined ? commentary.value || "" : "",
              pointages: pointagesToday.map((p, idxPt) => (
                  moment.unix(p.timestamp).format("HH:mm")
              )),
              locations: pointagesToday.map((p, idxPt) => (
                getOrigin(p)
              )),
              approximativeAddrs: pointagesToday.map((p, idxPt) => (
                p.gpsCoords !== null && p.gpsCoords !== undefined ?
                  reverseGeocodeAddrStr(p.gpsCoords.split('|')[0], p.gpsCoords.split('|')[1])
                  : Promise.resolve("")
              )),
              validated: formatHoursWithMinutes(
                validatedToday.amount
              ),
              validatedCustom: formatHoursWithMinutes(
                validatedToday.amountCustom
              ),
              validatedManual: formatHoursWithMinutes(
                validatedToday.amountManual
              ),
              validatedSecondManual: formatHoursWithMinutes(
                validatedToday.amountSecondManual
              ),
              validatedThirdManual: formatHoursWithMinutes(
                validatedToday.amountThirdManual
              ),
              expected: formatHoursWithMinutes(
                getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))
              ),
              balance: formatHoursWithMinutes(
                validatedToday.amount
                +
                (manualCounterEnabled() ? manualCounterFactor()*(validatedToday.amountManual !== undefined ? validatedToday.amountManual : 0.0) : 0.0)
                +
                (secondManualCounterEnabled() ? secondManualCounterFactor()*(validatedToday.amountSecondManual !== undefined ? validatedToday.amountSecondManual : 0.0) : 0.0)
                +
                (thirdManualCounterEnabled() ? thirdManualCounterFactor()*(validatedToday.amountThirdManual !== undefined ? validatedToday.amountThirdManual : 0.0) : 0.0)
                -
                getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))
              )
            };

            const currentWeek = momentDate.week();
            const currentWeekIdx = `${momentDate.weekYear()}_${currentWeek}`;
            const nextDayWeek = moment.unix(momentDate.unix()).add(1, 'd').week();
            const lastMonday = moment.unix(momentDate.unix()).subtract(6, 'd').format("DD/MM/YYYY");
            if(nextDayWeek !== currentWeek) {
              return [...acc, dayData, ({
                day: `Semaine ${currentWeek}`,
                dow: '',
                employeeId: '',
                employee: '',
                employeeNumber: '',
                commentary: "",
                pointages: [],
                locations: [],
                approximativeAddrs: [],
                validated: formatHoursWithMinutes(validatedPerWeek[currentWeekIdx]),
                validatedCustom: formatHoursWithMinutes(validatedCustomPerWeek[currentWeekIdx]),
                validatedManual: formatHoursWithMinutes(validatedManualPerWeek[currentWeekIdx]),
                validatedSecondManual: formatHoursWithMinutes(validatedSecondManualPerWeek[currentWeekIdx]),
                validatedThirdManual: formatHoursWithMinutes(validatedThirdManualPerWeek[currentWeekIdx]),
                expected: formatHoursWithMinutes(expectedPerWeek[currentWeekIdx]),
                balance: formatHoursWithMinutes(balancePerWeek[currentWeekIdx])
              })];
            } else {
              return [...acc, dayData];
            }
          }, []))];
        });
      }

      let ret = result.flat();

      if(this.state.onlyNotEmptyLines === true) {
        ret = ret.filter(x =>
          (x.header === true && x.numberOfPointages > 0) ||
          (x.header !== true && x.pointages.length > 0)
        );
      }

      if(this.state.onlyLinesWithExpectedHours === true) {
        ret = ret.filter(x =>
          (x.header === true) ||
          (x.header !== true && x.expected !== formatHoursWithMinutes(moment.duration(0, 'seconds')))
        );
      }

      return ret;
  }

  handleExportPDF(doPageBreaks) {
    return (event) => {
      this.setState({loadingHTML: true}, () => {
        const areLocationsVisible = this.state.locationsVisible;
        setTimeout(() => {
          new Promise((resolve, reject) => {
            const defaultAvatar = fetch('/logo.png').then(response => response.arrayBuffer())
                .then(ab => Buffer.from(ab, 'binary').toString('base64'));
            if(this.state.avatar !== undefined && this.state.avatar !== null) {
              return resolve([this.state.avatar, false]);
            } else {
              return defaultAvatar.then(logoData => resolve([logoData, true]));
            }
          }).then(([logoData, isLogoDefault]) => {
            const actualNightShiftLabel = "H. de nuit";
            const summary = this.getExportData();
            const lineBreak = ['', '', '', '', '', '', ''];
            const pageBreak = ['', '', '', '', '', '', {text: '', pageBreak: 'after'}];
            const getHeaderCell = (text, alignment = 'left', bold = true) => ({text, color: 'white', fillColor: '#1678c2', alignment, bold});
            const getSubHeaderCell = (text, alignment = 'left', bold = true) => ({text, color: 'white', fillColor: '#4d8ebf', alignment, bold});
            const table = (locationsVisible, allApproximativeAddrs) => ({
              width: 'auto',
              layout: 'oraneLayout',
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: this.state.allManualCountersVisible ? 2 : 1,
                widths: [ 'auto', 'auto', '*', 60, 60, 60, 60 ],
                body: [
                  [getHeaderCell('Journée'), getHeaderCell('Employé'), getHeaderCell(''), getHeaderCell(''), getHeaderCell('Réel', 'right'), getHeaderCell('Théorique', 'right'), getHeaderCell('Balance', 'right')],
                  this.state.allManualCountersVisible ? [getHeaderCell(''),getHeaderCell(''),getHeaderCell(''), manualCounterEnabled() ? getHeaderCell(manualCounterName() || 'Cpt. perso 1', 'right') : getHeaderCell(''), secondManualCounterEnabled() ? getHeaderCell(secondManualCounterName() || 'Cpt. perso 2', 'right') : getHeaderCell(''), thirdManualCounterEnabled() ? getHeaderCell(thirdManualCounterName() || 'Cpt. perso 3', 'right') : getHeaderCell(''), nightShiftCounterEnabled() ? getHeaderCell(actualNightShiftLabel, 'right') : getHeaderCell('')] : null,
                  lineBreak,
                  ...summary.map((line, lineIdx) => {
                    if(line.header === true) {
                      return [
                        doPageBreaks && lineIdx > 0 ? pageBreak : null, [getHeaderCell(line.headerText || 'Période entière'), getHeaderCell(line.name), getHeaderCell(''), getHeaderCell(''), getHeaderCell(line.validatedOnPeriod, 'right'), getHeaderCell(line.expectedOnPeriod, 'right'), getHeaderCell(line.balanceOnPeriod, 'right')],
                        this.state.allManualCountersVisible ? [getHeaderCell(''), getHeaderCell(''), getHeaderCell(''), getHeaderCell(line.validatedManualOnPeriod, 'right'), getHeaderCell(line.validatedSecondManualOnPeriod, 'right'), getHeaderCell(line.validatedThirdManualOnPeriod, 'right'), getHeaderCell(line.customValidatedOnPeriod, 'right')] : null,
                        lineBreak
                      ].filter(x => x !== null)
                    } else {
                      if(locationsVisible) {
                        const pointages = line.pointages.map(p => ({text: p, alignment: 'left'}));
                        const locations = line.locations;
                        const approximativeAddrs = allApproximativeAddrs[lineIdx]

                        const otherLines = pointages.reduce((acc, p, pIdx) => [
                          ...acc,
                          [p, {text: locations[pIdx], colSpan: 2}, '', '', '', '', ''],
                          approximativeAddrs[pIdx] ? [{text: 'Adresse approximative :', colSpan: 2}, '', {text: approximativeAddrs[pIdx], colSpan: 5}] : null
                        ], []).filter(x => x !== null);

                        const commentaryLine = ['Commentaires :', line.commentary, '', '', '', '', ''];
                        if(this.state.commentariesVisible && line.commentary !== undefined && line.commentary !== "") {
                          otherLines.push(commentaryLine);
                        }
                        return [[getSubHeaderCell(`${line.dow} ${line.day}`, 'left', false), getSubHeaderCell(line.employee, 'left', false), getSubHeaderCell(''), getSubHeaderCell(''), getSubHeaderCell(line.validated, 'right', false), getSubHeaderCell(line.expected, 'right', false), getSubHeaderCell(line.balance, 'right', false)], [getSubHeaderCell(''),getSubHeaderCell(''),getSubHeaderCell(''), getSubHeaderCell(manualCounterEnabled() && this.state.allManualCountersVisible ? line.validatedManual : '', 'right'), getSubHeaderCell(secondManualCounterEnabled() && this.state.allManualCountersVisible ? line.validatedSecondManual : '', 'right'), getSubHeaderCell(thirdManualCounterEnabled() && this.state.allManualCountersVisible ? line.validatedThirdManual : '', 'right'), getSubHeaderCell(nightShiftCounterEnabled() && this.state.allManualCountersVisible ? line.validatedCustom : '', 'right')], pointages.length > 0 ? ['Pointage', 'Origine', '', '', '', '', ''] : ['', '', '', '', '', '', ''], ...otherLines];
                      } else {
                        const pointagesByTwo = line.pointages.map(p => ({text: p, alignment: 'left'}))
                          .reduce((acc, p, idx) => {
                            if(idx%2 === 0) {
                              return [...acc, [p, idx+1 < line.pointages.length ? line.pointages[idx+1] : ""]];
                            } else {
                              return acc;
                            }
                          }, []);

                        const otherLines = [[line.pointages.length > 0 ? 'Pointages' : '', '', '', '', '', '', ''], ...pointagesByTwo.map(([one, two]) => [one, two, '', '', '', '', ''])];
                        const commentaryLine = ['Commentaires :', line.commentary, '', '', '', '', ''];
                        if(this.state.commentariesVisible && line.commentary !== undefined && line.commentary !== "") {
                          otherLines.push(commentaryLine);
                        }
                        return [[getSubHeaderCell(`${line.dow} ${line.day}`, 'left', false), getSubHeaderCell(line.employee, 'left', false), getSubHeaderCell(''), getSubHeaderCell(''), getSubHeaderCell(line.validated, 'right', false), getSubHeaderCell(line.expected, 'right', false), getSubHeaderCell(line.balance, 'right', false)], [getSubHeaderCell(''),getSubHeaderCell(''),getSubHeaderCell(''), getSubHeaderCell(manualCounterEnabled() && this.state.allManualCountersVisible ? line.validatedManual : '', 'right'), getSubHeaderCell(secondManualCounterEnabled() && this.state.allManualCountersVisible ? line.validatedSecondManual : '', 'right'), getSubHeaderCell(thirdManualCounterEnabled() && this.state.allManualCountersVisible ? line.validatedThirdManual : '', 'right'), getSubHeaderCell(nightShiftCounterEnabled() && this.state.allManualCountersVisible ? line.validatedCustom : '', 'right')], ...otherLines];
                      }
                    }
                  }).flat()
                ].filter(x => x !== null)
              }
            });
            const fromToStr = this.getDateFilterStr("au");
            return (areLocationsVisible ? Promise.all(summary.map(line => Promise.all(line.approximativeAddrs || []))) : Promise.resolve([])).then((allApproximativeAddrs) => {
              const docDefinition = {
                pageSize: 'A4',
                pageMargins: [40, 80, 40, 40],
                header: (currentPage, pageCount, pageSize) => {
                  return [
                    {
                      margin: [60,20],
                      columns: [
                        {
                          layout: 'noBorders',
                          table: {
                            headerRows: 0,
                            widths: [ 'auto', '*' ],
                            body: [[
                              { image: `${isLogoDefault ? 'data:image/png;base64,' : ''}${logoData}`, alignment: 'left', fit: isLogoDefault ? [90, 30] : [150, 50], fillColor: isLogoDefault ? '#1678c2' : null, margin: [2,2] },
                              { text: `Période du ${fromToStr}`, alignment: 'right', fontSize: 16, margin: [0,9,0,0] }
                            ]]
                          }
                        }
                      ]
                    }
                  ]
                },
                footer: (currentPage, pageCount, pageSize) => {
                  const now = moment();
                  const formattedDate = now.format("DD/MM/YYYY");
                  const formattedTime = now.format("HH:mm:ss");
                  return [
                    {
                      margin: [40,0,40,40],
                      columns: [
                        { text: `Export effectué le ${formattedDate} à ${formattedTime}`, alignment: 'left', fontSize: 10 },
                        { text: `Page ${currentPage} / ${pageCount}`, alignment: 'right', fontSize: 10 }
                      ]
                    }
                  ]
                },
                content: [
                  {
                    margin: [0,0,0,0],
                    columns: [
                      { width: '*', text: '' },
                      table(areLocationsVisible, allApproximativeAddrs),
                      { width: '*', text: '' }
                    ]
                  }
                ]
              };
              const tblLayouts = {
                oraneLayout: {
                  hLineWidth: (i, node) => {
                    if (i === 0 || i === node.table.body.length) {
                      return 0;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                  },
                  vLineWidth: (i) => {
                    return 0;
                  },
                  vLineColor: (i) => {
                    return '#1678c2';
                  },
                  hLineColor: (i) => {
                    return '#bbb';
                  },
                  paddingLeft: (i) => {
                    return i === 0 ? 0 : 8;
                  },
                  paddingRight: (i, node) => {
                    return (i === node.table.widths.length - 1) ? 0 : 8;
                  }
                }
              }
              pdfMake.createPdf(docDefinition, tblLayouts).getBuffer((contentBuffer) => {
                const blob = new Blob([contentBuffer], {type: "application/pdf;charset=utf8"});
                this.setState({loadingHTML: false});
                saveAs(blob, this.getFilenameForExport('pdf'));
              });
            })
          });
        }, 500);
      });
    }
  }

  getFilenameForExport(extension) {
    const now = moment();
  
    const toPart = this.state.selectedDateFilter.to !== undefined && this.state.selectedDateFilter.to.diff(this.state.selectedDateFilter.from, 'days') !== 0 ?
      "_AU_" + this.state.selectedDateFilter.to.format("YYYYMMDD")
      : "";
    
    const fromPart = this.state.selectedDateFilter.from !== undefined ? 
      "PERIODE_DU_" + this.state.selectedDateFilter.from.format("YYYYMMDD")
      : "";
    
    return `ORANE_EXPORT_${now.format("YYYYMMDD_HH[H]mm")}_${fromPart}${toPart}.${extension}`;
  }

  handleExportCSV(keepRawData = false) {
    return (event) => {
      this.setState({loadingHTML: true}, () => {
        setTimeout(() => {
          const areLocationsVisible = this.state.locationsVisible;
          const emptyCols = (maxNumberOfPointagesOnOneLine) => {
            let emptyCols = '';
            for(let i = 0; i<maxNumberOfPointagesOnOneLine-1; i++) {
              emptyCols += ';';
            }
            return emptyCols;
          }

          const formatString = (str) => keepRawData === true ? str : `\t${str}`;

          const actualNightShiftLabel = "H. de nuit";

          const columnsCSV = (maxNumberOfPointagesOnOneLine) => {
            return `Journée;Matricule;Employé;Pointages;${areLocationsVisible ? `Origine;Adresse approximative;${emptyCols(maxNumberOfPointagesOnOneLine*3 - 2)}` : emptyCols(maxNumberOfPointagesOnOneLine)};${manualCounterEnabled() && this.state.allManualCountersVisible ? `${manualCounterName() || 'Cpt. perso 1'};` : ''}${secondManualCounterEnabled() && this.state.allManualCountersVisible ? `${secondManualCounterName() || 'Cpt. perso 2'};` : ''}${thirdManualCounterEnabled() && this.state.allManualCountersVisible ? `${thirdManualCounterName() || 'Cpt. perso 3'};` : ''}${nightShiftCounterEnabled() && this.state.allManualCountersVisible ? `${actualNightShiftLabel};` : ''}Réel;Théorique;Balance${this.state.commentariesVisible ? ';Commentaires' : ''}`;
          };
          
          const fromToStr = this.getDateFilterStr("au");
                      
          const headerCSV = (maxNumberOfPointagesOnOneLine) => `Période du ${fromToStr}\r\n\r\n${columnsCSV(maxNumberOfPointagesOnOneLine)}`;
      
          const summaryCSV = this.getExportData();
          
          let maxNumberOfPointagesOnOneLine = summaryCSV.reduce((acc, line) =>
            line.header === true ? acc :
              line.pointages.length > acc ? line.pointages.length : acc
          , 0);
          if(maxNumberOfPointagesOnOneLine === 0) {
            maxNumberOfPointagesOnOneLine = 1;
          }

          const linesPromises = summaryCSV.map((line) => Promise.all(line.approximativeAddrs || []).then(addrs => {
            if(line.header === true) {
              return `\r\n\r\n${formatString(line.headerText !== undefined ? line.headerText : '(période entière)')};;${formatString(line.name)};;${emptyCols(maxNumberOfPointagesOnOneLine*(areLocationsVisible ? 3 : 1))};${manualCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.validatedManualOnPeriod)};` : ''}${secondManualCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.validatedSecondManualOnPeriod)};` : ''}${thirdManualCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.validatedThirdManualOnPeriod)};` : ''}${nightShiftCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.customValidatedOnPeriod)};` : ''}${formatString(line.validatedOnPeriod)};${formatString(line.expectedOnPeriod)};${formatString(line.balanceOnPeriod)}`;
            } else {
              const pointages = line.pointages.reduce((acc, p, pIdx) => `${acc}${p};${areLocationsVisible ? `${line.locations[pIdx]};${addrs[pIdx] || ""};` : ''}`, '') + emptyCols(maxNumberOfPointagesOnOneLine*(areLocationsVisible ? 3 : 1) - line.pointages.length*(areLocationsVisible ? 3 : 1) + 1);
              return `\r\n${formatString(line.day)};${formatString(line.employeeNumber)};${formatString(line.employee)};${formatString(pointages)};${manualCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.validatedManual)};` : ''}${secondManualCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.validatedSecondManual)};` : ''}${thirdManualCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.validatedThirdManual)};` : ''}${nightShiftCounterEnabled() && this.state.allManualCountersVisible ? `${formatString(line.validatedCustom)};` : ''}${formatString(line.validated)};${formatString(line.expected)};${formatString(line.balance)}${this.state.commentariesVisible ? `;${formatString(line.commentary)}` : ''}`;
            }
          }));

          Promise.all(linesPromises).then((lines) => {
            const contentCSV = lines.reduce((acc, line) => `${acc}${line}`, `${headerCSV(maxNumberOfPointagesOnOneLine)}\r\n`);
            const decodedBuffer = iconv.decode(Buffer.from(contentCSV), 'utf8');
            const contentBuffer = iconv.encode(decodedBuffer, 'win1252');
            this.setState({loadingHTML: false});
            saveAs(new Blob([contentBuffer], {type: "text/csv;charset=windows-1252"}), this.getFilenameForExport('csv'));
          })
        })
      });
    }
  }

  onToggleOnlyNotEmptyLines(event) {
    this.setState({
      onlyNotEmptyLines: !this.state.onlyNotEmptyLines,
      gridData: []
    }, () => {
      this.getGridData(this.state.groupType || "DATE");
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  onToggleOnlyLinesWithExpectedHours(event) {
    this.setState({
      onlyLinesWithExpectedHours: !this.state.onlyLinesWithExpectedHours,
      gridData: []
    }, () => {
      this.getGridData(this.state.groupType || "DATE");
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  onToggleOnlyTotals(event) {
    const enablingTotals = this.state.onlyTotals === false;
    this.setState({
      onlyTotals: !this.state.onlyTotals,
      onlyLinesWithExpectedHours: enablingTotals ? false : this.state.onlyLinesWithExpectedHours,
      onlyNotEmptyLines: enablingTotals ? false : this.state.onlyNotEmptyLines,
      displayOnlyInvalid: enablingTotals ? false : this.state.displayOnlyInvalid
    }, () => {
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  onToggleDisplayOnlyInvalid(event) {
    this.setState({
      displayOnlyInvalid: !this.state.displayOnlyInvalid,
      gridData: []
    }, () => {
      this.getGridData(this.state.groupType || "DATE");
      this.onToggleRememberFilters(this.state.rememberFilters)(null);
    });
  }

  onToggleRememberFilters(value) {
    return (event) => {
      this.setState({
        rememberFilters: value
      }, () => {
        const settings = JSON.parse(ls('settings')) || {};
        const {selectedEmployeeFilter, selectedTeamFilter, selectedDateFilter, selectedPredefinedDateFilter, selectedTeamId, selectedLocationFilter, selectedLocationName, groupType, onlyNotEmptyLines, onlyLinesWithExpectedHours, onlyTotals, displayOnlyInvalid, optsAccordionActiveIdx, pointagesVisible, rawPointagesVisible, commentariesVisible, locationsVisible, allManualCountersVisible} = this.state;
        const displayOpts = optsAccordionActiveIdx === 0;
        ls('settings', JSON.stringify(Object.assign(settings, {
          pointages: Object.assign({}, settings.pointages, {
            filters: value ? ({
              selectedEmployeeFilter,
              selectedTeamFilter,
              selectedDateFilter: {
                from: selectedDateFilter.from !== undefined ? selectedDateFilter.from.unix() : undefined,
                to: selectedDateFilter.to !== undefined ? selectedDateFilter.to.unix() : undefined,
                readable: selectedDateFilter.readable
              },
              selectedPredefinedDateFilter,
              selectedTeamId,
              selectedLocationFilter,
              selectedLocationName,
              groupType
            }) : false,
            opts: value ? ({
              onlyNotEmptyLines,
              onlyLinesWithExpectedHours,
              onlyTotals,
              displayOnlyInvalid,
              displayOpts,
              pointagesVisible,
              rawPointagesVisible,
              commentariesVisible,
              locationsVisible,
              allManualCountersVisible
            }) : false
          })
        })));
      });
    };
  }

  getDayRange() {
    return moment.range(
      moment.unix(this.state.selectedDateFilter.from.unix()),
      this.state.selectedDateFilter.to ?
        moment.unix(this.state.selectedDateFilter.to.unix())
        : moment.unix(this.state.selectedDateFilter.from.unix()).hour(23).minute(59).second(59)
    ).by('day');
  }

  getWeekRange() {
    return moment.range(
      moment.unix(this.state.selectedDateFilter.from.unix()),
      this.state.selectedDateFilter.to ?
        moment.unix(this.state.selectedDateFilter.to.unix())
        : moment.unix(this.state.selectedDateFilter.from.unix()).hour(23).minute(59).second(59)
    ).by('week');
  }

  getGridData(groupType, andThen) {
    //console.log("GRID DATA CALLED " + this.state.summary.length + " "+groupType)
    const onlyKeys = (obj, keys) => {
      return Object.fromEntries(Object.entries(obj).filter(([k, v]) => keys.includes(k)));
    };
    this.setState({
      loadingHTML: true
    }, () => {
      if(groupType === "EMPLOYEE") {
        //console.log("/////////////////////////////// EMP W CREATE")
        const w = new EmployeeDataWorker();
        w.onmessage = (e) => {
          const gridData = e.data;
          //console.log("GOT RESULT " + gridData.length)
          this.setState({gridData, loadingHTML: false}, () => andThen !== undefined ? andThen() : {});
        };
        w.postMessage([
          onlyKeys(this.state, ['selectedLocationFilter', 'selectedLocationName', 'summary', 'onlyNotEmptyLines', 'onlyLinesWithExpectedHours', 'displayOnlyInvalid']),
          Array.from(this.getDayRange()).map(x => x.unix()),
          manualCounterEnabled(),
          secondManualCounterEnabled(),
          thirdManualCounterEnabled(),
          manualCounterFactor(),
          secondManualCounterFactor(),
          thirdManualCounterFactor(),
          hourEndByDay(),
          nightShiftBegin(),
          nightShiftBeginMinute(),
          nightShiftEnd(),
          nightShiftEndMinute(),
          timezone()
        ]);
      } else if(groupType === "LOCATION") {
        //console.log("/////////////////////////////// LOC W CREATE")
        const w = new LocationDataWorker();
        w.onmessage = (e) => {
          const gridData = e.data;
          //console.log("GOT RESULT " + gridData.length)
          this.setState({gridData, loadingHTML: false}, () => andThen !== undefined ? andThen() : {});
        };
        w.postMessage([
          onlyKeys(this.state, ['selectedLocationFilter', 'selectedLocationName', 'summary', 'locations', 'onlyNotEmptyLines', 'onlyLinesWithExpectedHours', 'displayOnlyInvalid']),
          Array.from(this.getDayRange()).map(x => x.unix()),
          manualCounterEnabled(),
          secondManualCounterEnabled(),
          thirdManualCounterEnabled(),
          manualCounterFactor(),
          secondManualCounterFactor(),
          thirdManualCounterFactor(),
          hourEndByDay(),
          nightShiftBegin(),
          nightShiftBeginMinute(),
          nightShiftEnd(),
          nightShiftEndMinute(),
          timezone()
        ]);
      } else {
        // DATE
        //console.log("/////////////////////////////// DATE W CREATE")
        const w = new DateDataWorker();
        w.onmessage = (e) => {
          const gridData = e.data;
          //console.log("GOT RESULT " + gridData.length)
          this.setState({gridData, loadingHTML: false}, () => andThen !== undefined ? andThen() : {});
        };
        w.postMessage([
          onlyKeys(this.state, ['selectedLocationFilter', 'selectedLocationName', 'summary', 'onlyNotEmptyLines', 'onlyLinesWithExpectedHours', 'displayOnlyInvalid']),
          Array.from(this.getDayRange()).map(x => x.unix()),
          manualCounterEnabled(),
          secondManualCounterEnabled(),
          thirdManualCounterEnabled(),
          manualCounterFactor(),
          secondManualCounterFactor(),
          thirdManualCounterFactor(),
          hourEndByDay(),
          nightShiftBegin(),
          nightShiftBeginMinute(),
          nightShiftEnd(),
          nightShiftEndMinute(),
          timezone()
        ]);
      }
    });
  }

  getShowHideIcons(popupPos = 'bottom center') {
    return [
      <Popup key={`showHide_pointages`} size='small' flowing position={popupPos} content={this.state.pointagesVisible ? 'Masquer les pointages' : 'Afficher les pointages'} trigger={
        <Button circular basic icon size='mini' onClick={this.handleTogglePointagesVisibility.bind(this)}>
          <Icon name='clock outline' color={this.state.pointagesVisible ? 'red' : 'green'} />
        </Button>
      } />,
      <Popup key={`showHide_location`} size='small' flowing position={popupPos} content={this.state.locationsVisible ? 'Masquer l\'origine des pointages' : 'Afficher l\'origine des pointages'} trigger={
        <Button circular basic icon size='mini' onClick={this.handleToggleLocationVisibility.bind(this)}>
          <Icon name='location arrow' color={this.state.locationsVisible ? 'red' : 'green'} />
        </Button>
      } />,
      <Popup key={`showHide_rawPointages`} size='small' flowing position={popupPos} content={this.state.rawPointagesVisible ? 'Masquer les pointages bruts' : 'Afficher les pointages bruts'} trigger={
        <Button circular basic icon size='mini' onClick={this.handleToggleRawPointageVisibility.bind(this)}>
          <Icon name={this.state.rawPointagesVisible ? 'eye slash' : 'eye'} color={this.state.rawPointagesVisible ? 'red' : 'green'} />
        </Button>
      } />,
      <Popup key={`showHide_comments`} size='small' flowing position={popupPos} content={this.state.commentariesVisible ? 'Masquer les commentaires' : 'Afficher les commentaires'} trigger={
        <Button circular basic icon size='mini' onClick={this.handleToggleCommentaryVisibility.bind(this)}>
          <Icon name='comment alternate' color={this.state.commentariesVisible ? 'red' : 'green'} />
        </Button>
      } />,
      manualCounterEnabled() || secondManualCounterEnabled() || thirdManualCounterEnabled() || nightShiftCounterEnabled() ?
      <Popup key={`showHide_manualCounters`} size='small' flowing position={popupPos} content={this.state.allManualCountersVisible ? 'Masquer les compteurs supplémentaires' : 'Afficher les compteurs supplémentaires'} trigger={
        <Button circular basic icon size='mini' onClick={this.handleToggleManualCountersVisibility.bind(this)}>
          <Icon name='unordered list' color={this.state.allManualCountersVisible ? 'red' : 'green'} />
        </Button>
      } />
      : null
    ].filter(x => x !== null);
  }

  // To calculate the HTML asynchronously
  computeSummaryHTML() {
    // TODO refactoring
    let pointagesW = 10, rawPointagesW = 0, locationsW = 0, commentaryW = 0;
    if(this.state.rawPointagesVisible === true) {
      if(this.state.locationsVisible === true) {
        if(this.state.commentariesVisible === true) {
          pointagesW = this.state.pointagesVisible ? 3 : 0;
          rawPointagesW = this.state.pointagesVisible ? 3 : 4;
          locationsW = this.state.pointagesVisible ? 3 : 4;
          commentaryW = this.state.pointagesVisible ? 2 : 3;
        } else {
          pointagesW = this.state.pointagesVisible ? 4 : 0;
          rawPointagesW = this.state.pointagesVisible ? 3 : 5;
          locationsW = this.state.pointagesVisible ? 3 : 5;
        }
      } else {
        if(this.state.commentariesVisible === true) {
          pointagesW = this.state.pointagesVisible ? 4 : 0;
          rawPointagesW = this.state.pointagesVisible ? 4 : 6;
          commentaryW = this.state.pointagesVisible ? 3 : 5;
        } else {
          pointagesW = this.state.pointagesVisible ? 5 : 0;
          rawPointagesW = this.state.pointagesVisible ? 5 : 10;
        }
      }
    } else {
      if(this.state.locationsVisible === true) {
        if(this.state.commentariesVisible === true) {
          pointagesW = this.state.pointagesVisible ? 4 : 0;
          locationsW = this.state.pointagesVisible ? 4 : 6;
          commentaryW = this.state.pointagesVisible ? 3 : 5;
        } else {
          pointagesW = this.state.pointagesVisible ? 5 : 0;
          locationsW = this.state.pointagesVisible ? 5 : 10;
        }
      } else {
        if(this.state.commentariesVisible === true) {
          pointagesW = this.state.pointagesVisible ? 5 : 0;
          commentaryW = this.state.pointagesVisible ? 5 : 10;
        }
      }
    }

    const actualNightShiftLabel = "H. de nuit";

    const byDate = this.state.groupType === 'DATE';
    const byLocation = this.state.groupType === 'LOCATION';
    //const byEmployee = byDate === false && byLocation === false;
    if(byDate) {
      const gridData = this.state.gridData;
      
      const validatedPerWeek = gridData.reduce((acc, [formattedDate, dateEntries], idx) => {
        const momentDate = moment(formattedDate, "DD/MM/YYYY");
        const week = momentDate.week();
        const validatedInDateEntries = dateEntries.reduce((accDe, de) => accDe + de.validatedHours, 0.0);
        const validatedCustomInDateEntries = dateEntries.reduce((accDe, de) => accDe + de.validatedHoursCustom, 0.0);
        const validatedManualInDateEntries = dateEntries.reduce((accDe, de) => accDe + (de.manualCounter ? de.manualCounter.hours : 0.0), 0.0);
        const validatedSecondManualInDateEntries = dateEntries.reduce((accDe, de) => accDe + (de.secondManualCounter ? de.secondManualCounter.hours : 0.0), 0.0);
        const validatedThirdManualInDateEntries = dateEntries.reduce((accDe, de) => accDe + (de.thirdManualCounter ? de.thirdManualCounter.hours : 0.0), 0.0);
        const expectedInDateEntries = dateEntries.reduce((accDe, e) => accDe + (getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)) || 0.0), 0.0);
        const balanceInDateEntries = validatedInDateEntries + (manualCounterEnabled() ? manualCounterFactor()*validatedManualInDateEntries : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualInDateEntries : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualInDateEntries : 0.0) - expectedInDateEntries;

        return Object.assign({}, acc, {
          validated: Object.assign({}, acc.validated, {
            [week]: (acc.validated[week] !== undefined && isNaN(acc.validated[week]) === false ? acc.validated[week] + validatedInDateEntries : validatedInDateEntries)
          }),
          validatedCustom: Object.assign({}, acc.validatedCustom, {
            [week]: (acc.validatedCustom[week] !== undefined && isNaN(acc.validatedCustom[week]) === false ? acc.validatedCustom[week] + validatedCustomInDateEntries : validatedCustomInDateEntries)
          }),
          validatedManual: Object.assign({}, acc.validatedManual, {
            [week]: (acc.validatedManual[week] !== undefined && isNaN(acc.validatedManual[week]) === false ? acc.validatedManual[week] + validatedManualInDateEntries : validatedManualInDateEntries)
          }),
          validatedSecondManual: Object.assign({}, acc.validatedSecondManual, {
            [week]: (acc.validatedSecondManual[week] !== undefined && isNaN(acc.validatedSecondManual[week]) === false ? acc.validatedSecondManual[week] + validatedSecondManualInDateEntries : validatedSecondManualInDateEntries)
          }),
          validatedThirdManual: Object.assign({}, acc.validatedThirdManual, {
            [week]: (acc.validatedThirdManual[week] !== undefined && isNaN(acc.validatedThirdManual[week]) === false ? acc.validatedThirdManual[week] + validatedThirdManualInDateEntries : validatedThirdManualInDateEntries)
          }),
          expected: Object.assign({}, acc.expected, {
            [week]: (acc.expected[week] !== undefined && isNaN(acc.expected[week]) === false ? acc.expected[week] + expectedInDateEntries : expectedInDateEntries)
          }),
          balance: Object.assign({}, acc.balance, {
            [week]: (acc.balance[week] !== undefined && isNaN(acc.balance[week]) === false ? acc.balance[week] + balanceInDateEntries : balanceInDateEntries)
          }),
        })
      }, {validated: {}, validatedCustom: {}, validatedManual: {}, validatedSecondManual: {}, validatedThirdManual: {}, expected: {}, balance: {}});


      const allDaysFormatted = gridData.map(([formattedDate, dateEntries]) => formattedDate);

      const gridHTML = gridData.map(([formattedDate, dateEntries], idx) => {
        const filteredDateEntries = dateEntries.map(x => Object.assign({}, x, {momentDate: moment(formattedDate, "DD/MM/YYYY")}));
        
        const validatedToday = filteredDateEntries.map(dateEntry => dateEntry.validatedHours).reduce((acc, x) => acc+x, 0.0);
        const expectedToday = filteredDateEntries.map(dateEntry => dateEntry.nbHoursExpected).reduce((acc,x) => acc+x, 0.0);
        const manualToday = filteredDateEntries.map(dateEntry => dateEntry.manualCounter ? dateEntry.manualCounter.hours : 0.0).reduce((acc,x) => acc+x, 0.0);
        const secondManualToday = filteredDateEntries.map(dateEntry => dateEntry.secondManualCounter ? dateEntry.secondManualCounter.hours : 0.0).reduce((acc,x) => acc+x, 0.0);
        const thirdManualToday = filteredDateEntries.map(dateEntry => dateEntry.thirdManualCounter ? dateEntry.thirdManualCounter.hours : 0.0).reduce((acc,x) => acc+x, 0.0);
        const validatedCustomToday = filteredDateEntries.map(dateEntry => dateEntry.validatedHoursCustom).reduce((acc, x) => acc+x, 0.0);
        const balanceToday = validatedToday + (manualCounterEnabled() ? manualCounterFactor()*manualToday : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*secondManualToday : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*thirdManualToday : 0.0) - expectedToday;

        const momentDate = moment(formattedDate, "DD/MM/YYYY");
        return [
          <Grid.Row key={`grid_row_summary_${idx}`}>
            <Grid.Column key={`grid_colwide_summary_${idx}`}>
              {
                dateEntries.length === 0 ?
                  <Table stackable selectable>
                    <Table.Header>
                      <Table.Row verticalAlign='middle' textAlign='center'>
                        <Table.HeaderCell>Journée</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Pointages</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row verticalAlign='middle' textAlign='center'>
                        <Table.Cell width={1} style={{textTransform: 'capitalize'}}>{`${momentDate.format('dddd')}`}</Table.Cell>
                        <Table.Cell width={1}>{`${momentDate.format('DD/MM/YYYY')}`}</Table.Cell>
                        <Table.Cell>
                          <Label as="span" color='orange' size='large'>
                            Aucun employé actif dans le portail pour cette date
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                :
              <Table fixed={this.state.onlyTotals} stackable striped singleLine selectable color='blue'>
              <Table.Header id="firstHeader">
                <Table.Row verticalAlign='middle'>
                  <Table.HeaderCell colSpan={this.state.onlyTotals === false ? '3' : '2'}>
                      <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row'}}>
                        <h2 style={{textTransform: 'capitalize', marginBottom: '0px', display: 'inline', color: '#6f6f6f'}}>{momentDate.format('dddd DD/MM/YYYY')}</h2>
                        <div style={{padding: '0px', textAlign: 'right', display: 'inline-block', position: 'absolute', right: '0px'}}>
                          {this.state.onlyTotals === false ?
                            this.state.groupMode !== momentDate.unix() && (this.props.isAdmin() || this.props.isManagerWithWriteAccess()) ?
                              <Popup size='small' flowing position='bottom center' content='Lancer le calcul des compteurs' trigger={
                                <Button icon='history' size='mini' color='blue' onClick={this.handleClickOnEditGroup(filteredDateEntries, momentDate.unix()).bind(this)} />
                              } />
                              :
                              <Popup size='small' flowing position='bottom center' content='Enregistrer les compteurs / commentaires' trigger={
                                <Button icon='check' size='mini' color='green' onClick={this.handleClickOnEditGroup(filteredDateEntries, momentDate.unix()).bind(this)} />
                              } />
                          : ''}
                          {this.state.onlyTotals === false && this.state.groupMode === momentDate.unix() ?
                            <Popup size='small' flowing position='bottom center' content='Annuler' trigger={
                              <Button icon='delete' size='mini' color='red' onClick={this.handleClickOnEditGroupCancel().bind(this)} />
                            } />
                          : ''}
                          {this.state.onlyTotals === false && this.state.groupMode === momentDate.unix() && (this.props.isAdmin() || this.props.isManagerWithWriteAccess()) ?
                            <Popup size='small' flowing position='bottom center' content='Remettre les compteurs à zéro' trigger={
                              <Button icon='trash' size='mini' color='orange' onClick={() => this.setState({confirmClearCounters: filteredDateEntries})} />
                            } />
                          : ''}
                          {this.state.onlyTotals === false && this.state.groupMode !== momentDate.unix() ?
                            <Popup style={{padding: '0px', zIndex: 'auto'}} size='mini' position='bottom center' on='click' pinned content={
                              <Button.Group>
                                {this.getShowHideIcons()}
                              </Button.Group>
                            } trigger={
                              <Button size='mini' color='blue' icon='ellipsis vertical' />
                            } />
                          : ''}
                        </div>
                      </div>
                  </Table.HeaderCell>
                  { this.state.pointagesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                  { this.state.locationsVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                  { this.state.rawPointagesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                  { this.state.commentariesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                  {
                    this.state.allManualCountersVisible && manualCounterEnabled() ?
                    <Table.HeaderCell textAlign='center'>
                      <Label as='span' color='grey'>{formatHoursWithMinutes(manualToday)}</Label>
                    </Table.HeaderCell>
                    : <Table.HeaderCell />
                  }
                  {
                    this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                    <Table.HeaderCell textAlign='center'>
                      <Label as='span' color='grey'>{formatHoursWithMinutes(secondManualToday)}</Label>
                    </Table.HeaderCell>
                    : <Table.HeaderCell />
                  }
                  {
                    this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                    <Table.HeaderCell textAlign='center'>
                      <Label as='span' color='grey'>{formatHoursWithMinutes(thirdManualToday)}</Label>
                    </Table.HeaderCell>
                    : <Table.HeaderCell />
                  }
                  {
                    this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                    <Table.HeaderCell textAlign='center'>
                      <Label as='span' color='grey'>{formatHoursWithMinutes(validatedCustomToday)}</Label>
                    </Table.HeaderCell>
                    : null
                  }
                  <Table.HeaderCell textAlign='center'>
                    <Label as='span' color='grey'>{formatHoursWithMinutes(validatedToday)}</Label>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>
                    <Label as='span' color='grey'>{formatHoursWithMinutes(expectedToday)}</Label>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>
                    <Label as='span' color={round(balanceToday, 2) < 0 ? 'orange' : round(balanceToday, 2) === 0 ? 'grey' : 'green'}>{formatHoursWithMinutes(balanceToday)}</Label>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {
                this.state.onlyTotals === false ?
                <Table.Header id="secondHeader">
                  <Table.Row>
                    <Table.HeaderCell>Journée</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Date</Table.HeaderCell>
                    <Table.HeaderCell>Employé</Table.HeaderCell>
                    {
                      this.state.pointagesVisible ?
                      <Table.HeaderCell>Pointages</Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.locationsVisible ?
                      <Table.HeaderCell>Origine</Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.rawPointagesVisible ?
                      <Table.HeaderCell>Pointages bruts</Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.commentariesVisible ?
                      <Table.HeaderCell>Commentaires</Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.allManualCountersVisible && manualCounterEnabled() ?
                      <Table.HeaderCell textAlign='center'>
                        <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{manualCounterName() || 'Cpt. perso 1'} <Icon name='info circle' /></div>
                      </Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                      <Table.HeaderCell textAlign='center'>
                        <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{secondManualCounterName() || 'Cpt. perso 2'} <Icon name='info circle' /></div>
                      </Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                      <Table.HeaderCell textAlign='center'>
                        <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{thirdManualCounterName() || 'Cpt. perso 3'} <Icon name='info circle' /></div>
                      </Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {this.state.allManualCountersVisible && nightShiftCounterEnabled() ? <Table.HeaderCell textAlign='center'>{actualNightShiftLabel}</Table.HeaderCell> : null}
                    <Table.HeaderCell textAlign='center'>Réel</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'><div data-tooltip="Cliquez sur un horaire théorique pour le modifier" data-position="top right">Théorique <Icon name='info circle' /></div></Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Balance</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                : null
              }
              {
                this.state.onlyTotals === false ?
                <Table.Body>
                  {
                    filteredDateEntries.map((dateEntry, deIdx, deArr) => {
                      const lastDateEntry = deIdx === deArr.length-1;
                      const balanceForThisDateEntry = dateEntry.validatedHours
                        + (manualCounterEnabled() ? manualCounterFactor()*(dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0.0) : 0.0)
                        + (secondManualCounterEnabled() ? secondManualCounterFactor()*(dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0.0) : 0.0)
                        + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*(dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0.0) : 0.0)
                        -  dateEntry.nbHoursExpected;

                      const foundManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_1 && x.employee.id === dateEntry.id);
                      const foundSecondManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_2 && x.employee.id === dateEntry.id);
                      const foundThirdManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_3 && x.employee.id === dateEntry.id);

                      const tableRow = (<Table.Row
                        style={(dateEntry.pointages.length % 2 !== 0 ?
                          {
                            background: '#f7ddbb',
                            color: '#9f3a38'
                          }
                          : {}
                        )}
                        key={`ACC_ROW_${dateEntry.id}_${idx}`}
                      >
                        <Table.Cell width={1} style={{textTransform: 'capitalize'}}>{`${momentDate.format('dddd')}`}</Table.Cell>
                        <Table.Cell width={1}>{`${momentDate.format('DD/MM/YYYY')}`}</Table.Cell>
                        <Table.Cell width={1}>{dateEntry.name}</Table.Cell>
                        {
                          this.state.pointagesVisible ?
                          <Table.Cell width={pointagesW-1} className="pointagesCell">
                            {
                            this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                              <Button compact={true} className="addPointageButton" data-tooltip="Ajouter manuellement un pointage" data-position="bottom right" icon onClick={this.onCreatingPointage(momentDate, dateEntry.id).bind(this)} color='green' size='mini' floated='left'>
                                <Icon name='plus' />
                              </Button>
                            : ''
                            }
                          {
                            dateEntry.pointages.length === 0 && (this.state.pointageToAdd === undefined || this.state.pointageToAdd.employeeId !== dateEntry.id) ?
                              <Label as="span" color={dateEntry.nbHoursExpected !== 0 ? 'orange' : 'grey'}>
                                Aucun pointage pour cette journée
                              </Label>
                              :
                              dateEntry.pointages.map((p, idxPt) => (
                                <Label data-tooltip={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? "Supprimer le pointage" : undefined} data-position={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? "bottom right" : undefined} size='medium' as='span' key={`ACC_ROW_${dateEntry.id}_${idx}_${idxPt}`}>
                                  <Icon name='clock' color={p.type === 'E' ? 'green' : 'red'} />
                                  {moment.unix(p.timestamp).format("HH:mm")}
                                  {this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? <Icon name='delete' onClick={this.onDelPointage(p.id).bind(this)} /> : ''}
                                </Label>
                              ))                  
                          }
                              {
                                  this.state.pointageToAdd !== undefined && this.state.pointageToAdd.employeeId === dateEntry.id && moment.unix(this.state.pointageToAdd.timestamp).format("DD/MM/YYYY") === momentDate.format("DD/MM/YYYY") ?
                                    <Input focus size='mini'
                                      style={{width: '55pt'}}
                                      placeholder="Heure"
                                      icon="clock"
                                      iconPosition="left"
                                      autoFocus={true}
                                      onKeyPress={this.manageEnter(this.onAddPointage.bind(this)).bind(this)}
                                      onFocus={this.onFocusOnPointageDateTime.bind(this)}
                                      onClick={this.onFocusOnPointageDateTime.bind(this)}
                                      value={moment.unix(this.state.pointageToAdd.timestamp).format("HH:mm")}
                                      onChange={this.onChangeCreatingPointageDateTime(dateEntry.id).bind(this)}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer le pointage",
                                        'data-position': "bottom right",
                                        onClick: this.onAddPointage.bind(this)
                                      }}
                                    />
                                  : ""
                              }
                          </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.locationsVisible ?
                          <Table.Cell width={locationsW}>
                          {
                            dateEntry.pointages.map((p, idxPt) => {
                              const labelKey = `ACC_ROW_SITE_${dateEntry.id}_${idx}_${idxPt}`;
                              const origin = getOriginForPointage(p, this.props.rpiHardware, this.state.rpiHardwareNames);
                              return (
                                origin.raw === 'WEB' ?
                                <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage en ligne" data-position="bottom left"><Icon name='laptop' style={{marginRight: '0'}} /> PC</Label> :
                                  origin.raw === 'NA' ?
                                    <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage ajouté" data-position="bottom left"><Icon name='edit' style={{marginRight: '0'}} /></Label> :
                                      origin.raw === 'PHONE' ?
                                          (
                                            origin.location !== "" && origin.location !== "Inconnu" ?
                                            <Label key={labelKey} as='span' size='medium'>{origin.location}</Label>
                                            :
                                            <Label key={labelKey} as='span' size='medium' data-tooltip="Retrouvez l'adresse dans le menu 'Déplacements'" data-position="bottom left"><Icon name='street view' style={{marginRight: '0'}} /></Label>
                                          ) :
                                          origin.raw === 'NIGHTSHIFT' ?
                                          <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage auto. coupure journée" data-position="bottom left"><Icon name='cut' style={{marginRight: '0'}} /></Label> :
                                            origin.raw !== 'UNKNOWN' ?
                                              <Label key={labelKey} as='span' size='medium'>{origin.display}</Label> :
                                              <Label key={labelKey} as='span' size='medium' data-tooltip="Origine inconnue" data-position="bottom left"><Icon name='question circle outline' style={{marginRight: '0'}} /></Label>
                              );
                            })
                          }
                          </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.rawPointagesVisible ? 
                          <Table.Cell width={rawPointagesW}>
                          {
                            dateEntry.rawPointages.map((p, idxPt) => (
                              <Label size='medium' as='span' key={`ACC_ROW_RAW_${dateEntry.id}_${idx}_${idxPt}`}>
                                <Icon name='clock' color={p.type === 'E' ? 'green' : 'red'} />
                                {moment.unix(p.timestamp).format("HH:mm")}
                              </Label>
                            ))
                          }
                          </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.commentariesVisible ?
                            <Table.Cell width={commentaryW} textAlign='center'>
                              {
                                (() => {
                                  const commentary = this.state.commentariesToSet.find(x => x.employeeId === dateEntry.id && x.day === momentDate.format("DD/MM/YYYY"));
                                  return dateEntry.commentary !== undefined || (this.state.commentariesToSet.length > 0 && commentary !== undefined) ?
                                    //this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                                    <Input size='mini'
                                      style={{width: '100%'}}
                                      placeholder="Commentaires"
                                      icon="comment alternate"
                                      iconPosition="left"
                                      autoFocus={false}
                                      onKeyPress={this.manageEnter(this.onSetCommentary(dateEntry.id, momentDate).bind(this)).bind(this)}
                                      value={commentary !== undefined && commentary.day === momentDate.format("DD/MM/YYYY") && commentary.employeeId === dateEntry.id ? commentary.value : dateEntry.commentary.value}
                                      onChange={this.onChangeCommentaryValue(dateEntry.id, momentDate).bind(this)}
                                      onFocus={dateEntry.commentary !== undefined ? this.onSettingCommentary(dateEntry.commentary.id, momentDate, dateEntry.id, dateEntry.commentary.value).bind(this) : undefined}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer les commentaires",
                                        'data-position': "bottom right",
                                        disabled: this.state.groupMode !== false || !(commentary !== undefined && commentary.day === momentDate.format("DD/MM/YYYY") && commentary.employeeId === dateEntry.id),
                                        onClick: this.onSetCommentary(dateEntry.id, momentDate).bind(this)
                                      }}
                                    />
                                    //: dateEntry.commentary.value
                                  : 
                                    //this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                                    <Button compact={true} className="addCommentaryButton" data-tooltip="Ajouter des commentaires" data-position="bottom right" icon onClick={this.onSettingCommentary(undefined, momentDate, dateEntry.id, '').bind(this)} color='green' size='mini' floated='left'>
                                      <Icon name='plus' />
                                    </Button>
                                    //: ''
                                  ;
                                })()
                              }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && manualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              {
                                (() => {
                                  return this.state.manualCounterHours.length > 0 && foundManual !== undefined ?
                                  <Input focus size='mini'
                                      style={{width: '40pt'}}
                                      placeholder="Heures"
                                      onChange={this.onChangeOnManualCounter(dateEntry.id, momentDate.unix(), MANUAL_COUNTER_1).bind(this)}
                                      onKeyPress={this.manageEnter(this.onSetManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_1).bind(this)).bind(this)}
                                      /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                      onBlur={this.onBlurOnManualCounter.bind(this)}
                                      onClick={this.onFocusOnManualCounter.bind(this)}*/
                                      autoFocus={this.state.manualCounterHours.length === 1}
                                      value={formatHoursWithMinutes(
                                        foundManual.hours
                                      )}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer",
                                        'data-position': "bottom right",
                                        onClick: this.onSetManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_1).bind(this),
                                        disabled: this.state.groupMode !== false
                                      }}
                                    />
                                  : 
                                    (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                    <Label as='a' color={dateEntry.manualCounter !== undefined ? 'black' : 'grey'} data-tooltip={dateEntry.manualCounter !== undefined ? `Cliquez pour remettre le compteur "${manualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${manualCounterName()}"`} data-position="bottom right">
                                      <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_1, dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : formulas.manualCounter({hours: dateEntry.validatedHours, expected: dateEntry.nbHoursExpected, manual: dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0.0, secondManual: dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0.0, thirdManual: dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0.0, night: dateEntry.validatedHoursCustom})).bind(this)} >{formatHoursWithMinutes(
                                        dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0
                                      )}</span>
                                      {dateEntry.manualCounter !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_1).bind(this)} /> : ""}
                                    </Label>
                                    :
                                    <Label as='div' color={dateEntry.manualCounter !== undefined ? 'black' : 'grey'}>
                                      <span>{formatHoursWithMinutes(
                                        dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0
                                      )}</span>
                                    </Label>
                                  ;
                                })()
                                }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              {
                                (() => {
                                  return this.state.manualCounterHours.length > 0 && foundSecondManual !== undefined ?
                                  <Input focus size='mini'
                                      style={{width: '40pt'}}
                                      placeholder="Heures"
                                      onChange={this.onChangeOnManualCounter(dateEntry.id, momentDate.unix(), MANUAL_COUNTER_2).bind(this)}
                                      onKeyPress={this.manageEnter(this.onSetManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_2).bind(this)).bind(this)}
                                      /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                      onBlur={this.onBlurOnManualCounter.bind(this)}
                                      onClick={this.onFocusOnManualCounter.bind(this)}*/
                                      autoFocus={this.state.manualCounterHours.length === 1}
                                      value={formatHoursWithMinutes(
                                        foundSecondManual.hours
                                      )}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer",
                                        'data-position': "bottom right",
                                        onClick: this.onSetManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_2).bind(this),
                                        disabled: this.state.groupMode !== false
                                      }}
                                    />
                                  : 
                                    (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                    <Label as='a' color={dateEntry.secondManualCounter !== undefined ? 'black' : 'grey'} data-tooltip={dateEntry.secondManualCounter !== undefined ? `Cliquez pour remettre le compteur "${secondManualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${secondManualCounterName()}"`} data-position="bottom right">
                                      <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_2, dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : formulas.secondManualCounter({hours: dateEntry.validatedHours, expected: dateEntry.nbHoursExpected, manual: dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0, secondManual: dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0, thirdManual: dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0, night: dateEntry.validatedHoursCustom})).bind(this)} >{formatHoursWithMinutes(
                                        dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0
                                      )}</span>
                                      {dateEntry.secondManualCounter !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_2).bind(this)} /> : ""}
                                    </Label>
                                    :
                                    <Label as='div' color={dateEntry.secondManualCounter !== undefined ? 'black' : 'grey'}>
                                      <span>{formatHoursWithMinutes(
                                        dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0
                                      )}</span>
                                    </Label>
                                  ;
                                })()
                                }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              {
                                (() => {
                                  return this.state.manualCounterHours.length > 0 && foundThirdManual !== undefined ?
                                  <Input focus size='mini'
                                      style={{width: '40pt'}}
                                      placeholder="Heures"
                                      onChange={this.onChangeOnManualCounter(dateEntry.id, momentDate.unix(), MANUAL_COUNTER_3).bind(this)}
                                      onKeyPress={this.manageEnter(this.onSetManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_3).bind(this)).bind(this)}
                                      /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                      onBlur={this.onBlurOnManualCounter.bind(this)}
                                      onClick={this.onFocusOnManualCounter.bind(this)}*/
                                      autoFocus={this.state.manualCounterHours.length === 1}
                                      value={formatHoursWithMinutes(
                                        foundThirdManual.hours
                                      )}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer",
                                        'data-position': "bottom right",
                                        onClick: this.onSetManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_3).bind(this),
                                        disabled: this.state.groupMode !== false
                                      }}
                                    />
                                  : 
                                    (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                    <Label as='a' color={dateEntry.thirdManualCounter !== undefined ? 'black' : 'grey'} data-tooltip={dateEntry.thirdManualCounter !== undefined ? `Cliquez pour remettre le compteur "${thirdManualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${thirdManualCounterName()}"`} data-position="bottom right">
                                      <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_3, dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : formulas.thirdManualCounter({hours: dateEntry.validatedHours, expected: dateEntry.nbHoursExpected, manual: dateEntry.manualCounter !== undefined ? dateEntry.manualCounter.hours : 0, secondManual: dateEntry.secondManualCounter !== undefined ? dateEntry.secondManualCounter.hours : 0, thirdManual: dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0, night: dateEntry.validatedHoursCustom})).bind(this)} >{formatHoursWithMinutes(
                                        dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0
                                      )}</span>
                                      {dateEntry.thirdManualCounter !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(dateEntry, momentDate.unix(), MANUAL_COUNTER_3).bind(this)} /> : ""}
                                    </Label>
                                    :
                                    <Label as='div' color={dateEntry.thirdManualCounter !== undefined ? 'black' : 'grey'}>
                                      <span>{formatHoursWithMinutes(
                                        dateEntry.thirdManualCounter !== undefined ? dateEntry.thirdManualCounter.hours : 0
                                      )}</span>
                                    </Label>
                                  ;
                                })()
                                }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                          <Table.Cell textAlign='center'>
                            <Label as='span' color='grey'>{
                              formatHoursWithMinutes(
                                dateEntry.validatedHoursCustom
                              )
                            }</Label>
                          </Table.Cell>
                          : null
                        }
                        <Table.Cell width={1} textAlign='center'>
                          <Label as='span' color='grey'>{formatHoursWithMinutes(dateEntry.validatedHours)}</Label>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                          {
                            (() => {
                              const expected = this.state.changingExpectedHours.find(x => x.day === momentDate.format('DD/MM/YYYY') && x.employee.id === dateEntry.id);
                              return this.state.changingExpectedHours.length > 0 && expected !== undefined ?
                              <Input focus size='mini'
                                  style={{width: '40pt'}}
                                  placeholder="Heures"
                                  onChange={this.onChangeOnExpected(dateEntry, momentDate).bind(this)}
                                  onKeyPress={this.manageEnter(this.onSetExpected(dateEntry, momentDate).bind(this)).bind(this)}
                                  onFocus={this.onFocusOnExpected.bind(this)}
                                  onBlur={this.onBlurOnExpected.bind(this)}
                                  onClick={this.onFocusOnExpected.bind(this)}
                                  autoFocus={this.state.changingExpectedHours.length === 1}
                                  value={formatHoursWithMinutes(
                                    expected.hours
                                  )}
                                  action={{
                                    color: 'green',
                                    icon: 'check',
                                    size: 'mini',
                                    'data-tooltip': "Enregistrer",
                                    'data-position': "bottom right",
                                    onClick: this.onSetExpected(dateEntry, momentDate).bind(this),
                                    disabled: this.state.groupMode !== false
                                  }}
                                />
                              : 
                                (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                <Label as='a' color={checkIfSpecificRuleForToday(dateEntry, momentDate.unix()) ? 'black' : 'grey'} data-tooltip={checkIfSpecificRuleForToday(dateEntry, momentDate.unix()) ? "Cliquez pour revenir à l'horaire par défaut" : "Cliquez pour modifier le théorique"} data-position="bottom right">
                                  <span onFocus={this.onFocusOnExpected.bind(this)} onClick={this.onClickOnExpected(dateEntry, momentDate.unix()).bind(this)} >{formatHoursWithMinutes(
                                    dateEntry.nbHoursExpected
                                  )}</span>
                                  {checkIfSpecificRuleForToday(dateEntry, momentDate.unix()) ? <Icon name='delete' onClick={this.onDelExpected(dateEntry, momentDate.unix()).bind(this)} /> : ""}
                                </Label>
                                :
                                <Label as='div' color={checkIfSpecificRuleForToday(dateEntry, momentDate.unix()) ? 'black' : 'grey'}>
                                  <span>{formatHoursWithMinutes(
                                    dateEntry.nbHoursExpected
                                  )}</span>
                                </Label>
                              ;
                            })()
                          }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                          <Label 
                            as='span' 
                            color={
                              round(balanceForThisDateEntry, 2) < 0 ? 'orange'
                              : 
                              round(balanceForThisDateEntry, 2) === 0 ? 'grey'
                              : 'green'
                              }
                            >
                            {formatHoursWithMinutes(balanceForThisDateEntry)}
                          </Label>
                        </Table.Cell>
                      </Table.Row>)
                        // Special case : week counter line
                        const lastMonday = moment.unix(momentDate.unix()).subtract(6, 'd').format("DD/MM/YYYY");
                        if(lastDateEntry && momentDate.week() === moment.unix(momentDate.unix()).add(1, 'd').week()-1 && allDaysFormatted.includes(lastMonday)) {
                          return [tableRow, <Table.Row
                            style={{height: '60px', verticalAlign: 'bottom'}}
                            key={`WEEK_ROW_${momentDate.week()}`}
                          >
                            <Table.Cell width={1} style={{fontWeight: 'bold', textTransform: 'capitalize'}}>Semaine {momentDate.week()}</Table.Cell>
                            <Table.Cell width={1}></Table.Cell>
                            <Table.Cell width={1}></Table.Cell>
                            { this.state.pointagesVisible ? <Table.Cell width={pointagesW}></Table.Cell> : <Table.Cell />}
                            { this.state.locationsVisible ? <Table.Cell width={locationsW}></Table.Cell> : <Table.Cell /> }
                            { this.state.rawPointagesVisible ? <Table.Cell width={rawPointagesW}></Table.Cell> : <Table.Cell /> }
                            { this.state.commentariesVisible ? <Table.Cell width={1}></Table.Cell> : <Table.Cell /> }
                            {
                              this.state.allManualCountersVisible && manualCounterEnabled() ?
                              <Table.Cell textAlign='center' width={1}>
                                <Label as='span' color='grey'>{formatHoursWithMinutes(validatedPerWeek.validatedManual[momentDate.week()] || 0)}</Label>
                              </Table.Cell>
                              : <Table.Cell />
                            }
                            {
                              this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                              <Table.Cell textAlign='center' width={1}>
                                <Label as='span' color='grey'>{formatHoursWithMinutes(validatedPerWeek.validatedSecondManual[momentDate.week()] || 0)}</Label>
                              </Table.Cell>
                              : <Table.Cell />
                            }
                            {
                              this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                              <Table.Cell textAlign='center' width={1}>
                                <Label as='span' color='grey'>{formatHoursWithMinutes(validatedPerWeek.validatedThirdManual[momentDate.week()] || 0)}</Label>
                              </Table.Cell>
                              : <Table.Cell />
                            }
                            {
                              this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                              <Table.Cell width={1} textAlign='center'>
                                <Label as='span' color='grey'>{formatHoursWithMinutes(validatedPerWeek.validatedCustom[momentDate.week()] || 0)}</Label>
                              </Table.Cell>
                              : null
                            }
                            <Table.Cell width={1} textAlign='center'>
                              <Label as='span' color='grey'>{formatHoursWithMinutes(validatedPerWeek.validated[momentDate.week()] || 0)}</Label>
                            </Table.Cell>
                            <Table.Cell width={1} textAlign='center'>
                              <Label as='span' color='grey'>{formatHoursWithMinutes(validatedPerWeek.expected[momentDate.week()] || 0)}</Label>
                            </Table.Cell>
                            <Table.Cell width={1} textAlign='center'>
                              <Label as='span' color='grey'>{formatHoursWithMinutes(validatedPerWeek.balance[momentDate.week()] || 0)}</Label>
                            </Table.Cell>
                          </Table.Row>]
                        } else {
                          return tableRow
                        }
                    }).flat()
                  }
                </Table.Body>
                : null
              }
            </Table>
            }
          </Grid.Column>
        </Grid.Row>,
        <Grid.Row key={`separator_${idx}`} />
        ]
      });

      const validatedOnWholePeriod = gridData.map(([formattedDate, dateEntries], idx) => dateEntries.map(dateEntry => dateEntry.validatedHours)).flat().reduce((acc,x) => acc+x, 0.0);
      const expectedOnWholePeriod = gridData.map(([formattedDate, dateEntries], idx) => dateEntries.map(dateEntry => dateEntry.nbHoursExpected)).flat().reduce((acc,x) => acc+x, 0.0);
      const manualOnWholePeriod = gridData.map(([formattedDate, dateEntries], idx) => dateEntries.map(dateEntry => dateEntry.manualCounter ? dateEntry.manualCounter.hours : 0.0)).flat().reduce((acc,x) => acc+x, 0.0);
      const secondManualOnWholePeriod = gridData.map(([formattedDate, dateEntries], idx) => dateEntries.map(dateEntry => dateEntry.secondManualCounter ? dateEntry.secondManualCounter.hours : 0.0)).flat().reduce((acc,x) => acc+x, 0.0);
      const thirdManualOnWholePeriod = gridData.map(([formattedDate, dateEntries], idx) => dateEntries.map(dateEntry => dateEntry.thirdManualCounter ? dateEntry.thirdManualCounter.hours : 0.0)).flat().reduce((acc,x) => acc+x, 0.0);
      const validatedCustomOnWholePeriod = gridData.map(([formattedDate, dateEntries], idx) => dateEntries.map(dateEntry => dateEntry.validatedHoursCustom)).flat().reduce((acc,x) => acc+x, 0.0);
      const balanceOnWholePeriod = validatedOnWholePeriod + (manualCounterEnabled() ? manualCounterFactor()*manualOnWholePeriod : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*secondManualOnWholePeriod : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*thirdManualOnWholePeriod : 0.0) - expectedOnWholePeriod;

      const html = (
      <Grid padded stackable container={true} fluid="true" id="manageResultGrid">
        <Grid.Row>
          <Grid.Column width={this.state.allManualCountersVisible ? 2 : 4} verticalAlign='middle'>
            <Grid.Row>
              <Label size='big' color='blue' style={{textTransform: 'uppercase'}}>Total sur la période</Label>
            </Grid.Row>
          </Grid.Column>
          {
              this.state.allManualCountersVisible ?
                (!manualCounterEnabled() || !secondManualCounterEnabled() || !thirdManualCounterEnabled() || !nightShiftCounterEnabled() ?
                  <Grid.Column verticalAlign='middle' width={(manualCounterEnabled() ? 0 : 2) + (secondManualCounterEnabled() ? 0 : 2) + (thirdManualCounterEnabled() ? 0 : 2) + (nightShiftCounterEnabled() ? 0 : 2)}></Grid.Column>
                  :
                  ''
                )
                :
                <Grid.Column verticalAlign='middle' textAlign='center' width={6}></Grid.Column>
          }
          {
            this.state.allManualCountersVisible && manualCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{manualCounterName() || 'Cpt. perso 1'}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(manualOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          {
            this.state.allManualCountersVisible && secondManualCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{secondManualCounterName() || 'Cpt. perso 2'}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(secondManualOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          {
            this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{thirdManualCounterName() || 'Cpt. perso 3'}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(thirdManualOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          {
            this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{actualNightShiftLabel}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(validatedCustomOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Grid.Row>
              <Label size='big' as='span'>Réel</Label>
            </Grid.Row>
            <Grid.Row>
              <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(validatedOnWholePeriod)}</Label>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Grid.Row>
              <Label size='big' as='span'>Théorique</Label>
            </Grid.Row>
            <Grid.Row>
              <Label size='big' color='grey' as='span'>{
                formatHoursWithMinutes(expectedOnWholePeriod)
              }</Label>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Grid.Row>
              <Label size='big' as='span'>Balance</Label>
            </Grid.Row>
            <Grid.Row>
              <Label size='big' as='span' color={round(balanceOnWholePeriod, 2) < 0 ? 'orange' : round(balanceOnWholePeriod, 2) === 0 ? 'grey' : 'green'}>{
                formatHoursWithMinutes(balanceOnWholePeriod)
              }</Label>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row></Grid.Row>
        {
          gridHTML.length > 0 ? gridHTML :
            <Grid.Row>
              <Grid.Column>
                {
                  this.state.displayOnlyInvalid ?
                  <Label as="span" color='green'>
                    Pas d'anomalie sur la période
                  </Label>
                  :
                  <Label as="span" color='red'>
                    Aucune donnée sur la période sélectionnée
                  </Label>
                }
              </Grid.Column>
            </Grid.Row>
        }
      </Grid>
      );

      return html;
    } else if(byLocation) {
      const gridData = this.state.gridData;
      
      const allDays = Array.from(this.getDayRange());
      const allDaysFormatted = allDays.map(x => x.format("DD/MM/YYYY"));
      
      const manualOnWholePeriod = gridData.map(([location, {validatedManualOnPeriod}]) => validatedManualOnPeriod).reduce((acc,x) => acc+x, 0.0);
      const secondManualOnWholePeriod = gridData.map(([location, {validatedSecondManualOnPeriod}]) => validatedSecondManualOnPeriod).reduce((acc,x) => acc+x, 0.0);
      const thirdManualOnWholePeriod = gridData.map(([location, {validatedThirdManualOnPeriod}]) => validatedThirdManualOnPeriod).reduce((acc,x) => acc+x, 0.0);
      const validatedCustomOnWholePeriod = gridData.map(([location, {customValidatedOnPeriod}]) => customValidatedOnPeriod).reduce((acc,x) => acc+x, 0.0);
      const validatedOnWholePeriod = gridData.map(([location, {validatedOnPeriod}]) => validatedOnPeriod).reduce((acc,x) => acc+x, 0.0);
      // TODO Expected does not depend on location...
      //const expectedOnWholePeriod = gridData.map(([location, {expectedHoursOnPeriod}]) => expectedHoursOnPeriod).reduce((acc,x) => acc+x, 0.0);
      const expectedOnWholePeriod = allDays.reduce((acc, day) => {
        return acc + this.state.summary.reduce((accS, e) => accS + getNbHoursExpectedFromProfile(e, day.unix(), this.props.hourEndByDay(e)), 0.0);
      }, 0.0);
      const balanceOnWholePeriod = validatedOnWholePeriod + (manualCounterEnabled() ? manualCounterFactor()*manualOnWholePeriod : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*secondManualOnWholePeriod : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*thirdManualOnWholePeriod : 0.0) - expectedOnWholePeriod;
      const gridHTML = gridData.map(([location, {l, pointagesForThisLocation, utils, rawUtils, validated, validatedAllLocations, validatedOnPeriod, validatedManualOnPeriod, validatedSecondManualOnPeriod, validatedThirdManualOnPeriod, customValidatedOnPeriod, expectedHoursOnPeriod, balanceOnPeriod}], idx) => {
        const weeks = Array.from(this.getWeekRange()).map((x) => `${x.weekYear()}_${x.week()}`);

        const defaultPerWeek = () => weeks.reduce((a, k) => Object.assign(a, {[k]: 0.0}), {});
        const calculatePerWeekFromValidated = (keyToAdd, customValidated) => {
          return weeks.reduce((a, w) => {
            return Object.assign(a, {
              [w]: a[w] + customValidated.map(x => x[keyToAdd]).reduce((acc,pw) => acc+(pw[w] !== undefined ? pw[w] : 0.0), 0.0)
            })
          }, defaultPerWeek());
        };
        const validatedPerWeek = calculatePerWeekFromValidated('perWeek', validated);
        const validatedCustomPerWeek = calculatePerWeekFromValidated('perWeekCustom', validated);
        const validatedManualPerWeek = calculatePerWeekFromValidated('perWeekManual', validatedAllLocations);
        const validatedSecondManualPerWeek = calculatePerWeekFromValidated('perWeekSecondManual', validatedAllLocations);
        const validatedThirdManualPerWeek = calculatePerWeekFromValidated('perWeekThirdManual', validatedAllLocations);
        // TODO Expected does not depend on location...
        const summaryByWeek = pointagesForThisLocation.map(e => e.allDaysFormattedForThisEmp.map(x => moment(x, "DD/MM/YYYY")).reduce((acc, day) => {
          return Object.assign(acc, {
            [`${day.weekYear()}_${day.week()}`]: acc[`${day.weekYear()}_${day.week()}`] + getNbHoursExpectedFromProfile(e, day.unix(), this.props.hourEndByDay(e))
          });
        }, defaultPerWeek()));
        const expectedPerWeek = weeks.reduce((a, w) => {
          return Object.assign(a, {
            [w]: a[w] + summaryByWeek.reduce((acc, x) => acc + x[w], 0.0)
          });
        }, defaultPerWeek());
        const balancePerWeek = weeks.reduce((a, w) => {
          return Object.assign(a, {
            [w]: validatedPerWeek[w] + (manualCounterEnabled() ? manualCounterFactor()*validatedManualPerWeek[w] : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualPerWeek[w] : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualPerWeek[w] : 0.0) - expectedPerWeek[w]
          });
        }, {});

        if(
          this.state.onlyNotEmptyLines &&
            pointagesForThisLocation.reduce((acc, e) => acc+e.pointages.filter(p => e.allDaysFormattedForThisEmp.includes(moment.unix(p.timestamp).format("DD/MM/YYYY"))).length, 0) === 0
          || this.state.displayOnlyInvalid && pointagesForThisLocation.filter((e) => e.pointages.length % 2 !== 0).length === 0
          ) {
          return [];
        } else {
          return [
              <Grid.Row key={`grid_row_summary2_${idx}`}>
                <Grid.Column key={`grid_colwide_summary_${idx}`}>
                  {
                  <Table fixed={this.state.onlyTotals} stackable striped singleLine selectable color='blue'>
                    <Table.Header id="firstHeader">
                    <Table.Row verticalAlign='middle'>
                      <Table.HeaderCell colSpan={this.state.onlyTotals === false ? '3' : '3'}>
                        <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row'}}>
                          <h2 style={{textTransform: 'capitalize', marginBottom: '0px', display: 'inline', color: '#6f6f6f'}}>{l}&nbsp;</h2>
                          <div style={{padding: '0px', textAlign: 'right', display: 'inline-block', position: 'absolute', right: '0px'}}>
                            <Popup style={{padding: '0px', zIndex: 'auto'}} size='mini' position='bottom center' on='click' pinned content={
                              <Button.Group>
                                {this.getShowHideIcons()}
                              </Button.Group>
                            } trigger={
                              <Button size='mini' color='blue' icon='ellipsis vertical' />
                            } />
                          </div>
                        </div>
                      </Table.HeaderCell>
                      { this.state.pointagesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                      { this.state.locationsVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                      { this.state.rawPointagesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                      { this.state.commentariesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                      {
                        this.state.allManualCountersVisible && manualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <Label as='span' color='grey'>{formatHoursWithMinutes(validatedManualOnPeriod)}</Label>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <Label as='span' color='grey'>{formatHoursWithMinutes(validatedSecondManualOnPeriod)}</Label>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <Label as='span' color='grey'>{formatHoursWithMinutes(validatedThirdManualOnPeriod)}</Label>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <Label as='span' color='grey'>{formatHoursWithMinutes(customValidatedOnPeriod)}</Label>
                        </Table.HeaderCell>
                        : null
                      }
                      <Table.HeaderCell textAlign='center'>
                        <Label as='span' color='grey'>{formatHoursWithMinutes(validatedOnPeriod)}</Label>
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>
                        <Label as='span' color='grey'>{formatHoursWithMinutes(expectedHoursOnPeriod)}</Label>
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>
                        <Label as='span' color={round(balanceOnPeriod, 2) < 0 ? 'orange' : round(balanceOnPeriod, 2) === 0 ? 'grey' : 'green'}>{formatHoursWithMinutes(balanceOnPeriod)}</Label>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {
                    this.state.onlyTotals === false ? 
                    <Table.Header id="secondHeader">
                      <Table.Row>
                        <Table.HeaderCell>Journée</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Date</Table.HeaderCell>
                        <Table.HeaderCell>Employé</Table.HeaderCell>
                      {
                        this.state.pointagesVisible ?
                        <Table.HeaderCell>Pointages</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.locationsVisible ?
                        <Table.HeaderCell>Origine</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.rawPointagesVisible ?
                        <Table.HeaderCell>Pointages Bruts</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.commentariesVisible ?
                        <Table.HeaderCell>Commentaires</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && manualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{manualCounterName() || 'Cpt. perso 1'} <Icon name='info circle' /></div>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{secondManualCounterName() || 'Cpt. perso 2'} <Icon name='info circle' /></div>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{thirdManualCounterName() || 'Cpt. perso 3'} <Icon name='info circle' /></div>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      { this.state.allManualCountersVisible && nightShiftCounterEnabled() ? <Table.HeaderCell textAlign='center'>{actualNightShiftLabel}</Table.HeaderCell> : null}
                      <Table.HeaderCell textAlign='center'>Réel</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'><div data-tooltip="Cliquez sur un horaire théorique pour le modifier" data-position="top right">Théorique <Icon name='info circle' /></div></Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Balance</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    : null
                  }
                  {
                    this.state.onlyTotals === false ? 
                    <Table.Body>
                      {
                        allDaysFormatted.map((day, idx) => {
                          return pointagesForThisLocation.map((e, eIdx) => {
                            const isLastEmployee = eIdx === pointagesForThisLocation.length-1;
                            const momentDate = moment(day, "DD/MM/YYYY");
                            const commentaryForTheDay = e.commentaries.find(c => c.day === momentDate.format("DD/MM/YYYY"));

                            const pointagesToday = utils[eIdx].pointages.filter(p => getFakeDay(p, this.props.hourEndByDay(e)).format("DD/MM/YYYY") === day);
                            const validatedToday = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(pointagesToday || [], [day]).validatedHours();
                            const rawPointagesToday = rawUtils[eIdx].pointages.filter(p => getFakeDay(p, this.props.hourEndByDay(e)).format("DD/MM/YYYY") === day);

                            const week = momentDate.week();
                            const weekIdx = `${momentDate.weekYear()}_${week}`;

                            const lastMonday = moment.unix(momentDate.unix()).subtract(6, 'd').format("DD/MM/YYYY");

                            const manualToday = e.manualCounters.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_1);
                            const secondManualToday = e.manualCounters.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_2);
                            const thirdManualToday = e.manualCounters.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_3);

                            const foundManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_1 && x.employee.id === e.id);
                            const foundSecondManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_2 && x.employee.id === e.id);
                            const foundThirdManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_3 && x.employee.id === e.id);

                            const balanceToday = validatedToday.amount
                              + (manualCounterEnabled() ? manualCounterFactor()*(manualToday !== undefined ? manualToday.hours : 0.0) : 0.0)
                              + (secondManualCounterEnabled() ? secondManualCounterFactor()*(secondManualToday !== undefined ? secondManualToday.hours : 0.0) : 0.0)
                              + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*(thirdManualToday !== undefined ? thirdManualToday.hours : 0.0) : 0.0)
                              - getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e));

                            const emptyRow = (key, h) => (<Table.Row style={{height: h || '5px'}}
                            key={`WEEK_ROW_EMPTY_${key}_${weekIdx}`}>
                              <Table.Cell width={1}></Table.Cell>
                              <Table.Cell width={1}></Table.Cell>
                              <Table.Cell width={1}></Table.Cell>
                              { this.state.pointagesVisible ? <Table.Cell width={pointagesW}></Table.Cell> : <Table.Cell />}
                              { this.state.locationsVisible ? <Table.Cell width={locationsW}></Table.Cell> : <Table.Cell /> }
                              { this.state.rawPointagesVisible ? <Table.Cell width={rawPointagesW}></Table.Cell> : <Table.Cell /> }
                              { this.state.commentariesVisible ? <Table.Cell width={commentaryW}></Table.Cell> : <Table.Cell /> }
                              { this.state.allManualCountersVisible && manualCounterEnabled() ? <Table.Cell width={1}></Table.Cell> : <Table.Cell /> }
                              { this.state.allManualCountersVisible && secondManualCounterEnabled() ? <Table.Cell width={1}></Table.Cell> : <Table.Cell /> }
                              { this.state.allManualCountersVisible && thirdManualCounterEnabled() ? <Table.Cell width={1}></Table.Cell> : <Table.Cell /> }
                              { this.state.allManualCountersVisible && nightShiftCounterEnabled() ? <Table.Cell width={1} textAlign='center'></Table.Cell> : null}
                              <Table.Cell width={1} textAlign='center'></Table.Cell>
                              <Table.Cell width={1} textAlign='center'></Table.Cell>
                              <Table.Cell width={1} textAlign='center'></Table.Cell>
                            </Table.Row>);
                            const isLastLine = isLastEmployee && (moment.unix(momentDate.unix()).add(1, 'd').week() !== week);
                            const generateEmptyLineAfter = (isLastLine && !e.allDaysFormattedForThisEmp.includes(lastMonday));
                            const weekRow = isLastLine && e.allDaysFormattedForThisEmp.includes(lastMonday) ? (
                              <Table.Row
                                style={{height: '60px', verticalAlign: 'bottom'}}
                                key={`WEEK_ROW_${e.id}_${idx}`}
                              >
                                <Table.Cell width={1} style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{`Semaine ${week}`}</Table.Cell>
                                <Table.Cell width={1}></Table.Cell>
                                <Table.Cell width={1}></Table.Cell>
                                { this.state.pointagesVisible ? <Table.Cell width={pointagesW}></Table.Cell> : <Table.Cell />}
                                { this.state.locationsVisible ? <Table.Cell width={locationsW}></Table.Cell> : <Table.Cell /> }
                                { this.state.rawPointagesVisible ? <Table.Cell width={rawPointagesW}></Table.Cell> : <Table.Cell /> }
                                { this.state.commentariesVisible ? <Table.Cell width={commentaryW}></Table.Cell> : <Table.Cell /> }
                                { this.state.allManualCountersVisible && manualCounterEnabled() ?
                                  <Table.Cell textAlign='center' width={1}>
                                    <Label as='span' color='grey'>{formatHoursWithMinutes(validatedManualPerWeek[weekIdx])}</Label>
                                  </Table.Cell>
                                : <Table.Cell /> }
                                { this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                                  <Table.Cell textAlign='center' width={1}>
                                    <Label as='span' color='grey'>{formatHoursWithMinutes(validatedSecondManualPerWeek[weekIdx])}</Label>
                                  </Table.Cell>
                                : <Table.Cell /> }
                                { this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                                  <Table.Cell textAlign='center' width={1}>
                                    <Label as='span' color='grey'>{formatHoursWithMinutes(validatedThirdManualPerWeek[weekIdx])}</Label>
                                  </Table.Cell>
                                : <Table.Cell /> }
                                {this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                                  <Table.Cell width={1} textAlign='center'>
                                    <Label as='span' color='grey'>{formatHoursWithMinutes(validatedCustomPerWeek[weekIdx])}</Label>
                                  </Table.Cell>
                                : null}
                                <Table.Cell width={1} textAlign='center'>
                                  <Label as='span' color='grey'>{
                                    formatHoursWithMinutes(
                                      validatedPerWeek[weekIdx]
                                    )
                                  }</Label>
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                  <Label as='span' color='grey'>{
                                    formatHoursWithMinutes(
                                      expectedPerWeek[weekIdx]
                                    )
                                  }</Label>
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                  <Label 
                                    as='span'
                                    color={formatHoursWithMinutes(round(balancePerWeek[weekIdx]), 2) < 0 ? 'orange' : formatHoursWithMinutes(round(balancePerWeek[weekIdx]), 2) === 0 ? 'grey' : 'green'}
                                    >
                                    {formatHoursWithMinutes(balancePerWeek[weekIdx])
                                  }</Label>
                                </Table.Cell>
                              </Table.Row>
                            ) : undefined;
                            if(this.state.onlyNotEmptyLines && pointagesToday.length === 0) {
                              return weekRow !== undefined ? weekRow : null;
                            } else if(this.state.onlyLinesWithExpectedHours && getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)) === 0) {
                              return weekRow !== undefined ? weekRow : null;
                            } else if(this.state.displayOnlyInvalid && pointagesToday.length % 2 === 0) {
                              return null;
                            } else {
                              const pointageRow = (
                              <Table.Row
                                style={(pointagesToday !== undefined && pointagesToday.length % 2 !== 0 ?
                                  {
                                    background: '#f7ddbb',
                                    color: '#9f3a38'
                                  }
                                  : {}
                                )}
                                key={`ACC_ROW_${e.id}_${idx}`}
                              >
                              <Table.Cell width={1} style={{textTransform: 'capitalize'}}>{`${moment(day, "DD/MM/YYYY").format('dddd')}`}</Table.Cell>
                              <Table.Cell width={1}>{`${moment(day, "DD/MM/YYYY").format('DD/MM/YYYY')}`}</Table.Cell>
                              <Table.Cell width={1}>{e.name}</Table.Cell>
                              {
                                this.state.pointagesVisible ?
                                <Table.Cell width={pointagesW-1}>
                                  {
                                    pointagesToday !== undefined && pointagesToday.length > 0 ?
                                    pointagesToday.map((p, idxPt) => (
                                      <Label data-tooltip={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? "Supprimer le pointage" : undefined}  data-position={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? "bottom right" : undefined} size='medium' as='span' key={`ACC_ROW_${e.id}_${idx}_${idxPt}`}>
                                        <Icon name='clock' color={p.type === 'E' ? 'green' : 'red'} />
                                        {moment.unix(p.timestamp).format("HH:mm")}
                                        {this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? <Icon name='delete' onClick={this.onDelPointage(p.id).bind(this)} /> : ''}
                                      </Label>
                                    ))
                                    :
                                      this.state.pointageToAdd === undefined || this.state.pointageToAdd.employeeId !== e.id ?
                                        <Label
                                          color={(getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))) > 0 ? 'orange' : 'grey'}
                                          as="span">
                                        Aucun pointage pour cette journée
                                        </Label>
                                        : ''
                                  }
                                  {
                                  this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                                    <Button compact={true} data-tooltip="Ajouter manuellement un pointage" data-position="bottom right" icon onClick={this.onCreatingPointage(momentDate, e.id, location.name).bind(this)} color='green' size='mini' floated='left'>
                                      <Icon name='plus' />
                                    </Button>
                                    : ''
                                  }
                                  {
                                      this.state.pointageToAdd !== undefined && this.state.pointageToAdd.employeeId === e.id && location.name === this.state.pointageToAdd.location && moment.unix(this.state.pointageToAdd.timestamp).format("DD/MM/YYYY") === momentDate.format("DD/MM/YYYY") ?
                                        <Input size='mini'
                                          style={{width: '55pt'}}
                                          placeholder="Heure"
                                          icon="clock"
                                          iconPosition="left"
                                          autoFocus={true}
                                          value={moment.unix(this.state.pointageToAdd.timestamp).format("HH:mm")}
                                          onKeyPress={this.manageEnter(this.onAddPointage.bind(this)).bind(this)}
                                          onClick={this.onFocusOnPointageDateTime.bind(this)}
                                          onFocus={this.onFocusOnPointageDateTime.bind(this)}
                                          onChange={this.onChangeCreatingPointageDateTime(e.id).bind(this)}
                                          action={{
                                            color: 'green',
                                            icon: 'check',
                                            size: 'mini',
                                            'data-tooltip': "Enregistrer le pointage",
                                            'data-position': "bottom right",
                                            onClick: this.onAddPointage.bind(this)
                                          }}
                                        />
                                      : ""
                                  }
                                </Table.Cell>
                                : <Table.Cell />
                                }
                                {
                                this.state.locationsVisible ?
                                  <Table.Cell width={locationsW}>
                                  {
                                    pointagesToday !== undefined ?
                                    pointagesToday.map((p, idxPt) => {
                                      const labelKey = `ACC_ROW_SITE_${e.id}_${idx}_${idxPt}`;
                                      const origin = getOriginForPointage(p, this.props.rpiHardware, this.state.rpiHardwareNames);
                                      return (
                                        origin.raw === 'WEB' ?
                                        <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage en ligne" data-position="bottom left"><Icon name='laptop' style={{marginRight: '0'}} /> PC</Label> :
                                          origin.raw === 'NA' ?
                                            <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage ajouté" data-position="bottom left"><Icon name='edit' style={{marginRight: '0'}} /></Label> :
                                              origin.raw === 'PHONE' ?
                                                  <Label key={labelKey} as='span' size='medium' data-tooltip="Retrouvez l'adresse dans le menu 'Déplacements'" data-position="bottom left"><Icon name='street view' style={{marginRight: '0'}} /></Label> :
                                                  origin.raw === 'NIGHTSHIFT' ?
                                                  <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage auto. coupure journée" data-position="bottom left"><Icon name='cut' style={{marginRight: '0'}} /></Label> :
                                                    origin.raw !== 'UNKNOWN' ?
                                                      <Label key={labelKey} as='span' size='medium'>{origin.display}</Label> :
                                                      <Label key={labelKey} as='span' size='medium' data-tooltip="Origine inconnue" data-position="bottom left"><Icon name='question circle outline' style={{marginRight: '0'}} /></Label>
                                      );
                                    })
                                    : ''
                                  }
                                  </Table.Cell>
                                  : <Table.Cell />
                                }
                                {
                                  this.state.rawPointagesVisible ?
                                  <Table.Cell width={rawPointagesW}>
                                    {
                                      rawPointagesToday !== undefined ?
                                      rawPointagesToday.map((p, idxPt) => (
                                        <Label size='medium' as='span' key={`ACC_ROW_RAW_${e.id}_${idx}_${idxPt}`}>
                                          <Icon name='clock' color={p.type === 'E' ? 'green' : 'red'} />
                                          {moment.unix(p.timestamp).format("HH:mm")}
                                        </Label>
                                      ))
                                      : ''
                                    }
                                  </Table.Cell>
                                  : <Table.Cell />
                                }
                                {
                                  this.state.commentariesVisible ?
                                    <Table.Cell width={commentaryW} textAlign='center'>
                                      {
                                        (() => {
                                          const commentary = this.state.commentariesToSet.find(x => x.employeeId === e.id && x.day === momentDate.format("DD/MM/YYYY"));
                                          return commentaryForTheDay !== undefined || (this.state.commentariesToSet.length > 0 && commentary !== undefined) ?
                                              //this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                                              <Input size='mini'
                                              style={{width: '100%'}}
                                              placeholder="Commentaires"
                                              icon="comment alternate"
                                              iconPosition="left"
                                              autoFocus={false}
                                              onKeyPress={this.manageEnter(this.onSetCommentary(e.id, momentDate).bind(this)).bind(this)}
                                              value={commentary !== undefined && commentary.day === momentDate.format("DD/MM/YYYY") && commentary.employeeId === e.id ? commentary.value : commentaryForTheDay.value}
                                              onChange={this.onChangeCommentaryValue(e.id, momentDate).bind(this)}
                                              onFocus={commentaryForTheDay !== undefined ? this.onSettingCommentary(commentaryForTheDay.id, momentDate, e.id, commentaryForTheDay.value).bind(this) : undefined}
                                              action={{
                                                color: 'green',
                                                icon: 'check',
                                                size: 'mini',
                                                'data-tooltip': "Enregistrer les commentaires",
                                                'data-position': "bottom right",
                                                disabled: this.state.groupMode !== false || !(commentary !== undefined && commentary.day === momentDate.format("DD/MM/YYYY") && commentary.employeeId === e.id),
                                                onClick: this.onSetCommentary(e.id, momentDate).bind(this)
                                              }}
                                            />
                                            //: commentaryForTheDay.value
                                          : 
                                            //this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                                            <Button compact={true} className="addCommentaryButton" data-tooltip="Ajouter des commentaires" data-position="bottom right" icon onClick={this.onSettingCommentary(undefined, momentDate, e.id, '').bind(this)} color='green' size='mini' floated='left'>
                                              <Icon name='plus' />
                                            </Button>
                                            //: ''
                                          ;
                                        })()
                                      }
                                    </Table.Cell>
                                  : <Table.Cell />
                                }
                                {
                                  this.state.allManualCountersVisible && manualCounterEnabled() ?
                                    <Table.Cell width={1} textAlign='center'>
                                      {
                                        (() => {
                                          return this.state.manualCounterHours.length > 0 && foundManual !== undefined ?
                                          <Input focus size='mini'
                                              style={{width: '40pt'}}
                                              placeholder="Heures"
                                              onChange={this.onChangeOnManualCounter(e.id, momentDate.unix(), MANUAL_COUNTER_1).bind(this)}
                                              onKeyPress={this.manageEnter(this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1).bind(this)).bind(this)}
                                              /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                              onBlur={this.onBlurOnManualCounter.bind(this)}
                                              onClick={this.onFocusOnManualCounter.bind(this)}*/
                                              autoFocus={this.state.manualCounterHours.length === 1}
                                              value={formatHoursWithMinutes(
                                                foundManual.hours
                                              )}
                                              action={{
                                                color: 'green',
                                                icon: 'check',
                                                size: 'mini',
                                                'data-tooltip': "Enregistrer",
                                                'data-position': "bottom right",
                                                onClick: this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1).bind(this),
                                                disabled: this.state.groupMode !== false
                                              }}
                                            />
                                          : 
                                            (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                            <Label as='a' color={manualToday !== undefined ? 'black' : 'grey'} data-tooltip={manualToday !== undefined ? `Cliquez pour remettre le compteur "${manualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${manualCounterName()}"`} data-position="bottom right">
                                              <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1, manualToday !== undefined ? manualToday.hours : formulas.manualCounter({hours: validatedToday.amount, expected: getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)), manual: manualToday !== undefined ? manualToday.hours : 0.0, secondManual: secondManualToday !== undefined ? secondManualToday.hours : 0.0, thirdManual: thirdManualToday !== undefined ? thirdManualToday.hours : 0.0, night: validatedToday.amountCustom})).bind(this)} >{formatHoursWithMinutes(
                                                manualToday !== undefined ? manualToday.hours : 0
                                              )}</span>
                                              {manualToday !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1).bind(this)} /> : ""}
                                            </Label>
                                            :
                                            <Label as='div' color={manualToday !== undefined ? 'black' : 'grey'}>
                                              <span>{formatHoursWithMinutes(
                                                manualToday !== undefined ? manualToday.hours : 0
                                              )}</span>
                                            </Label>
                                          ;
                                        })()
                                        }
                                    </Table.Cell>
                                  : <Table.Cell />
                                }
                                {
                                  this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                                    <Table.Cell width={1} textAlign='center'>
                                      {
                                        (() => {
                                          return this.state.manualCounterHours.length > 0 && foundSecondManual !== undefined ?
                                          <Input focus size='mini'
                                              style={{width: '40pt'}}
                                              placeholder="Heures"
                                              onChange={this.onChangeOnManualCounter(e.id, momentDate.unix(), MANUAL_COUNTER_2).bind(this)}
                                              onKeyPress={this.manageEnter(this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2).bind(this)).bind(this)}
                                              /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                              onBlur={this.onBlurOnManualCounter.bind(this)}
                                              onClick={this.onFocusOnManualCounter.bind(this)}*/
                                              autoFocus={this.state.manualCounterHours.length === 1}
                                              value={formatHoursWithMinutes(
                                                foundSecondManual.hours
                                              )}
                                              action={{
                                                color: 'green',
                                                icon: 'check',
                                                size: 'mini',
                                                'data-tooltip': "Enregistrer",
                                                'data-position': "bottom right",
                                                onClick: this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2).bind(this),
                                                disabled: this.state.groupMode !== false
                                              }}
                                            />
                                          : 
                                            (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                            <Label as='a' color={secondManualToday !== undefined ? 'black' : 'grey'} data-tooltip={secondManualToday !== undefined ? `Cliquez pour remettre le compteur "${secondManualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${secondManualCounterName()}"`} data-position="bottom right">
                                              <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2, secondManualToday !== undefined ? secondManualToday.hours : formulas.secondManualCounter({hours: validatedToday.amount, expected: getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)), manual: manualToday !== undefined ? manualToday.hours : 0.0, secondManual: secondManualToday !== undefined ? secondManualToday.hours : 0.0, thirdManual: thirdManualToday !== undefined ? thirdManualToday.hours : 0.0, night: validatedToday.amountCustom})).bind(this)} >{formatHoursWithMinutes(
                                                secondManualToday !== undefined ? secondManualToday.hours : 0
                                              )}</span>
                                              {secondManualToday !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2).bind(this)} /> : ""}
                                            </Label>
                                            :
                                            <Label as='div' color={secondManualToday !== undefined ? 'black' : 'grey'}>
                                              <span>{formatHoursWithMinutes(
                                                secondManualToday !== undefined ? secondManualToday.hours : 0
                                              )}</span>
                                            </Label>
                                          ;
                                        })()
                                        }
                                    </Table.Cell>
                                  : <Table.Cell />
                                }
                                {
                                  this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                                    <Table.Cell width={1} textAlign='center'>
                                      {
                                        (() => {
                                          return this.state.manualCounterHours.length > 0 && foundThirdManual !== undefined ?
                                          <Input focus size='mini'
                                              style={{width: '40pt'}}
                                              placeholder="Heures"
                                              onChange={this.onChangeOnManualCounter(e.id, momentDate.unix(), MANUAL_COUNTER_3).bind(this)}
                                              onKeyPress={this.manageEnter(this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3).bind(this)).bind(this)}
                                              /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                              onBlur={this.onBlurOnManualCounter.bind(this)}
                                              onClick={this.onFocusOnManualCounter.bind(this)}*/
                                              autoFocus={this.state.manualCounterHours.length === 1}
                                              value={formatHoursWithMinutes(
                                                foundThirdManual.hours
                                              )}
                                              action={{
                                                color: 'green',
                                                icon: 'check',
                                                size: 'mini',
                                                'data-tooltip': "Enregistrer",
                                                'data-position': "bottom right",
                                                onClick: this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3).bind(this),
                                                disabled: this.state.groupMode !== false
                                              }}
                                            />
                                          : 
                                            (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                            <Label as='a' color={thirdManualToday !== undefined ? 'black' : 'grey'} data-tooltip={thirdManualToday !== undefined ? `Cliquez pour remettre le compteur "${thirdManualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${thirdManualCounterName()}"`} data-position="bottom right">
                                              <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3, thirdManualToday !== undefined ? thirdManualToday.hours : formulas.thirdManualCounter({hours: validatedToday.amount, expected: getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)), manual: manualToday !== undefined ? manualToday.hours : 0.0, secondManual: secondManualToday !== undefined ? secondManualToday.hours : 0.0, thirdManual: thirdManualToday !== undefined ? thirdManualToday.hours : 0.0, night: validatedToday.amountCustom})).bind(this)} >{formatHoursWithMinutes(
                                                thirdManualToday !== undefined ? thirdManualToday.hours : 0
                                              )}</span>
                                              {thirdManualToday !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3).bind(this)} /> : ""}
                                            </Label>
                                            :
                                            <Label as='div' color={thirdManualToday !== undefined ? 'black' : 'grey'}>
                                              <span>{formatHoursWithMinutes(
                                                thirdManualToday !== undefined ? thirdManualToday.hours : 0
                                              )}</span>
                                            </Label>
                                          ;
                                        })()
                                        }
                                    </Table.Cell>
                                  : <Table.Cell />
                                }
                                {
                                this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                                <Table.Cell width={1} textAlign='center'>
                                  <Label as='span' color='grey'>{
                                    formatHoursWithMinutes(
                                      validatedToday.amountCustom
                                    )
                                  }</Label>
                                </Table.Cell>
                                : null
                                }
                                <Table.Cell width={1} textAlign='center'>
                                  <Label as='span' color='grey'>{
                                    formatHoursWithMinutes(
                                      validatedToday.amount
                                    )
                                  }</Label>
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                  {
                                    (() => {
                                      const expected = this.state.changingExpectedHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.employee.id === e.id);
                                      return this.state.changingExpectedHours.length > 0 && expected !== undefined ?
                                      <Input focus size='mini'
                                          style={{width: '40pt'}}
                                          placeholder="Heures"
                                          onChange={this.onChangeOnExpected(e, momentDate).bind(this)}
                                          onKeyPress={this.manageEnter(this.onSetExpected(e, momentDate).bind(this)).bind(this)}
                                          onFocus={this.onFocusOnExpected.bind(this)}
                                          onClick={this.onFocusOnExpected.bind(this)}
                                          autoFocus={this.state.changingExpectedHours.length === 1}
                                          value={formatHoursWithMinutes(
                                            expected.hours
                                          )}
                                          action={{
                                            color: 'green',
                                            icon: 'check',
                                            size: 'mini',
                                            'data-tooltip': "Enregistrer",
                                            'data-position': "bottom right",
                                            onClick: this.onSetExpected(e, momentDate).bind(this),
                                            disabled: this.state.groupMode !== false
                                          }}
                                        />
                                      :
                                      (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                      <Label as='a' color={checkIfSpecificRuleForToday(e, momentDate.unix()) ? 'black' : 'grey'} data-tooltip={checkIfSpecificRuleForToday(e, momentDate.unix()) ? "Cliquez pour revenir à l'horaire par défaut" : "Cliquez pour modifier le théorique"} data-position="bottom right">
                                        <span onClick={this.onClickOnExpected(e, momentDate.unix()).bind(this)} >{formatHoursWithMinutes(getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)))}</span>
                                        {checkIfSpecificRuleForToday(e, momentDate.unix()) ? <Icon name='delete' onClick={this.onDelExpected(e, momentDate.unix()).bind(this)} /> : "" }
                                      </Label>
                                      :
                                      <Label as='div' color={checkIfSpecificRuleForToday(e, momentDate.unix()) ? 'black' : 'grey'}>
                                        <span>{formatHoursWithMinutes(
                                          getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))
                                        )}</span>
                                      </Label>
                                      ;
                                    })()
                                  }
                                </Table.Cell>
                                <Table.Cell width={1} textAlign='center'>
                                  <Label 
                                    as='span'
                                    color={round(balanceToday, 2) < 0 ? 'orange'
                                      :
                                      round(balanceToday, 2) === 0 ? 'grey' : 'green' 
                                    }
                                  >
                                    {
                                    formatHoursWithMinutes(balanceToday)
                                  }</Label>
                                </Table.Cell>
                              </Table.Row>
                              );

                              // Special case : week counter line
                              return weekRow !== undefined ? [pointageRow, /*emptyRow('BEFORE'), */weekRow, emptyRow('AFTER', '40px')] : generateEmptyLineAfter ? [pointageRow, emptyRow('AFTER', '40px')] : pointageRow;
                            }
                          }).flat();
                        }).flat()
                      }
                    </Table.Body>
                    : null
                  }
                </Table>
                }
              </Grid.Column>
            </Grid.Row>,
            this.state.onlyTotals === false ? <Divider key={`grid_row_summary2_dvd_${idx}`} fitted hidden /> : ''
            ].flat()
        }
      });
      const html = (
        <Grid container={true} padded stackable fluid="true" id="manageResultGrid">
          <Grid.Row key={`grid_row_summary_global`}>
            <Grid.Column width={this.state.allManualCountersVisible ? 2 : 4} verticalAlign='middle'>
              <Label size='big' color='blue' style={{textTransform: 'uppercase'}}>Total sur la période</Label>
            </Grid.Column>
            {
              this.state.allManualCountersVisible ?
                (!manualCounterEnabled() || !secondManualCounterEnabled() || !thirdManualCounterEnabled() || !nightShiftCounterEnabled() ?
                  <Grid.Column verticalAlign='middle' width={(manualCounterEnabled() ? 0 : 2) + (secondManualCounterEnabled() ? 0 : 2) + (thirdManualCounterEnabled() ? 0 : 2) + (nightShiftCounterEnabled() ? 0 : 2)}></Grid.Column>
                  :
                  ''
                )
                :
                <Grid.Column verticalAlign='middle' textAlign='center' width={6}></Grid.Column>
            }
            {
              this.state.allManualCountersVisible && manualCounterEnabled() ?
              <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
                <Label size='big' as='span'>{manualCounterName() || 'Cpt. perso 1'}</Label><br />
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(manualOnWholePeriod)}</Label>
              </Grid.Column>
              :
              ''
            }
            {
              this.state.allManualCountersVisible && secondManualCounterEnabled() ?
              <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
                <Label size='big' as='span'>{secondManualCounterName() || 'Cpt. perso 2'}</Label><br />
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(secondManualOnWholePeriod)}</Label>
              </Grid.Column>
              :
              ''
            }
            {
              this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
              <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
                <Label size='big' as='span'>{thirdManualCounterName() || 'Cpt. perso 2'}</Label><br />
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(thirdManualOnWholePeriod)}</Label>
              </Grid.Column>
              :
              ''
            }
            {
              this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
              <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
                <Label size='big' as='span'>{actualNightShiftLabel}</Label><br />
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(validatedCustomOnWholePeriod)}</Label>
              </Grid.Column>
              :
              ''
            }
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Label size='big' as='span'>Réel</Label><br />
              <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(validatedOnWholePeriod)}</Label>
            </Grid.Column>
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Label size='big' as='span'>Théorique</Label><br />
                <Label size='big' color='grey' as='span'>{
                  formatHoursWithMinutes(expectedOnWholePeriod)
                }</Label>
            </Grid.Column>
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Label size='big' as='span'>Balance</Label><br />
              <Label size='big' as='span' color={round(balanceOnWholePeriod, 2) < 0 ? 'orange' : round(balanceOnWholePeriod, 2) === 0 ? 'grey' : 'green'}>{
                formatHoursWithMinutes(balanceOnWholePeriod)
              }</Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          {gridHTML}
          {
            gridHTML.reduce((acc, x) => acc + (Array.isArray(x) ? x.length : 0), 0) > 0 ? '' :
            <Grid.Row>
              <Grid.Column>
                {
                  this.state.displayOnlyInvalid ?
                  <Label as="span" color='green'>
                    Pas d'anomalie sur la période
                  </Label>
                  :
                  <Label as="span" color='red'>
                    Aucune donnée sur la période sélectionnée
                  </Label>
                }
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
      );
      return html;
    } else {
      const gridData = this.state.gridData;

      const validatedOnWholePeriod = gridData.map(([e, allDaysFormattedForThisEmp, {validatedOnPeriod}]) => validatedOnPeriod).reduce((acc,x) => acc+x, 0.0);
      const expectedOnWholePeriod = gridData.map(([e, allDaysFormattedForThisEmp, {expectedOnPeriod}]) => expectedOnPeriod).reduce((acc,x) => acc+x, 0.0);
      const validatedCustomOnWholePeriod = gridData.map(([e, allDaysFormattedForThisEmp, {customValidated}]) => customValidated).reduce((acc,x) => acc+x, 0.0);
      const manualOnWholePeriod = gridData.map(([e, allDaysFormattedForThisEmp, {manualCounterTotal}]) => manualCounterTotal).reduce((acc,x) => acc+x, 0.0);
      const secondManualOnWholePeriod = gridData.map(([e, allDaysFormattedForThisEmp, {secondManualCounterTotal}]) => secondManualCounterTotal).reduce((acc,x) => acc+x, 0.0);
      const thirdManualOnWholePeriod = gridData.map(([e, allDaysFormattedForThisEmp, {thirdManualCounterTotal}]) => thirdManualCounterTotal).reduce((acc,x) => acc+x, 0.0);
      const balanceOnWholePeriod = validatedOnWholePeriod + (manualCounterEnabled() ? manualCounterFactor()*manualOnWholePeriod : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*secondManualOnWholePeriod : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*thirdManualOnWholePeriod : 0.0) - expectedOnWholePeriod;

      const gridHTML = gridData.map(([e, allDaysFormattedForThisEmp, {pointagesPerDay, rawPointagesPerDay, validated, validatedOnPeriod, manualCounterTotal, secondManualCounterTotal, thirdManualCounterTotal, expectedOnPeriod, balanceOnPeriod, customValidated}], idx) => {
        if((this.state.onlyNotEmptyLines && allDaysFormattedForThisEmp.length === 0) || (this.state.onlyLinesWithExpectedHours && expectedOnPeriod === 0)) {
          return [];
        }
        const weeks = Array.from(this.getWeekRange()).map((x) => `${x.weekYear()}_${x.week()}`);
        const defaultPerWeek = () => weeks.reduce((a, k) => Object.assign(a, {[k]: 0.0}), {});
        const calculatePerWeekFromValidated = (keyToAdd) => {
          return weeks.reduce((a, w) => {
            return Object.assign(a, {
              [w]: a[w] + (validated[keyToAdd][w] || 0.0)
            })
          }, defaultPerWeek());
        };
        const validatedPerWeek = calculatePerWeekFromValidated('perWeek');
        
        const validatedCustomPerWeek = calculatePerWeekFromValidated('perWeekCustom');
        const validatedManualPerWeek = calculatePerWeekFromValidated('perWeekManual');
        const validatedSecondManualPerWeek = calculatePerWeekFromValidated('perWeekSecondManual');
        const validatedThirdManualPerWeek = calculatePerWeekFromValidated('perWeekThirdManual');
        const summaryByWeek = allDaysFormattedForThisEmp.map(x => moment(x, "DD/MM/YYYY")).reduce((acc, day) => {
          return Object.assign(acc, {
            [`${day.weekYear()}_${day.week()}`]: acc[`${day.weekYear()}_${day.week()}`] + getNbHoursExpectedFromProfile(e, day.unix(), this.props.hourEndByDay(e))
          });
        }, defaultPerWeek());
        const expectedPerWeek = weeks.reduce((a, w) => {
          return Object.assign(a, {
            [w]: a[w] + summaryByWeek[w]
          });
        }, defaultPerWeek());
        const balancePerWeek = weeks.reduce((a, w) => {
          return Object.assign(a, {
            [w]: validatedPerWeek[w] + (manualCounterEnabled() ? manualCounterFactor()*validatedManualPerWeek[w] : 0.0) + (secondManualCounterEnabled() ? secondManualCounterFactor()*validatedSecondManualPerWeek[w] : 0.0) + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*validatedThirdManualPerWeek[w] : 0.0) - expectedPerWeek[w]
          });
        }, {});

        const filteredEmpEntries = allDaysFormattedForThisEmp
          .map((day, idx) => {
          const momentDate = moment(day, "DD/MM/YYYY");
          const commentary = e.commentaries.find(c => c.day === momentDate.format("DD/MM/YYYY"));
          const manualCounter = e.manualCounters.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_1);
          const secondManualCounter = e.manualCounters.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_2);
          const thirdManualCounter = e.manualCounters.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_3);
          const validated = PointageUtils(this.props.hourEndByDay(e), e.manualCounters)(pointagesPerDay[day] || [], [day]).validatedHours();
          const nbHoursExpected = getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e));
          return ({
            id: e.id,
            momentDate,
            commentary,
            manualCounter,
            secondManualCounter,
            thirdManualCounter,
            validated,
            nbHoursExpected
          });
        });

        return [
          <Grid.Row key={`grid_row_summary2_${idx}`}>
            <Grid.Column key={`grid_colwide_summary_${idx}`}>
              {
                /*utils.pointages.length === 0 && rawUtils.pointages.length === 0 ?
                <Label as="span" color='red'>
                  Aucun pointage sur la période sélectionnée
                </Label>
                :*/
              <Table fixed={this.state.onlyTotals} stackable striped singleLine selectable color='blue'>
                <Table.Header id="firstHeader">
                  <Table.Row verticalAlign='middle'>
                    <Table.HeaderCell colSpan={this.state.onlyTotals === false ? '2' : '3'}>
                      <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row'}}>
                        <h2 style={{textTransform: 'capitalize', marginBottom: '0px', display: 'inline', color: '#6f6f6f'}}>{e.name}&nbsp;</h2>
                        <div style={{padding: '0px', textAlign: 'right', display: 'inline-block'}}>
                          {this.state.onlyTotals === false ?
                            this.state.groupMode !== e.id && (this.props.isAdmin() || this.props.isManagerWithWriteAccess()) ?
                              <Popup size='small' flowing position='bottom center' content='Lancer le calcul des compteurs' trigger={
                                <Button icon='history' size='mini' color='blue' onClick={this.handleClickOnEditGroup(filteredEmpEntries, e.id).bind(this)} />
                              } />
                              :
                              <Popup size='small' flowing position='bottom center' content='Enregistrer les compteurs / commentaires' trigger={
                                <Button icon='check' size='mini' color='green' onClick={this.handleClickOnEditGroup(filteredEmpEntries, e.id).bind(this)} />
                              } />
                          : ''}
                          {this.state.onlyTotals === false && this.state.groupMode === e.id ?
                            <Popup size='small' flowing position='bottom center' content='Annuler' trigger={
                              <Button icon='delete' size='mini' color='red' onClick={this.handleClickOnEditGroupCancel().bind(this)} />
                            } />
                          : ''}
                          {this.state.onlyTotals === false && this.state.groupMode === e.id && (this.props.isAdmin() || this.props.isManagerWithWriteAccess()) ?
                            <Popup size='small' flowing position='bottom center' content='Remettre les compteurs à zéro' trigger={
                              <Button icon='trash' size='mini' color='orange' onClick={() => this.setState({confirmClearCounters: filteredEmpEntries})} />
                            } />
                          : ''}
                          {this.state.onlyTotals === false && this.state.groupMode !== e.id ?
                            <Popup style={{padding: '0px', zIndex: 'auto'}} size='mini' position='bottom center' on='click' pinned content={
                              <Button.Group>
                                {this.getShowHideIcons()}
                              </Button.Group>
                            } trigger={
                              <Button size='mini' color='blue' icon='ellipsis vertical' />
                            } />
                          : ''}
                        </div>
                      </div>
                    </Table.HeaderCell>
                    { this.state.pointagesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                    { this.state.locationsVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                    { this.state.rawPointagesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                    { this.state.commentariesVisible ? <Table.HeaderCell></Table.HeaderCell> : <Table.HeaderCell /> }
                    {
                      this.state.allManualCountersVisible && manualCounterEnabled() ?
                      <Table.HeaderCell textAlign='center'>
                        <Label as='span' color='grey'>{formatHoursWithMinutes(manualCounterTotal)}</Label>
                      </Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                      <Table.HeaderCell textAlign='center'>
                        <Label as='span' color='grey'>{formatHoursWithMinutes(secondManualCounterTotal)}</Label>
                      </Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                      <Table.HeaderCell textAlign='center'>
                        <Label as='span' color='grey'>{formatHoursWithMinutes(thirdManualCounterTotal)}</Label>
                      </Table.HeaderCell>
                      : <Table.HeaderCell />
                    }
                    {
                      this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                      <Table.HeaderCell textAlign='center'>
                        <Label as='span' color='grey'>{formatHoursWithMinutes(customValidated)}</Label>
                      </Table.HeaderCell>
                      : null
                    }
                    <Table.HeaderCell textAlign='center'>
                      <Label as='span' color='grey'>{formatHoursWithMinutes(validatedOnPeriod)}</Label>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>
                      <Label as='span' color='grey'>{formatHoursWithMinutes(expectedOnPeriod)}</Label>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>
                      <Label as='span' color={round(balanceOnPeriod, 2) < 0 ? 'orange' : round(balanceOnPeriod, 2) === 0 ? 'grey' : 'green'}>{formatHoursWithMinutes(balanceOnPeriod)}</Label>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {
                  this.state.onlyTotals === false ?
                    <Table.Header id="secondHeader">
                      <Table.Row>
                        <Table.HeaderCell>Journée</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      {
                        this.state.pointagesVisible ?
                        <Table.HeaderCell>Pointages</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.locationsVisible ?
                        <Table.HeaderCell>Origine</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.rawPointagesVisible ?
                        <Table.HeaderCell>Pointages Bruts</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.commentariesVisible ?
                        <Table.HeaderCell>Commentaires</Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && manualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{manualCounterName() || 'Cpt. perso 1'} <Icon name='info circle' /></div>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{secondManualCounterName() || 'Cpt. perso 2'} <Icon name='info circle' /></div>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {
                        this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                        <Table.HeaderCell textAlign='center'>
                          <div data-tooltip="Cliquez sur une valeur pour la modifier" data-position="top right">{thirdManualCounterName() || 'Cpt. perso 3'} <Icon name='info circle' /></div>
                        </Table.HeaderCell>
                        : <Table.HeaderCell />
                      }
                      {this.state.allManualCountersVisible && nightShiftCounterEnabled() ? <Table.HeaderCell textAlign='center'>{actualNightShiftLabel}</Table.HeaderCell> : null}
                      <Table.HeaderCell textAlign='center'>Réel</Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'><div data-tooltip="Cliquez sur un horaire théorique pour le modifier" data-position="top right">Théorique <Icon name='info circle' /></div></Table.HeaderCell>
                      <Table.HeaderCell textAlign='center'>Balance</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    : null
              }
              {
                this.state.onlyTotals === false ? 
                <Table.Body>
                  {
                    filteredEmpEntries.map(({momentDate, commentary, manualCounter, secondManualCounter, thirdManualCounter, validated, nbHoursExpected}, idx) => {
                      const day = momentDate.format("DD/MM/YYYY");
                      const commentaryForTheDay = commentary;
                      const manualToday = manualCounter;
                      const secondManualToday = secondManualCounter;
                      const thirdManualToday = thirdManualCounter;
                      const todayValidated = validated;
                      const todayExpected = nbHoursExpected;

                      const foundManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_1 && x.employee.id === e.id);
                      const foundSecondManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_2 && x.employee.id === e.id);
                      const foundThirdManual = this.state.manualCounterHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.type === MANUAL_COUNTER_3 && x.employee.id === e.id);            

                      const todayBalance = todayValidated.amount
                        + (manualCounterEnabled() ? manualCounterFactor()*(manualToday !== undefined ? manualToday.hours : 0.0) : 0.0)
                        + (secondManualCounterEnabled() ? secondManualCounterFactor()*(secondManualToday !== undefined ? secondManualToday.hours : 0.0) : 0.0)
                        + (thirdManualCounterEnabled() ? thirdManualCounterFactor()*(thirdManualToday !== undefined ? thirdManualToday.hours : 0.0) : 0.0)
                        - todayExpected;

                      const dayRow = [(
                      <Table.Row
                        style={(pointagesPerDay[day] !== undefined && pointagesPerDay[day].length % 2 !== 0 ?
                          {
                            background: '#f7ddbb',
                            color: '#9f3a38'
                          }
                          : {}
                        )}
                        key={`ACC_ROW_${e.id}_${idx}`}
                      >
                      <Table.Cell width={1} style={{textTransform: 'capitalize'}}>{`${moment(day, "DD/MM/YYYY").format('dddd')}`}</Table.Cell>
                      <Table.Cell width={1}>{`${moment(day, "DD/MM/YYYY").format('DD/MM/YYYY')}`}</Table.Cell>
                      {
                        this.state.pointagesVisible ?
                        <Table.Cell width={pointagesW}>
                          {
                            pointagesPerDay[day] !== undefined ?
                              pointagesPerDay[day].map((p, idxPt) => (
                                <Label data-tooltip={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? "Supprimer le pointage" : undefined}  data-position={this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? "bottom right" : undefined} size='medium' as='span' key={`ACC_ROW_${e.id}_${idx}_${idxPt}`}>
                                  <Icon name='clock' color={p.type === 'E' ? 'green' : 'red'} />
                                  {moment.unix(p.timestamp).format("HH:mm")}
                                  {this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ? <Icon name='delete' onClick={this.onDelPointage(p.id).bind(this)} /> : ''}
                                </Label>
                              ))
                            :
                              this.state.pointageToAdd === undefined || this.state.pointageToAdd.employeeId !== e.id ?
                                <Label
                                as="span"
                                color={(getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))) > 0 ? 'orange' : 'grey'}
                                >
                                Aucun pointage pour cette journée
                                </Label>
                                : ''
                          }
                          {
                            this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                            <Button compact={true} data-tooltip="Ajouter manuellement un pointage" data-position="bottom right" icon onClick={this.onCreatingPointage(momentDate, e.id).bind(this)} color='green' size='mini' floated='left'>
                              <Icon name='plus' />
                            </Button>
                            : ''
                          }
                          {
                              this.state.pointageToAdd !== undefined && this.state.pointageToAdd.employeeId === e.id && moment.unix(this.state.pointageToAdd.timestamp).format("DD/MM/YYYY") === momentDate.format("DD/MM/YYYY") ?
                                <Input size='mini'
                                  style={{width: '55pt'}}
                                  placeholder="Heure"
                                  icon="clock"
                                  iconPosition="left"
                                  autoFocus={true}
                                  value={moment.unix(this.state.pointageToAdd.timestamp).format("HH:mm")}
                                  onKeyPress={this.manageEnter(this.onAddPointage.bind(this)).bind(this)}
                                  onClick={this.onFocusOnPointageDateTime.bind(this)}
                                  onFocus={this.onFocusOnPointageDateTime.bind(this)}
                                  onChange={this.onChangeCreatingPointageDateTime(e.id).bind(this)}
                                  action={{
                                    color: 'green',
                                    icon: 'check',
                                    size: 'mini',
                                    'data-tooltip': "Enregistrer le pointage",
                                    'data-position': "bottom right",
                                    onClick: this.onAddPointage.bind(this)
                                  }}
                                />
                              : ""
                          }
                        </Table.Cell>
                        : <Table.Cell />
                        }
                        {
                        this.state.locationsVisible ?
                          <Table.Cell width={locationsW}>
                          {
                            pointagesPerDay[day] !== undefined ?
                            pointagesPerDay[day].map((p, idxPt) => {
                              const labelKey = `ACC_ROW_SITE_${e.id}_${idx}_${idxPt}`;
                              const origin = getOriginForPointage(p, this.props.rpiHardware, this.state.rpiHardwareNames);
                              return (
                                origin.raw === 'WEB' ?
                                <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage en ligne" data-position="bottom left"><Icon name='laptop' style={{marginRight: '0'}} /> PC</Label> :
                                  origin.raw === 'NA' ?
                                    <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage ajouté" data-position="bottom left"><Icon name='edit' style={{marginRight: '0'}} /></Label> :
                                      origin.raw === 'PHONE' ?
                                          <Label key={labelKey} as='span' size='medium' data-tooltip="Retrouvez l'adresse dans le menu 'Déplacements'" data-position="bottom left"><Icon name='street view' style={{marginRight: '0'}} /></Label> :
                                          origin.raw === 'NIGHTSHIFT' ?
                                          <Label key={labelKey} as='span' size='medium' data-tooltip="Pointage auto. coupure journée" data-position="bottom left"><Icon name='cut' style={{marginRight: '0'}} /></Label> :
                                            origin.raw !== 'UNKNOWN' ?
                                              <Label key={labelKey} as='span' size='medium'>{origin.display}</Label> :
                                              <Label key={labelKey} as='span' size='medium' data-tooltip="Origine inconnue" data-position="bottom left"><Icon name='question circle outline' style={{marginRight: '0'}} /></Label>
                              );
                            })
                            : ''
                          }
                          </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.rawPointagesVisible ?
                          <Table.Cell width={rawPointagesW}>
                            {
                              rawPointagesPerDay[day] !== undefined ?
                              rawPointagesPerDay[day].map((p, idxPt) => (
                                <Label size='medium' as='span' key={`ACC_ROW_RAW_${e.id}_${idx}_${idxPt}`}>
                                  <Icon name='clock' color={p.type === 'E' ? 'green' : 'red'} />
                                  {moment.unix(p.timestamp).format("HH:mm")}
                                </Label>
                              ))
                              : ''
                            }
                          </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.commentariesVisible ?
                            <Table.Cell width={commentaryW} textAlign='center'>
                              {
                                (() => {
                                  const commentary = this.state.commentariesToSet.find(x => x.employeeId === e.id && x.day === momentDate.format("DD/MM/YYYY"));
                                  return commentaryForTheDay !== undefined || (this.state.commentariesToSet.length > 0 && commentary !== undefined) ?
                                    //this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                                      <Input size='mini'
                                      style={{width: '100%'}}
                                      placeholder="Commentaires"
                                      icon="comment alternate"
                                      iconPosition="left"
                                      autoFocus={false}
                                      onKeyPress={this.manageEnter(this.onSetCommentary(e.id, momentDate).bind(this)).bind(this)}
                                      value={commentary !== undefined && commentary.day === momentDate.format("DD/MM/YYYY") && commentary.employeeId === e.id ? commentary.value : commentaryForTheDay.value}
                                      onChange={this.onChangeCommentaryValue(e.id, momentDate).bind(this)}
                                      onFocus={commentaryForTheDay !== undefined ? this.onSettingCommentary(commentaryForTheDay.id, momentDate, e.id, commentaryForTheDay.value).bind(this) : undefined}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer les commentaires",
                                        'data-position': "bottom right",
                                        disabled: this.state.groupMode !== false || !(commentary !== undefined && commentary.day === momentDate.format("DD/MM/YYYY") && commentary.employeeId === e.id),
                                        onClick: this.onSetCommentary(e.id, momentDate).bind(this)
                                      }}
                                    />
                                  //  : commentaryForTheDay.value
                                  : 
                                    //this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true ?
                                    <Button compact={true} className="addCommentaryButton" data-tooltip="Ajouter des commentaires" data-position="bottom right" icon onClick={this.onSettingCommentary(undefined, momentDate, e.id, '').bind(this)} color='green' size='mini' floated='left'>
                                      <Icon name='plus' />
                                    </Button>
                                    //: ''
                                  ;
                                })()
                              }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && manualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              {
                                (() => {
                                  return this.state.manualCounterHours.length > 0 && foundManual !== undefined ?
                                  <Input focus size='mini'
                                      style={{width: '40pt'}}
                                      placeholder="Heures"
                                      onChange={this.onChangeOnManualCounter(e.id, momentDate.unix(), MANUAL_COUNTER_1).bind(this)}
                                      onKeyPress={this.manageEnter(this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1).bind(this)).bind(this)}
                                      /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                      onBlur={this.onBlurOnManualCounter.bind(this)}
                                      onClick={this.onFocusOnManualCounter.bind(this)}*/
                                      autoFocus={this.state.manualCounterHours.length === 1}
                                      value={formatHoursWithMinutes(
                                        foundManual.hours
                                      )}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer",
                                        'data-position': "bottom right",
                                        onClick: this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1).bind(this),
                                        disabled: this.state.groupMode !== false
                                      }}
                                    />
                                  : 
                                    (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                    <Label as='a' color={manualToday !== undefined ? 'black' : 'grey'} data-tooltip={manualToday !== undefined ? `Cliquez pour remettre le compteur "${manualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${manualCounterName()}"`} data-position="bottom right">
                                      <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1, manualToday !== undefined ? manualToday.hours : formulas.manualCounter({hours: todayValidated.amount, expected: getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)), manual: manualToday !== undefined ? manualToday.hours : 0.0, secondManual: secondManualToday !== undefined ? secondManualToday.hours : 0.0, thirdManual: thirdManualToday !== undefined ? thirdManualToday.hours : 0.0, night: todayValidated.amountCustom})).bind(this)} >{formatHoursWithMinutes(
                                        manualToday !== undefined ? manualToday.hours : 0
                                      )}</span>
                                      {manualToday !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(e, momentDate.unix(), MANUAL_COUNTER_1).bind(this)} /> : ""}
                                    </Label>
                                    :
                                    <Label as='div' color={manualToday !== undefined ? 'black' : 'grey'}>
                                      <span>{formatHoursWithMinutes(
                                        manualToday !== undefined ? manualToday.hours : 0
                                      )}</span>
                                    </Label>
                                  ;
                                })()
                                }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              {
                                (() => {
                                  return this.state.manualCounterHours.length > 0 && foundSecondManual !== undefined ?
                                  <Input focus size='mini'
                                      style={{width: '40pt'}}
                                      placeholder="Heures"
                                      onChange={this.onChangeOnManualCounter(e.id, momentDate.unix(), MANUAL_COUNTER_2).bind(this)}
                                      onKeyPress={this.manageEnter(this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2).bind(this)).bind(this)}
                                      /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                      onBlur={this.onBlurOnManualCounter.bind(this)}
                                      onClick={this.onFocusOnManualCounter.bind(this)}*/
                                      autoFocus={this.state.manualCounterHours.length === 1}
                                      value={formatHoursWithMinutes(
                                        foundSecondManual.hours
                                      )}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer",
                                        'data-position': "bottom right",
                                        onClick: this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2).bind(this),
                                        disabled: this.state.groupMode !== false
                                      }}
                                    />
                                  : 
                                    (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                    <Label as='a' color={secondManualToday !== undefined ? 'black' : 'grey'} data-tooltip={secondManualToday !== undefined ? `Cliquez pour remettre le compteur "${secondManualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${secondManualCounterName()}"`} data-position="bottom right">
                                      <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2, secondManualToday !== undefined ? secondManualToday.hours : formulas.secondManualCounter({hours: todayValidated.amount, expected: getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)), manual: manualToday !== undefined ? manualToday.hours : 0.0, secondManual: secondManualToday !== undefined ? secondManualToday.hours : 0.0, thirdManual: thirdManualToday !== undefined ? thirdManualToday.hours : 0.0, night: todayValidated.amountCustom})).bind(this)} >{formatHoursWithMinutes(
                                        secondManualToday !== undefined ? secondManualToday.hours : 0
                                      )}</span>
                                      {secondManualToday !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(e, momentDate.unix(), MANUAL_COUNTER_2).bind(this)} /> : ""}
                                    </Label>
                                    :
                                    <Label as='div' color={secondManualToday !== undefined ? 'black' : 'grey'}>
                                      <span>{formatHoursWithMinutes(
                                        secondManualToday !== undefined ? secondManualToday.hours : 0
                                      )}</span>
                                    </Label>
                                  ;
                                })()
                                }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              {
                                (() => {
                                  return this.state.manualCounterHours.length > 0 && foundThirdManual !== undefined ?
                                  <Input focus size='mini'
                                      style={{width: '40pt'}}
                                      placeholder="Heures"
                                      onChange={this.onChangeOnManualCounter(e.id, momentDate.unix(), MANUAL_COUNTER_3).bind(this)}
                                      onKeyPress={this.manageEnter(this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3).bind(this)).bind(this)}
                                      /*onFocus={this.onFocusOnManualCounter.bind(this)}
                                      onBlur={this.onBlurOnManualCounter.bind(this)}
                                      onClick={this.onFocusOnManualCounter.bind(this)}*/
                                      autoFocus={this.state.manualCounterHours.length === 1}
                                      value={formatHoursWithMinutes(
                                        foundThirdManual.hours
                                      )}
                                      action={{
                                        color: 'green',
                                        icon: 'check',
                                        size: 'mini',
                                        'data-tooltip': "Enregistrer",
                                        'data-position': "bottom right",
                                        onClick: this.onSetManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3).bind(this),
                                        disabled: this.state.groupMode !== false
                                      }}
                                    />
                                  : 
                                    (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                    <Label as='a' color={thirdManualToday !== undefined ? 'black' : 'grey'} data-tooltip={thirdManualToday !== undefined ? `Cliquez pour remettre le compteur "${thirdManualCounterName()}" à zéro` : `Cliquez pour modifier le compteur "${thirdManualCounterName()}"`} data-position="bottom right">
                                      <span /*onFocus={this.onFocusOnExpected.bind(this)}*/ onClick={this.onClickOnManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3, thirdManualToday !== undefined ? thirdManualToday.hours : formulas.thirdManualCounter({hours: todayValidated.amount, expected: getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e)), manual: manualToday !== undefined ? manualToday.hours : 0.0, secondManual: secondManualToday !== undefined ? secondManualToday.hours : 0.0, thirdManual: thirdManualToday !== undefined ? thirdManualToday.hours : 0.0, night: todayValidated.amountCustom})).bind(this)} >{formatHoursWithMinutes(
                                        thirdManualToday !== undefined ? thirdManualToday.hours : 0
                                      )}</span>
                                      {thirdManualToday !== undefined ? <Icon name='delete' onClick={this.onDelManualCounter(e, momentDate.unix(), MANUAL_COUNTER_3).bind(this)} /> : ""}
                                    </Label>
                                    :
                                    <Label as='div' color={thirdManualToday !== undefined ? 'black' : 'grey'}>
                                      <span>{formatHoursWithMinutes(
                                        thirdManualToday !== undefined ? thirdManualToday.hours : 0
                                      )}</span>
                                    </Label>
                                  ;
                                })()
                                }
                            </Table.Cell>
                          : <Table.Cell />
                        }
                        {
                          this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                          <Table.Cell textAlign='center'>
                            <Label as='span' color='grey'>{
                              formatHoursWithMinutes(
                                todayValidated.amountCustom
                              )
                            }</Label>
                          </Table.Cell>
                          : null
                        }
                        <Table.Cell width={1} textAlign='center'>
                          <Label as='span' color='grey'>{
                            formatHoursWithMinutes(
                              todayValidated.amount
                            )
                          }</Label>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                          {
                            (() => {
                              const expected = this.state.changingExpectedHours.find(x => x.day === momentDate.format("DD/MM/YYYY") && x.employee.id === e.id);
                              return this.state.changingExpectedHours.length > 0 && expected !== undefined ?
                              <Input focus size='mini'
                                  style={{width: '40pt'}}
                                  placeholder="Heures"
                                  onChange={this.onChangeOnExpected(e, momentDate).bind(this)}
                                  onKeyPress={this.manageEnter(this.onSetExpected(e, momentDate).bind(this)).bind(this)}
                                  onFocus={this.onFocusOnExpected.bind(this)}
                                  onBlur={this.onBlurOnExpected.bind(this)}
                                  onClick={this.onFocusOnExpected.bind(this)}
                                  autoFocus={this.state.changingExpectedHours.length === 1}
                                  value={formatHoursWithMinutes(
                                    expected.hours
                                  )}
                                  action={{
                                    color: 'green',
                                    icon: 'check',
                                    size: 'mini',
                                    'data-tooltip': "Enregistrer",
                                    'data-position': "bottom right",
                                    onClick: this.onSetExpected(e, momentDate).bind(this),
                                    disabled: this.state.groupMode !== false
                                  }}
                                />
                              : 
                                (this.props.isAdmin() === true || this.props.isManagerWithWriteAccess() === true) ?
                                <Label as='a' color={checkIfSpecificRuleForToday(e, momentDate.unix()) ? 'black' : 'grey'} data-tooltip={checkIfSpecificRuleForToday(e, momentDate.unix()) ? "Cliquez pour revenir à l'horaire par défaut" : "Cliquez pour modifier le théorique"} data-position="bottom right">
                                  <span onClick={this.onClickOnExpected(e, momentDate.unix()).bind(this)} >{formatHoursWithMinutes(
                                    getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))
                                  )}</span>
                                  {checkIfSpecificRuleForToday(e, momentDate.unix()) ? <Icon name='delete' onClick={this.onDelExpected(e, momentDate.unix()).bind(this)} /> : ""}
                                </Label>
                                :
                                <Label as='div' color={checkIfSpecificRuleForToday(e, momentDate.unix()) ? 'black' : 'grey'}>
                                  <span>{formatHoursWithMinutes(
                                    getNbHoursExpectedFromProfile(e, momentDate.unix(), this.props.hourEndByDay(e))
                                  )}</span>
                                </Label>
                              ;
                            })()
                          }
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='center'>
                          <Label
                            as='span'
                            color={round(todayBalance, 2) < 0 ? 'orange'
                            :
                            round(todayBalance, 2) === 0 ? 'grey' : 'green'}
                          >
                          {
                          formatHoursWithMinutes(todayBalance)}
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    )];

                    // Special case : week counter line
                    const currentWeek = momentDate.week();
                    const currentWeekYear = momentDate.weekYear();
                    const currentWeekIdx = `${currentWeekYear}_${currentWeek}`;
                    const nextDayWeek = moment.unix(momentDate.unix()).add(1, 'd').week();
                    const lastMonday = moment.unix(momentDate.unix()).subtract(6, 'd').format("DD/MM/YYYY");
                    const emptyRow = (key, h) => (<Table.Row style={{height: h || '5px'}}
                      key={`WEEK_ROW_EMPTY_${key}_${currentWeekIdx}`}>
                        <Table.Cell width={1}></Table.Cell>
                        <Table.Cell width={1}></Table.Cell>
                        { this.state.pointagesVisible ? <Table.Cell width={pointagesW}></Table.Cell> : <Table.Cell />}
                        { this.state.locationsVisible ? <Table.Cell width={locationsW}></Table.Cell> : <Table.Cell /> }
                        { this.state.rawPointagesVisible ? <Table.Cell width={rawPointagesW}></Table.Cell> : <Table.Cell /> }
                        { this.state.commentariesVisible ? <Table.Cell width={commentaryW}></Table.Cell> : <Table.Cell /> }
                        { this.state.allManualCountersVisible && manualCounterEnabled() ? <Table.Cell width={1}></Table.Cell> : <Table.Cell /> }
                        { this.state.allManualCountersVisible && secondManualCounterEnabled() ? <Table.Cell width={1}></Table.Cell> : <Table.Cell /> }
                        { this.state.allManualCountersVisible && thirdManualCounterEnabled() ? <Table.Cell width={1}></Table.Cell> : <Table.Cell /> }
                        { this.state.allManualCountersVisible && nightShiftCounterEnabled() ? <Table.Cell width={1} textAlign='center'></Table.Cell> : null}
                        <Table.Cell width={1} textAlign='center'></Table.Cell>
                        <Table.Cell width={1} textAlign='center'></Table.Cell>
                        <Table.Cell width={1} textAlign='center'></Table.Cell>
                      </Table.Row>);
                    let weekRow = [];
                    if(nextDayWeek !== currentWeek) {
                      if(allDaysFormattedForThisEmp.includes(lastMonday)) {
                        weekRow = [(
                          <Table.Row
                            style={{height: '60px', verticalAlign: 'bottom'}}
                            key={`WEEK_ROW_${currentWeekIdx}`}
                          >
                          <Table.Cell width={1} style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{`Semaine ${currentWeek}`}</Table.Cell>
                          <Table.Cell width={1}></Table.Cell>
                          { this.state.pointagesVisible ? <Table.Cell width={pointagesW}></Table.Cell> : <Table.Cell />}
                          { this.state.locationsVisible ? <Table.Cell width={locationsW}></Table.Cell> : <Table.Cell /> }
                          { this.state.rawPointagesVisible ? <Table.Cell width={rawPointagesW}></Table.Cell> : <Table.Cell /> }
                          { this.state.commentariesVisible ? <Table.Cell width={commentaryW}></Table.Cell> : <Table.Cell /> }
                          { this.state.allManualCountersVisible && manualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              <Label as='span' color='grey'>{
                                formatHoursWithMinutes(
                                  validatedManualPerWeek[currentWeekIdx]
                                )
                              }</Label>
                            </Table.Cell>
                          : <Table.Cell /> }
                          { this.state.allManualCountersVisible && secondManualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              <Label as='span' color='grey'>{
                                formatHoursWithMinutes(
                                  validatedSecondManualPerWeek[currentWeekIdx]
                                )
                              }</Label>
                            </Table.Cell>
                          : <Table.Cell /> }
                          { this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              <Label as='span' color='grey'>{
                                formatHoursWithMinutes(
                                  validatedThirdManualPerWeek[currentWeekIdx]
                                )
                              }</Label>
                            </Table.Cell>
                          : <Table.Cell /> }
                          { this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
                            <Table.Cell width={1} textAlign='center'>
                              <Label as='span' color='grey'>{
                                formatHoursWithMinutes(
                                  validatedCustomPerWeek[currentWeekIdx]
                                )
                              }</Label>
                            </Table.Cell>
                          : null }
                          <Table.Cell width={1} textAlign='center'>
                            <Label as='span' color='grey'>{
                              formatHoursWithMinutes(
                                validatedPerWeek[currentWeekIdx]
                              )
                            }</Label>
                          </Table.Cell>
                          <Table.Cell width={1} textAlign='center'>
                          <Label as='span' color='grey'>{
                              formatHoursWithMinutes(
                                expectedPerWeek[currentWeekIdx]
                              )
                            }</Label>
                          </Table.Cell>
                          <Table.Cell width={1} textAlign='center'>
                            <Label
                              as='span'
                              color={round((balancePerWeek[currentWeekIdx]), 2) < 0 ? 'orange' : round((balancePerWeek[currentWeekIdx]), 2) === 0 ? 'grey' : 'green'}>
                              {
                              formatHoursWithMinutes(
                                balancePerWeek[currentWeekIdx]
                              )
                              }
                            </Label>
                          </Table.Cell>
                          </Table.Row>
                        ), emptyRow('AFTER', '40px')];
                      } else {
                        weekRow = [emptyRow('AFTER', '40px')];
                      }
                    }

                    return [...dayRow, ...weekRow].filter(x => x !== null);
                  }).flat()
                  }
                </Table.Body>
                : null
              }
            </Table>
            }
          </Grid.Column>
        </Grid.Row>,
        this.state.onlyTotals === false ? <Divider key={`grid_row_summary2_dvd_${idx}`} fitted hidden /> : ''
        ].flat()
      });

      const html = <Grid padded stackable container={true} fluid="true" id="manageResultGrid">
        <Grid.Row>
          <Grid.Column width={this.state.allManualCountersVisible ? 2 : 4} verticalAlign='middle'>
            <Grid.Row>
              <Label size='big' color='blue' style={{textTransform: 'uppercase'}}>Total sur la période</Label>
            </Grid.Row>
          </Grid.Column>
          {
              this.state.allManualCountersVisible ?
                (!manualCounterEnabled() || !secondManualCounterEnabled() || !thirdManualCounterEnabled() || !nightShiftCounterEnabled() ?
                  <Grid.Column verticalAlign='middle' width={(manualCounterEnabled() ? 0 : 2) + (secondManualCounterEnabled() ? 0 : 2) + (thirdManualCounterEnabled() ? 0 : 2) + (nightShiftCounterEnabled() ? 0 : 2)}></Grid.Column>
                  :
                  ''
                )
                :
                <Grid.Column verticalAlign='middle' textAlign='center' width={6}></Grid.Column>
          }
          {
            this.state.allManualCountersVisible && manualCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{manualCounterName() || 'Cpt. perso 1'}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(manualOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          {
            this.state.allManualCountersVisible && secondManualCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{secondManualCounterName() || 'Cpt. perso 2'}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(secondManualOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          {
            this.state.allManualCountersVisible && thirdManualCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{thirdManualCounterName() || 'Cpt. perso 3'}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(thirdManualOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          {
            this.state.allManualCountersVisible && nightShiftCounterEnabled() ?
            <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
              <Grid.Row>
                <Label size='big' as='span'>{actualNightShiftLabel}</Label>
              </Grid.Row>
              <Grid.Row>
                <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(validatedCustomOnWholePeriod)}</Label>
              </Grid.Row>
            </Grid.Column>
            :
            ''
          }
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Grid.Row>
              <Label size='big' as='span'>Réel</Label>
            </Grid.Row>
            <Grid.Row>
              <Label size='big' color='grey' as='span'>{formatHoursWithMinutes(validatedOnWholePeriod)}</Label>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Grid.Row>
              <Label size='big' as='span'>Théorique</Label>
            </Grid.Row>
            <Grid.Row>
              <Label size='big' color='grey' as='span'>{
                formatHoursWithMinutes(expectedOnWholePeriod)
              }</Label>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='center' width={2}>
            <Grid.Row>
              <Label size='big' as='span'>Balance</Label>
            </Grid.Row>
            <Grid.Row>
              <Label size='big' as='span' color={round(balanceOnWholePeriod, 2) < 0 ? 'orange' : round(balanceOnWholePeriod, 2) === 0 ? 'grey' : 'green'}>{
                formatHoursWithMinutes(balanceOnWholePeriod)
              }</Label>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row></Grid.Row>
        {
          gridHTML.length > 0 ? gridHTML :
            <Grid.Row>
              <Grid.Column>
                {
                  this.state.displayOnlyInvalid ?
                  <Label as="span" color='green'>
                    Pas d'anomalie sur la période
                  </Label>
                  :
                  <Label as="span" color='red'>
                    Aucune donnée sur la période sélectionnée
                  </Label>
                }
              </Grid.Column>
            </Grid.Row>
        }
      </Grid>;

      return html;
    }
  }

  getDateDiffInDays() {
    return this.state.selectedDateFilter.to === undefined || this.state.selectedDateFilter.from === undefined ? 0 : this.state.selectedDateFilter.to.diff(this.state.selectedDateFilter.from, 'days');
  }

  getNumberOfPointages() {
    return this.state.summary.reduce((acc, e) => acc+e.pointages.length, 0);
  }

  getDateFilterStr(separator = "-") {
    return (
      (
        this.state.selectedDateFilter.from !== undefined && this.state.selectedDateFilter.from.isValid() ? 
          this.state.selectedDateFilter.from.format("DD/MM/YYYY")
          : ""
      )
      +
      (
        this.state.selectedDateFilter.to !== undefined && this.state.selectedDateFilter.to.isValid() ?
          ` ${separator} ` + moment.unix(this.state.selectedDateFilter.to.unix()).subtract(hourEndByDay(), 'hours').subtract(1, 'second').format("DD/MM/YYYY")
          : ""
      )
    );
  }

  render() {
    let {from,to} = this.state.selectedDateFilter;
    if(from === undefined) {
      from = moment().subtract(1, 'day').hour(hourEndByDay()).minute(0).second(0);
    }
    if(to === undefined) {
      to = moment().hour(hourEndByDay()).minute(59).second(59).subtract(1, 'hour');
    }
    const filterEmployeeOpts = this.state.employees.filter(e => e.entryDate <= to.unix() && (e.leftDate === null || e.leftDate >= from.unix()))
      .filter(e => this.state.selectedTeamFilter !== "ALL" ? e.teamId === this.state.selectedTeamId : true)
      .map((e, idx) => ({
        key: e.id,
        text: e.name,
        value: e.id
      }));
    
    const filterTeamOpts = this.state.teams.map((t, idx) => ({
      key: t.id,
      text: t.name,
      value: t.id
    }));

    const filterLocationOpts = this.state.locations.map((l, idx) => ({
      key: `${l.radius === undefined ? 'rpih' : 'location'}_${l.id}`,
      text: l.customName !== undefined ? l.customName : l.name,
      value: `${l.radius === undefined ? 'rpih' : 'location'}_${l.id}`,
      icon: l.gpsCoords !== undefined ? 'location arrow' : l.name !== 'Inconnu' ? 'hdd' : 'question'
    }));
    
    return (
      <Grid columns={16} stackable padded >
          <Grid.Row className="firstRow">
            <Header dividing size="huge" as="h1">
              <Icon name='clock'/>
              <Header.Content>
                Gestion des temps
              </Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Label size="big" style={{backgroundColor: 'transparent'}}>Période actuellement affichée&nbsp;&nbsp;:</Label>
              <Label id="currentPeriodFilter" color="blue" size="big">
                {
                  this.getDateFilterStr()
                }
              </Label>
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={3} id="employeeFilter">
              {
                this.props.isAdmin() === true ?
                  <Dropdown
                    placeholder='Par équipe'
                    search
                    fluid
                    selection
                    onChange={this.onChangeTeamFilter.bind(this)}
                    value={this.state.selectedTeamFilter}
                    options={[
                      {
                        key: "ALL",
                        text: "Toutes les équipes",
                        value: "ALL"
                      },
                      ...filterTeamOpts
                    ]}
                  />
                  : ""
              }
              <Dropdown
                placeholder='Tous les employés'
                search
                fluid
                selection
                multiple
                clearable
                onChange={this.onChangeEmployeeFilter.bind(this)}
                value={this.state.selectedEmployeeFilter}
                options={filterEmployeeOpts}
              />
            </Grid.Column>
            <Grid.Column width={3} id="periodFilter">
            <DatesRangeInput
                name="dateFilter"
                fluid
                autoComplete="off"
                localization="fr"
                dateFormat="DD/MM/YYYY"
                placeholder="Par période"
                closeOnMouseLeave={false}
                onClear={this.initDateFilter.bind(this)}
                value={this.state.selectedDateFilter.readable}
                closable
                clearable
                clearIcon="trash"
                allowSameEndDate
                iconPosition="right"
                hideMobileKeyboard
                onChange={this.onChangeDateFilter.bind(this)}
                animation='none'
              />
            <Dropdown
                placeholder='Périodes définies'
                fluid
                selection
                onChange={this.onChangePredefinedDateFilter.bind(this)}
                options={getPredefinedFilters(PREDEFINED_DATE_FILTERS).map(({key, text, value}) => ({key, text, value: key}))}
                value={this.state.selectedPredefinedDateFilter}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Radio
                  label={<Label as='a' size='big' icon='search' content='par Employé' color={this.state.groupType === 'EMPLOYEE' ? "blue" : "grey"}></Label>}
                  name='groupType'
                  value='EMPLOYEE'
                  checked={this.state.groupType === 'EMPLOYEE'}
                  onChange={this.handleGroupTypeChange.bind(this)}
                  disabled={this.state.loadingHTML}
                />
              <Radio
                  label={<Label as='a' size='big' icon='search' content='par Date' color={this.state.groupType === 'DATE' ? "blue" : "grey"}></Label>}
                  name='groupType'
                  value='DATE'
                  checked={this.state.groupType === 'DATE'}
                  onChange={this.handleGroupTypeChange.bind(this)}
                  disabled={this.state.loadingHTML}
                />
            </Grid.Column>
            <Grid.Column>
              <Button.Group>
                <Popup wide position='top right' on='click' pinned content={
                  <Button.Group>
                    <Button size='medium' data-tooltip="Pour intégrer les données dans un autre logiciel." data-position="bottom right" compact basic color='green' onClick={this.handleExportCSV(true).bind(this)} disabled={this.state.loadingHTML}>Données brutes</Button>
                    <Button.Or text='ou' />
                    <Button size='medium' data-tooltip="Pour exporter un fichier lisible sous Excel." data-position="bottom right" compact basic color='blue' onClick={this.handleExportCSV(false).bind(this)} disabled={this.state.loadingHTML}>Données lisibles</Button>
                  </Button.Group>
                } trigger={
                  <Button size='big' compact color='green' icon='file excel' data-tooltip="Exporter vers Excel" data-position="bottom right" disabled={this.state.loadingHTML} />
                } />
                <Popup wide position='top right' on='click' pinned content={
                  <Button.Group>
                    <Button size='medium' data-tooltip="Pour un fichier PDF compact." data-position="bottom right" compact basic color='green' onClick={this.handleExportPDF(false).bind(this)} disabled={this.state.loadingHTML}>Sans sauts de page</Button>
                    <Button.Or text='ou' />
                    <Button size='medium' data-tooltip="Pour un fichier PDF séparable." data-position="bottom right" compact basic color='blue' onClick={this.handleExportPDF(true).bind(this)} disabled={this.state.loadingHTML}>Avec sauts de page</Button>
                  </Button.Group>
                } trigger={
                  <Button size='big' compact color='red' icon='file pdf' data-tooltip="Exporter vers PDF" data-position="bottom right" disabled={this.state.loadingHTML} />
                } />
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} style={{paddingLeft: '0px', paddingRight: '0px'}}>
              <Accordion fluid>
                <Accordion.Title
                  active={this.state.optsAccordionActiveIdx === 0}
                  index={0}
                  onClick={this.handleClickOnOptsAccordion.bind(this)}
                  style={{width: 'fit-content'}}
                >
                  <Icon name={`arrow circle ${this.state.optsAccordionActiveIdx === 0 ? 'down' : 'right'}`} />
                  <span id='accordionOptInManage'>{`${this.state.optsAccordionActiveIdx === 0 ? 'Masquer' : 'Afficher'} les options`}</span>
                </Accordion.Title>
                <Accordion.Content active={this.state.optsAccordionActiveIdx === 0}>
                  <Grid stackable celled>
                    <Grid.Column width={6} textAlign='center'>
                      <Radio
                        label={<Label as='a' size='big' icon='search' content='par Site' color={this.state.groupType === 'LOCATION' ? "blue" : "grey"}></Label>}
                        name='groupType'
                        value='LOCATION'
                        checked={this.state.groupType === 'LOCATION'}
                        onChange={this.handleGroupTypeChange.bind(this)}
                        disabled={this.state.loadingHTML}
                      />
                      <Divider hidden />
                      Filtrer par badgeuse / site :&nbsp;&nbsp;&nbsp;
                      <Dropdown
                        placeholder='Par site'
                        search
                        selection
                        onChange={this.onChangeLocationFilter.bind(this)}
                        value={this.state.selectedLocationFilter}
                        options={[
                          {
                            key: "ALL",
                            text: "Tous les sites",
                            value: "ALL"
                          },
                          ...filterLocationOpts
                        ]}
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Checkbox slider disabled={this.state.onlyTotals} checked={this.state.onlyNotEmptyLines} onChange={this.onToggleOnlyNotEmptyLines.bind(this)}  label='Afficher seulement les journées contenant des pointages'/> <br /><br />
                      <Checkbox slider disabled={this.state.onlyTotals} checked={this.state.onlyLinesWithExpectedHours} onChange={this.onToggleOnlyLinesWithExpectedHours.bind(this)} label='Afficher seulement les journées avec un théorique > 0' /><br /><br />
                      <Checkbox slider checked={this.state.onlyTotals} onChange={this.onToggleOnlyTotals.bind(this)} label='Afficher seulement les cumuls' />&nbsp;&nbsp;&nbsp;
                    </Grid.Column>
                    <Grid.Column width={5} textAlign='center'>
                      <Checkbox toggle checked={this.state.rememberFilters} onChange={this.onToggleRememberFilters(!this.state.rememberFilters).bind(this)} label='Retenir ma recherche' />
                      <Divider />
                      <Button size='medium' disabled={this.state.onlyTotals} color={this.state.displayOnlyInvalid ? 'orange' : 'green'} onClick={this.onToggleDisplayOnlyInvalid.bind(this)} icon='eye' content={this.state.displayOnlyInvalid ? `Quitter le mode correction` : `Corriger les anomalies`}></Button>
                    </Grid.Column>
                  </Grid>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
          </Grid.Row>
          <Dimmer.Dimmable id="pointagesTbl" as={Grid.Row} style={{minHeight: '100px', zIndex: 0}} dimmed={this.state.loadingHTML}>
            {this.computeSummaryHTML()}
            <Modal open={this.state.confirmClearCounters !== false} basic size='small'>
              <Header icon='trash' content="Remise à zéro des compteurs" />
              <Modal.Content>
                <p>
                  Attention, la remise à zéro des compteurs sur une période / un employé est irréversible.<br /><br />
                  Êtes-vous certain de vouloir effectuer cette action ?<br />
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color='red' inverted onClick={() => this.setState({confirmClearCounters: false})}>
                  <Icon name='remove' /> Non, Annuler
                </Button>
                <Button color='green' inverted onClick={this.handleZero(this.state.confirmClearCounters).bind(this)}>
                  <Icon name='checkmark' /> Oui, Confirmer
                </Button>
              </Modal.Actions>
            </Modal>
            <Dimmer inverted verticalAlign='top' active={this.state.loadingHTML}>
              <Loader inline>Chargement</Loader>
            </Dimmer>
          </Dimmer.Dimmable>
        </Grid>
    );
  }
}

export default Manage;
