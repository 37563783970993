import React from "react";

import "semantic-ui-css/semantic.min.css";

import { Redirect } from "react-router-dom";

import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Tab
} from "semantic-ui-react";

import io from "./utils/io";
import bareIO from 'socket.io-client';
import Moment from './utils/momentfr';
import ls from 'local-storage';

//import "./LoginForm.css";
const moment = Moment();

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: bareIO(),
      login: {
        emailInput: '',
        passwordInput: ''
      },
      loginTeam: {
        loginInput: '',
        passwordInput: '',
        companyCodeInput: ''
      },
      pointage: {
        companyId: '',
        email: '',
        pointPassword: '',
        locationId: ''
      },
      message: {
        companyId: '',
        email: '',
        pointPassword: '',
        content: '',
        picture: '',
        pictureData: ''
      },
      all: {
        currentTabIndex: undefined
      },
      locations: [],
      loginErr: undefined,
      pointageResult: {},
      messageResult: {},
      retainID: false,
      displayPassword: false,
      displayForgetPassword: false,
      forgetPasswordForm: {
        companyCodeInput: '',
        emailInput: '',
        tokenInput: ''
      },
      forgetPasswordResult: undefined,
      forgetPasswordTokenResult: undefined,
    };
    this.state = Object.assign(this.state, {
      listeners: {
        REMOTE_POINTAGE: this.onRemotePointageResponse.bind(this),
        REMOTE_MESSAGE: this.onRemoteMessageResponse.bind(this),
        REMOTE_GET_LOCATION: this.onRemoteGetLocationResponse.bind(this),
        FORGET_PASSWORD: this.onForgetPasswordResponse.bind(this),
        FORGET_PASSWORD_TOKEN: this.onForgetPasswordTokenResponse.bind(this)
      }
    });
  }
  
  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    const lsRetained = ls('retained');
    this.setState({retainID: lsRetained !== undefined && lsRetained !== null}, () => {
      this.refreshRetained();
    });
  }

  storeRetained(tab, field, value) {
    const retained = ls('retained');
    ls('retained', Object.assign({}, retained, {
      [tab]: Object.assign({}, retained !== undefined && retained !== null ? retained[tab] : {}, {
        [field]: value
      })
    }));
  }

  clearRetained() {
    ls.remove('retained');
  }

  refreshRetained() {
    if(this.state.retainID === true) {
      const retained = ls('retained');
      if(retained !== undefined && retained !== null) {
        Object.keys(retained).forEach(tab => {
          const data = retained[tab];
          Object.keys(data).forEach(field => {
            this.setState({
              [tab]: Object.assign(this.state[tab], {
                [field]: data[field]
              })
            })
          });
        })
      }
    }
  }

  toggleRetainID(event) {
    const newState = !this.state.retainID;
    if(!newState) {
      this.clearRetained();
    } else {
      ls('retained', {});
    }

    this.setState({
      retainID: newState
    });
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));
  }

  onRemotePointageResponse(pointage) {
    this.setState({pointageResult: pointage});
  }

  onRemoteMessageResponse(message) {
    this.setState({messageResult: message});
  }

  onRemoteGetLocationResponse(locations) {
    if(locations.err === undefined) {
      this.setState({locations});
    }
  }

  onForgetPasswordResponse(result) {
    this.setState({forgetPasswordResult: result});
  }

  onForgetPasswordTokenResponse(result) {
    this.setState({forgetPasswordTokenResult: result});
  }

  handleFieldChange(tab, field) {
    return (event) => {
      if(this.state.retainID) {
        const shouldIStoreRetained = (tab === 'login' && field === 'emailInput')
          || (tab === 'pointage' && (field === 'email' || field === 'companyId'))
          || (tab === 'message' && (field === 'email' || field === 'companyId'))
          || (tab === 'loginTeam' && (field === 'loginInput' || field === 'companyCodeInput'))
        if(shouldIStoreRetained) {
          this.storeRetained(tab, field, event.target.value);
        }
      }

      this.setState({
        [tab]: Object.assign(this.state[tab], {[field]: event.target.value})
      }, () => {
        if(tab === 'pointage' && (field === "email" || field === "pointPassword")) {
          this.state.socket.emit('REMOTE_GET_LOCATION', this.state.pointage);
        }
      });
    }
  }

  handleMessagePictureChange() {
    return (event) => {
      const file = event.target.files[0];
      this.handleFieldChange('message', 'picture')(event);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // convert image file to base64 string
        const b64 = reader.result;
        this.handleFieldChange('message', 'pictureData')({
          target: {
            value: b64
          }
        });
      }, false);
      if(file) {
        reader.readAsDataURL(file);
      }
    }
  }

  handleLocationChange(event, data) {
    this.handleFieldChange('pointage', 'locationId')({target: {value: data.value}});
  }
  
  handleLogin(event) {
    io.ioWithAuth(this.state.login.emailInput, this.state.login.passwordInput).then(([socket, token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, isAdmin]) => {
      this.props.logIn(token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, true);
    }).catch((e) => {
      this.setState({
        loginErr: e
      });
      //console.log(`ERR ${JSON.stringify(e)}`);
    })
  }

  handleLoginAsUser(event) {
    io.ioWithAuthAsUser(this.state.loginTeam.loginInput, this.state.loginTeam.passwordInput, this.state.loginTeam.companyCodeInput).then(([socket, token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, isAdmin, isManagerWithWriteAccess, myTeamId]) => {
      this.props.logIn(token, companyCode, companyName, subscriptionStart, rpiHardware, phoneHardware, false, isManagerWithWriteAccess, myTeamId);
    }).catch((e) => {
      this.setState({
        loginErr: e
      });
      //console.log(`ERR ${JSON.stringify(e)}`);
    })
  }

  handleDisplayForgetPassword(event) {
    this.setState({
      displayForgetPassword: !this.state.displayForgetPassword
    });
  }

  handleForgetPassword(isAdmin = true) {
    return (event) => {
      this.setState({
        forgetPasswordResult: {
          loading: true
        }
      }, () => {
        this.state.socket.emit('FORGET_PASSWORD', {
          companyCode: this.state.forgetPasswordForm.companyCodeInput,
          email: this.state.forgetPasswordForm.emailInput,
          isAdmin
        });
      });
    };
  }

  handleForgetPasswordToken(isAdmin = true) {
    return (event) => {
      this.setState({
        forgetPasswordTokenResult: {
          loading: true
        }
      }, () => {
        this.state.socket.emit('FORGET_PASSWORD_TOKEN', {
          companyCode: this.state.forgetPasswordForm.companyCodeInput,
          email: this.state.forgetPasswordForm.emailInput,
          token: this.state.forgetPasswordForm.tokenInput,
          isAdmin
        });
      });
    };
  }

  handlePoint(event) {
    this.state.socket.emit('REMOTE_POINTAGE', this.state.pointage);
  }

  handleMessage(event) {
    this.state.socket.emit('REMOTE_MESSAGE', this.state.message);
  }

  handleTabChange(event, data) {
    this.setState({
      all: {currentTabIndex: data.activeIndex || 0},
      loginErr: undefined,
      message: Object.assign(this.state.message, {
        picture: '',
        pictureData: ''
      })
    }, () => {
      if(this.state.retainID) {
        this.storeRetained("all", "currentTabIndex", this.state.all.currentTabIndex);
      }
    });
  }

  render() {
    if(this.props.loggedIn === true) {
      const location = this.props.location.state !== undefined ? this.props.location.state.from : "/";
      return <Redirect to={location} />
    } else {
      const retainCheckbox = <Form.Checkbox label={{ children: 'Retenir mes identifiants' }} slider size='big' checked={this.state.retainID} onChange={this.toggleRetainID.bind(this)} />;
      return (
        <div className="LoginForm" style={{backgroundColor: '#1678c2', width: '100%', height: '100vh', overflow: 'hidden'}}>
          <Grid stackable textAlign="center" verticalAlign="top">
            {/*<Grid.Row style={{height: '20vh', marginTop: '5vh'}}>
              <Grid.Column width={16}>
                <div style={{
                  backgroundColor: '#ffffff',
                  //backgroundColor: '#1678c2',
                  //borderRadius: '20px',
                  padding: '10px',
                  margin: 'auto',
                  maxWidth: '100%',
                  height: '20vh'
                }}>
                  <Header size="huge" style={{fontFamily: 'Arial', fontSize: '2vw', marginTop: '6vh'}}>ESPACE DE CONNEXION</Header>
                </div>
              </Grid.Column>
              </Grid.Row>*/}
            <Grid.Row style={{marginTop: '5vw'}}>
            <Grid.Column only = {"computer"} width={5}>
              <Image style={{padding: '5px'}} src='/banner.png' />
            </Grid.Column>
            <Grid.Column only = {"mobile"} width={5}>
              <Image size="small" centered src='/banner.png' />
            </Grid.Column>
            <Grid.Column width={4}>
              <Tab
                className="loginTabPanes"
                activeIndex={this.state.all.currentTabIndex}
                onTabChange={this.handleTabChange.bind(this)}
                menu={{ color: 'blue', pointing: true, fluid: true, widths: 4, inverted: true, tabular: false }}
                panes={[
                {
                  menuItem: {
                    key:'identification',
                    icon: 'lock',
                    content: '',
                    style: {fontSize: '150%'}
                  },
                  render: () => this.state.displayForgetPassword === true ? 
                    <Tab.Pane attached={false}>
                      <Header as="h2" color="blue" textAlign="center">
                        Réinitialisation du code d'accès
                      </Header>
                      <Form size="large">
                        <Segment stacked>
                          <Form.Input
                            fluid
                            icon="building"
                            iconPosition="left"
                            placeholder="Code entreprise"
                            value={this.state.forgetPasswordForm.companyCodeInput}
                            onChange={this.handleFieldChange('forgetPasswordForm', 'companyCodeInput').bind(this)}
                          />
                          <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="E-mail de l'administrateur"
                            value={this.state.forgetPasswordForm.emailInput}
                            onChange={this.handleFieldChange('forgetPasswordForm', 'emailInput').bind(this)}
                          />

                          {
                            this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading === undefined &&  this.state.forgetPasswordResult.err === undefined ?
                              <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Clé de sécurité"
                                value={this.state.forgetPasswordForm.tokenInput}
                                onChange={this.handleFieldChange('forgetPasswordForm', 'tokenInput').bind(this)}
                              /> : ''
                          }

                          <Button.Group fluid>
                            {
                              this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading === undefined && this.state.forgetPasswordResult.err === undefined ?
                                <Button color="green" loading={this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.loading} disabled={this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.loading} onClick={this.handleForgetPasswordToken().bind(this)}>
                                  Confirmer la clé
                                </Button>
                              : <Button color="blue" loading={this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading} disabled={this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading} onClick={this.handleForgetPassword().bind(this)}>
                                  Réinitialiser
                                </Button>
                            }

                            <Button color="red" onClick={this.handleDisplayForgetPassword.bind(this)}>
                              Annuler
                            </Button>
                          </Button.Group>
                        </Segment>
                      </Form>
                      {
                        (this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.err !== undefined) || (this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.err !== undefined) ?
                        <Message negative>
                          <Message.Header>Erreur lors de la réinitialisation</Message.Header>
                          <p>
                            Merci de vérifier les données saisies.
                          </p>
                        </Message> : this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.loading === undefined && this.state.forgetPasswordTokenResult.err === undefined ? <Message positive>
                          <Message.Header>Réinitialisation confirmée</Message.Header>
                          <p>
                            Un email vous a été envoyé à l'adresse renseignée. Il contient votre nouveau code d'accès.
                          </p>
                        </Message> : this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading === undefined && this.state.forgetPasswordResult.err === undefined ?
                          <Message positive>
                            <Message.Header>Confirmation requise</Message.Header>
                            <p>
                              Un email vous a été envoyé à l'adresse renseignée. Merci de bien vouloir rentrer la clé de sécurité qu'il contient.
                            </p>
                          </Message> : ''
                      }
                    </Tab.Pane> : <Tab.Pane attached={false}>
                      <Header as="h2" color="blue" textAlign="center">
                        Connexion Administrateur
                      </Header>
                      <Form size="large">
                        <Segment stacked>
                          <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="E-mail"
                            value={this.state.login.emailInput}
                            onChange={this.handleFieldChange('login', 'emailInput').bind(this)}
                          />
                          <Form.Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Code d'accès"
                            type={this.state.displayPassword === true ? "text" : "password"}
                            value={this.state.login.passwordInput}
                            onChange={this.handleFieldChange('login', 'passwordInput').bind(this)}
                            action={<Button type="button" compact icon={`eye${this.state.displayPassword === true ? " slash" : ""}`} circular data-position="bottom center" data-tooltip={this.state.displayPassword === true ? "Masquer le code d'accès" : "Afficher le code d'accès"} onClick={() => this.setState({displayPassword: !this.state.displayPassword})}/>}
                          />
                          <Button.Group fluid>
                            <Button color="blue" onClick={this.handleLogin.bind(this)}>
                              CONNEXION
                            </Button>
                            <Button style={{flex: "0.1 0 auto"}} compact icon="help circle" circular data-position="bottom center" data-tooltip="Code d'accès oublié ?" onClick={this.handleDisplayForgetPassword.bind(this)} />
                          </Button.Group><br /><br />
                          {retainCheckbox}
                        </Segment>
                      </Form>
                      {
                        this.state.loginErr !== undefined ?
                        <Message negative>
                          <Message.Header>Erreur lors de la connexion</Message.Header>
                            {
                              (() => {
                                switch(this.state.loginErr) {
                                  case 'INVALID_CREDS':
                                    return <p>Les données saisies sont incorrectes.<br />Veuillez vérifier vos identifiants.</p>;
                                  case 'LOCKED_ACCOUNT':
                                    return <p>Votre compte rencontre un problème, nous vous invitons à contacter votre service client au 0899 250 450</p>;
                                  default:
                                    return <p>Merci de réessayer plus tard.</p>;
                                }
                              })()
                            }
                        </Message> : ''
                      }
                  </Tab.Pane>
                },
                {
                  menuItem: {
                    key:'identification_equipe',
                    icon: 'users',
                    content: '',
                    style: {fontSize: '150%'}
                  },
                  render: () => this.state.displayForgetPassword === true ? 
                    <Tab.Pane attached={false}>
                      <Header as="h2" color="blue" textAlign="center">
                        Réinitialisation du code d'accès
                      </Header>
                      <Form size="large">
                        <Segment stacked>
                          <Form.Input
                            fluid
                            icon="building"
                            iconPosition="left"
                            placeholder="Code entreprise"
                            value={this.state.forgetPasswordForm.companyCodeInput}
                            onChange={this.handleFieldChange('forgetPasswordForm', 'companyCodeInput').bind(this)}
                          />
                          <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="E-mail"
                            value={this.state.forgetPasswordForm.emailInput}
                            onChange={this.handleFieldChange('forgetPasswordForm', 'emailInput').bind(this)}
                          />

                          {
                            this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading === undefined &&  this.state.forgetPasswordResult.err === undefined ?
                              <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Clé de sécurité"
                                value={this.state.forgetPasswordForm.tokenInput}
                                onChange={this.handleFieldChange('forgetPasswordForm', 'tokenInput').bind(this)}
                              /> : ''
                          }

                          <Button.Group fluid>
                            {
                              this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading === undefined && this.state.forgetPasswordResult.err === undefined ?
                                <Button color="green" loading={this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.loading} disabled={this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.loading} onClick={this.handleForgetPasswordToken(false).bind(this)}>
                                  Confirmer la clé
                                </Button>
                              : <Button color="blue" loading={this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading} disabled={this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading} onClick={this.handleForgetPassword(false).bind(this)}>
                                  Réinitialiser
                                </Button>
                            }

                            <Button color="red" onClick={this.handleDisplayForgetPassword.bind(this)}>
                              Annuler
                            </Button>
                          </Button.Group><br /><br />
                        </Segment>
                      </Form>
                      {
                        (this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.err !== undefined) || (this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.err !== undefined) ?
                        <Message negative>
                          <Message.Header>Erreur lors de la réinitialisation</Message.Header>
                          <p>
                            Merci de vérifier les données saisies.
                          </p>
                        </Message> : this.state.forgetPasswordTokenResult !== undefined && this.state.forgetPasswordTokenResult.loading === undefined && this.state.forgetPasswordTokenResult.err === undefined ? <Message positive>
                          <Message.Header>Réinitialisation confirmée</Message.Header>
                          <p>
                            Un email contenant votre nouveau code d'accès vous a été envoyé à l'adresse renseignée.
                          </p>
                        </Message> : this.state.forgetPasswordResult !== undefined && this.state.forgetPasswordResult.loading === undefined && this.state.forgetPasswordResult.err === undefined ?
                          <Message positive>
                            <Message.Header>Confirmation requise</Message.Header>
                            <p>
                              Un email contenant une clé de sécurité vous a été envoyé à l'adresse renseignée. Merci de bien vouloir saisir la clé de sécurité correspondante.
                            </p>
                          </Message> : ''
                      }
                    </Tab.Pane> : <Tab.Pane attached={false}>
                      <Header as="h2" color="blue" textAlign="center">
                        Connexion Managers
                      </Header>
                      <Form size="large">
                        <Segment stacked>
                          <Form.Input
                              fluid
                              icon="building"
                              iconPosition="left"
                              placeholder="Code entreprise"
                              value={this.state.loginTeam.companyCodeInput}
                              onChange={this.handleFieldChange('loginTeam', 'companyCodeInput').bind(this)}
                            />
                          <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="Login"
                            value={this.state.loginTeam.loginInput}
                            onChange={this.handleFieldChange('loginTeam', 'loginInput').bind(this)}
                          />
                          <Form.Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Code d'accès"
                            type={this.state.displayPassword === true ? "text" : "password"}
                            value={this.state.loginTeam.passwordInput}
                            onChange={this.handleFieldChange('loginTeam', 'passwordInput').bind(this)}
                            action={<Button type="button" compact icon={`eye${this.state.displayPassword === true ? " slash" : ""}`} circular data-position="bottom center" data-tooltip={this.state.displayPassword === true ? "Masquer le code d'accès" : "Afficher le code d'accès"} onClick={() => this.setState({displayPassword: !this.state.displayPassword})}/>}
                          />
                          <Button.Group fluid>
                            <Button color="blue" onClick={this.handleLoginAsUser.bind(this)}>
                              CONNEXION
                            </Button>
                            <Button style={{flex: "0.1 0 auto"}} compact icon="help circle" circular data-position="bottom center" data-tooltip="Code d'accès oublié ?" onClick={this.handleDisplayForgetPassword.bind(this)} />
                          </Button.Group><br /><br />
                          {retainCheckbox}
                        </Segment>
                      </Form>
                      {
                        this.state.loginErr !== undefined ?
                        <Message negative>
                          <Message.Header>Erreur lors de la connexion</Message.Header>
                          <p>
                            Les données saisies sont incorrectes.<br /> Veuillez vérifier le code entreprise et vos identifiants.
                          </p>
                        </Message> : ''
                      }
                  </Tab.Pane>
                },
                {
                  menuItem: {
                    key: 'pointage',
                    icon: 'clock',
                    content: '',
                    style: {fontSize: '150%'}
                  },
                  render: () => {
                    const resultMessage = this.state.pointageResult.err !== undefined ?
                      <Message negative>
                        <Message.Header>Une erreur est survenue</Message.Header>
                          {
                            (() => {
                              switch(this.state.pointageResult.err) {
                                case 'INVALID_CREDS':
                                  return <p>Les données saisies sont incorrectes.<br />Veuillez vérifier le code entreprise et vos identifiants.</p>;
                                default:
                                  return <p>Merci de réessayer plus tard.</p>;
                              }
                            })()
                          }
                      </Message>
                      : this.state.pointageResult.name !== undefined ?
                        <Message positive>
                          <Message.Header>Pointage confirmé !</Message.Header>
                          <p>
                            {this.state.pointageResult.name} en date du {moment.unix(this.state.pointageResult.timestamp).format("DD/MM/YYYY à HH:mm")}
                          </p>
                        </Message>
                      : <div />;

                    return <Tab.Pane attached={false}>
                      <Header as="h2" color="blue" textAlign="center">
                        Pointage depuis le PC
                      </Header>
                      <Form size="large">
                        <Segment stacked>
                          <Form.Input
                            fluid
                            icon="building"
                            iconPosition="left"
                            placeholder="Code entreprise"
                            value={this.state.pointage.companyId}
                            onChange={this.handleFieldChange('pointage', 'companyId')}
                          />
                          <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="Login"
                            value={this.state.pointage.email}
                            onChange={this.handleFieldChange('pointage', 'email')}
                          />
                          <Form.Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Code d'accès"
                            type="password"
                            value={this.state.pointage.pointPassword}
                            onChange={this.handleFieldChange('pointage', 'pointPassword')}
                          />
                          <Form.Dropdown
                            placeholder='Choix du site'
                            fluid
                            search
                            selection
                            clearable
                            value={this.state.pointage.locationId}
                            onChange={this.handleLocationChange.bind(this)}
                            disabled={this.state.locations.length === 0}
                            options={this.state.locations.map(l => ({
                              key: `loc_${l.id}`,
                              text: l.name,
                              value: l.id
                            })).sort((x,y) => x.text < y.text ? -1 : 1)}
                            />
                          <Button color="blue" fluid size="large" onClick={this.handlePoint.bind(this)}>
                            POINTER MON HORAIRE
                          </Button><br />
                          {retainCheckbox}
                        </Segment>
                      </Form>
                      {resultMessage}
                    </Tab.Pane>
                  }
                },
                {
                  menuItem: {
                    key: 'messagerie',
                    icon: 'envelope',
                    content: '',
                    style: {fontSize: '150%'}
                  },
                  render: () => {
                    const resultMessage = this.state.messageResult.err !== undefined ?
                      <Message negative>
                        <Message.Header>Une erreur est survenue</Message.Header>
                        {
                          (() => {
                            switch(this.state.messageResult.err) {
                              case 'INVALID_CREDS':
                                return <p>Les données saisies sont incorrectes.<br />Veuillez vérifier le code entreprise et vos identifiants.</p>;
                              default:
                                return <p>Merci de réessayer plus tard.</p>;
                            }
                          })()
                        }
                      </Message>
                      : this.state.messageResult.name !== undefined ?
                        <Message positive>
                          <Message.Header>Message envoyé !</Message.Header>
                          <p>
                            {this.state.messageResult.name} en date du {moment.unix(this.state.messageResult.timestamp).format("DD/MM/YYYY à HH:mm")}
                          </p>
                        </Message>
                      : <div />;
                      
                    return <Tab.Pane attached={false}>
                      <Header as="h2" color="blue" textAlign="center">
                        Messagerie
                      </Header>
                      <Form size="large">
                        <Segment stacked>
                          <Form.Input
                            fluid
                            icon="building"
                            iconPosition="left"
                            placeholder="Code entreprise"
                            value={this.state.message.companyId}
                            onChange={this.handleFieldChange('message', 'companyId')}
                          />
                          <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="Login"
                            value={this.state.message.email}
                            onChange={this.handleFieldChange('message', 'email')}
                          />
                          <Form.Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Code d'accès"
                            type="password"
                            value={this.state.message.pointPassword}
                            onChange={this.handleFieldChange('message', 'pointPassword')}
                          />
                          <Form.Input
                            fluid
                            icon="envelope"
                            iconPosition="left"
                            placeholder="Saisir votre message"
                            value={this.state.message.content}
                            onChange={this.handleFieldChange('message', 'content')}
                          />
                          <Form.Input
                            fluid
                            icon="picture"
                            iconPosition="left"
                            placeholder="Photo"
                            type="file"
                            value={this.state.message.picture}
                            onChange={this.handleMessagePictureChange().bind(this)}
                            accept="multiple /*"
                          />
                          <Button color="blue" fluid size="large" onClick={this.handleMessage.bind(this)}>
                            ENVOYER
                          </Button><br />
                          {retainCheckbox}
                        </Segment>
                      </Form>
                      {resultMessage}
                    </Tab.Pane>
                  }
                }
              ]} />
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>  
      );
    }
  }
}

export default LoginForm;