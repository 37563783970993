import React from 'react';
//import './Locations.css';
import io from './utils/io';
import resolvePath from 'object-resolve-path';
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Dropdown,
  Divider,
  Grid,
  Header,
  Image,
  Loader,
  Icon,
  Input,
  Message,
  Table
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from './utils/momentfr';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment());

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: io.ioCurrent(),
      listeners: {},
      employees: [],
      // Employee -> Phone fake rel
      phoneEmployees: [],
      editingPhoneEmployee: {},
      // Employee -> Car fake rel
      carEmployees: [],
      editingCarEmployee: {},

      editingRpiHardware: {},
      error: "",
      errorRpiHardware: "",
      cars: [],
      // Reverse geocoding
      rpiHardwareNames: [],
      loadingRpiHardware: true,
      loadingPhoneHardware: true,
      loadingGpsHardware: true,
      sortState: {
        rpiHardwareNames: {},
        phoneEmployees: {},
        carEmployees: {}
      }
    };
    this.state = Object.assign(this.state, {
      listeners: {
        GET_EMPLOYEE: this.onEmployeesInit.bind(this),
        GET_RPIHARDWARENAME: this.onRpiHardwareNamesInit.bind(this),
        ADD_RPIHARDWARENAME: this.onRpiHardwareNameUpdateResponse.bind(this),
        UPD_RPIHARDWARENAME: this.onRpiHardwareNameUpdateResponse.bind(this),
        GET_PHONEEMPLOYEE: this.onPhoneEmployeesInit.bind(this),
        ADD_PHONEEMPLOYEE: this.onPhoneEmployeeUpdateResponse.bind(this),
        UPD_PHONEEMPLOYEE: this.onPhoneEmployeeUpdateResponse.bind(this),
        GET_CAREMPLOYEE: this.onCarEmployeesInit.bind(this),
        ADD_CAREMPLOYEE: this.onCarEmployeeUpdateResponse.bind(this),
        UPD_CAREMPLOYEE: this.onCarEmployeeUpdateResponse.bind(this),
        GET_CARS: this.onCarsInit.bind(this)
      }
    });
    this.handleEscape = this.handleEscape.bind(this);
  }

  componentDidMount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.on(k, this.state.listeners[k]));
    this.state.socket.emit('GET_EMPLOYEE');
    this.state.socket.emit('GET_CARS');
    this.state.socket.emit('GET_CAREMPLOYEE');
    this.state.socket.emit('GET_PHONEEMPLOYEE');
    this.state.socket.emit('GET_RPIHARDWARENAME');

    // For escape key
    document.addEventListener("keydown", this.handleEscape, false);
  }

  componentWillUnmount() {
    Object.keys(this.state.listeners).forEach(k => this.state.socket.removeListener(k, this.state.listeners[k]));

    // For escape key
    document.removeEventListener("keydown", this.handleEscape, false);
  }

  handleEscape(event) {
    if(event.keyCode === 27) {
      this.setState({
        editingPhoneEmployee: {},
        editingCarEmployee: {},
        editingRpiHardware: {}
      });
    }
  }

  onEmployeesInit(employees) {
    this.setState({
      employees
    });
  }

  onCarsInit(cars) {
    this.setState({
      cars,
      loadingGpsHardware: false
    });
  }

  manageErrors(data, stateErr = 'error') {
    if(data.err !== undefined) {
      this.setState({
        [stateErr]: data.err
      });
      setTimeout(() => {
        this.setState({
          [stateErr]: ""
        });
      }, 5000);
    }
  }

  onRpiHardwareNamesInit(rpiHardwareNames) {
    this.setState({
      rpiHardwareNames,
      loadingRpiHardware: false
    });
  }

  onRpiHardwareNameUpdate(rpiHardwareName) {
    if(rpiHardwareName.id === undefined) {
      this.state.socket.emit('ADD_RPIHARDWARENAME', rpiHardwareName);
    } else {
      this.state.socket.emit('UPD_RPIHARDWARENAME', rpiHardwareName);
    }
  }

  onRpiHardwareNameUpdateResponse(rpiHardwareName) {
    this.setState({
      editingRpiHardware: {}
    });
    this.state.socket.emit('GET_RPIHARDWARENAME');
    this.manageErrors(rpiHardwareName, 'errorRpiHardware');
  }

  onClickOnRpiHardwareNameRow(id, hostname) {
    return (event) => {
      if(hostname !== this.state.editingRpiHardware.hostname) {
        const rpiHardwareName = this.state.rpiHardwareNames.find(rhn => rhn.hostname === hostname);
        this.setState({
          editingRpiHardware: {
            id,
            hostname,
            name: rpiHardwareName ? rpiHardwareName.name : ''
          },
          editingPhoneEmployee: {},
          editingCarEmployee: {},
        });
      }
    };
  }

  onClickOnUpdRpiHardwareName() {
    return (event) => {
      this.onRpiHardwareNameUpdate(this.state.editingRpiHardware);
      this.setState({
        editingRpiHardware: {}
      });
    };
  }

  onChangeRpiHardwareName(event) {
    this.setState({
      editingRpiHardware: Object.assign(this.state.editingRpiHardware, {
        name: event.target.value
      })
    });
  }

  onPhoneEmployeesInit(phoneEmployees) {
    this.setState({
      phoneEmployees,
      loadingPhoneHardware: false
    });
  }

  onPhoneEmployeeUpdate(phoneEmployee) {
    if(phoneEmployee.id === undefined) {
      this.state.socket.emit('ADD_PHONEEMPLOYEE', phoneEmployee);
    } else {
      this.state.socket.emit('UPD_PHONEEMPLOYEE', phoneEmployee);
    }
  }

  onPhoneEmployeeUpdateResponse(phoneEmployee) {
    this.setState({
      editingPhoneEmployee: {}
    });
    this.state.socket.emit('GET_PHONEEMPLOYEE');
  }

  onCarEmployeesInit(carEmployees) {
    this.setState({
      carEmployees
    });
  }

  onCarEmployeeUpdate(carEmployee) {
    if(carEmployee.id === undefined) {
      this.state.socket.emit('ADD_CAREMPLOYEE', carEmployee);
    } else {
      this.state.socket.emit('UPD_CAREMPLOYEE', carEmployee);
    }
  }

  onCarEmployeeUpdateResponse(carEmployee) {
    this.setState({
      editingCarEmployee: {}
    });
    this.state.socket.emit('GET_CAREMPLOYEE');
  }

  onClickOnPhoneRow(phoneHostname) {
    return (event) => {
      if(phoneHostname !== this.state.editingPhoneEmployee.phoneHostname) {
        this.setState({
          editingPhoneEmployee: this.state.phoneEmployees.find(pe => pe.phoneHostname === phoneHostname) || {phoneHostname},
          editingCarEmployee: {},
          editingRpiHardware: {}
        });
      }
    };
  }

  onClickOnUpdPhone() {
    return (event) => {
      this.onPhoneEmployeeUpdate(this.state.editingPhoneEmployee);
      this.setState({
        editingPhoneEmployee: {}
      });
    };
  }

  onChangePhoneProp(propName) {
    return (event, data) => {
      this.setState({
        editingPhoneEmployee: Object.assign({}, this.state.editingPhoneEmployee, {[propName]: data.value})
      });
    };
  }

  onClickOnCarRow(carImei) {
    return (event) => {
      if(carImei !== this.state.editingCarEmployee.carImei) {
        this.setState({
          editingCarEmployee: this.state.carEmployees.find(ce => ce.carImei === carImei) || {carImei},
          editingPhoneEmployee: {},
          editingRpiHardware: {}
        });
      }
    };
  }

  onClickOnUpdCar() {
    return (event) => {
      this.onCarEmployeeUpdate(this.state.editingCarEmployee);
      this.setState({
        editingCarEmployee: {}
      });
    };
  }

  onChangeCarProp(propName) {
    return (event, data) => {
      this.setState({
        editingCarEmployee: Object.assign({}, this.state.editingCarEmployee, {[propName]: data.value})
      });
    };
  }

  
  handleSort(submodule, clickedColumn) {
    return (evt) => {
      const { column, direction } = this.state.sortState[submodule];
      const nextDirection = direction === 'ascending' ? 'descending' : 'ascending';
      //const sortedData = this.state[submodule].sort((d1,d2) => resolvePath(d1, clickedColumn) < resolvePath(d2, clickedColumn) ? -1 : 1);
      this.setState({
        sortState: Object.assign(this.state.sortState, {
          [submodule]: {
            column: clickedColumn,
            //data: nextDirection === 'ascending' ? sortedData : sortedData.reverse(),
            direction: nextDirection
          }
        }),
        //[submodule]: nextDirection === 'ascending' ? sortedData : sortedData.reverse()
      });
    }
  }

  manageEnterKey(event) {
    if(event.key === 'Enter') {
      if(Object.keys(this.state.editingRpiHardware).length > 0) {
        this.onClickOnUpdRpiHardwareName()(event);
      } else if(Object.keys(this.state.editingPhoneEmployee).length > 0) {
        this.onClickOnUpdPhone()(event);
      } else if(Object.keys(this.state.editingCarEmployee).length > 0) {
        this.onClickOnUpdCar()(event);
      }
    }
  }

  render() {
    const rpiH = Object.keys(this.props.rpiHardware);
    if(this.state.sortState.rpiHardwareNames.column === 'name') {
      rpiH.sort((h1, h2) => {
        const rpiHName1 = this.state.rpiHardwareNames.find(rhn => rhn.hostname === h1);
        const rpiHName2 = this.state.rpiHardwareNames.find(rhn => rhn.hostname === h2);
        if(rpiHName1 === undefined) {
          return 1;
        } else if(rpiHName2 === undefined) {
          return -1;
        } else {
          return rpiHName1.name < rpiHName2.name ? -1 : 1;
        }
      });
    }

    const morePhonesThanRpi = (Object.keys(this.props.phoneHardware).length - Object.keys(this.props.rpiHardware).length > 7);

    const rpiHardwareHTML = (this.state.sortState.rpiHardwareNames.direction === 'descending' ? rpiH.reverse() : rpiH).map((hostname, idx) => {
      const editingThisRpiH = this.state.editingRpiHardware.hostname === hostname;
      const rpiHardwareNameId = this.state.rpiHardwareNames.find(rhn => rhn.hostname === hostname);
      return <Table.Row onKeyPress={this.manageEnterKey.bind(this)} textAlign='center' key={`rpiHardware_${idx}`} onClick={this.onClickOnRpiHardwareNameRow(rpiHardwareNameId ? rpiHardwareNameId.id : undefined, hostname).bind(this)}>
        <Table.Cell>{hostname}</Table.Cell>
        <Table.Cell>
          <Input disabled={!editingThisRpiH} fluid placeholder='Nom personnalisé...' value={editingThisRpiH ? this.state.editingRpiHardware.name : (rpiHardwareNameId ? rpiHardwareNameId.name : "")} onChange={this.onChangeRpiHardwareName.bind(this)} />
        </Table.Cell>
        <Table.Cell>
          <Button data-tooltip="Enregistrer le nom personnalisé" data-position="bottom right" icon color='green' disabled={this.state.editingRpiHardware.hostname !== hostname} onClick={this.onClickOnUpdRpiHardwareName().bind(this)}>
            <Icon name='check' />
          </Button>
        </Table.Cell>
      </Table.Row>;
    });

    const errorMessageRpiHardware = (this.state.errorRpiHardware !== "" ?
      <Message negative>
        <Message.Header>Une erreur est survenue</Message.Header>
        <p>{JSON.stringify(this.state.errorRpiHardware)}</p>
      </Message>
      :
      <div />
    );

    const phoneH = Object.keys(this.props.phoneHardware);
    if(this.state.sortState.phoneEmployees.column === 'simNumber') {
      phoneH.sort((h1, h2) => {
        const simNumber1 = this.props.phoneHardware[h1];
        const simNumber2 = this.props.phoneHardware[h2];
        if(simNumber1 === undefined) {
          return 1;
        } else if(simNumber2 === undefined) {
          return -1;
        } else {
          return simNumber1.simNumber < simNumber2.simNumber ? -1 : 1;
        }
      });
    } else if(this.state.sortState.phoneEmployees.column === 'employeeName') {
      phoneH.sort((h1, h2) => {
        const id1 = this.state.phoneEmployees.find(pe => pe.phoneHostname === h1);
        const id2 = this.state.phoneEmployees.find(pe => pe.phoneHostname === h2);
        if(id1 === undefined) {
          return 1;
        } else if(id2 === undefined) {
          return -1;
        } else {
          const e1 = this.state.employees.find(x => x.id === id1.employeeId);
          const e2 = this.state.employees.find(x => x.id === id2.employeeId);
          if(e1 === undefined) {
            return 1;
          } else if(e2 === undefined) {
            return -1;
          } else {
            return e1.name < e2.name ? -1 : 1;
          }
        }
      });
    } else if(this.state.sortState.phoneEmployees.column === 'hostname') {
      phoneH.sort((h1, h2) => {
        return h1 < h2 ? -1 : 1;
      });
    }
    const phonesHTML = (this.state.sortState.phoneEmployees.direction === 'descending' ? phoneH.reverse() : phoneH).map((phoneHostname, idx) => {
      const {simNumber} = this.props.phoneHardware[phoneHostname];
      return <Table.Row onKeyPress={this.manageEnterKey.bind(this)} textAlign='center' key={`phones_${idx}`} onClick={this.onClickOnPhoneRow(phoneHostname).bind(this)}>
        <Table.Cell>{simNumber} - {phoneHostname}</Table.Cell>
        <Table.Cell>
          <Dropdown
            placeholder='Collaborateur...'
            fluid
            search
            selection
            disabled={this.state.editingPhoneEmployee.phoneHostname !== phoneHostname}
            options={[{key: 0, value: 0, text: 'Non assigné'},...this.state.employees.map(e => ({
              key: e.id,
              value: e.id,
              text: e.name
            }))]}
            loading={this.state.employees.length === 0}
            value={this.state.editingPhoneEmployee.phoneHostname === phoneHostname ? this.state.editingPhoneEmployee.employeeId : (this.state.phoneEmployees.find(pe => pe.phoneHostname === phoneHostname) || {employeeId: 0}).employeeId}
            onClick={this.onClickOnPhoneRow(phoneHostname).bind(this)}
            onChange={this.onChangePhoneProp('employeeId').bind(this)}
          />
        </Table.Cell>
        <Table.Cell>
          <Button data-tooltip="Enregistrer les changements" data-position="bottom right" icon color='green' disabled={this.state.editingPhoneEmployee.phoneHostname !== phoneHostname} onClick={this.onClickOnUpdPhone().bind(this)}>
            <Icon name='check' />
          </Button>
        </Table.Cell>
      </Table.Row>;
    });

    const carH = [...this.state.cars];
    if(this.state.sortState.carEmployees.column === 'status' || this.state.sortState.carEmployees.column === 'lastUpdate') {
      carH.sort((c1, c2) => {
        const v1 = c1[this.state.sortState.carEmployees.column];
        const v2 = c2[this.state.sortState.carEmployees.column];
        return v1 < v2 ? -1 : 1;
      });
    } else if(this.state.sortState.carEmployees.column === 'imei') {
      carH.sort((c1, c2) => {
        const v1 = c1.name+'-'+c1[this.state.sortState.carEmployees.column];
        const v2 = c2.name+'-'+c2[this.state.sortState.carEmployees.column];
        return v1 < v2 ? -1 : 1;
      });
    } else if(this.state.sortState.carEmployees.column === 'licensePlate') {
      carH.sort((c1, c2) => {
        const carEmployeeEntity1 = this.state.carEmployees.find(x => x.carImei === c1.imei);
        const carEmployeeEntity2 = this.state.carEmployees.find(x => x.carImei === c2.imei);
        if(carEmployeeEntity1 === undefined) {
          return 1;
        } else if(carEmployeeEntity2 === undefined) {
          return -1;
        } else {
          return carEmployeeEntity1.licensePlate < carEmployeeEntity1.licensePlate ? -1 : 1;
        }
      });
    } else if(this.state.sortState.carEmployees.column === 'employeeName') {
      carH.sort((c1, c2) => {
        const carEmployeeEntity1 = this.state.carEmployees.find(x => x.carImei === c1.imei);
        const carEmployeeEntity2 = this.state.carEmployees.find(x => x.carImei === c2.imei);
        if(carEmployeeEntity1 === undefined) {
          return 1;
        } else if(carEmployeeEntity2 === undefined) {
          return -1;
        } else {
          const e1 = this.state.employees.find(x => x.id === carEmployeeEntity1.employeeId);
          const e2 = this.state.employees.find(x => x.id === carEmployeeEntity2.employeeId);
          if(e1 === undefined) {
            return 1;
          } else if(e2 === undefined) {
            return -1;
          } else {
            return e1.name < e2.name ? -1 : 1;
          }
        }
      });
    }
    const carsHTML = (this.state.sortState.carEmployees.direction === 'descending' ? carH.reverse() : carH).map((car, idx) => {
      const carEmployeeEntity = this.state.carEmployees.find(x => x.carImei === car.imei);
      return <Table.Row onKeyPress={this.manageEnterKey.bind(this)} textAlign='center' onClick={this.onClickOnCarRow(car.imei).bind(this)} key={`cars_${idx}`}>
        <Table.Cell>{car.name} - {car.imei}</Table.Cell>
        <Table.Cell>
          <Icon name="circle" color={car.status === 'online' ? 'green' : 'red'}/>
        </Table.Cell>
        <Table.Cell>{moment(car.lastUpdate).format('DD/MM/YYYY à HH:mm')}</Table.Cell>
        <Table.Cell>
          {
            this.state.editingCarEmployee.carImei !== car.imei ?
              carEmployeeEntity !== undefined ? carEmployeeEntity.licensePlate : ""
              : <Input fluid placeholder='Immatriculation...' value={this.state.editingCarEmployee.licensePlate} onChange={(evt) => {
                this.setState({
                  editingCarEmployee: Object.assign({}, this.state.editingCarEmployee, {licensePlate: evt.target.value})
                });
              }} />
          }
          </Table.Cell>
        <Table.Cell>
        <Dropdown
            placeholder='Collaborateur...'
            fluid
            search
            selection
            disabled={this.state.editingCarEmployee.carImei !== car.imei}
            options={[{key: 0, value: 0, text: 'Non assigné'},...this.state.employees.map(e => ({
              key: e.id,
              value: e.id,
              text: e.name
            }))]}
            loading={this.state.employees.length === 0}
            value={this.state.editingCarEmployee.carImei === car.imei ? this.state.editingCarEmployee.employeeId : (this.state.carEmployees.find(ce => ce.carImei === car.imei) || {employeeId: 0}).employeeId}
            onChange={this.onChangeCarProp('employeeId').bind(this)}
          />
        </Table.Cell>
        <Table.Cell width={1}> 
          <Button data-tooltip="Enregistrer les changements" data-position="bottom right" icon color='green' disabled={this.state.editingCarEmployee.carImei !== car.imei} onClick={this.onClickOnUpdCar().bind(this)}>
            <Icon name='check' />
          </Button>
        </Table.Cell>
      </Table.Row>;
    });

    
    const rpiHColumn = (
      <Grid.Column width={8}>
        <Divider hidden />
        <Header dividing as='h2'>
          <Image src='/badgeuse-murale.png' />
          <Header.Content>
            Badgeuses murales
            <Loader style={{float: 'right', marginLeft: '10px'}} inline active={this.state.loadingRpiHardware} />
            <Link to="/commande"><Button compact icon='plus' floated='right' color='green' content="Ajouter" size="tiny" style={{marginLeft: '10px'}} /></Link>
            <Header.Subheader>{Object.keys(this.props.rpiHardware).length} Badgeuse(s) connectée(s) {errorMessageRpiHardware}</Header.Subheader>
          </Header.Content>
        </Header>
        <Table singleLine striped selectable sortable id="rpiHardwareTbl">
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell
                width={6}
                sorted={this.state.sortState.rpiHardwareNames.column === 'hostname' ? this.state.sortState.rpiHardwareNames.direction : null}
                onClick={this.handleSort('rpiHardwareNames', 'hostname').bind(this)}>
                Numéro de série
              </Table.HeaderCell>
              <Table.HeaderCell
                width={9}
                sorted={this.state.sortState.rpiHardwareNames.column === 'name' ? this.state.sortState.rpiHardwareNames.direction : null}
                onClick={this.handleSort('rpiHardwareNames', 'name').bind(this)}>
                Appellation personnalisée&nbsp;
                <span data-tooltip="Nommez vos badgeuses pour identifier facilement l'origine des pointages." data-position="bottom right"><Icon name='info circle' size='small'></Icon></span>
              </Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rpiHardwareHTML}
            {rpiHardwareHTML.length === 0 ? <Table.Row><Table.Cell colSpan='15'></Table.Cell></Table.Row>: null}
          </Table.Body>
        </Table>
      </Grid.Column>
    );
    const phoneHColumn = (
      <Grid.Column width={8}>
        <Divider hidden />
        <Header dividing as='h2'>
          <Image src='/badgeuse-nomade.png' avatar />
          <Header.Content>
            Badgeuses nomades
            <Loader style={{float: 'right', marginLeft: '10px'}} inline active={this.state.loadingPhoneHardware} />
            <Link to="/commande"><Button compact icon='plus' floated='right' color='green' content="Ajouter" size="tiny" style={{marginLeft: '10px'}} /></Link>
            <Header.Subheader>{Object.keys(this.props.phoneHardware).length} Badgeuse(s) connectée(s)</Header.Subheader>
          </Header.Content>
        </Header>
        <Table singleLine striped selectable sortable id="phoneHardwareTbl">
          <Table.Header>
            <Table.Row textAlign='center'>
            <Table.HeaderCell
                width={6} 
                sorted={this.state.sortState.phoneEmployees.column === 'hostname' ? this.state.sortState.phoneEmployees.direction : null}
                onClick={this.handleSort('phoneEmployees', 'hostname').bind(this)}>
                Numéro de série
              </Table.HeaderCell>
              <Table.HeaderCell
                width={9}
                sorted={this.state.sortState.phoneEmployees.column === 'employeeName' ? this.state.sortState.phoneEmployees.direction : null}
                onClick={this.handleSort('phoneEmployees', 'employeeName').bind(this)}>
                Assigné à&nbsp;
                <span data-tooltip="L'assignation à un employé vous aide à gérer vos badgeuses nomades.&#10;Elle n'est pas utilisée pour les calculs." data-position="bottom right"><Icon name='info circle' size='small'></Icon></span>
              </Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {phonesHTML}
            {phonesHTML.length === 0 ? <Table.Row><Table.Cell colSpan='15'></Table.Cell></Table.Row>: null}
          </Table.Body>
        </Table>
      </Grid.Column>
    );

    return (
        <Grid padded stackable>
          <Grid.Row className="firstRow">
            <Header dividing as='h1'>
              <Icon name='hdd' />
                <Header.Content>
                  Matériels
                  <Loader style={{float: 'right', marginLeft: '10px'}} inline active={this.state.loadingRpiHardware} />
                </Header.Content>
            </Header>
          </Grid.Row>
          <Grid.Row className="firstRow">
            {
              morePhonesThanRpi ? phoneHColumn : rpiHColumn
            }
            {
              morePhonesThanRpi ? rpiHColumn : phoneHColumn
            }
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Divider hidden />
          <Grid.Row className="firstRow">
              <Header dividing as='h2'>
                <Image src='/traceur-gps.png' avatar />
                <Header.Content>
                  Traceurs GPS Véhicules
                  <Loader style={{float: 'right', marginLeft: '10px'}} inline active={this.state.loadingGpsHardware} />
                  <Link to="/commande"><Button compact icon='plus' floated='right' color='green' content="Ajouter" size="tiny" style={{marginLeft: '10px'}} /></Link>
                  <Header.Subheader>{this.state.cars.length} Traceur(s) GPS connecté(s)</Header.Subheader>
                </Header.Content>
              </Header>
          </Grid.Row>
          <Grid.Row>
            <Table singleLine striped selectable sortable id="gpsHardwareTbl">
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell
                    width={4}
                    sorted={this.state.sortState.carEmployees.column === 'imei' ? this.state.sortState.carEmployees.direction : null}
                    onClick={this.handleSort('carEmployees', 'imei').bind(this)}>
                    Numéro de série
                  </Table.HeaderCell>
                  <Table.HeaderCell 
                    width={1}
                    sorted={this.state.sortState.carEmployees.column === 'status' ? this.state.sortState.carEmployees.direction : null}
                    onClick={this.handleSort('carEmployees', 'status').bind(this)}>
                    Statut
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    sorted={this.state.sortState.carEmployees.column === 'lastUpdate' ? this.state.sortState.carEmployees.direction : null}
                    onClick={this.handleSort('carEmployees', 'lastUpdate').bind(this)}>
                    Dernière mise à jour
                  </Table.HeaderCell>
                  <Table.HeaderCell 
                    width={4}
                    sorted={this.state.sortState.carEmployees.column === 'licensePlate' ? this.state.sortState.carEmployees.direction : null}
                    onClick={this.handleSort('carEmployees', 'licensePlate').bind(this)}>
                    Immatriculation
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    sorted={this.state.sortState.carEmployees.column === 'employeeName' ? this.state.sortState.carEmployees.direction : null}
                    onClick={this.handleSort('carEmployees', 'employeeName').bind(this)}>
                    Assigné à&nbsp;
                    <span data-tooltip="L'assignation à un employé vous aide à gérer vos traceurs GPS.&#10;Elle n'est pas utilisée pour les calculs." data-position="bottom right"><Icon name='info circle' size='small'></Icon></span>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {carsHTML}
                {carsHTML.length === 0 ? <Table.Row><Table.Cell colSpan='15'></Table.Cell></Table.Row>: null}
              </Table.Body>
            </Table>
          </Grid.Row>
          <Divider hidden />
          <Divider hidden />
        </Grid>
    );
  }
}

export default Locations;
