import React from 'react';
import {
    Button,
    Icon,
    Label,
    Image,
} from "semantic-ui-react";

const getTourStep = (title, content, selector = 'body', placement = 'center', clickToLeave = false) => {
    return {
      target: selector,
      content,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      showSkipButton: true,
      hideFooter: clickToLeave,
      placement,
      spotlightClicks: clickToLeave,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title
    };
};

const CURRENT_CHANGELOG_VERSION = 5;

const CHANGELOG_PAGES = [
  <div>
    <h3>Nouvelle mise à jour au 01/05/2023 :</h3><br />
    - <b>Onglets Pointages intégralement repensé</b> : refonte et uniformisation des différents masques d'affichage, meilleur export Excel, possibilité de retenir vos filtres.<br />
    - <b>Tableau de Bord enrichi</b> : Visualisez également les pointages géolocalisés.<br />
    - <b>Nouveaux compteurs personnalisés</b> : Gérez vos absences, heures supplémentaires, et heures de nuit.<br />
    - <b>Rapports automatiques généralisés</b> : Envoyez automatiquement leurs feuilles d'heures par email à vos employés.<br />
    - <b>Améliorations visuelles</b> : Meilleure ergonomie générale, composants plus interactifs.<br />
    - <b>Améliorations techniques</b> : Diverses corrections et améliorations.<br /><br />
    <b>IMPORTANT :</b> Nous vous invitons à vous déconnecter / reconnecter de votre session afin que les changements prennent pleinement effet.
  </div>,
  <div>
    Votre portail Orane s'est enrichi de nouvelles fonctionnalités au 26/09/2022 :<br /><br />
    - <b>Droits  des managers</b> : les managers disposant des droits en lecture et modification peuvent désormais créer et ajouter des employés à leur équipe.<br />
    - <b>Ajoutez les matricules de vos employés</b> : Une nouvelle colonne matricule a fait son apparition dans le menu "collaborateurs", vous la retrouverez dans tous vos exports Excel pour interfacer facilement Orane avec votre logiciel de paie.<br />
    - <b>Taux de présence</b> : votre tableau de bord affiche désormais le taux de présence du jour.<br />
    - <b>Suivi des déplacements</b> : retrouvez plus d'informations utiles lors de la consultation des déplacements des véhicules et des collaborateurs.<br />
    - <b>Origine des pointages</b> : l'icone <Icon name='location arrow' size='mini' circular /> vous donne désormais plus d'informations sur l'origine des pointages enregistrés.<br />
    - <b>Divers</b> : optimisations graphiques et ajout de statistiques diverses.<br /><br />
    <b>IMPORTANT :</b> Nous vous invitons à vous déconnecter / reconnecter de votre session afin que les changements prennent pleinement effet.
  </div>,
  <div>
    Votre portail Orane s'est enrichi de nouvelles fonctionnalités au 11/08/2022 :<br /><br />
    - <b>Une toute nouvelle page profil</b>, où vos factures sont consultables au fur et à mesure de leur émission et où vous pouvez modifier votre mot de passe.<br />
    - <b>Recherche multiple</b>, vous pouvez désormais sélectionner plusieurs salariés dans l'onglet Pointages.<br />
    - <b>Mot de passe oublié</b>, cette fonctionnalité est désormais accessible pour les managers sur la page de login.<br />
    - <b>Totaux à la semaine</b>, l'onglet Pointages vous présente désormais le détail du réel à la semaine.<br />
    - <b>Divers</b>, multiples améliorations au niveau de l'ergonomie et des performances.<br /><br />
    <b>IMPORTANT :</b> Nous vous invitons à vous déconnecter / reconnecter de votre session afin que les changements prennent pleinement effet.
  </div>,
  <div>
    Votre portail Orane s'est enrichi de nouvelles fonctionnalités au 15/07/2022 :<br /><br />
    - <b>Affichage des heures au centième ou à la minute</b>, rendez-vous dans les paramètres pour choisir l'option qui vous convient.<br />
    - <b>Gestion des Travailleurs de nuit</b>, rendez-vous dans les paramètres pour définir l'heure de lancement des calculs de fin de journée.<br />
    - <b>Gestion d'équipes et Managers</b>, rendez-vous dans l'onglet "Collaborateurs" pour créer vos équipes et définir des managers.<br /><br />
    <b>IMPORTANT :</b> Nous vous invitons à vous déconnecter / reconnecter de votre session afin que les changements prennent pleinement effet.
  </div>
];

const AFTERMODAL_STEPS = (isAdmin, isManagerWithWriteAccess) => [
    isAdmin || isManagerWithWriteAccess ? getTourStep(
        'Notification',
        'Nouveauté : commandez directement vos badgeuses supplémentaires en cliquant ici.',
        '#orderProducts',
        'bottom'
    ) : null
].filter(x => x !== null);

export {
    CURRENT_CHANGELOG_VERSION,
    CHANGELOG_PAGES,
    AFTERMODAL_STEPS
};