import React from 'react';

const ANNOUNCEMENTS_ENABLED = false;

const CURRENT_ANNOUNCEMENT_VERSION = 2;

const CURRENT_ANNOUNCEMENT_HEADER = "Information importante : congés annuels";
const CURRENT_ANNOUNCEMENT = (
  <p>
    Nous vous informons que la société Timefast fermera ses portes pour congés annuels du 14 décembre 2023 au 18 décembre 2023 inclus.<br />
    Un service après-vente minimum sera assuré par email sur l'adresse <a href="mailto:sav@timefast.fr">sav@timefast.fr</a>.<br />
    Tous les membres de la société vous souhaitent de bonnes fêtes de fin d'année.
  </p>
);

export {
  ANNOUNCEMENTS_ENABLED,
  CURRENT_ANNOUNCEMENT_VERSION,
  CURRENT_ANNOUNCEMENT_HEADER,
  CURRENT_ANNOUNCEMENT
};